import React, { useState, useEffect } from "react";
import moment from "moment";
import "./NotificationItem.scss";
import { Close, ExpandMore, ExpandLess } from "@material-ui/icons";
import NotificationDoc from "./NotificationDoc";
import Token from '../../../../core-components/misc/Token'
import { displayPortalAccType, getPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import { withRemote } from "@threeskye/global";


const NotificationItem = (props) => {

	const [expanded, setExpanded] = useState(false)
	const [account, setAccount] = useState({ managementBasis: "", name: "", id: "" })
	const [docs, setDocs] = useState([])

	const { onClick, expandable, deleteNotification, availableAccounts, remote } = props;
	const { content, createdDate, unread, attachments, accounts, heading } = props.notification;
	const parsedDate = moment(createdDate);
	const displayDate = parsedDate && parsedDate.fromNow ? parsedDate.fromNow() : "Time not available.";
	const draft = JSON.parse(content)
	const text = draft.blocks.map((block) => { return block.text })

	const checkForBankAccount = (string) => {
		if (string.startsWith("Bank Account:")) {
			return "Bank Account"
		} else return string
	}

	const displayText = checkForBankAccount(text.join(" "));

	let classNames = "notification-item";
	// Conditions for classes
	if (expandable) classNames += " notification-expandable";
	if (expanded) classNames += " notification-expanded";
	if (unread) classNames += " notification-unread";

	useEffect(() => {
		let recipientAccount = availableAccounts.find((acc) => {
			return acc.id == accounts[0]
		})
		setAccount(recipientAccount ? recipientAccount : { managementBasis: "", name: "", id: "" })
	
		let token = remote.createTokenHeader().headers["X-3Skye-Session"];
		setDocs(attachments.map(attachment => {
			return {
				filename: attachment.filename,
				// if this onClick changes, also change ViewNotificationModal.js line 20
				onClick: () => window.open(`/api/direct/${token}/modules/crm/events/${attachment.eventId}/attachments/${attachment.id}/${attachment.filename}`)
				
			};
		}));
	}, []) 

	return (
		<div className="notification-item" onClick={() => onClick(props.notification, account)}>
			<div className="notification-content-wrapper">
				<div className="notification-li-header">
					{account && account.id && <Token small>{displayPortalAccType(getPortalAccType(account))}</Token>}
					<div className="notification-li-account">{account && account.name}</div>
				</div>
				<div className="notification-content">
				{heading && <p className="notification-heading">{heading !== "Bulk Sharing" && heading}</p>}
				<span className={heading && heading !== "Bulk Sharing" ? "notification-text" : "notification-text notification-heading"}>{displayText}</span>
				</div>
				<div className="notification-item-footer">
					<div className="notification-date">{displayDate}</div>
					{expandable &&
						(expanded ? (
							<div
								className="notification-expand-button"
								onClick={(e) => {
									e.stopPropagation();
									setExpanded(!expanded);
								}}
							>
								See Less <ExpandLess />
							</div>
						) : (
							<div
								className="notification-expand-button"
								onClick={(e) => {
									e.stopPropagation();
									setExpanded(!expanded);
								}}
							>
								See More <ExpandMore />
							</div>
						))}
				</div>
			</div>
			<Close className="notification-delete" onClick={deleteNotification} />
			{expanded && (
				<div className="notification-expanded-content">
					<div className="notification-expand-content">
						{docs.length > 0 && docs.map((doc, index) => {
							return <NotificationDoc key={index} document={doc} />
						})}
					</div>
				</div>
			)}
		</div>
	);
}

export default withRemote(NotificationItem);