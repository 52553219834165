import React, { Component } from "react";
import ConfirmEmail from "./ConfirmEmail";
import ConfirmCode from "../../../../core-components/layouts/ConfirmCode";
import SetPasswordPage from "../SetPasswordPage";
import LoginPage from "../LoginPage";
import CenteredLogo from "../CenteredLogo";

class ForgotPasswordPage extends Component {
	state = {
		email: null,
		key: null,
		emailIsConfirmed: false,
		type: null,
		code: null,
		codeIsConfirmed: false,
		password: null,
		passwordIsSet: false,
	};
	render() {
		const { login } = this.props;
		const { emailIsConfirmed, email, key, codeIsConfirmed, type, code, passwordIsSet, password } = this.state;
		const showLoginPage = emailIsConfirmed && codeIsConfirmed && passwordIsSet;
		if (showLoginPage) {
			return <LoginPage source="forgotPassword" login={login} showPostLogin={true} email={email} password={password} />;
		}
		return (
			<CenteredLogo>
				{!emailIsConfirmed ? (
					<ConfirmEmail onConfirm={(email, key, type) => this.setState({ emailIsConfirmed: true, email, key, type })} />
				) : !codeIsConfirmed ? (
					<ConfirmCode source="forgotPassword" username={email} respKey={key} type={type} onConfirm={(code) => this.setState({ codeIsConfirmed: true, code })} />
				) : !passwordIsSet ? (
					<SetPasswordPage source="forgotPassword" username={email} respKey={key} code={code} onSetPassword={(password) => this.setState({ passwordIsSet: true, password })} />
				) : (
					<LoginPage source="forgotPassword" login={login} showPostLogin={true} email={email} password={password} />
				)}
			</CenteredLogo>
		);
	}
}

export default ForgotPasswordPage;
