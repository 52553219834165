import React, { Component } from "react";
import moment from "moment";
import SaveAlt from "@material-ui/icons/SaveAlt";
import DocumentTag from "./DocumentTag";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";
import ErrorMessage from "../../../../core-components/layouts/ErrorMessage";
import GridTable from "../../../../core-components/layouts/GridTable";
import GridTableRow from "../../../../core-components/layouts/GridTableRow";

class DocumentsTable extends Component {
	state = { documentsToDisplay: null };
	constructor(props) {
		super(props);
		this.updateDocumentsToDisplay = this.updateDocumentsToDisplay.bind(this);
	}

	componentDidMount() {
		this.updateDocumentsToDisplay();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters || prevProps.documents !== this.props.documents) {
			this.updateDocumentsToDisplay();
		}
	}

	updateDocumentsToDisplay() {
		const { documents } = this.props;
		if (documents) {
			const { from, to, search, tag } = this.props.filters || {};
			let documentsToDisplay = documents;
			if (search) {
				documentsToDisplay = documentsToDisplay.filter((doc) => doc && doc.filename && doc.filename.toLowerCase().includes(search.toLowerCase()));
			}
			if (tag){
				documentsToDisplay = documentsToDisplay.filter((doc) => doc && doc.tags && doc.tags.includes(tag.value));
			}
			if (from) {
				documentsToDisplay = documentsToDisplay.filter((doc) => {
					if (doc && doc.uploadedAt) {
						const date = moment(doc.uploadedAt);
						if (date) {
							return date.isAfter(moment(from));
						}
					}
					return false;
				});
			}
			if (to) {
				documentsToDisplay = documentsToDisplay.filter((doc) => {
					if (doc && doc.uploadedAt) {
						const date = moment(doc.uploadedAt);
						if (date) {
							return date.isBefore(moment(to).add(1, "day"));
						}
					}
					return false;
				});
			}
			this.setState({ documentsToDisplay });
		}
	}

	render() {
		const { documentsToDisplay } = this.state;
		return (
			<> 
				{/* Grid Table column widths use css grid values. */}
				{documentsToDisplay && documentsToDisplay.length > 0 ? (
					<GridTable className="documents-table" headers={["Name", "Upload Date", " "]} columnWidths="1fr 160px 30px">
						{documentsToDisplay.map((document, index) => {
							return (<GridTableRow
								onClick={() => document.onClick()}
								key={index}
								className={document.tags && document.tags.length > 0 && document.tags.map ? "" : "no-tags"}
								columns={[
									document.tags && document.tags.length > 0 && document.tags.map ? [document.filename, ...document.tags.map((tag) => <DocumentTag tag={tag} />)] : document.filename,
									moment(document.uploadedAt).format("Do MMM YYYY"),
									document.tags && document.tags.length > 0 && document.tags.map ? document.tags.map((tag) => <DocumentTag tag={tag} />) : "",
									<SaveAlt />,
								]}
							/>)
						})}
					</GridTable>
				) : (
					<ErrorMessage infoIcon>No documents to display.</ErrorMessage>
				)}
			</>
		);
	}
}

export default withFilters(DocumentsTable);
