import React from "react";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import Input from '../../core-components/inputs/Input';
import PhoneNumberInput from '../../core-components/inputs/PhoneNumberInput'
import AddyLocationField from '../../core-components/inputs/AddyLocationField'
import './Onboarding.scss'


const Contact = (props) => {
    const { onBoardingState, setOnBoardingState, section, parsedData } = props
    const { email, mobile, physicalAddress, postalAddress} = onBoardingState
    const label = section && section.displayLabel ? section.label : null
    const subLabel = section && section.displaySubLabel ? section.subLabel : null
    
    function onNzLocationChange(e, string) {
        if (e == null) return;
        const { text } = e;
        if (string === "physical") setOnBoardingState({ onBoardingState: { ...onBoardingState, physicalAddress: text } })
        else if (string === "portal") setOnBoardingState({ onBoardingState: { ...onBoardingState, postalAddress: text } })
    }

    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel && <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                    <Input
                        label={<><span className="form-label">Email*</span><i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 800 }}>check</i></>}
                        labelStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
                        type="email" name="email"
                        value={email || parsedData.email}
                        placeholder="Email"
                        onChange={() => null}
                        disabled
                        iconEnd={<i className="material-icons lock-icon">lock</i>}
                    />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <span className={`form-label`}>Mobile*</span>
                                <i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 900 }}>check</i>
                            </div>
                        </div>
                        <PhoneNumberInput
                            type="number"
                            name="phone-number"
                            placeholderIDD="IDD"
                            placeholderValue="Enter phone number"
                            invalidNumberMessage=""
                            noPrefixMessage=""
                            onChange={() => null}
                            value={mobile}
                            placeholderExt="Ext"
                            extraContainerClassNme="onbording-phone"
                            publicRoute
                            phoneErrorMessage=""
                            disabled
                            iconEnd={<i className="material-icons lock-icon">lock</i>}
                        />
                    </label>
                </Col>
                <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Physical Address</span>
                        </div>
                        <AddyLocationField
                            value={{ text: physicalAddress, fullAddress: physicalAddress }}
                            name={"physical-address"}
                            placeholder={'Search an Address'}
                            onUpdate={(e) => onNzLocationChange(e, "physical")}
                            isClearable
                            className="onbording-address-field"
                            publicRoute
                        />
                    </label>
                </Col>
                <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Postal Address</span>
                        </div>
                        <AddyLocationField
                            value={{ text: postalAddress, fullAddress: postalAddress }}
                            name={"portal-address"}
                            placeholder={'Search an Address'}
                            onUpdate={(e) => onNzLocationChange(e, "portal")}
                            isClearable
                            className="onbording-address-field"
                            publicRoute
                        />
                    </label>

                </Col>
            </Row>
        </>
    )
}

export default Contact