import React, { Component } from "react";
import "./Loading.scss";
import LoadingIcon from "@threeskye/loading-icon/dist/LoadingIcon";

class Loading extends Component {
	render() {
		const { centered, size, containerClassName } = this.props;

		let className = "loading-container";
		if (centered) {
			className += " centered";
		}
		if (containerClassName) {
			className += " " + containerClassName;
		}

		return (
			<div className={className}>
				<LoadingIcon size={size || 50} />
			</div>
		);
	}
}

export default Loading;
