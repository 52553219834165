import React, { useState } from "react";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import { format, isValid, parseISO } from 'date-fns'
import Input from '../../core-components/inputs/Input';
import SingleDatePicker from "../../core-components/inputs/SingleDatePicker";
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import FormErrorMessage from '../../core-components/layouts/FormErrorMessage'
import CountryTokenField from '../../core-components/inputs/CountryTokenField';
import './Onboarding.scss'


const Identification = (props) => {
    const { onBoardingState, setOnBoardingState, section, parsedData } = props
    const { passportNumber, passportCountryOfIssue, passportExpiracyDate, driversLicenceNumber, driversLicenceCountryOfIssue, driversLicenceExpiracyDate } = onBoardingState
    const label = section && section.displayLabel ? section.label : null
    const subLabel = section && section.displaySubLabel ? section.subLabel : null

    const [invalidDate, setInvalidDate] = useState(false)
    const [dateErrorMessage, setDateErrorMessage] = useState("")
    const [invalidDate2, setInvalidDate2] = useState(false)
    const [dateErrorMessage2, setDateErrorMessage2] = useState("")
    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel && <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <Input label="Passport Number*" name="passport" value={passportNumber} placeholder="Passport Number" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, passportNumber: e } })} />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <CountryTokenField
                        value={passportCountryOfIssue}
                        isMulti={false}
                        label="Passport Country of Issue*"
                        onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, passportCountryOfIssue: option } })}
                        publicRoute
                    />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Passport Expiracy Date*</span>
                        </div>
                        <SingleDatePicker
                            value={onBoardingState.passportExpiracyDate}
                            placeholder={passportExpiracyDate && passportExpiracyDate.length > 0 ? "" : "31/01/1970"}
                            format="dd/MM/yyyy"
                            onChange={(newValue) => {
                                setInvalidDate(false)
                                const dateArray = newValue.split("/").reverse()
                                const dateString = dateArray.join("-")

                                const valid = isValid(parseISO(dateString))
                                if (valid) {
                                    const date = new Date(dateString)
                                    const formattedFromDate = format(date, "dd/MM/yyyy")
                                    setOnBoardingState({ onBoardingState: { ...onBoardingState, passportExpiracyDate: formattedFromDate } })
                                    setDateErrorMessage("")
                                    setInvalidDate(false)
                                } else {
                                    setInvalidDate(true)
                                    setDateErrorMessage("Invalid Date")
                                    setOnBoardingState({ onBoardingState: { ...onBoardingState, passportExpiracyDate: null } })
                                }
                            }}
                            autoSubmit
                            noCalendar={true}
                            inputClassName={invalidDate ? "invalid-input" : ""}
                        />
                        <div className="label-container">
                            {invalidDate && <span className="form-error"><FormErrorMessage>{dateErrorMessage}</FormErrorMessage></span>}
                        </div>
                    </label>
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <Input label="Drivers Licence Number*" name="drivers-Licence" value={driversLicenceNumber} placeholder="Drivers Licence Number" onChange={(e) => setOnBoardingState({ onBoardingState: { ...driversLicenceNumber, passportNumber: e } })} />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <CountryTokenField
                        value={driversLicenceCountryOfIssue}
                        isMulti={false}
                        label="Drivers Licence Country of Issue*"
                        onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, driversLicenceCountryOfIssue: option } })}
                        publicRoute
                    />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Drivers Licence Expiracy Date*</span>
                        </div>
                        <SingleDatePicker
                            value={onBoardingState.driversLicenceExpiracyDate}
                            placeholder={driversLicenceExpiracyDate && driversLicenceExpiracyDate.length > 0 ? "" : "31/01/1970"}
                            format="dd/MM/yyyy"
                            onChange={(newValue) => {
                                setInvalidDate2(false)
                                const dateArray = newValue.split("/").reverse()
                                const dateString = dateArray.join("-")

                                const valid = isValid(parseISO(dateString))
                                if (valid) {
                                    const date = new Date(dateString)
                                    const formattedFromDate = format(date, "dd/MM/yyyy")
                                    setOnBoardingState({ onBoardingState: { ...onBoardingState, driversLicenceExpiracyDate: formattedFromDate } })
                                    setDateErrorMessage2("")
                                    setInvalidDate2(false)
                                } else {
                                    setInvalidDate2(true)
                                    setDateErrorMessage2("Invalid Date")
                                    setOnBoardingState({ onBoardingState: { ...onBoardingState, driversLicenceExpiracyDate: null } })
                                }
                            }}
                            autoSubmit
                            noCalendar={true}
                            inputClassName={invalidDate2 ? "invalid-input" : ""}
                        />
                        <div className="label-container">
                            {invalidDate2 && <span className="form-error"><FormErrorMessage>{dateErrorMessage2}</FormErrorMessage></span>}
                        </div>
                    </label>
                </Col>
            </Row>
        </>
    )
}

export default Identification