import React, { Component } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import NavigatorAssetTable from "../../../../core-components/layouts/NavigatorAssetTable";
import CardHeader from "../../../../core-components/card/CardHeader";
import PropTypes from "prop-types";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";

class NavigatorAssetCard extends Component {
	constructor(props) {
		super(props);
		this.setGetCSVFunc = this.setGetCSVFunc.bind(this);
		this.generateCSV = this.generateCSV.bind(this);

		props.setGetCSVFunc(this.generateCSV);
	}

	setGetCSVFunc(getCSVFunc) {
		this.getTableAsCSV = getCSVFunc;
	}

	generateCSV() {
		if (this.getTableAsCSV) {
			const { fund } = this.props;
			return `${this.getFundName(fund)}\n${this.getTableAsCSV()}`;
		}
	}

	render() {
		const { fund } = this.props;
		const name = this.getFundName(fund);
		return (
			<CardContainer xs={12}>
				<Card>
					<CardHeader>
						<div >
							<a style={{ display: "block", position: "relative", top: "-100px", visibility: "hidden"}} name={"fund-" + fund.name.split(" ").join("-")} />
							<h3>{name}</h3>
						</div>
					</CardHeader>
					<NavigatorAssetTable fund={fund} setGetCSVFunc={this.setGetCSVFunc} />
				</Card>
			</CardContainer>
		);
	}

	getFundName(fund) {
		const mapping = this.fundsNameMap.find((map) => map.name === fund.name);
		if (mapping === undefined) {
			return fund.name;
		}
		return mapping.display;
	}

	fundsNameMap = [
		{ name: "Hobson Wealth NZ Fixed Interest Fund", display: "NZ Fixed Interest" },
		{ name: "Hobson Wealth NZ Equity Fund", display: "NZ Equities" },
		{ name: "Hobson Wealth International Growth Fund", display: "International Growth" },
	];
}

NavigatorAssetCard.propTypes = {
	fund: PropTypes.object.isRequired,
};

export default withAccountInfo(NavigatorAssetCard);
