import React, { Component } from "react";
import RangeBar from "../graphs/RangeBar";
import "./AssetTableMobile.scss";
import Formatter from "../functions/Formatter";
import { withAccountInfo } from "../contexts/AccountsContext";

const AssetTableMobile = ({ name, min, max, value, percentPortfolio, navigator, portalAccountType }) => {

	return (
		<div className="asset-table-mobile" onClick={() => {
			portalAccountType === "NAVIGATOR" ?  window.location.href = "#fund-" + name.split(" ").join("-") :  window.location.href = "#class-" + name}}>
			<div className="asset-table-mobile__top">
				<div className="asset-table-mobile__name">{name}</div>
				<div className="asset-table-mobile__value">{Formatter.asMoney(value)}</div>
			</div>
			<div className="asset-table-mobile__bottom">
				<div className="asset-table-mobile__percentage">{Formatter.asPercentage(percentPortfolio, 2)}</div>
				<div className="asset-table-mobile__bar">
					<RangeBar navigator={navigator} extent={100} value={percentPortfolio} min={min} max={max} />
				</div>
			</div>
		</div>
	);
}

export default withAccountInfo(AssetTableMobile);
