// import { getDifference, showableChanges } from "./ChangeListBindables";
// import { getRequirements } from './ChangeListBindables';
import _ from "lodash";
import { withRemote } from "@threeskye/global"

const generalIgnorableKeys = ["id"];
const ignorableKeysByLabel = {
	"account.addresses": ["longitude", "latitude"],
	"clientSummary.addresses": ["longitude", "latitude"],
	"nonPersonClient.addresses": ["longitude", "latitude"],
	"account.bankAccounts": ["international", "country.key"],
	"accountPeripherals.debitAuthorities": ["type"],
	"accountPeripherals.creditApprovals": ["type"],
	"bankAccount.country": ["key"]
}
// const updateList = []

// const update = {
// 	account: {
// 		addresses: [],
// 		interests: [],
// 		dependants: [],
// 		phone: [],
// 		email: [],
// 		taxNumbers: [],
// 		regularDeposits: [],
// 		regularWithdrawals: [],
// 		bankAccounts: [],
// 		debitAuthorities: [],
// 		creditApprovals: [],
// 	},
// 	accountPeripherals: {
// 		associatedClients: {},
// 	},
// 	unstructured: {},
// 	attachments: [],
// 	comments: "",
// }

export function sectionFor(field) {
	// eslint-disable-next-line
	switch (field[0]) {
		case "client":
			// eslint-disable-next-line
			switch (field[1]) {
				case "employer":
				case "driversLicense":
				case "passport":
				case "birthDate":
				case "occupation":
				case "salutation":
					return "General";
				case "phoneNumbers":
				case "emailAddresses":
					return "Contact";
				case "dependants":
					return "Dependants";
				case "interests":
					return "Interests";
			}
		// eslint-disable-next-line
		case "clientSummary":
			// eslint-disable-next-line
			switch (field[1]) {
				case "givenName":
				case "preferredName":
				case "middleNames":
				case "familyName":
				case "title":
					return "General";
				case "addresses":
					return "Addresses";
			}
		// eslint-disable-next-line
		case "account":
			// eslint-disable-next-line
			switch (field[1]) {
				case "email":
				case "phone":
				case "salutation":
					return "Contact Details";
				case "reviewIntervalMonths":
				case "lastReview":
					return "Review";
				case "addresses":
					return "Account Addresses";
			}
	}
}

export const serviceLevels = [
	{
		"id": 4,
		"name": "Portal"
	},
	{
		"id": 1,
		"name": "Retail"
	},
	{
		"id": 3,
		"name": "Unactivated"
	},
	{
		"id": 2,
		"name": "Wholesale"
	}
]

export const mockSchema = [
	{
		"title": "AccountInfo",
		"fields": [
			{
				"fieldType": "CORE",
				"title": "Account Name",
				"field": "account.name",
				"type": "accountName",
				"validation": "AccountName",
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "CORE",
				"title": "Account Type",
				"field": "accountType",
				"type": "accountType",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "CORE",
				"title": "Service",
				"field": "managementBasis",
				"type": "managementBasis",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "CORE",
				"title": "Sub Service",
				"field": "subService",
				"type": "subService",
				"validation": null,
				"defaultValue": "7",
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Type of Custody",
				"field": "CustodyType",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					" ",
					"Full Custody",
					"Safe-keeping",
					"Margin Lending",
					"Stockfox"
				]
			},
			{
				"fieldType": "CORE",
				"title": "Classification",
				"field": "serviceLevel",
				"type": "serviceLevel",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			}
		],
		"linkToOnboarding": true
	},
	{
		"title": "Contact",
		"fields": [
			{
				"fieldType": "CORE",
				"title": "Address",
				"field": "addresses",
				"type": "addresses",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": true,
				"groupedFields": [],
				"possibleLabels": [
					"Physical",
					"Postal",
					"Other"
				]
			},
			{
				"fieldType": "CORE",
				"title": "Phone Number",
				"field": "phone",
				"type": "phones",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": true,
				"groupedFields": [],
				"possibleLabels": [
					"Work",
					"Mobile",
					"Home"
				]
			},
			{
				"fieldType": "CORE",
				"title": "Email",
				"field": "email",
				"type": "emailAddresses",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": [
					"Personal",
					"Work",
					"Correspondence"
				]
			}
		],
		"linkToOnboarding": false
	},
	{
		"title": "Tax",
		"fields": [
			{
				"fieldType": "DYNAMIC",
				"title": "Tax Type",
				"field": "TaxType",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					"NZ Resident",
					"Non-Resident"
				]
			},
			{
				"fieldType": "CORE",
				"title": "Tax Identification Number",
				"field": "account.taxNumbers",
				"type": "taxNumbers",
				"validation": "NZTax",
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": true,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Tax Percentage",
				"field": "TaxPercentage",
				"type": "list",
				"validation": "hideIf(TaxType==Non-Resident)",
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					"0",
					"10.5",
					"17.5",
					"28",
					"30",
					"33",
					"39",
					"45"
				]
			},
			{
				"fieldType": "DYNAMIC",
				"title": "PIR",
				"field": "PIETaxPercentage",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					"0",
					"10.5",
					"17.5",
					"28"
				]
			},
			{
				"fieldType": "DYNAMIC",
				"title": "FIF Optionality",
				"field": "FIF",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					" ",
					"Foreign Investment Funds Reporting (FIF)",
					"De Minimis"
				]
			},
			{
				"fieldType": "DYNAMIC",
				"title": "FIF Reporting Categorisation",
				"field": "FIFReportingCat",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					" ",
					"Not Exempt - FDR",
					"Not Exempt – Both"
				]
			}
		],
		"linkToOnboarding": false
	},
	{
		"title": "Contract Notes",
		"fields": [

			{
				"fieldType": "DYNAMIC",
				"title": "Contract Note Address",
				"field": "ContractNoteAddresses",
				"type": "address",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": []
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Contract Note Email",
				"field": "ContractNoteEmails",
				"type": "emailAddress",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": []
			}
		],
		"linkToOnboarding": false
	},
	{
		"title": "Other",
		"fields": [
			{
				"fieldType": "DYNAMIC",
				"title": "Distribution Preference",
				"field": "DefaultDistributionMethodNZD",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					" ",
					"Cash",
					"Reinvest"
				]
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Income Accrual Method",
				"field": "IncomeAccrualMethod",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					" ",
					"Cash",
					"Accrual: Straight Line Basis",
					"Accrual: Mark to Market Basis",
					"Yield to Maturity Basis"
				]
			}
		],
		"linkToOnboarding": false
	}
]

export const personalInformationMockSchema = [
	{
		"title": "Personal",
		"fields": [
			{
				"fieldType": "CORE",
				"title": "Given Name",
				"field": "givenName",
				"type": "givenName",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "CORE",
				"title": "Middle Names",
				"field": "middleNames",
				"type": "middleNames",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "CORE",
				"title": "Family Name",
				"field": "familyName",
				"type": "familyName",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "CORE",
				"title": "Birthdate",
				"field": "birthDate",
				"type": "birthDate",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"possibleLabels": null
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Occupation",
				"field": "Occupation",
				"type": "string",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": []
			},
		],
		"linkToOnboarding": false
	},
	{
		"title": "Contact",
		"fields": [
			{
				"fieldType": "CORE",
				"title": "Phone Number",
				"field": "phones",
				"type": "phones",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": true,
				"groupedFields": [],
				"possibleLabels": [
					"Work",
					"Mobile",
					"Home"
				]
			},
			{
				"fieldType": "CORE",
				"title": "Email",
				"field": "emailAddresses",
				"type": "emailAddresses",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": true,
				"groupedFields": [],
				"possibleLabels": [
					"Personal",
					"Work",
					"Correspondence"
				]
			},
			{
				"fieldType": "CORE",
				"title": "Address",
				"field": "addresses",
				"type": "addresses",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": true,
				"groupedFields": [],
				"possibleLabels": [
					"Physical",
					"Postal",
					"Other"
				]
			},
		],
		"linkToOnboarding": false
	},
	
	{
		"title": "Tax",
		"fields": [
			{
				"fieldType": "DYNAMIC",
				"title": "Tax Type",
				"field": "TaxType",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					"NZ Resident",
					"Non-Resident"
				]
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Tax Rate",
				"field": "TaxPercentage",
				"type": "list",
				"validation": "hideIf(TaxType==Non-Resident)",
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					"0",
					"10.5",
					"17.5",
					"30",
					"33",
					"39",
					"45"
				]
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Prescribed Investor Rate",
				"field": "ClientPIR",
				"type": "list",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": [
					"0",
					"10.5",
					"17.5",
					"28"
				]
			},
			{
				"fieldType": "DYNAMIC",
				"title": "Individual is a Prescribed Person",
				"field": "ClientPP",
				"type": "boolean",
				"validation": null,
				"defaultValue": null,
				"additionalProcessing": null,
				"searchable": false,
				"groupedFields": [],
				"options": []
			},
		],
		"linkToOnboarding": false
	}
]

export const requirements = {
	"success": true,
	"data": {
		"approvers": 1,
		"docs": [
			"Letter from IRD ",
			"Bank Statement",
		],
		"labels": [
			"IRD Number",
			"Bank Account"
		]

	}
}
export function testEmail(email) {
	return !!email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/
		.test(email.toUpperCase());
}
export function validateEmail(email) {
	if (testEmail(email)) {
		return { isValid: true };
	} else {
		return { isValid: false, errorMessage: "Invalid email address" }
	}
}

export const testInternationalNumberSeperately = (idd, number) => {
	return !!idd && /^\+[0-9]{1,4}$/.test(idd.replace(/\s/g, "")) //assumes that the prefix is a string!!!
		&& /^[1-9][0-9]{6,10}$/.test(number);//also total length test?
}

export function toIntIfPossible(value) {
	if (!(value === null || value === undefined) && value.match && value.match(/[0-9]+/g)) {
		return parseInt(value);
	}
	return value;
}