import React, { useState, useEffect } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Banner from "../../Banner";
import BannerInfoText, { BannerInfoIntroText } from "../../../../core-components/misc/BannerInfoText";
import PortalContentWrapper from "../../PortalContentWrapper";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import DocumentsFilters from "../MyDocuments/DocumentsFilters";
import { FiltersWrapper } from "../../../../core-components/filters-and-toggles/withFilters";
import ThirdPartyAccessFilters from "./ThirdPartyAccessFilters";
import ThirdPartyAccessDocuments from "./ThirdPartyAccessDocuments";
import { withRemote } from "@threeskye/global"

const ThirdPartyAccess = (props) => {
	const [documents, setDocuments] = useState([])	
	
	const {remote} = props;
	useEffect(() => {

		const token = remote.createTokenHeader().headers["X-3Skye-Session"];
		remote.get("/modules/hobson-support/third-party-tax-reports")
		.then((reports) => {
			setDocuments(reports.files.map((doc, idx) => {
				return {...doc, 
					entityName: reports.fileDTOs[idx].entityName, 
					entityId: reports.fileDTOs[idx].entityId,
					url: `/api/direct/${token}/modules/crm/accounts/${reports.fileDTOs[idx].entityId}/documents/${doc.id}`
				}}))})
	},[])

	return (
		<>
			<Mobile>
				<DefaultMobileBanner thirdPartyView={true} />
			</Mobile>
			<Desktop>
				<Banner thirdPartyView={true}>
					<BannerInfoText>
						<BannerInfoIntroText>You’re now viewing your Third Party accounts</BannerInfoIntroText>
					</BannerInfoText>
				</Banner>
			</Desktop>
			<PortalContentWrapper  thirdPartyView={true}>
				<CardContainer>
					<Card>
						<CardHeader>
							<h3>Third Party Access</h3>
						</CardHeader>
						<FiltersWrapper>
							<ThirdPartyAccessFilters documents={documents} />
							<hr />
							<ThirdPartyAccessDocuments documents={documents} />
						</FiltersWrapper>
					</Card>
				</CardContainer>
			</PortalContentWrapper>
		</>
	);
}

export default withRemote(ThirdPartyAccess);