import React, { useEffect, useState } from 'react';
import { withRemote } from '@threeskye/global';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toastDanger, toastSuccess } from "../../../../core-components/popups/Toast";
import VerticalSpacer from '../../../../core-components/layouts/VerticalSpacer';
import Card from '../../../../core-components/card/Card';
import CardHeader from '../../../../core-components/card/CardHeader';
import Button from '../../../../core-components/inputs/Button';
import CommonFunctions from '../../../../core-components/functions/CommonFunctions'
import PhoneNumberInput from '../../../../core-components/inputs/PhoneNumberInput'
import FormErrorMessage from '../../../../core-components/layouts/FormErrorMessage'
import Input from "../../../../core-components/inputs/Input";


const ConfirmPhoneNumber = ({ remote, triggerNextView, email, check, codeExpected, goBack }) => {
    const [phone, setPhone] = useState({ idd: "", number: "", extension: "", valid: true })
    const [invalidMObile, setInvalidMObile] = useState(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
    const [phoneNumberValue, setPhoneNumberValue] = useState(phone)
    const [verificationCode, setVerificationCode] = useState("")
    const [verficationCodeErrorMessage, setVerficationCodeErrorMessage] = useState("")
    const [verificationResponse, setVerificationResponse] = useState(null);

    const [mobile, setMobile] = useState("");
    const [codeSent, setCodeSent] = useState(false);

    console.log("Confirm phone number has check ", check)

    useEffect(() => {
        if (phone.idd && phone.number && !invalidMObile) {
            const phoneString = phone.idd + phone.number
            setMobile(phoneString)
        }
    }, [invalidMObile, phone])

    function toIntIfPossible(value) {
        if (!(value === null || value === undefined) && value.match && value.match(/[0-9]+/g)) {
            return parseInt(value);
        }
        return value;
    }

    function testInternationalNumberSeperately(idd, number) {
        return !!idd && /^\+[0-9]{1,4}$/.test(idd.replace(/\s/g, "")) //assumes that the prefix is a string!!!
            && /^[1-9][0-9]{6,10}$/.test(number);//also total length test?
    }

    function resendCode() {
        setVerificationCode("")
        setVerficationCodeErrorMessage("")
        toastSuccess('A new code has been sent', { className: 'prelogin-toast' })
    }

    const sendCodeVerification = () => {
        const data = {
            email,
            check : verificationResponse,
            code : verificationCode
        }
        remote.post("/public/modules/koura/verify-2fa", data).then(response => {
            triggerNextView(true);
        });
    }

    const registerMobile = () => {
        if (codeSent) {
            sendCodeVerification();
            return;
        }
        const data = {
            idd: phone.idd,
            phone: phone.number,
            email,
            check: check
        }
        remote.post("/public/modules/koura/register/phone", data).then(response => {
            console.log("Got response", response);
            if (response.success) {
                setCodeSent(true);
                setVerificationResponse(response.data.emailVerification);
                return;
            } else {
                toastDanger(response.message, {className: 'prelogin-toast'})
            }
            setVerficationCodeErrorMessage(response.message);
        })
    }

    return (
        <Card className="no-box-shadow">
            <CardHeader className="header-with-subheader">
                <h3 className="onbording-header no-margin">Verify your mobile number</h3>
                <div style={{ paddingTop: 10 }}><p style={{ margin: 0 }}>{codeSent && mobile ? <>Please verify your mobile using the code we sent to <span style={{ fontWeight: 600 }}>{mobile}</span></> : `Before you go any further, we'll need to verify your mobile number`}</p></div>
            </CardHeader>
            <Row>
                <Col xs="12" xl="12" className="pr-xl-3" style={{ height: 120 }}>
                    {codeSent ?
                        <Input
                            label="Verification Code"
                            type="number" name="number"
                            value={verificationCode}
                            placeholder="Code"
                            errorMessage={verficationCodeErrorMessage}
                            onChange={(e) => setVerificationCode(e)}
                            inputClass={verficationCodeErrorMessage !== "" ? "invalid-input" : ""}
                            errorMessageDown
                        /> :
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>Mobile Number</span>
                            </div>

                            <PhoneNumberInput
                                type="number"
                                name="phone-number"
                                placeholderIDD="IDD"
                                placeholderValue="Enter phone number"
                                invalidNumberMessage="Please enter a valid number (without spaces)"
                                noPrefixMessage="Please enter a prefix"
                                validate={(content) => testInternationalNumberSeperately(content.idd, content.number)}
                                onChange={(data) => {
                                    const newObject = { extension: data.extension, idd: data.idd, number: data.number }
                                    setPhoneNumberValue(newObject)
                                }}
                                onValidChange={(data) => {
                                    const newPhone = {
                                        idd: data.idd,
                                        number: toIntIfPossible(data.number).toString(),
                                        extension: toIntIfPossible(data.extension === "" ? null : data.extension) || null,
                                        valid: true
                                    }
                                    setPhone(newPhone)
                                }}
                                value={phoneNumberValue}
                                placeholderExt="Ext"
                                extraContainerClassNme="onbording-phone"
                                publicRoute
                                overrideValidation={setInvalidMObile}
                                phoneErrorMessage={phoneErrorMessage}
                                setPhoneErrorMessage={setPhoneErrorMessage}
                            />

                            <div className="label-container">
                                {invalidMObile && <span className="form-error"><FormErrorMessage>{invalidMObile ? phoneErrorMessage : ""}</FormErrorMessage></span>}
                            </div>
                        </label>}
                </Col>
            </Row>
            <VerticalSpacer size="40px" />
            <Row className="justify-content-end">
                {codeSent && <Col xs="12" xl="6" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ textAlign: 'start', margin: 0 }}><span>{verficationCodeErrorMessage ? "Having trouble?" : "Didn't receive a text message?"}</span><span onClick={resendCode} style={{ fontWeight: 600, cursor: 'pointer' }}>{verficationCodeErrorMessage ? " Send new code" : " Resend message"}</span></p>
                </Col>}
                <Col xs="12" xl="6" className="justify-self-end">
                    <div style={{ display: "flex", gap: 20, justifyContent: "end" }}>
                        <Button onClick={() => codeSent ? setCodeSent(false) : goBack(false)} className="button-colour-secondary capitalized">
                            Go Back
                        </Button>
                        {/* TODO: add endpoint to verify phone/code */}
                        <Button className="capitalized" onClick={registerMobile} disabled={codeSent ? verificationCode.length < 6 : !mobile}>
                            {codeSent ? 'Verify' : 'Send Code'}
                        </Button>
                    </div>

                </Col>
            </Row>
        </Card>
    )
}

export default ConfirmPhoneNumber