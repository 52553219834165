import React, { Component } from 'react';
import ExpandMore from "@material-ui/icons/ExpandMore";
import "./MobileCardDropdown.scss";

class MobileCardDropdown extends Component {
	state = {showOptions: false};
	render() { 
		const { options, accountSelect, value, onSelect, format } = this.props;
		const { showOptions } = this.state;
		return ( 
			<div className="mobile-card-dropdown" onClick={() => this.setState({showOptions: !showOptions})}>
				<div className={`dropdown-option ellipse${accountSelect ? " account-option" : ""}`}>{format ? format(value) : value}</div>
				<ExpandMore className="dropdown-select-icon" />
				<div className={`mobile-card-dropdown-options${showOptions ? " show-options" : ""}`}>
					<ul>
						{options && options.map((option, index) => (
							<li key={index} onClick={() => onSelect(option)}>{format ? format(option) : option}</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
}
 
export default MobileCardDropdown;