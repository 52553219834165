import React, { Component } from "react";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import PortalContentWrapper from "../../PortalContentWrapper";
import Banner from "../../Banner";
import BannerInfoText, { BannerInfoHighlightText, BannerInfoMainText } from "../../../../core-components/misc/BannerInfoText";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import Mobile from "../../../../core-components/layouts/Mobile";
import Desktop from "../../../../core-components/layouts/Desktop";
import { PortalAccTypes, getPortalAccType, withAccountInfo, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import ErrorMessage from "../../../../core-components/layouts/ErrorMessage";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";

const AnalyticsBannerExtension = withAccountInfo(
	class extends Component {
		render() {
			const { account } = this.props;
			return (
				<BannerInfoText>
					<BannerInfoMainText>{account.name}</BannerInfoMainText>
					<BannerInfoHighlightText>{displayPortalAccType(getPortalAccType(account))}</BannerInfoHighlightText>
				</BannerInfoText>
			);
		}
	}
);

class NavigatorAnalyticsPage extends Component {
	render() {
		return (
			<>
				<Desktop>
					<Banner>
						<AnalyticsBannerExtension />
					</Banner>
				</Desktop>
				<PortalContentWrapper>
					<CardContainer xs={12}>
						<Card>
							<ErrorMessage infoIcon>Analytics not available for this account.</ErrorMessage>
						</Card>
					</CardContainer>
				</PortalContentWrapper>
			</>
		);
	}
}

class PortfolioAnalyticsPage extends Component {
	render() {
		const { children } = this.props;
		return (
			<>
				<Desktop>
					<Banner>
						<AnalyticsBannerExtension />
						<BannerNavLinks parent={LINK_ITEMS().ANALYTICS} />

					</Banner>
				</Desktop>
				<PortalContentWrapper>{children}</PortalContentWrapper>
			</>
		);
	}
}

class AnalyticsPage extends Component {
	components = {
		[PortalAccTypes.NAVIGATOR]: NavigatorAnalyticsPage,
		[PortalAccTypes.PORTFOLIO]: PortfolioAnalyticsPage,
	};
	render() {
		const { children, account } = this.props;
		const ComponentToShow = this.components[getPortalAccType(account)];
		return (
			<>
				<Mobile>
					<DefaultMobileBanner />
				</Mobile>
				<ComponentToShow>{children}</ComponentToShow>
			</>
		);
	}
}

export default withAccountInfo(AnalyticsPage);
