import React, { useEffect, useState } from "react";
import ChangeYourPassword from "./ChangeYourPassword";
import Banner from "../../Banner";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import BannerInfoText, { BannerInfoIntroText } from "../../../../core-components/misc/BannerInfoText";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks"
import PortalContentWrapper from "../../PortalContentWrapper";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import Desktop from "../../../../core-components/layouts/Desktop";


const MyDetails = ({ thirdPartyView, userDetails }) => {
	const [isPendingAccount, setIsPendingAccount] = useState(null)

	useEffect(() => {
		if (userDetails && userDetails.clientStatus === 'PROSPECT') setIsPendingAccount(true)
		if (userDetails && userDetails.clientStatus && userDetails.clientStatus !== 'PROSPECT') setIsPendingAccount(false)
	}, [userDetails.clientStatus])

	return <>
		<Mobile>
			<DefaultMobileBanner thirdPartyView={thirdPartyView} />
		</Mobile>
		<Desktop>
			<Banner thirdPartyView={thirdPartyView}>
				<BannerInfoText>
					<BannerInfoIntroText className={isPendingAccount ? "pending-account" : ""}>{thirdPartyView ? "You’re now viewing your Third Party accounts" : isPendingAccount ? <><i className="material-icons">info</i> Account Pending</> : "Edit your account details, password, and 2FA"}</BannerInfoIntroText>
				</BannerInfoText>
				{!thirdPartyView && <BannerNavLinks thirdPartyView={thirdPartyView} parent={LINK_ITEMS(null, null, isPendingAccount).SETTINGS} />}
			</Banner>
		</Desktop>
		<PortalContentWrapper thirdPartyView={thirdPartyView} >
			<div id="portal-my-details">
				{/* <EditAccountDetails /> */}
				{/* <TwoFactorAuth /> */}
				<ChangeYourPassword />
			</div>
		</PortalContentWrapper>
	</>
}

export default withAccountInfo(MyDetails);
