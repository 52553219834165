import React, { Component, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Search } from "@material-ui/icons";
import withReactKey from "@threeskye/core-components/dist/components/withReactKey";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";
import Button from "../../../../core-components/inputs/Button";
import DateRangeInput from "../../../../core-components/inputs/DateRangeInput";
import FilterInput from "../../../../core-components/inputs/FilterInput";
import SearchInput from '../../../../core-components/inputs/SearchInput';
import DateFieldInput from "../../../../core-components/inputs/DateFieldInput";
import { isBefore, isSameDay, isAfter } from 'date-fns'


const ReviewDocsFilters = (props) => {
	const { documents, setDocuments, originalDocumentsList } = props
	const [from, setFrom] = useState(null)
	const [to, setTo] = useState(null)
	const [search, setSearch] = useState("")


	useEffect(() => {
		onFiltersUpdate()
	}, [from, to, search])

	
	function applayDateFilter(array) {
		const filteredDocumentsByDate = array && array.filter(d => {
			const startDate = new Date(d.startDate)
			const fromDate = from && new Date(from.from)
			const toDate = to && new Date(to.to)

			if (from && to) {
				return (isBefore(fromDate, startDate) || isSameDay(fromDate, startDate)) && (isAfter(toDate, startDate) || isSameDay(toDate, startDate))
			} else if (from && !to) {
				return (isBefore(fromDate, startDate) || isSameDay(fromDate, startDate))
			} else if (!from && to) {
				return (isAfter(toDate, startDate) || isSameDay(toDate, startDate))
			} else return null
		})

		if (!from && !to && search !== "") setDocuments(array)
		else if (!from && !to && search === "") setDocuments(originalDocumentsList)
		else setDocuments(filteredDocumentsByDate)
	}


	function onFiltersUpdate() {
		if (search !== "" && search) {
			const filteredDocumentsBySearch = documents && documents.filter(d => {
				if (d.reviewer.toLowerCase().startsWith(search.toLowerCase())) return true
				else if (d.filename.toLowerCase().startsWith(search.toLowerCase())) return true
			})
			if (filteredDocumentsBySearch) applayDateFilter(filteredDocumentsBySearch) 
			
		} else applayDateFilter(originalDocumentsList) 		
	}

	function clearFilters() {
		setFrom(null)
		setTo(null)
		setSearch("")
	}

	function handleKeyPress(event) {
		if (event.key === "Enter") {
			onFiltersUpdate();
		}
	}


	return (
		<div className="document-filters" tabIndex="-1" onKeyDown={handleKeyPress} >
			<Row>
				{/* <Col xs="12" lg="4">
						<FilterInput
							placeholder="Search Reviews"
							label="Search"
							value={search}
							onChange={(search) => setSearch(search)}
							icon={<Search />}
							onBlur={onFiltersUpdate}
							name="search"
							autoFocus={true}
						/>
					</Col> */}
				<Col xs="12" lg="4" className="date-range-filter-wrapper">
					<DateRangeInput
						// key={reactKey}
						from={from}
						onFromChange={(from) => setFrom({ from }, onFiltersUpdate())}
						to={to}
						onToChange={(to) => setTo({ to }, onFiltersUpdate())}
						stateShouldUpdateWhenPropsChange={true}
					/>
				</Col>
				<Col xs="12" lg="4" xl="4" className="documents-apply-button documents-clear-button mt-3" style={{ alignSelf: "center" }}>
					<Button fullWidth small onClick={clearFilters}>
						Clear Filters
					</Button>
				</Col>
			</Row>
		</div>
	);
}

export default withReactKey(withFilters(withAccountInfo(ReviewDocsFilters)));
