import React, { useEffect, useState } from "react";
import moment from "moment";
import GridTable from "../../../../core-components/layouts/GridTable";
import GridTableRow from "../../../../core-components/layouts/GridTableRow";
import withRouteChange from "@threeskye/route-change";
import { CheckCircle, Info, SaveAlt } from "@material-ui/icons";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import "./ReviewDocsList.scss";

function countSignedSignatures(signatures) {
	const signedSignatures = signatures ? signatures.filter((signature) => signature.signed) : 0;
	return signedSignatures.length || 0;
}


const ReviewDocsList = (props) => {
	const { documents, user, storage, remote, account, notifications } = props
	const [documentSelected, setDocumentSelected] = useState(null)


	useEffect(() => {
		if (documentSelected) {
			props.changeRoute(`/${documentSelected.accountNumber}/my-documents/review/${documentSelected.id}`)
		}
	}, [documentSelected])

	function markReviewAsRead(document) {
		if (document.status !== "Authorised" && !document.clientRead) {

			const notification = notifications && notifications.length > 0 && notifications.find(n => {
				const content = JSON.parse(n.content)
				if (content && content.clientPortalLink) {
					const linkArray = content.clientPortalLink.split("/")
					const reviewId = +linkArray[linkArray.length - 1] 
					if (reviewId === document.id) return true
				} else return null
			})

			remote.put(`/modules/crm/account/${account.id}/review/${document.id}/read`).then((resp) => {
				console.log("resp", resp)
				storage.refresh(`/modules/crm/account/${account.id}/review/${document.id}/client/${user.id}/publish`)
				
				remote.put(`/modules/crm/client-notifications/${notification.id}`).then(() => {
					storage.refresh("/modules/crm/client-notifications")
				})
				// storage.refresh(`/modules/crm/review/${review.id}`)
				// storage.refresh(`/modules/crm/review/account/${account.id}`)
			})
		}
		setDocumentSelected(document)
	}


	return (<GridTable id="review-documents-table" headers={["Name", "Upload Date", "Signatures", "Complete", ""]} columnWidths="1fr 1fr 1fr 150px 32px">
		{documents.map((document, index) => {
			return (
				<GridTableRow
					className={document.clientRead ? "" : "unread-review"}
					style={{ cursor: "pointer" }}
					onClick={() => document.id && document.id !== 0 ? markReviewAsRead(document) : null}
					key={index}
					columns={[
						<div className="name-column">
							{document.clientRead ? "" : <Info className="unread-icon" />}
							{document.filename}
						</div>,
						moment(document.uploadedAt || document.startDate).format("Do MMM YYYY"),
						<>{countSignedSignatures(document.signatures) + "/" + document.signaturesRequired}</>,
						<CheckCircle className={`complete-icon${document.status === "Authorised" ? " review-complete" : ""}`} />,
						<SaveAlt />,
					]}
				/>
			);
		})}
	</GridTable>
	);
}

export default withRouteChange(withAccountInfo(ReviewDocsList));