import React, { useEffect, useState } from "react";
import moment from "moment";
import "./NotificationList.scss";
import CommonFunctions from "../functions/CommonFunctions";
import ErrorMessage from "../layouts/ErrorMessage";
import withNotifications from "../notifications/withNotifications";
import { Close } from "@material-ui/icons";
import { withAccountInfo } from "../contexts/AccountsContext";
import Loading from "../layouts/Loading";
import Token from './Token'
import { displayPortalAccType, getPortalAccType } from "../contexts/AccountsContext";

const NotificationListItem = ({ onClick, deleteNotification, availableAccounts, notification }) => {
	const [account, setAccount] = useState({managementBasis: {name: ""}, name: "", id: ""})

	const { content, createdDate, accounts, heading } = notification;
	const draft = JSON.parse(content)

	useEffect(() => {
		if (!accounts || !accounts.length || !availableAccounts) {
			return;
		}
		let recipientAccount 
		if (draft && draft.clientPortalLink) {
			recipientAccount = availableAccounts.find((acc) => acc.name == heading)
		} else {
			recipientAccount = availableAccounts.find((acc) => acc.id == accounts[0])
		}
		setAccount(recipientAccount)
	}, [])
	
	const checkForBankAccount = (string) => {
		if (string.startsWith("Bank Account:")) {
			return "Bank Account"
		} else return string
	}
	
	const text = draft.blocks.map((block) => { return block.text })
	const displayText = checkForBankAccount(text.join(" "))
	const parsedDate = moment(createdDate);
	const displayDate = parsedDate && parsedDate.fromNow ? parsedDate.fromNow() : "Time not available.";

	return (
		<div className={`notification-li ${notification.clientRead ? "" : "unread-notification"}`} onClick={() => onClick(notification, account)} title={text}>
			<div className="notification-li-header">
				{account && account.id && <Token small>{displayPortalAccType(getPortalAccType(account))}</Token>}
				<div className="notification-li-account">{account && account.name}</div>
			</div>
			{draft && draft.clientPortalLink ? "" : heading && <div className="notification-li-heading">{heading !== "Bulk Sharing" && heading}</div>}
			<div className={heading && heading !== "Bulk Sharing"  ? "notification-li-label" : "notification-li-label notification-li-heading"}>{displayText}</div>
			<div className="notification-li-time">{displayDate}</div>
			<Close onClick={deleteNotification} className="notification-li-delete" />
		</div>
	);

}

const NotificationList = ({ notificationsOpen, notifications, onNotificationClick, deleteNotification, accounts, notificationsLoading }) => {
	const className = CommonFunctions.asClassName(["notification-list-container", notificationsOpen && "notifications-open"]);
	const [sortedNotifications, setSortedNotifications] = useState(notifications)
	useEffect(() => {
		let sortedList = notifications
			? notifications.sort((note1, note2) => {
				if ((!note1 || !note1.createdDate) && (!note2 || !note2.createdDate)) { return 0 }
				else if (!note1 || !note1.createdDate) { return -1 }
				else if (!note2 || !note2.createdDate) { return 1 }
				else if (moment(note1.createdDate).isSame(note2.createdDate)) { return 0 }
				else if (moment(note1.createdDate).isBefore(note2.createdDate)) { return 1 }
				else { return -1 }
			})
			: []
		setSortedNotifications(sortedList)
	}, [notifications])
	return (
		<div className={className}>
			<div className="notification-list-header sub-header-small">NOTIFICATIONS</div>
			<div className="notification-list">
				{notificationsLoading ? (
					<Loading containerClassName="pb-4" centered size={40} />
				) : sortedNotifications && sortedNotifications.length > 0 ? (
					sortedNotifications.map((notification, index) => (
						<NotificationListItem
							availableAccounts={accounts}
							key={index}
							notification={notification}
							onClick={onNotificationClick}
							deleteNotification={(e) => deleteNotification(notification, e)}
						/>
					))
				) : (
					<ErrorMessage infoIcon>No notifications to display.</ErrorMessage>
				)}
			</div>
		</div>
	);

}

export default withAccountInfo(withNotifications(NotificationList));
