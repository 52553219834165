import React, { Component } from 'react';
import Button from './Button';

class GenerateButton extends Component {
	render() { 
		const { onClick, disabled, generating } = this.props;
		return ( 
			<Button disabled={disabled || generating} generating={generating} className="generate-button" compact secondary onClick={onClick}>generate</Button>
		 );
	}
}

export default GenerateButton;