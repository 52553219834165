import { LockOpenOutlined, ExitToApp } from "@material-ui/icons";
import React, { Component } from "react";
import Button from "../../../core-components/inputs/Button";
import VerticalSpacer from "../../../core-components/layouts/VerticalSpacer";
import Input from "../../../core-components/inputs/Input";
import TopLeftLogoOnMobile from "./TopLeftLogoOnMobile";

class TwoFAPage extends Component {
	state = {
		code: "",
	};

	render() {
		return (
			<TopLeftLogoOnMobile>
				<div className="fade-in" id="sms-verification-form">
					<div className="text-center">
						<h3>Two Factor Authentication</h3>
					</div>
					<p>
						{this.props.setup
							? "That's great. To make sure we've got your details right we've sent a code to your phone now.  Please enter the code below."
							: "You haven't logged in from this device before. A code has been sent to your registered cellphone.  Please enter the code below."}
					</p>
					<VerticalSpacer size="28px" />
					{this.props.message && <div>{this.props.message}</div>}
					<Input label="Verification Code" placeholder="Code" name="verification-code" autoComplete="one-time-code" value={this.state.code} onChange={(code) => this.setState({ code })} />
					<VerticalSpacer size="16px" />
					<Button onClick={() => this.props.onSubmit(this.state.code)}>
						<LockOpenOutlined />
						sign in securely
					</Button>
					{this.props.setup && (
						<>
							<VerticalSpacer size="16px" />
							<Button onClick={this.props.cancelSetup}>
								<ExitToApp />
								go back
							</Button>
						</>
					)}
				</div>
			</TopLeftLogoOnMobile>
		);
	}
}

export default TwoFAPage;
