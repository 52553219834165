import React, { useEffect, useState, useRef } from "react";
import "./Input.scss";


const DropDown = (props) => {
    const { value, placeholder, options, updateValue, clickable, objectValue, propertyName, id, openDropDown } = props
    const [isActive, setIsActive] = useState(false)
    const [isHiding, setIsHiding] = useState(false)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1)
    const optionsRef = useRef(null);


    useEffect(() => {
        if (openDropDown) {
            showDropdown()
        }
    }, [openDropDown])

    useEffect(() => {
        if (optionsRef.current && selectedOptionIndex > -1 && optionsRef.current.children && optionsRef.current.children[selectedOptionIndex]) {
            optionsRef.current.children[selectedOptionIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
            });
        }
    }, [selectedOptionIndex]);

    function showDropdown() {
        setIsActive(true)
    }

    function hideDropdown() {
        setIsHiding(true)
        setTimeout(() => {
            setIsActive(false)
            setIsHiding(false)
        }, 100);
    }

    function handleKeyPress(event) {
        if (event.key === 'ArrowDown' && selectedOptionIndex < options.length - 1) {
            setSelectedOptionIndex(selectedOptionIndex + 1)
        } else if (event.key === 'ArrowUp' && selectedOptionIndex > -1) {
            setSelectedOptionIndex(selectedOptionIndex - 1)
        } else if (event.key === 'Enter' && selectedOptionIndex > -1) {
            updateValue(options[selectedOptionIndex])
            hideDropdown()
        } else if (event.key === 'Escape') {
            hideDropdown()
        }
    }

    function handleOptionHover(index) {
        setSelectedOptionIndex(index)
    };

    let className = "dropdown-selection-list-container";
    let classNames = "dropdown-container";
    let allocationDropdown;

    if (props.classNames) {
        classNames += " " + props.classNames;
    }
    if (props.disabled) {
        classNames += " dd-disabled";
    }
    if (props.filterLabel) {
        allocationDropdown = <div className="filter-inner-label">{props.filterLabel}</div>;
    }
    if (isActive) {
        className += " dropdown-selection__active";
    } else {
        className += "";
    }
    if (props.fullWidth) {
        classNames += " dropdown-full-width";
    }
    if (isHiding) {
        className += " fade-out";
    }

    return (
        <div
            onBlur={hideDropdown}
            className={classNames}
            onClick={isActive ? hideDropdown : showDropdown}
            onMouseEnter={clickable ? null : showDropdown}
            onMouseLeave={clickable ? null : hideDropdown}
            style={props.style ? props.style : null}
            tabIndex={0}
            onKeyDown={handleKeyPress}
            id={id}
        >
            {allocationDropdown}
            <div
                className={`dropdown-value-wrapper flex-center${isActive ? ' dropdown-value__active' : ''}`}
                style={props.inputDropdown && { padding: "9px 12px" }}
            >
                <div className={!value ? " placeholder-value" : "dropdown-value"}>{value ? value : placeholder ? placeholder : "Select"}</div>
                <i className="material-icons">expand_more</i>
            </div>
            <div className={className}>
                <ul className="dropdown-selection-list" onClick={hideDropdown} ref={optionsRef}>
                    {options && options.map((option, idx) => {
                        return <li
                            key={"rwtRate-option-" + idx}
                            onClick={(e) => {setSelectedOptionIndex(idx); hideDropdown(); updateValue(option)}}
                            onMouseEnter={() => handleOptionHover(idx)}
                            style={{ backgroundColor: idx === selectedOptionIndex ? "#f3f6f7" : null }}
                        >
                            {objectValue ? option[propertyName] : option}
                        </li>
                    })}
                </ul>
            </div>
        </div>
    );
}

export default DropDown;
