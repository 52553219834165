import React, { Component } from "react";
import Notifications from "@material-ui/icons/Notifications";
import "./NotificationBell.scss";
import NotificationList from "./NotificationList";
import CommonFunctions from "../functions/CommonFunctions";
import withNotifications from "../notifications/withNotifications";
import useComponentVisible from "../notifications/useComponentVisible"

const NotificationBell = ({ unreadNotificationCount, }) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	const className = CommonFunctions.asClassName(["notification-bell-wrapper", unreadNotificationCount > 0 && "unread-notifications", isComponentVisible && "notifications-open"]);

	return (
		<div className={className} onClick={() => {setIsComponentVisible(!isComponentVisible)}} ref={ref} >
			<Notifications className="notification-bell" />
			<UnreadIndicator />
			<NotificationList notificationsOpen={isComponentVisible} />
		</div>
	);
}

const UnreadIndicator = withNotifications(
	class extends Component {
		render() {
			const { unreadNotificationCount } = this.props;
			if (unreadNotificationCount > 0) {
				return <div className="unread-indicator">{unreadNotificationCount}</div>;
			}
			return null;
		}
	}
);

export default withNotifications(NotificationBell);
