import React, { Component } from "react";
import moment from "moment";
import { DATE_FORMAT } from "@threeskye/core-components/dist/components/DateField/DateField";
import AssetTableMobile from "../../../../../core-components/layouts/AssetTableMobile";
import Formatter from "../../../../../core-components/functions/Formatter";
import CommonFunctions from "../../../../../core-components/functions/CommonFunctions";
import RangeBar from "../../../../../core-components/graphs/RangeBar";
import ErrorMesssage from "../../../../../core-components/layouts/ErrorMessage";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import Loading from "../../../../../core-components/layouts/Loading";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import "./NavigatorAssetAllocation.scss"

class NavigatorAssetAllocation extends Component {
	state = { loading: true, allocations: null };

	constructor(props) {
		super(props);
		this.updateAssetGroups = this.updateAssetGroups.bind(this);
		this.getCSV = this.getCSV.bind(this);

		props.setGetCSVFunc(this.getCSV);
	}

	componentDidMount() {
		this.updateAssetGroups();
	}

	componentDidUpdate(prevProps) {
		if ((prevProps.filters && prevProps.filters.date) !== (this.props.filters && this.props.filters.date) || prevProps.account.id !== this.props.account.id) {
			this.updateAssetGroups();
		}
	}

	updateAssetGroups() {
		const { account, filters } = this.props;
		if (filters) {
			this.setState({ loading: true });
			const notToday = filters.date !== moment().format(DATE_FORMAT);
			Promise.all([
				this.props.storage.getOrFetch(`/modules/crm/classification-scheme/${account.classificationScheme.id}/model-data`),
				this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/model`),
				this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio${notToday ? `/${filters.date}` : ""}`),
			]).then(([scheme, model, remoteReliantDTO]) => {
				const portfolioValue = remoteReliantDTO && !remoteReliantDTO.connectionError && remoteReliantDTO.data && remoteReliantDTO.data.valuation;
				if (scheme && scheme.classes && model && model.success && model.data && model.data.categories) {
					const portfolioClasses = remoteReliantDTO && !remoteReliantDTO.connectionError && remoteReliantDTO.data && remoteReliantDTO.data.classes ? remoteReliantDTO.data.classes : [];
					const funds = portfolioClasses
						.map((fund) => fund.holdings)
						.flat()
						.map(({ name, percentPortfolio, value }) => ({ name, percentPortfolio, value }));

					const allocations = model.data.categories.map((cat) => cat.allocations).flat();
					const defensiveClassId = (scheme.classes.find((schemeClass) => schemeClass.name === "Defensive") || {}).id;
					const defensiveAllocation = allocations.find((allocation) => allocation.classId === defensiveClassId);
					const defensiveValue = (portfolioClasses.find((portfolioClass) => portfolioClass.classId === defensiveClassId) || {}).value;
					const growthClassId = (scheme.classes.find((schemeClass) => schemeClass.name === "Growth") || {}).id;
					const growthAllocation = allocations.find((allocation) => allocation.classId === growthClassId);
					const growthValue = (portfolioClasses.find((portfolioClass) => portfolioClass.classId === growthClassId) || {}).value;
					if (growthAllocation && defensiveAllocation && !CommonFunctions.isNullOrUndefined(defensiveValue) && !CommonFunctions.isNullOrUndefined(growthValue)) {
						const value = (defensiveValue / (defensiveValue + growthValue)) * 100;
						let min = Math.max(defensiveAllocation.min, 100 - growthAllocation.max);
						let max = Math.min(defensiveAllocation.max, 100 - growthAllocation.min);
						this.setState({ defensiveVsGrowth: { value, min, max } });
					}
					this.setState({ funds });
				}
				this.setState({ loading: false, portfolioValue });
			});
		}
	}

	getCSV() {
		const { funds, portfolioValue } = this.state;
		const table = [["Asset Allocation", "Value", "% of Portfolio"]]
			.concat(funds.map(({ name, value, percentPortfolio }) => [name, `"${Formatter.asMoney(value)}"`, `"${Formatter.asPercentage(percentPortfolio, 1)}"`]))
			.concat([["Total Portfolio Value", `"${portfolioValue}"`]]);
		return table.map((row) => row.join(",")).join("\n");
	}

	render() {
		const { funds, loading, defensiveVsGrowth } = this.state;
		const isMobile = CommonFunctions.isMobile();

		if (loading) {
			return <Loading centered size={80} />;
		} else if (!funds || funds.length === 0) {
			return <ErrorMesssage infoIcon>There is no model or valuation for this account.</ErrorMesssage>;
		}

		return (
			<div className="navigator-asset-allocation">
				<table id="portal-overview-asset-groups" className={CommonFunctions.responsiveClassname()}>
					{funds.map(({ name, min, max, value, percentPortfolio }, index) =>
						isMobile ? (
							<AssetTableMobile navigator key={index} {...{ name, min, max, value, percentPortfolio }} />
						) : (
							<tbody key={index} className={index === 0 ? "tbody--first" : ""}>
								<tr className="tr-navigator-asset-allocation" onClick={() => (window.location.href = "#fund-" + name.split(" ").join("-"))}>
									<td className="td-name">{name}</td>
									<td className="td-value">{Formatter.asMoney(value)}</td>
									<td className="td-perc">{Formatter.asPercentage(percentPortfolio, 1)}</td>
									<td className="td-graphic">
										<RangeBar navigator extent={100} value={percentPortfolio} min={min} max={max} />
									</td>
								</tr>
							</tbody>
						)
					)}
				</table>
				{defensiveVsGrowth && (
					<div className="defensive-vs-growth">
						<div className="graphic">
							<RangeBar defensiveVsGrowth extent={100} value={defensiveVsGrowth.value} min={defensiveVsGrowth.min} max={defensiveVsGrowth.max} />
						</div>
						<div className="legend">
							<div className="defensive" /> Defensive <div className="growth" /> Growth
						</div>
					</div>
				)}
			</div>
		);
	} 
}

export default withFilters(withAccountInfo(withThreeSkyeGlobal(NavigatorAssetAllocation)));
