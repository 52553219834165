import React, { Component } from "react";
import moment from "moment";
import { compareNumbers, compareMonthsFromToday } from "@threeskye/core-components/dist/components/Sorting/CompareFunctions";
import MonthYearBarChart from "./MonthYearBarChart";
import { MONTH_YEAR_TIME_FRAMES } from "../../../../../core-components/functions/ChartFunctions";
import { withThreeSkyeGlobal } from "@threeskye/global";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import { emptyDataByMonth } from "./MonthBarChart";

class IncomeBarChart extends Component {
	state = { dataByTimeFrame: null };

	constructor(props) {
		super(props);
		this.updateDataByTimeFrame = this.updateDataByTimeFrame.bind(this);
	}

	updateDataByTimeFrame(callback) {
		const { data } = this.props;
		const { selectedTimeFrame } = this.props.filters;
		const monthTimeFrame = selectedTimeFrame === MONTH_YEAR_TIME_FRAMES.MONTHS;
		let dataByTimeFrame = [];
		if (data && data.length > 0) {
			const valueBySelectedTime = data.reduce((valueByTimeMap, { interestPayments, annualIncome, maturityDate }) => {
				let timesAndValues = [];
				const months = interestPayments ? interestPayments.split("/") : [];
				const incomePerMonth = months.length > 0 ? annualIncome / months.length : 0;
				const maturity = moment(maturityDate);
				if (monthTimeFrame) {
					const validMonths = maturity.isBefore(moment())
						? []
						: months.filter((month) => {
								const monthDate = moment(month, "MMM");
								monthDate.set("year", moment().year() + (monthDate.month() < moment().month() ? 1 : 0));
								return monthDate.isBetween(moment().subtract(1, "month"), maturity);
						  });
					timesAndValues = validMonths.map((month) => ({ time: month, value: incomePerMonth }));
				} else {
					const start = moment();
					const end = moment(maturityDate);
					if (end.isAfter(start)) {
						if (end.year() === start.year()) {
							const validMonths = months.filter((month) => start.clone().set("month", month).isBetween(start, end));
							if (validMonths.length > 0) {
								timesAndValues = [{ time: start.year(), value: incomePerMonth * validMonths.length }];
							}
						} else {
							const numOfYearsInBetween = end.years() - start.years() - 1;
							const validStartMonths = months; // months.filter((month) => start.clone().set("month", month).isAfter(start));
							const validEndMonths = months.filter((month) => end.clone().set("month", month).isBefore(end));
							const timesAndValuesBetween =
								numOfYearsInBetween === 0
									? []
									: Array(numOfYearsInBetween)
											.fill("")
											.map((_, i) => start.years() + i + 1)
											.map((time) => ({ time, value: annualIncome }));
							timesAndValues = [{ time: start.year(), value: incomePerMonth * validStartMonths.length }]
								.concat(timesAndValuesBetween)
								.concat([{ time: end.year(), value: incomePerMonth * validEndMonths.length }]);
						}
					} else {
						return valueByTimeMap;
					}
				}
				return timesAndValues.reduce((map, { time, value }) => ({ ...map, [time]: (map[time] || 0) + value }), valueByTimeMap);
			}, monthTimeFrame ? emptyDataByMonth : {});
			dataByTimeFrame = Object.keys(valueBySelectedTime)
				.sort(monthTimeFrame ? compareMonthsFromToday : compareNumbers)
				.map((time) => ({ x: monthTimeFrame ? time : parseInt(time), y: valueBySelectedTime[time] }));
		}
		this.setState({ dataByTimeFrame }, callback);
	}

	render() {
		const { data } = this.props;
		const { selectedTimeFrame } = this.props.filters;
		const { dataByTimeFrame } = this.state;
		return <MonthYearBarChart updateDataByTimeFrame={this.updateDataByTimeFrame} selectedTimeFrame={selectedTimeFrame} data={dataByTimeFrame} watchedObj={data} />;
	}
}

export default withFilters(withThreeSkyeGlobal(IncomeBarChart));
