import React, { Component } from "react";
import PropTypes from "prop-types";
import CommonFunctions from "../functions/CommonFunctions";
import "./Input.scss";
import "./FilterInput.scss";

class FilterInput extends Component {
	render() {
		let { label, name, type, value, placeholder, onChange, onKeyPress, disabled, onBlur, autoFocus, icon } = this.props;
		type = type || name;
		return (
			<label className={`portal-label filter-input ${CommonFunctions.responsiveClassname()}`}>
				{label && (
					<div className="label-container">
						<span className="filter-heading">{label}</span>
					</div>
				)}
				<div className="input-wrapper">
					<input
						style={{paddingLeft: icon ? 37 : ""}}
						autoFocus={autoFocus}
						name={name}
						type={type}
						value={value || ""}
						placeholder={placeholder}
						onChange={userInput => onChange(userInput.target.value)}
						onKeyPress={onKeyPress}
						disabled={disabled}
						onBlur={onBlur}
					/>
					{icon && icon}
				</div>
			</label>
		);
	}
}

FilterInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
};

export default FilterInput;
