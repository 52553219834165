import React, { Component } from 'react';
import { ScreenRotation } from '@material-ui/icons';
import "./LandscapePrompt.scss";

class LandscapePrompt extends Component {
	render() { 
		return (
			<div className="landscape-prompt">
				<p>Rotate to see more</p>
				<ScreenRotation className="screen-rotation-icon" />
			</div>
		);
	}
}
 
export default LandscapePrompt;