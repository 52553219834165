import React, { useState } from "react";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import { format, isValid, parseISO, isAfter } from 'date-fns'
import Input from '../../core-components/inputs/Input';
import SingleDatePicker from "../../core-components/inputs/SingleDatePicker";
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import FormErrorMessage from '../../core-components/layouts/FormErrorMessage'
import './Onboarding.scss'


const General = (props) => {
    const { onBoardingState, setOnBoardingState, section, parsedData } = props
    const { firstName, middleNames, lastName, dateOfBirth } = onBoardingState
    const label = section && section.displayLabel ? section.label : null
    const subLabel = section && section.displaySubLabel ? section.subLabel : null

    const [invalidDate, setInvalidDate] = useState(false)
    const [dateErrorMessage, setDateErrorMessage] = useState("")
    
    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel &&<div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <Input label="First Name*" name="first-name" value={firstName || onBoardingState.givenName || parsedData.givenName} placeholder="First Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, firstName: e } })} />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <Input label="Middle Name" name="middle-name" value={middleNames} placeholder="Middle Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, middleNames: e } })} />
                </Col>
                <Col xs="12" xl="6" className="pl-xl-3">
                    <Input label="Last Name*" name="last-name" value={lastName || onBoardingState.familyName || parsedData.familyName} placeholder="Last Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, lastName: e } })} />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Date of Birth*</span>
                        </div>
                        <SingleDatePicker
                            value={onBoardingState.dateOfBirth}
                            placeholder={dateOfBirth && dateOfBirth.length > 0 ? "" : "31/01/1970"}
                            format="dd/MM/yyyy"
                            onChange={(newValue) => {
                                setInvalidDate(false)
                                const dateArray = newValue.split("/").reverse()
                                const dateString = dateArray.join("-")

                                const valid = isValid(parseISO(dateString))
                                if (valid) {
                                    const today = new Date()
                                    const date = new Date(dateString)
                                    const formattedFromDate = format(date, "dd/MM/yyyy")
                                    setOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: formattedFromDate } })
                                    const ridiculousDate = isAfter(date, today)
                                    if (ridiculousDate) {
                                        setDateErrorMessage("The date entered has not yet happened")
                                        setInvalidDate(true)
                                    } else {
                                        setDateErrorMessage("")
                                        setInvalidDate(false)
                                    }
                                } else {
                                    setInvalidDate(true)
                                    setDateErrorMessage("Invalid Date")
                                    setOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: null } })
                                }
                            }}
                            autoSubmit
                            noCalendar={true}
                            inputClassName={invalidDate ? "invalid-input" : ""}
                        />
                        <div className="label-container">
                            {invalidDate && <span className="form-error"><FormErrorMessage>{dateErrorMessage}</FormErrorMessage></span>}
                        </div>
                    </label>
                </Col>
            </Row>
        </>
    )
}

export default General