import React, { Component } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import CardNoPadding from "../../../../core-components/card/CardNoPadding";
import CardList from "../../../../core-components/card/CardList";
import NotificationItem from "./NotificationItem";
import withNotifications from "../../../../core-components/notifications/withNotifications";
import ErrorMessage from "../../../../core-components/layouts/ErrorMessage";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import Loading from "../../../../core-components/layouts/Loading";


class DashboardNotifications extends Component {
	render() {
		const { notifications, onNotificationClick, deleteNotification, accounts, notificationsLoading } = this.props;
		return (
			<CardContainer xs={12} xl={7}>
				<CardNoPadding header="Notifications">
					{notificationsLoading ? (
						<Loading centered size={50} />
					) : notifications && notifications.length > 0 ? (
						<CardList
							style={{ height: 280 }}
							list={notifications.map((notification, index) => (
								<NotificationItem
									availableAccounts={accounts}
									key={index}
									expandable={notification.attachments.length > 0}
									notification={notification}
									onClick={onNotificationClick}
									deleteNotification={(e) => deleteNotification(notification, e)}
								/>
							))}
						/>
					) : (
						<ErrorMessage infoIcon>No notifications to display.</ErrorMessage>
					)}
				</CardNoPadding>
			</CardContainer>
		);
	}
}

export default withAccountInfo(withNotifications(DashboardNotifications));
