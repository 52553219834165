import React, { Component } from "react";
import moment from "moment";
import { SortParamsWrapper } from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import CashFundTable from "./CashFundTable";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import PortfolioAssetTable from "./PortfolioAssetTable";
import { DATE_FORMAT } from "@threeskye/core-components/dist/components/DateField/DateField";
import Loading from "../../../../../core-components/layouts/Loading";
import { Col } from "reactstrap";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import { GenericConnectionFailErrorMessage } from "../../../../../core-components/layouts/ErrorMessage";
import CardContainer from "../../../../../core-components/card/CardContainer";
import CardHeader from "../../../../../core-components/card/CardHeader";
import Card from "../../../../../core-components/card/Card";
import CommonFunctions from "../../../../../core-components/functions/CommonFunctions";

const defaultState = {
	portfolioIsLoading: true,
	portfolioConnectionError: false,
	cashFund: null,
	fixedInterestFund: null,
	otherFunds: null,
	schemeClasses: null,
	glIsLoading: true,
	glConnectionError: false,
	gainsAndLossesMap: null,
};

class PortfolioAssets extends Component {
	getCSVFuncList = [];
	state = { ...defaultState };

	constructor(props) {
		super(props);
		this.updatePortfolio = this.updatePortfolio.bind(this);
	}

	componentDidMount() {
		this.updatePortfolio();
	}

	componentDidUpdate(prevProps) {
		const datesChanged = (prevProps.filters && prevProps.filters.date) !== (this.props.filters && this.props.filters.date);
		const accountChanged = prevProps.account.id !== this.props.account.id;
		if (datesChanged || accountChanged) {
			this.updatePortfolio();
		}
	}

	updatePortfolio() {
		const { account, filters, setGetCSVFuncList } = this.props;
		const { isNullOrUndefined } = CommonFunctions;
		if (filters) {
			this.setState({ ...defaultState });
			const notToday = filters.date !== moment().format(DATE_FORMAT);
			Promise.all([
				this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio${notToday ? `/${filters.date}` : ""}`),
				this.props.storage.getOrFetch(`/modules/crm/classification-scheme/${account.classificationScheme.id}/model-data`),
			])
				.then(([remoteReliantPortfolio, scheme]) => {
					if (!remoteReliantPortfolio || remoteReliantPortfolio.connectionError) {
						this.setState({ portfolioConnectionError: true, cashFund: null, fixedInterestFund: null, otherFunds: null, schemeClasses: null });
					} else {
						const portfolio = remoteReliantPortfolio.data;
						if (portfolio && scheme && portfolio.classes && scheme.classes) {
							const schemeClasses = scheme.classes;
							const portfolioClasses = portfolio.classes;
							const cashFund = portfolioClasses.find((portfolioClass) => (schemeClasses.find((schemeClass) => portfolioClass.classId === schemeClass.id) || {}).cash);
							const fixedInterestFund = portfolioClasses.find((portfolioClass) => (schemeClasses.find((schemeClass) => portfolioClass.classId === schemeClass.id) || {}).fixedInterest);
							const otherFunds = portfolioClasses.filter((portfolioClass) => portfolioClass !== cashFund && portfolioClass !== fixedInterestFund);

							this.getCSVFuncList = Array(otherFunds.length + 1 + (fixedInterestFund ? 1 : 0));
							setGetCSVFuncList(this.getCSVFuncList);
							this.setState({ cashFund, fixedInterestFund, otherFunds, schemeClasses });
						}
					}
				})
				.finally(() => this.setState({ portfolioIsLoading: false }));
			this.props.storage
				.getOrFetch(`/modules/nzxwt/accounts/${account.id}/gains-and-losses?date=${notToday ? filters.date : null}`)
				.then((gainsAndLossesResp) => {
					if (!gainsAndLossesResp || gainsAndLossesResp.connectionError) {
						this.setState({ glConnectionError: true });
					} else if (!isNullOrUndefined(gainsAndLossesResp.data)) {
						this.setState({ gainsAndLossesMap: gainsAndLossesResp.data.reduce((map, { assetId, ...otherFields }) => ({ ...map, [assetId]: { ...otherFields } }), {}) });
					}
				})
				.finally(() => this.setState({ glIsLoading: false }));
		}
	}

	render() {
		const { account } = this.props;
		const { portfolioIsLoading, portfolioConnectionError, cashFund, fixedInterestFund, otherFunds, glIsLoading, glConnectionError, gainsAndLossesMap } = this.state;
		if (portfolioIsLoading) {
			return (
				<Col xs="12">
					<Loading centered size={80} />
				</Col>
			);
		}
		return (
			<SortParamsWrapper>
				{portfolioConnectionError ? (
					<CardContainer xs="12">
						<Card>
							<CardHeader>
								<h3>Portfolio</h3>
							</CardHeader>
							<GenericConnectionFailErrorMessage />
						</Card>
					</CardContainer>
				) : (
					<>
						{otherFunds &&
							otherFunds.length > 0 &&
							otherFunds.map((fund, index) => (
								<PortfolioAssetTable
									key={index}
									fund={fund}
									glIsLoading={glIsLoading}
									glConnectionError={glConnectionError}
									gainsAndLossesMap={gainsAndLossesMap}
									setGetCSVFunc={(func) => (this.getCSVFuncList[index + 1 + (fixedInterestFund ? 1 : 0)] = func)}
								/>
							))}
						{fixedInterestFund && (
							<PortfolioAssetTable
								fund={fixedInterestFund}
								glIsLoading={glIsLoading}
								glConnectionError={glConnectionError}
								gainsAndLossesMap={gainsAndLossesMap}
								fixedInterest
								setGetCSVFunc={(func) => (this.getCSVFuncList[1] = func)}
							/>
						)}
						<CashFundTable accountId={account.id} cashAssets={cashFund} setGetCSVFunc={(func) => (this.getCSVFuncList[0] = func)} />
					</>
				)}
			</SortParamsWrapper>
		);
	}
}

export default withFilters(withThreeSkyeGlobal(withAccountInfo(PortfolioAssets)));
