import React, { Component } from 'react';
import "./Token.scss";

class Token extends Component {
	render() { 
		const { small, text, children, warning } = this.props; 

		return (
			<div className={`token-container${warning ? " token-container-warning" : "" }${small ? " small-token" : ""}`}>
				{text || children}
			</div>
		 );
	}
}
 
export default Token;