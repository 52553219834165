import { ExpandMore, MoreVert } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import Input from '../../../../core-components/inputs/Input';
import FlexWrapper from '../../../../core-components/misc/FlexWrapper';
import ProfileImage from '../../../../core-components/misc/ProfileImage';
import Popup from '../../../../core-components/popups/Popup';
import PopupMenu from '../../../../core-components/popups/PopupMenu';
import PopupMenuItem from '../../../../core-components/popups/PopupMenuItem';
import { useOutsideAlerter } from '../../../../core-components/utils/Utils';
import "./CommentBlock.scss";

// Menu that appears when clicking the 3 vertical dots
const CommentMenu = ({ onClick, deleteOnClick, handleClickAway, setPopupVisible, replyOnClick }) => {

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, () => { setPopupVisible(false); handleClickAway() });

	return (
		<div ref={wrapperRef}>
			<PopupMenu width={140} onClick={onClick}>
				<PopupMenuItem label="Reply" onClick={replyOnClick} />
				<PopupMenuItem label="Delete Comment" onClick={deleteOnClick} />
			</PopupMenu>
		</div>
	);
}

const CommentBlock = ({ className, comment, subComment, lastComment }) => {
	const [replying, setReplying] = useState(false);
	const [menuShowing, setMenuShowing] = useState(false);
	const [showReplies, setShowReplies] = useState(false);
	const { user, date, text, replies } = comment;

	return (
		<div className={`comment-block${showReplies ? " replies-showing" : ""}`}>
			<div className={`comment-outer-wrapper${className ? " " + className : ""}${subComment ? ' sub-comment' : ''}`}>
				<FlexWrapper className="comment-header" gap={6}>
					{user && <ProfileImage client={user} />}
					{user && <div className="comment-user-details">
						<p className="comment-user-name">{user.fullName}</p>
						<p className="comment-date">{date}</p>
					</div>}
					<Popup
						placement="left-start"
						onClick={() => setMenuShowing(!menuShowing)}
						triggerElement={<MoreVert className="menu-trigger" />}
						popupElement={
							<CommentMenu
								onClick={() => setMenuShowing(!menuShowing)}
								deleteOnClick={() => alert("Delet")}
								replyOnClick={() => setReplying(true)}
								handleClickAway={() => setMenuShowing(false)}
							/>
						}
					/>
				</FlexWrapper>
				<div className="comment-wrapper">{text ? <p>{text}</p> : <p className="extension-label">No comment.</p>}</div>
				{!subComment && replies && replies.length > 0 ? (
					<FlexWrapper gap={2} className="replies-button" onClick={() => setShowReplies(!showReplies)}>
						<span>
							{replies.length} {replies.length === 1 ? "Reply" : "Replies"}
						</span>{" "}
						<ExpandMore className="replies-expand-icon" />
					</FlexWrapper>
				) : replying ? (
					<FlexWrapper wrap="wrap" className="reply-section" gap="4px">
						<Input placeholder="Reply" autoFocus />
						<FlexWrapper gap={10}>
							<span>Submit</span>
							<span onClick={() => setReplying(false)}>Cancel</span>
						</FlexWrapper>
					</FlexWrapper>
				) : (
					(!subComment || lastComment) && (
						<span className="reply-button" onClick={() => setReplying(true)}>
							Reply
						</span>
					)
				)}
			</div>
			{showReplies && replies.length > 0 && replies.map((reply, idx) => <SubComment key={idx} comment={reply} lastComment={idx === replies.length - 1} />)}
		</div>
	);
};

export default CommentBlock;

const SubComment = ({comment, lastComment}) => {
	return (
		<CommentBlock comment={comment} subComment lastComment={lastComment} />
	)
}

