import React, { useState, useEffect } from 'react';
import { withThreeSkyeGlobal } from '@threeskye/global'
import { withAccountInfo } from '../../../../core-components/contexts/AccountsContext';
import withReviewValuation from './withReviewValuation';
import Loading from '../../../../core-components/layouts/Loading';
import ReviewsLineChart from './ReviewsLineChart'
import "./AccountReviews.scss";


const ReviewsPortfolioPerformanceChart = (props) => {
    const { i18n, valuation, hasValuations, loadingValuations, chartProps, reviewPeriodSelected, setSelectedTimeFrameIndex, endDate, startDate } = props
    const i = i18n.get

    const [originalPeriod, setOriginalPeriod] = useState(reviewPeriodSelected)
    const newTimeFrame = reviewPeriodSelected === 3 ? 1 : reviewPeriodSelected === 6 ? 2 : 3

    useEffect(() => {
        if (originalPeriod !== reviewPeriodSelected) {
            setOriginalPeriod(reviewPeriodSelected)
            if (newTimeFrame) setSelectedTimeFrameIndex(newTimeFrame)
            console.log("newTimeFrame", newTimeFrame )
            console.log("#1 reviewPeriodSelected", reviewPeriodSelected, typeof reviewPeriodSelected)
        }
    }, [reviewPeriodSelected])


    return (
        <div className="portfolio-graph">
            {
                loadingValuations ? (
                    <Loading size={30} centered header={i("Loading")} />
                ) :

                    hasValuations ? (
                        <div className='ts-reviews-theme'>
                            <ReviewsLineChart chartData={valuation} {...chartProps} yearColour="black" fullWidth={true} />
                            <div className='chart-leyenda leyendas'>
                                <div className='chart-leyenda'><div className='reviews-dot dot-2'></div><span>Contribution</span></div>
                                <div className='chart-leyenda'><div className='reviews-dot dot-1'></div><span>Valuation</span></div>
                            </div>
                        </div>
                    ) : (
                        "CACA"
                    )}
        </div>
    )
}

export default withAccountInfo(withReviewValuation(withThreeSkyeGlobal(ReviewsPortfolioPerformanceChart)));