import React, { Component } from 'react';
import Button from './Button';

class ExportButton extends Component {
	render() { 
		const { onClick } = this.props;
		return ( 
			<Button primaryHighlight className="export-button" compact onClick={onClick} style={{height: 48}}>export</Button>
		 );
	}
}

export default ExportButton;