import React, { Component } from "react";
import LoadingOverlay from "../../../../core-components/layouts/LoadingOverlay";
import SetPasswordPage from "../SetPasswordPage";
import "./WelcomePage.scss";
import ConfirmCode from "../../../../core-components/layouts/ConfirmCode";
import LoginPage from "../LoginPage";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer";
import withRouteChange from "@threeskye/route-change";
import CenteredLogo from "../CenteredLogo";

class WelcomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			username: atob(props.match.params.code),
			password: null,
			key: null,
			code: null,
			invalid: false,
			isConfirmed: false,
			passwordIsSet: false,
		};
	}

	componentDidMount() {
		const { username } = this.state;
		const data = { username };
		this.props.remote.post("/um/hwp/welcome", data).then(
			(response) => this.setState({ key: response.key, loading: false }),
			(_) => this.setState({ invalid: true, loading: false })
		);
	}

	render() {
		const { login } = this.props;
		const { loading, invalid, isConfirmed, key, username, code, passwordIsSet, password } = this.state;
		const showLoginPage = !loading && !invalid && isConfirmed && passwordIsSet;
		if (showLoginPage) {
			return <LoginPage source="welcome" login={login} showPostLogin={true} email={username} password={password} />;
		}
		return (
			<CenteredLogo>
				{loading ? (
					<LoadingOverlay message="Getting you started" />
				) : invalid ? (
					<p>I'm sorry but there was an issue processing your request</p>
				) : (
					<>
						<p>Welcome to the 3SKYE customer portal</p>
						<VerticalSpacer size="14px" />
						{!isConfirmed ? (
							<ConfirmCode username={username} respKey={key} source={"welcome"} onConfirm={(code) => this.setState({ isConfirmed: true, code })} />
						) : !passwordIsSet ? (
							<SetPasswordPage source={"welcome"} username={username} respKey={key} code={code} onSetPassword={(password) => this.setState({ passwordIsSet: true, password })} />
						) : (
							<LoginPage source="welcome" login={login} showPostLogin={true} email={username} password={password} />
						)}
					</>
				)}
			</CenteredLogo>
		);
	}
}

export default withRouteChange(WelcomePage);
