import React, { Component } from "react";
// import moment from "moment";
import { SortParamsWrapper } from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import withRouteChange from "@threeskye/route-change";
import TransactionList from "./TransactionList";
import Desktop from "../../../../core-components/layouts/Desktop";
import { withAccountInfo, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import TransactionFilters, { transactionTypes } from "./TransactionFilters";
import Banner from "../../Banner";
import BannerInfoText, { BannerInfoMainText, BannerInfoHighlightText } from "../../../../core-components/misc/BannerInfoText";
import PortalContentWrapper from "../../PortalContentWrapper";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import ExportButton from "../../../../core-components/inputs/ExportButton";
import { SpaceBetweenDiv } from "../../../../core-components/layouts/FlexDiv";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import { dateRangeIsValidAndNonNull } from "../../../../core-components/inputs/DateRangeInput";
// import { DATE_FORMAT } from "@threeskye/core-components/dist/components/DateField/DateField";

class TransactionHistory extends Component {
	state = {
		errorMessage: null,
		transactionHistory: null,
		loadingMessage: null,
		currencies: [],
		selectedCurrency: null,
	};

	constructor(props) {
		super(props);
		this.updateTransactions = this.updateTransactions.bind(this);
		this.exportTransactions = this.exportTransactions.bind(this);
		this.setGenerateCSVFunc = this.setGenerateCSVFunc.bind(this);
	}

	async updateTransactions({ fromDate, toDate, selectedCurrency, units, price, totalCost, type, search, selectedAsset }) {
		const { account } = this.props;
		const { isNullOrUndefinedOrNaN } = CommonFunctions;
		if (this.transactionPromise) {
			await this.transactionPromise;
		}
		this.setState({ loadingMessage: "Getting transactions...", loadingInitialBalance: true });
		if (fromDate && toDate && dateRangeIsValidAndNonNull(fromDate, toDate)) {
			// const portfolioDate = moment(fromDate).subtract(1, "day").format(DATE_FORMAT);
			this.transactionPromise = Promise.all([
				this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/transactions?fromDate=${fromDate}&toDate=${toDate}`),
				// this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio/${portfolioDate}`),
				// this.props.storage.getOrFetch(`/modules/crm/classification-scheme/${account.classificationScheme.id}/model-data`),
			]).then(([transactionResp/*, portfolioResp, scheme*/]) => {
				if (!transactionResp || transactionResp.connectionError) {
					this.setState({ loadingMessage: null, connectionError: true, transactionHistory: null });
				} else {
					//Ensure its sorting from newest to oldest
					let transactionHistory = transactionResp.data && transactionResp.data.length > 0 ? transactionResp.data.slice() : null;
					const assets = transactionHistory
						? transactionHistory
								.filter(({ assetTransaction }) => !!assetTransaction)
								.reduce((assets, { asset, incomeTransaction, assetTransaction, nativeCurrency }) => {
									const ticker = (incomeTransaction && incomeTransaction.ticker) || (assetTransaction && assetTransaction.ticker) || nativeCurrency;
									if (!assets.find((tAndN) => tAndN.ticker === ticker && tAndN.asset === asset)) {
										assets.push({ asset, ticker });
									}
									return assets;
								}, [])
								.map(({ asset, ticker }) => ({ value: { asset, ticker }, label: asset.includes(`(${ticker})`) ? asset : `${asset} (${ticker})` }))
						: [];
					const currencies = transactionHistory
						? [...transactionHistory.reduce((currencies, { nativeCurrency }) => currencies.add(nativeCurrency), new Set())].map((c) => ({ value: c, label: c }))
						: [];
					if (transactionHistory && search && search.toLowerCase) {
						transactionHistory = transactionHistory.filter(({ narrative }) => narrative && narrative.toLowerCase && narrative.toLowerCase().includes(search.toLowerCase()));
					}
					if (transactionHistory && selectedAsset) {
						transactionHistory = transactionHistory.filter(({ incomeTransaction, assetTransaction, nativeCurrency, asset }) => {
							const ticker = (incomeTransaction && incomeTransaction.ticker) || (assetTransaction && assetTransaction.ticker) || nativeCurrency;
							return ticker === selectedAsset.ticker && asset === selectedAsset.asset;
						});
					}
					if (transactionHistory && units && !Number.isNaN(units)) {
						transactionHistory = transactionHistory.filter(
							({ assetTransaction }) =>
								assetTransaction && !isNullOrUndefinedOrNaN(assetTransaction.units) && assetTransaction.units.toFixed && Number(assetTransaction.units.toFixed(2)) === Number(units)
						);
					}
					if (transactionHistory && price && !Number.isNaN(price)) {
						transactionHistory = transactionHistory.filter(
							({ assetTransaction }) =>
								assetTransaction && !isNullOrUndefinedOrNaN(assetTransaction.price) && assetTransaction.price.toFixed && Number(assetTransaction.price.toFixed(2)) === Number(price)
						);
					}
					if (transactionHistory && totalCost && !Number.isNaN(totalCost)) {
						transactionHistory = transactionHistory.filter(
							({ nativeCurrencyValue }) => !isNullOrUndefinedOrNaN(nativeCurrencyValue) && nativeCurrencyValue.toFixed && Number(nativeCurrencyValue.toFixed(2)) === Number(totalCost)
						);
					}
					if (transactionHistory && type) {
						if (type === transactionTypes.sell) {
							transactionHistory = transactionHistory.filter(
								({ assetTransaction }) => assetTransaction && assetTransaction.type.includes("Sell")
							);
						} else if (type === transactionTypes.buy) {
							transactionHistory = transactionHistory.filter(
								({ assetTransaction }) => assetTransaction && assetTransaction.type.includes("Buy")
							);
						}
					}
					if (transactionHistory && selectedCurrency) {
						// transactionHistory = transactionHistory.filter(({ nativeCurrency }) => nativeCurrency === selectedCurrency);
						const thFiltered = [];
						transactionHistory.forEach(t => {
							if (t && t.nativeCurrency === selectedCurrency) {
								thFiltered.push(t);
							}
						});
						transactionHistory = thFiltered;
					}
					// if (!portfolioResp || portfolioResp.connectionError || !portfolioResp.data || !scheme || !portfolioResp.data.classes || !scheme.classes) {
					// 	this.setState({ loadingInitialBalance: false, initialBalanceConnectionError: true });
					// } else {
					// 	if (transactionHistory) {
					// 		const cashFund = portfolioResp.data.classes.find((portfolioClass) => (scheme.classes.find((schemeClass) => portfolioClass.classId === schemeClass.id) || {}).cash);
					// 		const balances =
					// 			cashFund && cashFund.holdings && cashFund.holdings.length > 0 ? (currencies.reduce((balances, { value }) => ({ ...balances, [value]: (cashFund.holdings.find(({ nativeCurrency }) => nativeCurrency === value) || {}).nativeValue }), {})) : {};
					// 		transactionHistory.forEach((transaction) => {
					// 			if (!CommonFunctions.isNullOrUndefinedOrNaN(balances[transaction.nativeCurrency]) && transaction.cashTransactions && transaction.cashTransactions.length > 0) {
					// 				balances[transaction.nativeCurrency] += transaction.cashTransactions.reduce((cashImpact, { amount }) => cashImpact + amount, 0);
					// 				transaction.nativeCurrencyBalance = balances[transaction.nativeCurrency];
					// 			} else {
					// 				transaction.nativeCurrencyBalance = null;
					// 			}
					// 		});
					// 	}
					// }
					transactionHistory = transactionHistory ? transactionHistory.slice().reverse() : null;
					this.setState({
						loadingMessage: null,
						connectionError: false,
						loadingInitialBalance: false,
						initialBalanceConnectionError: false,
						transactionHistory,
						currencies,
						selectedCurrency,
						assets,
					});
				}
			});
		} else {
			this.setState({
				loadingMessage: null,
				connectionError: false,
				loadingInitialBalance: false,
				initialBalanceConnectionError: false,
				transactionHistory: [],
				currencies: null,
				selectedCurrency,
				assets: [],
				withBalance: false,
			});
		}
	}

	exportTransactions() {
		if (this.generateCSV) {
			const { account } = this.props;
			const generatedCSV = this.generateCSV();
			const base64 = this.b64EncodeUnicode(generatedCSV);
			CommonFunctions.download({ base64, filename: `${account.name} Transaction History`, extension: "csv" });
		}
	}

	b64EncodeUnicode(str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    }

	setGenerateCSVFunc(func) {
		this.generateCSV = func;
	}

	render() {
		const { transactionHistory, loadingMessage, currencies, selectedCurrency, connectionError, assets, withBalance, loadingInitialBalance, initialBalanceConnectionError } = this.state;
		const { setGenerateCSVFunc } = this;
		const { account, portalAccountType } = this.props;
		return (
			<>
				<Mobile>
					<DefaultMobileBanner />
				</Mobile>
				<Desktop>
					<Banner>
						<BannerInfoText>
							<BannerInfoMainText>{account.name}</BannerInfoMainText>
							<BannerInfoHighlightText>{displayPortalAccType(portalAccountType)}</BannerInfoHighlightText>
						</BannerInfoText>
					</Banner>
				</Desktop>
				<PortalContentWrapper>
					<div id="portal-transaction-history">
						<div>
							<CardContainer>
								<Card>
									<CardHeader>
										<SpaceBetweenDiv>
											<h3>Transaction History</h3>
											<ExportButton onClick={this.exportTransactions} />
										</SpaceBetweenDiv>
									</CardHeader>
									{/* Filters */}
									<TransactionFilters currencies={currencies} assets={assets} onFiltersUpdate={this.updateTransactions} />
									<hr />
									<SortParamsWrapper>
										<TransactionList
											{...{ transactionHistory, loadingMessage, selectedCurrency, setGenerateCSVFunc, connectionError, withBalance, loadingInitialBalance, initialBalanceConnectionError }}
										/>
									</SortParamsWrapper>
								</Card>
							</CardContainer>
						</div>
					</div>
				</PortalContentWrapper>
			</>
		);
	}
}

export default withAccountInfo(withRouteChange(TransactionHistory));
