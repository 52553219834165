import React, { Component } from "react";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import CardContainer from "../../../../core-components/card/CardContainer";
import "./AssetGroups.scss";
import withRouteChange from "@threeskye/route-change";
import PortfolioAssetAllocationTable from "./portfolio-assets/PortfolioAssetAllocationTable";
import { withAccountInfo, PortalAccTypes } from "../../../../core-components/contexts/AccountsContext";
import NavigatorAssetAllocation from "./navigator-assets/NavigatorAssetAllocation";
import ErrorMessage from "../../../../core-components/layouts/ErrorMessage";

class AssetGroups extends Component {
	tableContentComponents = {
		[PortalAccTypes.NAVIGATOR]: NavigatorAssetAllocation,
		[PortalAccTypes.PORTFOLIO]: PortfolioAssetAllocationTable,
	};

	render() {
		const { account, portalAccountType, setGetCSVFunc } = this.props;
		const modelName = account.modelName ? ` - ${account.modelName}` : "";
		const AssetAllocationTable = this.tableContentComponents[portalAccountType];
		return (
			<CardContainer xs={12} xl={6}>
				<Card>
					<CardHeader>
						<h3>Asset Allocation {modelName}</h3>
					</CardHeader>
					{AssetAllocationTable ? <AssetAllocationTable setGetCSVFunc={setGetCSVFunc} /> : <ErrorMessage infoIcon>There is no asset allocation for this account type</ErrorMessage>}
				</Card>
			</CardContainer>
		);
	}
}

export default withAccountInfo(withRouteChange(AssetGroups));
