import React, { Component } from "react";
import "./AdviserListItem.scss";
import { Email, Phone } from "@material-ui/icons";
import { withThreeSkyeGlobal } from "@threeskye/global/dist/ThreeSkyeGlobal";
import ProfileImage from "../../../../core-components/misc/ProfileImage";

class AdviserListItem extends Component {

	state = {
		adviser: {}
	};

	componentDidMount() {
		const { adviser } = this.props;
		this.props.storage.getOrFetch(`/modules/crm/client/adviser/${adviser.id}`).then((adviser) => this.setState({ adviser }));
	}

	render() {
		const adviser = this.state.adviser;

		// if all adviser details are hidden return null.
		if (!adviser) {
			return null;
		}

		const adviserPhone = adviser && adviser.twoFANumber && adviser.twoFANumber.length > 0 && adviser.twoFANumber[0].content;
		const adviserName = adviser && `${adviser.preferredName || adviser.givenName || ""} ${adviser.middleNames || ""} ${adviser.familyName || ""}`;
		const adviserJobTitle = adviser && adviser.jobTitle ? adviser.jobTitle : "";

			return (
				<div className="adviser-list-item">
					<div className="adviser-info">
						<div className="advisor-image">
							<ProfileImage client={adviser} />
						</div>
						<div className="advisor-name-title">
							<span title={adviser && adviserName} className="adviser-name">{adviser ? adviserName : "-"}</span>
							<div className="adviser-role" >{adviserJobTitle}</div>
						</div>
					</div>
					<div className="adviser-list-icons">
						{adviser && adviser.userId &&
							<a href={`mailto:${adviser.userId}`} style={{ marginRight: adviser.ddi ? 10 : 34 }}>
								<Email />
							</a>
						}
						{adviser && adviser.ddi ? (
							<a href={`tel:${adviser.ddi}`}>
								<Phone />
							</a>
						) : adviserPhone ? (
							<a href={`tel:${[adviserPhone.idd ? `+${adviserPhone.idd}` : 0, adviserPhone.number, adviserPhone.extension || ""].join("")}`}>
								<Phone />
							</a>
						) : (
							""
						)
						}
					</div>
				</div >
			)
	}
}

export default withThreeSkyeGlobal(AdviserListItem);
