import React, { useState, useEffect } from "react";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import { displayIRDNumber, irdValidation, rwtOptions, pirOptions } from './OnboardingUtils'
import Input from '../../core-components/inputs/Input';
import DropDown from '../../core-components/inputs/DropDown'
import CountryTokenField from '../../core-components/inputs/CountryTokenField';
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import './Onboarding.scss'


const FatcaTax = (props) => {
    const { onBoardingState, setOnBoardingState, section } = props
    const { irdNumber, rwtRate, pirRate, countryOfBirth, countriesOfCitizenship, countriesOfResidence, contriesOfTaxResidence, } = onBoardingState
    const label = section && section.displayLabel ? section.label : null
    const subLabel = section && section.displaySubLabel ? section.subLabel : null

    const [isValidIRD, setIsValidIRD] = useState(true)
    const [onIRDEdit, setOnIRDEdit] = useState(false)
    const [openRWT, setOpenRWT] = useState(false)
    const [openPIR, setOpenPIR] = useState(false)


    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener("keydown", handleKeyDown)
    }, [])

    useEffect(() => {
        const validNumber = irdValidation(irdNumber)
        setIsValidIRD(validNumber)
    }, [irdNumber])


    function handleKeyDown(e) {
        if (e.key === "Tab" && !e.shiftKey) {
            if (e.target.getAttribute('name') && e.target.getAttribute('name') === "ird-number") {
                setOpenRWT(true)
                setOpenPIR(false)
            } else if (e.target.getAttribute('id') && e.target.getAttribute('id') === "rwt-rate") {
                setOpenRWT(false)
                setOpenPIR(true)
            } else {
                setOpenRWT(false)
                setOpenPIR(false)
            }
        } else if (e.key === "Tab" && e.shiftKey) {
            if (e.target.getAttribute('id') && e.target.getAttribute('id') === "pir") {
                setOpenRWT(true)
                setOpenPIR(false)
            } else if (e.target.parentElement.className.includes('token-field__input-container')) {
                const originalAncestor = e.target.parentElement.parentElement.parentElement.parentElement
                if (originalAncestor.className.includes('country-field single-value')) {
                    setOpenPIR(true)
                    setOpenRWT(false)
                }
            } else {
                setOpenPIR(false)
                setOpenRWT(false)
            }
        }
    }

    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel &&<div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
                <Row>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <Input
                            onBlur={() => setOnIRDEdit(false)}
                            onFocus={() => setOnIRDEdit(true)}
                            inputStyle={{ letterSpacing: 1 }}
                            type={onIRDEdit ? "number" : "text"}
                            label="IRD Number*"
                            name="ird-number"
                            value={onIRDEdit ? irdNumber : displayIRDNumber(irdNumber)}
                            placeholder="IRD Number"
                            onChange={(e) => {
                                setOnBoardingState({ onBoardingState: { ...onBoardingState, irdNumber: e } })
                            }}
                            errorMessage={isValidIRD ? "" : "Please enter a valid IRD number"}
                            errorMessageDown
                            invalidState={!isValidIRD}
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>RWT Rate</span>
                            </div>
                            <DropDown id="rwt-rate" openDropDown={openRWT} clickable placeholder="Select a Resident Withholding Tax rate" value={rwtRate} options={rwtOptions} updateValue={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, rwtRate: option } })} fullWidth />
                        </label>
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>PIR</span>
                            </div>
                            <DropDown id="pir" openDropDown={openPIR} clickable placeholder="Select a Prescribed Investor Rate" value={pirRate} options={pirOptions} updateValue={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, pirRate: option } })} fullWidth />
                        </label>
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={countryOfBirth}
                            isMulti={false}
                            label="Country of Birth*"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, countryOfBirth: option } })}
                            publicRoute
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={countriesOfCitizenship}
                            isMulti
                            label="Countries of Citizenship*"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, countriesOfCitizenship: option } })}
                            publicRoute
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={countriesOfResidence}
                            isMulti
                            label="Countries of Residence*"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, countriesOfResidence: option } })}
                            publicRoute
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={contriesOfTaxResidence}
                            isMulti
                            label="Countries of Tax Residence*"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, contriesOfTaxResidence: option } })}
                            publicRoute
                        />
                    </Col>
                </Row>
        </>
    )
}

export default FatcaTax