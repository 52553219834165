import React, { Component } from "react";
import { Col } from "reactstrap";
import PropTypes from 'prop-types';
import "./Cards.scss";

const style = { padding: 14.5 };

class CardContainer extends Component {
	render() {
		return (
			<Col xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} xl={this.props.xl} style={style} className={this.props.className}>
				{this.props.children}
			</Col>
		);
	}
}

const ColumnProps = PropTypes.oneOfType([
	PropTypes.number,
	PropTypes.string,
	PropTypes.shape({
		size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	})
]);

CardContainer.propTypes = {
	xs: ColumnProps,
	sm: ColumnProps,
	md: ColumnProps,
	lg: ColumnProps,
	xl: ColumnProps,
};

export default CardContainer;
