import React from 'react';
import PropTypes from 'prop-types';

const FlexWrapper = ({ justify, align, width, children, className, gap, wrap, style, onClick }) => {
  const wrapperStyles = {
    display: 'flex',
    justifyContent: justify || 'flex-start',
    alignItems: align || 'center',
    width: width || '100%',
    gap: gap || 0,
    flexWrap: wrap || 'wrap',
    ...style,
  };

  return <div style={wrapperStyles} className={className} onClick={onClick}>{children}</div>;
};

FlexWrapper.propTypes = {
  justify: PropTypes.string,
  align: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default FlexWrapper;
