import React, { Component } from 'react';
import "./HorizontalListRow.scss";

class HorizontalListRow extends Component {
	render() { 
		return ( 
			<div className="horizontal-list-row">
				{this.props.children}
			</div>
		 );
	}
}
 
export default HorizontalListRow;