import React, { Component } from "react";
import Token from "./Token";
import "./BannerInfoText.scss";

class BannerInfoText extends Component {
	render() {
		const { children } = this.props;
		return (
			<div className="banner-info-extension" style={ {padding: this.props.noPadding? 0: null}} >
				<div className="container">
					<div className="banner-info-text">{children}</div>
				</div>
			</div>
		);
	}
}

export class BannerInfoIntroText extends Component {
	render() {
		const { children, className} = this.props;
		return <div className={"banner-intro-text" + className ? " " + className : ""}>{children}</div>;
	}
}

export class BannerInfoMainText extends Component {
	render() {
		const { children, className } = this.props;
		return <div className={"banner-main-text" + className ? " " + className : ""}>{children}</div>;
	}
}

export class BannerInfoHighlightText extends Component {
	render() {
		const { children, warning } = this.props;
		return (
			<div className="banner-highlight-text">
				<Token warning={warning} text={children} />
			</div>
		);
	}
}

export default BannerInfoText;
