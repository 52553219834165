import React, { Component } from "react";
import Button from "@threeskye/buttons";
import './DetailsLinkage.scss';
import { withStorage, withRemote } from "@threeskye/global";
import ToolTip from "../../../../core-components/layouts/ToolTip";
// import Hotkeys from 'react-hot-keys';
import { debounce } from "throttle-debounce";
import {Info} from "@material-ui/icons"

// NB react-keyboard-shortcuts used elsewhere in 3skye provides global hot keys.  This model does not work
// here because DetailsLinkage is repeated, so the first one always grabs the event.  We can make it pass
// the event so all close on esc but that's not ideal either, so we'll use an in-focus handler here.
//When used MUST have a key otherwise will not update on props updates!!!
class DetailsLinkage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			editMode: this.props.instantAdd,
			addMode: this.props.instantAdd,
			deleteMode: false,
			value: this.props.value,
			isValid: true,
			uniquenessValidity: { isValid: true },
			isUniquenessLoading: false,
		}   
	
		this.cancel = this.cancel.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
		this.updateUniquness = debounce(300, this.updateUniquness.bind(this));
		this.autosave = debounce(300, this.saveChanges.bind(this));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value });
		}
		if (prevProps.revertKey && this.props.revertKey && prevProps.revertKey !== this.props.revertKey) {
			this.setState({ value: this.props.value });
		}
		if (prevState.value !== this.state.value) {
			if (this.props.fetchUniqueness) {
				//is a unique field
				this.setState({ isUniquenessLoading: true });
				this.updateUniquness();
			}
		}
	}

	componentDidMount() {
		this.mounted = true;

		if (this.props.setEdittableConsumer) {
			this.props.setEdittableConsumer(()=>this.state.editMode || (this.props.editable && this.setState({editMode: true})));
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	updateUniquness() {
		//is a unique field
		this.props.fetchUniqueness(this.state.value)
			.then(uniquenessValidity => {
				if (this.mounted) {
					this.setState({ isUniquenessLoading: false, uniquenessValidity})
				}
			})
	}

	cancel() {
		this.setState({value: this.props.value, editMode: false, editModeData: null}); 
		if (this.props.cancelDeletes) {
			this.props.onDelete();
		}
		return true;
	}

	saveChanges() {
		let canSave = this.props.validate ? this.props.validate(this.state.value).isValid : true;

		if(canSave) {
			this.props.onChange(this.state.value)
		}
	}

	render() {
		const { isUniquenessLoading, uniquenessValidity } = this.state;
		let outerClassName = "details-item-outer";
		if (this.props.className) {
			outerClassName += " " + this.props.className;
		}
		if (this.state.editMode) {
			outerClassName += " edit-mode";
		}
		if (!this.props.editable) {
			outerClassName += " read-only"
		} else {
			outerClassName += " editable"
		}
		//Prep for edit
		let { cancelDeletes, onChange, onDelete, value, validate, omitLabel, displayId, ...passableProps } = this.props;

		let label = this.state.editMode ? (this.props.hasOwnProperty("editLabel") ? this.props.editLabel : this.props.label) : this.props.label;

		let pending = (label && this.props.pending) ? (
			<ToolTip title={"Pending changes"}>
				<Info className="pending" />
			</ToolTip>
		) : null

		// let i = this.props.i18n.get;
		let canSave = validate ? validate(this.state.value).isValid : true;
		if (this.state.value && this.state.value.valid) {
			canSave = canSave && this.state.value.valid.isValid;
		}
		canSave = canSave && !isUniquenessLoading;
		if (uniquenessValidity) {
			canSave = canSave && uniquenessValidity.isValid;
		}

		const editComponent = this.state.addMode ? (this.props.add || this.props.edit) : this.props.edit;
		
		return <div className={outerClassName} id={displayId}
				onClick={() => {
					if (!this.state.editMode && this.props.editable && !(!this.props.editable && this.props.onClick && this.props.onClick())) {
						this.setState({editMode: true});
					}
				}}>
			{ 
				this.state.editMode ? 
					// <Hotkeys 
					// 	keyName="esc"
					// 	onKeyDown={this.cancel}
					// >
					<div className="edit-mode-content">
						{omitLabel ? "" : <label>{label} {pending}</label>}
						{React.cloneElement(editComponent, {
							value: this.state.value,
							onChange: (value, callback) => {this.setState({ value }, callback); this.autosave()},
							inDelete: this.state.deleteMode,
							validate,
							omitLabel,
							label,
							doSaveChanges: this.saveChanges,
							data: this.state.editModeData,
							uniquenessValidity: this.props.fetchUniqueness ? uniquenessValidity : undefined,
							remote: this.props.remote,
							storage: this.props.storage,
							instantAdd: this.props.instantAdd,
							...passableProps
						})}
						<div className="details-item-buttons">
							<Button onClick={this.saveChanges} classNames="mr-1" size="small" disabled={!canSave} loading={isUniquenessLoading}>Save Changes</Button>
							<Button onClick={this.cancel} type="secondary" size="small" >Cancel</Button>
							{this.props.deletable && <Button onClick={e=>{e.stopPropagation();this.setState({editMode: true, deleteMode: true})}} icon="delete_forever" type="warning" classNames="ml-auto" outlined size="small">Delete</Button>}
						</div>
						{ this.state.deleteMode && <div className="details-item-delete-overlay">
							<div className="details-item-overlay-inner">
								<p>Delete {label && label.toLowerCase && label.toLowerCase()}?</p>
								<div className="details-item-delete-overlay-buttons">
									<Button onClick={()=>this.props.onDelete()} classNames="mr-1" size="small">Confirm</Button>
									<Button onClick={()=>this.setState({deleteMode: false, editMode: false, editModeData: null})} type="secondary" size="small">Cancel</Button>
								</div>
							</div>
						</div> }
					</div>
					// </Hotkeys>
					: <>
						{omitLabel ? "" : <label>{label} {pending}</label>}
						{React.cloneElement(this.props.view, {
							onSetEditMode: data=>{this.setState({editMode: true, editModeData: data})},
							onDelete: e=>{e.stopPropagation();this.setState({editMode: true, deleteMode: true})},
							value: this.state.value,
							...passableProps})}
					</>
				}
		</div>
	}
}

export default withStorage(withRemote(DetailsLinkage));