import React, { Component } from "react";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import "./RadioInput.scss";

class RadioInput extends Component {
	render() {
		const { label, checked, name, onClick, radioButtonClass, disabled } = this.props;
		return (
			<div className={`radio-input-field ${radioButtonClass ? checked ? "checked " + radioButtonClass : radioButtonClass : ""} ${disabled? "disabled" : ""}`} onClick={onClick}>
				{checked ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
				<label htmlFor={name}>{label}</label>
			</div>
		);
	}
}

export default RadioInput;
