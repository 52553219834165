import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

class VerticalSpacer extends Component {
	render() {
		const VerticalSpacer = styled.div`
			width: "100%";
			${this.props.size === "fill" ? "flex: 1" : `height: ${this.props.size} ${this.props.borderBottom ? "; border-bottom: solid 1.5px red" : ""}`}
		`;
		return <VerticalSpacer />
	}
}

VerticalSpacer.propTypes = {
	size: PropTypes.string.isRequired,
};

export default VerticalSpacer;
