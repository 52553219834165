import React, { Component } from "react";
import { DateRange } from "@material-ui/icons";
import DateField from "@threeskye/core-components/dist/components/DateField/DateField";
import CommonFunctions from "../functions/CommonFunctions";

class DateFieldInput extends Component {
	constructor(props) {
		super(props);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	onKeyPress(e) {
		if (e.key === "Enter" && this.props.onEnter) {
			this.props.onEnter();
		}
	}

	render() {
		const { label, date, onChange, className, onBlur, stateShouldUpdateWhenPropsChange, acceptsNonNullPropsValues } = this.props;
		const { asClassName } = CommonFunctions;
		return (
			<div className={asClassName(["date-field", className])} tabIndex="-1" onBlur={onBlur} onKeyPress={this.onKeyPress}>
				{label && <div className="filter-heading">{label}</div>}
				<DateField IconOverride={<DateRange />} date={date} onChange={onChange} stateShouldUpdateWhenPropsChange={stateShouldUpdateWhenPropsChange} acceptsNonNullPropsValues={acceptsNonNullPropsValues} />
			</div>
		);
	}
}

export default DateFieldInput;
