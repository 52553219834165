import React, { useEffect, useState, useRef } from "react";
import withRouteChange from "@threeskye/route-change";
import { withThreeSkyeGlobal } from "@threeskye/global";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import Button from '../../core-components/inputs/Button';
import VerticalSpacer from '../../core-components/layouts/VerticalSpacer'
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import CompanyImage from '../../core-components/layouts/CompanyImage'
import './Onboarding.scss'

const Shares = (props) => {
    const { onBoardingState, setOnBoardingState, handleNumberChange, section, remote } = props
    const { shares } = onBoardingState
    const label = section.displayLabel ? section.label : null
    const subLabel = section.displaySubLabel ? section.subLabel : null

    const [allTickers, setAllTickers] = useState(null)
    const [searchValue, setSearchValue] = useState("");
    const [matchingOptions, setMatchingOptions] = useState([]);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const optionsListRef = useRef(null);

    useEffect(() => {
        remote.get("/public/modules/hobson-support/stockfox/classification-scheme/Hobson").then((fetchedAssets) => {
            if (fetchedAssets) {
                const anotherListOfAssets = fetchedAssets.unclassified.concat(
                    fetchedAssets.classes.flatMap(classObj => classObj.assets)
                )
                // const allTickers = fetchedTickers.classes.flatMap(classObj => classObj.assets)
                setAllTickers(anotherListOfAssets)
            }
        })
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (optionsListRef.current && !optionsListRef.current.contains(event.target)) {
                setMatchingOptions([])
                setSearchValue("")
            }
        };
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [optionsListRef]);


    useEffect(() => {
        if (optionsListRef.current && selectedOptionIndex > -1 && optionsListRef.current.children && optionsListRef.current.children[selectedOptionIndex]) {
            optionsListRef.current.children[selectedOptionIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
            });
        }
    }, [selectedOptionIndex]);


    useEffect(() => {
        if (matchingOptions && matchingOptions.length === 0) setSelectedOptionIndex(-1)
    }, [matchingOptions])


    function removeTicker(index) {
        const updatedShares = shares.filter((p, idx) => idx !== index)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, shares: updatedShares } })
    }

    function updateTickerValue(e, index, string) {
        const formattedValue = handleNumberChange(e, string)
        const updatedShares = shares.map((s, idx) => {
            if (idx === index) {
                const newObject = { ticker: s.ticker, units: string === "units" ? formattedValue : s.units, costPerUnit: string === "costPerUnit" ? formattedValue : s.costPerUnit }
                return newObject
            } else return s
        })
        setOnBoardingState({ onBoardingState: { ...onBoardingState, shares: updatedShares } })
    }


    const handleInputChange = (e) => {
        const { value } = e.target;
        setSearchValue(value);
        const list = allTickers.filter(ticker => {
            if (shares) {
                return !shares.map((t) => t.id).includes(ticker.id)
            } else return allTickers
        })
        const matchingOptions = list.filter((option) => (option.name !== null && option.name.toLowerCase().startsWith(value.toLowerCase())) || (option.tickerCode && option.tickerCode.toLowerCase().startsWith(value.toLowerCase())))
        if (list.length === matchingOptions.length) {
            setMatchingOptions([])
        } else setMatchingOptions(matchingOptions)
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            if (selectedOptionIndex < matchingOptions.length - 1) setSelectedOptionIndex(selectedOptionIndex + 1)
            if (selectedOptionIndex === matchingOptions.length - 1) setSelectedOptionIndex(-1)
        } else if (event.key === 'ArrowUp') {
            if (selectedOptionIndex > -1) setSelectedOptionIndex(selectedOptionIndex - 1)
            if (selectedOptionIndex === -1) setSelectedOptionIndex(matchingOptions.length - 1)
        } else if (event.key === 'Enter' && selectedOptionIndex > -1) {
            handleOptionClick(selectedOptionIndex)
            setSelectedOptionIndex(-1)
        }
    }

    const handleOptionClick = (index) => {
        const option = matchingOptions[index];
        const existingAsset = shares && shares.lengh > 0 && shares.find(s => s.id === option.id)

        if (existingAsset === false || existingAsset === undefined) {
            const tickers = shares && shares.length > 0 ? [...shares, { ticker: option, units: null, costPerUnit: null }] : [{ ticker: option, units: null, costPerUnit: null }]
            setOnBoardingState({ onBoardingState: { ...onBoardingState, shares: tickers } })
            setSelectedOptions([...selectedOptions, option]);
            setSearchValue("");
            setMatchingOptions([])
        } else {
            setSearchValue("")
            setMatchingOptions([])
        }
        setSelectedOptionIndex(index)
    };

    const handleOptionHover = (index) => {
        setSelectedOptionIndex(index)
    };


    return (
        <>
           {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel &&<div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="12" className="pl-xl-3">
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="shares-dropdown">
                            <input
                                placeholder="Search shares"
                                type="text"
                                value={searchValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                            />
                            {matchingOptions.length > 0 && (
                                <ul className='dropdown-selection-list' style={{ zIndex: 10, width: "fit-content" }} ref={optionsListRef} tabIndex='0'>
                                    {matchingOptions.map((option, index) => (
                                        <li
                                            key={option.id}
                                            onClick={() => handleOptionClick(index)}
                                            onMouseEnter={() => handleOptionHover(index)}
                                            onKeyDown={handleKeyDown}
                                            className={`individual-match ${selectedOptionIndex === index ? "selected" : ""}`}
                                            style={{ backgroundColor: index === selectedOptionIndex ? "#f3f6f7" : null }}
                                        >
                                            <div className="asset-label" >
                                                <span>{option.tickerCode + (option.exchange ? ("." + option.exchange) : "") + " - " + option.name}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </label>
                </Col>
                {shares && shares.length > 0 &&
                    <div className="share-sections-label">
                        <p className="share-section-label">Stock</p>
                        <p className="share-section-label">Units: </p>
                        <p className="share-section-label">Cost per unit: </p>
                        <p className="share-section-label">&nbsp;</p>
                    </div>
                }
                {shares && shares.length > 0 && shares.map((s, idx) => {
                    return (
                        <Col xs="12" xl="12" className="pl-xl-3" key={idx}>
                            <div className="shares-owned-card" >
                                <div className="share-section-container">
                                    <div className="ticker-info" >
                                        <CompanyImage exchange={s.ticker.nativeCurrency} tickerCode={s.ticker.tickerCode} alt={s.ticker.tickerCode} size={34} />
                                        <div className="ticker-code">{s.ticker.tickerCode || ""}</div>
                                        <div className="ticker-name">{s.ticker.name || ""}</div>
                                    </div>
                                </div>
                                <div className="share-section-container">
                                    <input autoFocus={s.units === null} value={s.units || ""} placeholder="0" name={"share-units-" + idx} onChange={(e) => updateTickerValue(e, idx, "units")} />
                                </div>
                                <div className="share-section-container">
                                    <input value={s.costPerUnit || ""} placeholder="$0" name={"share-cost-per-unit-" + idx} onChange={(e) => updateTickerValue(e, idx, "costPerUnit")} />
                                </div>
                                <div className="share-section-container">
                                    <i className="material-icons delete-icon" onClick={() => removeTicker(idx)}>delete_forever</i>                                    </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
            <VerticalSpacer size="20px" />
        </>

    )
}

export default withThreeSkyeGlobal(withRouteChange(Shares))