import React, { Component } from "react";
import moment from "moment";
import ThreeSkyeBarChart from "@threeskye/core-components/dist/components/Charts/ThreeSkyeBarChart";
import Formatter from "../../../../../core-components/functions/Formatter";
import { Y_AXIS_PROPS as yAxisProps } from "../../../../../core-components/functions/ChartFunctions";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";

class YearBarChart extends Component {
	xAxisPropsDefault = {
		type: "number",
		domain: [(dataMin) => Math.min(dataMin - 1, parseInt(moment().format("YYYY"))), "dataMax + 1"],
		allowDecimals: false,
		tickLine: true,
		axisLine: false,
		interval: 0,
		onClick: (e) => this.filterByYear(e.value)
	};
	refAreaProps = { fill: "#30395a" };
	barProps = { onClick: (e) => this.filterByYear(e.payload.x) };

	constructor(props) {
		super(props);
		this.renderTooltip = this.renderTooltip.bind(this);
		this.filterByYear = this.filterByYear.bind(this);
		this.getCellProps = this.getCellProps.bind(this);
	}

	renderTooltip(payload) {
		if (!payload.active || payload.payload.length === 0) {
			return null;
		}
		let value = Formatter.asMoney(payload.payload[0].payload.y, 2);
		let date = payload.payload[0].payload.x;
		return (
			<div className="graph-tooltip">
				<div className="graph-tooltip-label">{date}</div>
				<div className="graph-tooltip-value">{value}</div>
			</div>
		);
	}

	filterByYear(year) {
		const { filters, setFilters } = this.props;
		if (filters) {
			if (filters.filterAssetsBy !== year) {
				setFilters({ ...filters, filterAssetsBy: year });
			} else {
				setFilters({ ...filters, filterAssetsBy: null });
			}
		}
	}

	getCellProps(singleData) {
		const { filters } = this.props;
		if (filters) {
			const { filterAssetsBy } = filters;
			if (filterAssetsBy) {
				if (filterAssetsBy && filterAssetsBy === singleData.x) {
					return { className: "active-cell" };
				} else {
					return { className: "inactive-cell" };
				}
			}
		}
	}

	render() {
		const { data } = this.props;
        const { xAxisPropsDefault, renderTooltip, refAreaProps, barProps, getCellProps } = this;
        
        const xValues = data.map(({ x }) => x);
        const min = Math.min(...xValues);
        const max = Math.max(...xValues);
        const range = max - min;
        const tickInterval = range < 12 ? 1 : 4;
        const firstTick = Math.floor(min/tickInterval)*tickInterval;

        const tickCount = range * 2;
        const tickFormatter = (tick) => (tick % tickInterval === 0 ? tick : "");
		const xAxisProps = { ...xAxisPropsDefault, tickCount, tickFormatter };
		const refAreas = Array(Math.floor((max - min) / (tickInterval * 2) + 1)).fill("").map((_, idx) => ({ x1: firstTick + idx * tickInterval * 2, x2: firstTick + idx * tickInterval * 2 + tickInterval }));
		return <ThreeSkyeBarChart {...{ data, yAxisProps, xAxisProps, renderTooltip, refAreas, refAreaProps, barProps, getCellProps }} refAreaFill="#30395a" />;
	}
}

export default withFilters(YearBarChart);
