import React, { Component } from "react";
import moment from "moment";
import { compareMonthsFromToday, compareNumbers } from "@threeskye/core-components/dist/components/Sorting/CompareFunctions";
import MonthYearBarChart from "./MonthYearBarChart";
import { MONTH_YEAR_TIME_FRAMES } from "../../../../../core-components/functions/ChartFunctions";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import { emptyDataByMonth } from "./MonthBarChart";

class MaturityBarChart extends Component {
	state = { dataByTimeFrame: null };

	constructor(props) {
		super(props);
		this.updateDataByTimeFrame = this.updateDataByTimeFrame.bind(this);
	}

	updateDataByTimeFrame(callback) {
		const { data } = this.props;
		const { selectedTimeFrame } = this.props.filters;
		const monthTimeFrame = selectedTimeFrame === MONTH_YEAR_TIME_FRAMES.MONTHS;
		let dataByTimeFrame = [];
		if (data && data.length > 0) {
			const valueBySelectedTime = data
				.filter(({ maturityDate }) => (monthTimeFrame ? moment(maturityDate).isBetween(moment(), moment().add(1, "year")) : true))
				.reduce((valueByTimeMap, { maturityDate, units }) => {
					const time = monthTimeFrame ? moment(maturityDate).format("MMM") : moment(maturityDate).year();
					const valueOfTime = units + (valueByTimeMap[time] || 0);
					return { ...valueByTimeMap, [time]: valueOfTime };
				}, monthTimeFrame ? emptyDataByMonth : {});
			dataByTimeFrame = Object.keys(valueBySelectedTime)
				.sort(monthTimeFrame ? compareMonthsFromToday : compareNumbers)
				.map((time) => ({ x: monthTimeFrame ? time : parseInt(time), y: valueBySelectedTime[time] }));
		}
		this.setState({ dataByTimeFrame }, callback);
	}

	render() {
		const { data } = this.props;
		const { selectedTimeFrame } = this.props.filters;
		const { dataByTimeFrame } = this.state;
		return <MonthYearBarChart updateDataByTimeFrame={this.updateDataByTimeFrame} selectedTimeFrame={selectedTimeFrame} data={dataByTimeFrame} watchedObj={data} />;
	}
}

export default withFilters(withAccountInfo(withThreeSkyeGlobal(MaturityBarChart)));
