import React, { Component } from "react";
import moment from "moment";
import { compareStrings, compareDates, baseCompare, compareNumbers } from "@threeskye/core-components/dist/components/Sorting/CompareFunctions";
import withSortParams from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import Token from "../misc/Token";
import Formatter from "../functions/Formatter";
import ErrorMessage from "../layouts/ErrorMessage";
import ToolTip from "../layouts/ToolTip";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withAccountInfo } from "../contexts/AccountsContext";
import { DATE_FORMAT } from "@threeskye/core-components/dist/components/DateField/DateField";
import SortByHeader from "./SortByHeader";
import withFilters from "../filters-and-toggles/withFilters";
import { MONTH_YEAR_TIME_FRAMES } from "../functions/ChartFunctions";
import { FIXED_INTEREST_TYPES } from "../../layouts/CustomerPortalPage/subpages/Analytics/FixedInterest";
import CommonFunctions from "../functions/CommonFunctions";

const fields = {
	asset: "ASSET",
	name: "ASSET_NAME",
	type: "TYPE",
	rating: "RATING",
	maturity: "MATURITY",
	coupon: "COUPON",
	units: "UNITS",
	unitCost: "UNIT_COST",
	unitPrice: "UNIT_PRICE",
	nzdCost: "NZD_COST",
	nzdValue: "NZD_VALUE",
	annualIncome: "ANNUAL_INCOME",
	interestPayments: "INTEREST_PAYMENTS",
};

const display = {
	[fields.asset]: ({ tickerCode }) => tickerCode,
	[fields.name]: ({ name }) => name,
	[fields.type]: ({ type }) => type,
	[fields.rating]: ({ rating }) => rating,
	[fields.maturity]: ({ maturityDate }) => (maturityDate ? Formatter.fromIsoDateToDisplay(maturityDate) : null),
	[fields.coupon]: ({ couponRate }) => (CommonFunctions.isNullOrUndefined(couponRate) ? null : Formatter.asPercentage(couponRate * 100, 2)),
	[fields.units]: ({ units }) => (CommonFunctions.isNullOrUndefined(units) ? null : Formatter.numberWithCommas(units, 0)),
	[fields.unitCost]: ({ unitCost }) => (CommonFunctions.isNullOrUndefined(unitCost) ? null : Formatter.numberWithCommas(unitCost, 4)),
	[fields.unitPrice]: ({ unitPrice }) => (CommonFunctions.isNullOrUndefined(unitPrice) ? null : Formatter.numberWithCommas(unitPrice, 4)),
	[fields.nzdCost]: ({ assetCost }) => (CommonFunctions.isNullOrUndefined(assetCost) ? null : Formatter.numberWithCommas(assetCost, 2)),
	[fields.nzdValue]: ({ value }) => (CommonFunctions.isNullOrUndefined(value) ? null : Formatter.numberWithCommas(value, 2)),
	[fields.annualIncome]: ({ annualIncome }) => (CommonFunctions.isNullOrUndefined(annualIncome) ? null : Formatter.numberWithCommas(annualIncome, 2)),
	[fields.interestPayments]: ({ interestPayments }) => (CommonFunctions.isNullOrUndefined(interestPayments) ? null : interestPayments),
};

const headers = {
	[fields.asset]: "Asset",
	[fields.name]: "Asset Name",
	[fields.type]: "Type",
	[fields.rating]: "Rating",
	[fields.maturity]: "Maturity",
	[fields.coupon]: "Coupon",
	[fields.units]: "Units",
	[fields.unitCost]: "Unit Cost",
	[fields.unitPrice]: "Current Unit Price",
	[fields.nzdCost]: "NZD Cost",
	[fields.nzdValue]: "Current NZD Value",
	[fields.annualIncome]: "Annual Income",
	[fields.interestPayments]: "Interest Payments",
};

const compareFunctions = ({ field, isDescending }) => {
	let nameAndFuncTuple = null;
	switch (field) {
		case fields.asset:
			nameAndFuncTuple = ["tickerCode", compareStrings];
			break;
		case fields.type:
			nameAndFuncTuple = ["type", compareStrings];
			break;
		case fields.rating:
			nameAndFuncTuple = ["rating", compareStrings];
			break;
		case fields.maturity:
			nameAndFuncTuple = ["maturityDate", compareDates];
			break;
		case fields.coupon:
			nameAndFuncTuple = ["couponRate", compareNumbers];
			break;
		case fields.units:
			nameAndFuncTuple = ["units", compareNumbers];
			break;
		case fields.unitCost:
			nameAndFuncTuple = ["unitCost", compareNumbers];
			break;
		case fields.unitPrice:
			nameAndFuncTuple = ["unitPrice", compareNumbers];
			break;
		case fields.nzdCost:
			nameAndFuncTuple = ["assetCost", compareNumbers];
			break;
		case fields.nzdValue:
			nameAndFuncTuple = ["value", compareNumbers];
			break;
		case fields.annualIncome:
			nameAndFuncTuple = ["annualIncome", compareNumbers];
			break;
		case fields.interestPayments:
			nameAndFuncTuple = [
				"interestPayments",
				(a, b) => {
					a = a && a.split("/");
					a = a && a.length > 0 && moment(a[0], "MMM");
					b = b && b.split("/");
					b = b && b.length > 0 && moment(b[0], "MMM");
					// b = b && b.length > 0 && moment(`01-${b[0]}-2020`, "DD-MMMM-YYYY");
					if (a) {
						a = a.format(DATE_FORMAT);
					}
					if (b) {
						b = b.format(DATE_FORMAT);
					}
					return compareDates(a, b);
				},
			];
			break;
		default:
			return null;
	}
	const [name, compareFunction] = nameAndFuncTuple;
	return (f1, f2) => baseCompare(isDescending, f1, f2, name, compareFunction);
};

class Header extends Component {
	render() {
		const { field } = this.props;
		return <SortByHeader field={field}>{headers[field]}</SortByHeader>;
	}
}

class FixedInterestAssetTable extends Component {
	state = { sortedAndFilteredAssets: null };

	constructor(props) {
		super(props);
		this.updateSortedAndFilteredAssets = this.updateSortedAndFilteredAssets.bind(this);
		this.generateCSV = this.generateCSV.bind(this);

		if (props.setGenerateCSVFunc) {
			props.setGenerateCSVFunc(this.generateCSV);
		}
	}

	componentDidMount() {
		this.updateSortedAndFilteredAssets();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data || prevProps.sortParams !== this.props.sortParams || prevProps.filters !== this.props.filters) {
			this.updateSortedAndFilteredAssets();
		}
		if ((prevProps.filters && prevProps.filters.filterAssetsBy) !== (this.props.filters && this.props.filters.filterAssetsBy) && this.props.scrollIntoView) {
			this.props.scrollIntoView();
		}
	}

	updateSortedAndFilteredAssets() {
		const { data, sortParams, filters } = this.props;
		if (data && data.length > 0) {
			let sortedAndFilteredAssets = data.slice();
			if (filters && filters.filterAssetsBy) {
				if (filters.selectedFixedInterestType === FIXED_INTEREST_TYPES.INCOME) {
					if (filters.selectedTimeFrame === MONTH_YEAR_TIME_FRAMES.MONTHS) {
						sortedAndFilteredAssets = sortedAndFilteredAssets.filter(({ interestPayments }) => interestPayments && interestPayments.includes(filters.filterAssetsBy));
					} else if (filters.selectedTimeFrame === MONTH_YEAR_TIME_FRAMES.YEARS) {
						sortedAndFilteredAssets = sortedAndFilteredAssets.filter(({ maturityDate }) => filters.filterAssetsBy >= moment().year() && filters.filterAssetsBy <= moment(maturityDate).year());
					}
				} else if (filters.selectedFixedInterestType === FIXED_INTEREST_TYPES.MATURITY) {
					if (filters.selectedTimeFrame === MONTH_YEAR_TIME_FRAMES.MONTHS) {
						const today = moment();
						const nextYear = moment().add(1, "year");
						sortedAndFilteredAssets = sortedAndFilteredAssets.filter(
							({ maturityDate }) => moment(maturityDate).format("MMM") === filters.filterAssetsBy && nextYear.isAfter(maturityDate) && today.isBefore(maturityDate)
						);
					} else if (filters.selectedTimeFrame === MONTH_YEAR_TIME_FRAMES.YEARS) {
						sortedAndFilteredAssets = sortedAndFilteredAssets.filter(({ maturityDate }) => parseInt(moment(maturityDate).format("YYYY")) === filters.filterAssetsBy);
					}
				} else if (filters.selectedFixedInterestType === FIXED_INTEREST_TYPES.CREDIT_RATING) {
					sortedAndFilteredAssets = sortedAndFilteredAssets.filter(({ rating }) => rating === filters.filterAssetsBy);
				}
			}
			if (sortParams) {
				const compareFunction = compareFunctions(sortParams);
				if (compareFunction) {
					sortedAndFilteredAssets = sortedAndFilteredAssets.sort(compareFunction);
				}
			}
			this.setState({ sortedAndFilteredAssets });
		}
	}

	generateCSV() {
		const { sortedAndFilteredAssets } = this.state;
		const fieldsInOrder = [
			fields.asset,
			fields.name,
			fields.type,
			fields.rating,
			fields.maturity,
			fields.coupon,
			fields.units,
			fields.unitCost,
			fields.unitPrice,
			fields.nzdCost,
			fields.nzdValue,
			fields.annualIncome,
			fields.interestPayments,
		];
		return [fieldsInOrder.map((field) => headers[field])]
			.concat(sortedAndFilteredAssets.map((asset) => fieldsInOrder.map((field) => `"${display[field](asset)}"`)))
			.map((row) => row.join(","))
			.join("\n");
	}

	render() {
		const { sortedAndFilteredAssets } = this.state;
		return (
			<div className="fi-table-container">
				{sortedAndFilteredAssets && sortedAndFilteredAssets.length > 0 ? (
					<table>
						<thead>
							<tr>
								{/* Asset */}
								<th>
									<Header field={fields.asset} />
								</th>
								{/* Type */}
								<th className="d-none d-sm-table-cell">
									<Header field={fields.type} />
								</th>
								{/* Rating */}
								<th>
									<Header field={fields.rating} />
								</th>
								{/* Maturity */}
								<th>
									<Header field={fields.maturity} />
								</th>
								{/* Coupon */}
								<th className="d-none d-sm-table-cell text-right">
									<Header field={fields.coupon} />
								</th>
								{/* Units */}
								<th className="d-none d-sm-table-cell text-right">
									<Header field={fields.units} />
								</th>
								{/* Unit Cost */}
								<th className="d-none d-md-table-cell text-right">
									<Header field={fields.unitCost} />
								</th>
								{/* Unit Price */}
								<th className="d-none d-md-table-cell text-right">
									<Header field={fields.unitPrice} />
								</th>
								{/* NZD Cost */}
								<th className="d-none d-sm-table-cell text-right">
									<Header field={fields.nzdCost} />
								</th>
								{/* Current NZD Value */}
								<th className="d-none d-sm-table-cell text-right">
									<Header field={fields.nzdValue} />
								</th>
								{/* Annual Income */}
								<th className="text-right">
									<Header field={fields.annualIncome} />
								</th>
								{/* Interest Payments */}
								<th className="d-none d-sm-table-cell text-right">
									<Header field={fields.interestPayments} />
								</th>
							</tr>
						</thead>
						<tbody>
							{sortedAndFilteredAssets.map((asset, key) => (
								<tr key={key}>
									<td>
										<ToolTip medium leftAligned title={display[fields.name](asset)} style={{ bottom: "120%" }}>
											<Token small>{display[fields.asset](asset) || "-"}</Token>
										</ToolTip>
									</td>
									<td className="d-none d-sm-table-cell">{display[fields.type](asset) || "-"}</td>
									<td>{display[fields.rating](asset) || "-"}</td>
									<td>{display[fields.maturity](asset) || "-"}</td>
									<td className="d-none d-sm-table-cell text-right">{display[fields.coupon](asset) || ""}</td>
									<td className="d-none d-sm-table-cell text-right">{display[fields.units](asset) || ""}</td>
									<td className="d-none d-md-table-cell text-right">{display[fields.unitCost](asset) || ""}</td>
									<td className="d-none d-md-table-cell text-right">{display[fields.unitPrice](asset) || ""}</td>
									<td className="d-none d-sm-table-cell text-right">{display[fields.nzdCost](asset) || ""}</td>
									<td className="d-none d-sm-table-cell text-right">{display[fields.nzdValue](asset) || ""}</td>
									<td className="text-right">{display[fields.annualIncome](asset) || ""}</td>
									<td className="d-none d-sm-table-cell text-right wrap-cell">{display[fields.interestPayments](asset) || ""}</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<ErrorMessage infoIcon>This account has no fixed interest assets...</ErrorMessage>
				)}
			</div>
		);
	}
}

export default withFilters(withSortParams(withThreeSkyeGlobal(withAccountInfo(FixedInterestAssetTable))));
