import React, { Component } from 'react';
import ToggleButton from './ToggleButton';
import "./ToggleButtonField.scss";

class ToggleButtonField extends Component {
	render() { 
		const { label, toggleLabel, id, checked, toggleChecked } = this.props;
		return ( 
			<div className="toggle-button-field">
				{label && <div className="filter-heading">{label}</div>}
				<label htmlFor={id}>
					{toggleLabel}
				</label>
				<ToggleButton id={id} checked={checked} onClick={toggleChecked} />
			</div>
		 );
	}
}

export default ToggleButtonField;