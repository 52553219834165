import React, { Component } from 'react';
import "./ToggleButton.scss";
import CommonFunctions from '../functions/CommonFunctions';

class ToggleButton extends Component {
	render() {
		const { className, id, onChange, onClick, checked, name } = this.props;

		return (
			<div className={`switch-input-container ${className ? className : ""}`}>
				<label className="switch">
					<input id={id} onChange={onChange || CommonFunctions.doNothing} type="checkbox" name={name} className="selected" checked={checked} />
					<span
						className="slider round"
						onClick={onClick}
					/>
				</label>
			</div>
		);
	}
}

export default ToggleButton;