import React from 'react';
import { ToastContainer as Toaster } from 'react-toastify';
import { Close } from '@material-ui/icons';
import 'react-toastify/dist/ReactToastify.css';
import "./ToastOverrides.scss";


const ToastContainer = (props) => {
    return (
        <Toaster autoClose={false} closeButton={<i className='material-icons toast-close-button'>close</i>} />
    )
}

export default ToastContainer;