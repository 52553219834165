import React, { Component } from "react";
import "./LoadingMessage.scss";
import LoadingSpinner from "./LoadingSpinner";

class LoadingMessage extends Component {
	render() {
		const { message } = this.props;
		return (
			<div className="loading-message">
				<LoadingSpinner />
				<h4>{message}</h4>
			</div>
		);
	}
}

export default LoadingMessage;
