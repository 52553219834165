import React, { Component } from "react";
import Formatter from "../../core-components/functions/Formatter";
import withRouteChange from "@threeskye/route-change";
import NotificationBell from "../../core-components/misc/NotificationBell";
import { withAccountInfo } from "../../core-components/contexts/AccountsContext";
import { isNullOrUndefinedOrNaN } from "@threeskye/core-components/dist/components/Functions/isNullOrUndefined";
import Loading from "../../core-components/layouts/Loading";
import { Launch } from "@material-ui/icons";
import { FlexDiv } from "../../core-components/layouts/FlexDiv";
import "./Banner.scss";

// Changed this so it's only specific to desktop. Mobile version in it's own component now. DefaultMobileBanner.js
class Banner extends Component {
	render() {
		const { accounts, children, user, accPortfolioMap, accPortfolioIsLoading, thirdPartyView, userDetails } = this.props;
		const isLoading = Object.values(accPortfolioIsLoading).find((isLoading) => !!isLoading);
		const balance = isLoading
			? null
			: accounts && accounts.reduce((totalValue, account) => {
					if (totalValue === null || !accPortfolioMap[account.id]) {
						return null;
					}
					return totalValue + accPortfolioMap[account.id].valuation;
			  }, 0);
			  const isPendingAccount = userDetails && userDetails.clientStatus &&  userDetails.clientStatus === "PROSPECT"
		return (
			<div id="portal-banner" className="desktop">
				<div className="container portal-banner-top-container">
					<div id="portal-banner-left">
						<h2>Hi {user.preferredName || user.givenName}</h2>
					</div>
					{thirdPartyView ? (
						<a href="https://www.hobsonwealth.co.nz/wp-content/uploads/2023/05/Hobson-Final-Tax-guide-Mar23.pdf" target="_blank" id="tax-guide-link-outer">
							<FlexDiv className="tax-guide-link">
								<h5>Tax Guide</h5>
								<Launch className="banner-link-icon" />
							</FlexDiv>
						</a>
					) : (
						<div id="portal-banner-right">
							<div className="notifications-wrapper">
								<NotificationBell />
							</div>
							<div className="banner-valuation">
								<div>
									<h6>Total Portfolio Valuation</h6>
								</div>
								{isPendingAccount ? <h4>-</h4>
								: isLoading ? (
									<Loading size={30} />
								) : !isNullOrUndefinedOrNaN(balance) ? (
									<h3>{Formatter.asMoney(balance)}</h3>
								) : (
									<h4>
										Could not get valuation
										<br />
										Please try again later
									</h4>
								)}
							</div>
						</div>
					)}
				</div>
				{children}
			</div>
		);
	}
}

export default withRouteChange(withAccountInfo(Banner));
