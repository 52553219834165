import React from "react";

const ReviewRecommendedTacticalAllocation = (props) => {
    const {selectedSquares } = props 

    return (
        <div className="recomended-tactical-leyenda">
            <div className="tactical-squares">
                <div className={`tactical-square ${selectedSquares[0] === true ? "selected-square" : ""}`} ></div>
                <div className={`tactical-square ${selectedSquares[1] === true ? "selected-square" : ""}`} ></div>
                <div className={`tactical-square ${selectedSquares[2] === true ? "selected-square" : ""}`} ></div>
                <div className={`tactical-square ${selectedSquares[3] === true ? "selected-square" : ""}`} ></div>
                <div className={`tactical-square ${selectedSquares[4] === true ? "selected-square" : ""}`} ></div>
            </div>
        </div>
    )
}

export default ReviewRecommendedTacticalAllocation