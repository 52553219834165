import React, { Component } from "react";
import { withAccountInfo } from "../contexts/AccountsContext";
import { NavLink } from "react-router-dom";
import { getRoute } from "../../layouts/CustomerPortalPage/CustomerPortalPage";
import { Info } from "@material-ui/icons";
import "./BannerNavLinks.scss";

class BannerNavLinks extends Component {
	render() {
		const { parent, thirdPartyView, highlightReviews, grandparent } = this.props;
		return (
			<div className="banner-navlinks-container">
				<div className="banner-extension-options">
					{Object.keys(parent.subPages)
						.map(key => parent.subPages[key])
						.map((subLink, idx) => {
							if (thirdPartyView) {
								return subLink.thirdParty ? <BannerNavLink key={idx} linkItem={parent} subLinkItem={subLink} /> : null
							} else return <BannerNavLink key={idx} linkItem={parent} subLinkItem={subLink} highlightReviews={highlightReviews} grandparent={grandparent} />
						})
					}
				</div>
			</div>
		);
	}
}

export const BannerNavLink = withAccountInfo(
	class extends Component {
		render() {
			const { linkItem, subLinkItem, account, highlightReviews, grandparent } = this.props;
			return (subLinkItem.hide ? "" :
				<NavLink
					to={getRoute(account, linkItem, subLinkItem, null, grandparent)}
					className={`banner-navlink ${subLinkItem.path === "/reviews" && highlightReviews ? "active-option" : ""}`}
					exact
					activeClassName="active-option">
					{subLinkItem.infoIcon ?
						<span>{subLinkItem.text} <Info className="link-item-alert-icon" style={{ color: "#0D8EFD" }}></Info></span>
						: subLinkItem.text
					}
				</NavLink>
			);
		}
	}
);

export default BannerNavLinks;
