import React, { Component } from "react";
import Button from "../../core-components/inputs/Button";
import { ArrowUpward } from "@material-ui/icons";
import "./BackToTopButton.scss";
import CommonFunctions from "../../core-components/functions/CommonFunctions";
import { withThreeSkyeGlobal } from "@threeskye/global";

class BackToTopButton extends Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		const contentWrapperRef = this.props.storage.get("content-wrapper-ref");

		if (this.props.subcontentIdName) {
			const element = document.getElementById(this.props.subcontentIdName);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: "end"});
			} 

		} else if (contentWrapperRef && contentWrapperRef.current) {
			contentWrapperRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}
	}

	render() {
		const { showButton } = this.props;
		const { asClassName } = CommonFunctions;
		return (
			<Button onClick={this.onClick} className={asClassName(["back-to-top", showButton && "show-button"])}>
				<ArrowUpward /> <div className="button-text">Top</div>
			</Button>
		);
	}
}

export default withThreeSkyeGlobal(BackToTopButton);
