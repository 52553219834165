import React, { Component } from "react";

const FiltersContext = React.createContext({ filters: null, setFilters: null });

export default (WrappedComponent) => {
	return class extends Component {
		render() {
			return <FiltersContext.Consumer>{({ filters, setFilters }) => <WrappedComponent {...this.props} filters={filters} setFilters={setFilters} />}</FiltersContext.Consumer>;
		}
	};
};

export class FiltersWrapper extends Component {
	state = { filters: this.props.initialFilters };

	constructor(props) {
		super(props);
		this.setFilters = this.setFilters.bind(this);
	}

	setFilters(filters) {
		this.setState({ filters });
	}

	render() {
		const { children } = this.props;
		const { filters } = this.state;
		const { setFilters } = this;
		return <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>;
	}
}
