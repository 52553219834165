import React, { useEffect, useState } from "react";
import Card from '../../core-components/card/Card';
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import { withThreeSkyeGlobal } from "@threeskye/global";
import General from "./General";
import Contact from "./Contact";
import Identification from "./Identification";
import FatcaTax from "./FatcaTax";
import Aml from "./Aml";
import Kiwisaver from "./Kiwisaver";
import Property from "./Property";
import Shares from "./Shares";
import Businesses from "./Businesses";
import TermsAndConditions from './TermsAndConditions'
import withRouteChange from "@threeskye/route-change";
import Loading from "../../core-components/layouts/Loading";
import Button from '../../core-components/inputs/Button';
import VerticalSpacer from '../../core-components/layouts/VerticalSpacer'
import './Onboarding.scss'


const PreloginStep = (props) => {
    const { onBoardingState, setOnBoardingState, finishedPreLogin, handleNumberChange, parsedData, user, sections, stepIndex, preLoginRoutes, changeRoute, remote } = props

    const [checked, setChecked] = useState(false)
    const [onboardingStateKeysArray, setOnboardingStateKeysArray] = useState(null)
    const [disabled, setDisabled] = useState(null)
    const [skippable, setSkippable] = useState(null)

    const lastPageDisabled = getDisabled()

    useEffect(() => {
        if (!onBoardingState.firstName) {
            setOnBoardingState({
                onBoardingState: {
                    ...onBoardingState,
                    firstName: parsedData.givenName,
                    lastName: parsedData.familyName,
                    email: parsedData.email,
                    mobile: { idd: parsedData.phone.idd, number: parsedData.phone.number, valid: true }
                }
            })
        }
        const mandatoryFields = sections.flatMap(s => s.fields).filter(f => f.mandatory).map(mf => mf.inputType)
        setOnboardingStateKeysArray(mandatoryFields)
    }, [])

    useEffect(() => {
        if (onboardingStateKeysArray) {
            if (onboardingStateKeysArray.length > 0) {
                const invalidFieldsArray = Object.keys(onBoardingState).map((key, idx) => {
                    if (onboardingStateKeysArray.includes(key)) {
                        const field = onBoardingState[key]
                        if (key === 'mobile') return { invalid: field.valid && field.number.length > 0, field: key }
                        if (key === 'dateOfBirth' || key === 'isPrescribedPerson' || key === 'passportExpiracyDate' || key === 'driversLicenceExpiracyDate') return { invalid: field === null, field: key }
                        if (typeof field === 'string') return { invalid: field.length === 0, field: key }
                        if (Array.isArray(field)) return { invalid: field.length === 0, field: key }
                    } else return { invalid: false, field: key }
                })
                const isDisabled = invalidFieldsArray.find(element => element.invalid && element.invalid === true)
                setDisabled(!isDisabled ? false : true)

            } else {
                const fieldsKeys = sections.flatMap(section => section.fields).map(field => field.inputType)

                const isSkippableFields = Object.keys(onBoardingState).map((key, idx) => {
                    if (fieldsKeys.includes(key)) {
                        const field = onBoardingState[key]
                        if (typeof field === 'string') return { filled: field.length === 0, field: key }
                        if (Array.isArray(field)) return { filled: field.length === 0, field: key }
                    } else return { filled: false, field: key }
                })
                const isSkippable = isSkippableFields.find(elemnt => elemnt.filled === true)
                setSkippable(isSkippable ? true : false)
                setDisabled(false)
            }
        }
    }, [onBoardingState, onboardingStateKeysArray])


    function renderSection(section, sectionIndex) {
        if (section.id === 1) {
            const newLabel = section.label.includes("{user}") ? section.label.replace("{user}", user.givenName) : section.label.includes("{User}") ?  section.label.replace("{User}", user.givenName) : section.label
            return (
                <CardHeader className="header-with-subheader">
                    <h3 className="onbording-header">{newLabel}</h3>
                    {section.displaySubLabel && <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{section.subLabel}</div>}
                </CardHeader>
            )
        }
        if (section.id === 2) return <General onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} section={section} parsedData={parsedData} sectionIndex={sectionIndex} />
        if (section.id === 3) return <Contact onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} section={section} parsedData={parsedData} sectionIndex={sectionIndex} />
        if (section.id === 4) return <Identification onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} section={section} parsedData={parsedData} sectionIndex={sectionIndex} />
        if (section.id === 5) return <Aml onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} section={section} sectionIndex={sectionIndex} />
        if (section.id === 6) return <FatcaTax onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} section={section} sectionIndex={sectionIndex} />
        if (section.id === 8) return <Kiwisaver onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} handleNumberChange={handleNumberChange} section={section} sectionIndex={sectionIndex} />
        if (section.id === 9) return <Property onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} handleNumberChange={handleNumberChange} section={section} sectionIndex={sectionIndex} />
        if (section.id === 10) return <Businesses onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} handleNumberChange={handleNumberChange} section={section} sectionIndex={sectionIndex} />
        if (section.id === 11) return <Shares onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} handleNumberChange={handleNumberChange} section={section} sectionIndex={sectionIndex} />
        if (section.id === 12) return <TermsAndConditions onBoardingState={onBoardingState} checked={checked} setChecked={setChecked} section={section} sectionIndex={sectionIndex} />
    }

    function finalise() {
        remote.put("/modules/koura/portal/onboarding/finalise", { "terms": "accepted" }).then(t => finishedPreLogin());
    }

    function getDisabled() {
        if (sections && sections.length === 1 && sections[0].label === 'TermsAndConditions') {
            return !checked
        } else if (sections && sections.length > 1 && sections.find(s => s.label === 'TermsAndConditions')) {
            return !disabled && checked ? false : true
        } else {
            return disabled
        }
    }


    if (!sections || !user.givenName) return <Loading />

    return (
        <Card className="height-fit-content no-box-shadow">
            <div style={{ maxWidth: 1100 }}>
                {sections.map((section, index) => {
                    return (
                        <div key={index}>
                            {renderSection(section, index)}
                            {index !== sections.length - 1 && section.label !== 'WelcomeUser' &&
                                <hr style={{ borderTop: "1.5px solid", borderColor: "#b6bcbc", marginBottom: 35, opacity: 0.4 }} />
                            }
                        </div>
                    )
                })}
                <VerticalSpacer size="40px" />
                <Row className="justify-content-end">
                    <Col xs="auto" className="justify-self-end">
                        <div style={{ display: "flex", gap: 20 }}>
                            {stepIndex !== 0 && <Button onClick={() => changeRoute(preLoginRoutes[stepIndex - 1].path)} className="button-colour-secondary capitalized">
                                Back
                            </Button>}
                            {stepIndex !== preLoginRoutes.length - 1 && <Button onClick={() => changeRoute(preLoginRoutes[stepIndex + 1].path)} className='capitalized' disabled={disabled}>
                                {skippable ? "Skip" : "Next"}
                            </Button>}
                            {stepIndex === preLoginRoutes.length - 1 &&
                                <Button onClick={finalise} disabled={lastPageDisabled} className='normal-text' style={{ minWidth: 260 }}>
                                    Confirm and Login to Portal
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </Card >
    )
}

export default withThreeSkyeGlobal(withRouteChange(PreloginStep))