import Button from "../../../core-components/inputs/Button";
import React, { Component } from 'react';
import VerticalSpacer from '../../../core-components/layouts/VerticalSpacer';
import HobsonLogo from "../../../core-components/svgs/HobsonLogo";
import "./MaintenancePage.scss";
import MaintenanceIcon from '../../../resources/images/maintenance-icon.png';

class MaintenancePage extends Component {
	state = {  }
	render() { 
		return (
			<div className="maintenance-page-container">
				<div id="maintenance-page">
					<HobsonLogo width={120} />
					<div class="maintenance-content-container">
						<img src={MaintenanceIcon} className="maintenance-icon" alt="maintenance icon" />
						<VerticalSpacer size="18px" />
						<h3>We’re Down for Maintenance</h3>
						<VerticalSpacer size="18px" />
						<p>
							Sorry for the inconvenience. We’ll be back online soon.<br />
							Please contact your adviser if you have any questions. Alternatively, you can contact us via our
							main website.
						</p>
						<VerticalSpacer size="18px" />
						<Button><a href="https://www.hobsonwealth.co.nz/contact-us/" target="_blank" rel="noopener noreferrer">Go to Hobsonwealth.co.nz</a></Button>
					</div>
				</div>
			</div>
		);
	}
}
 
export default MaintenancePage;