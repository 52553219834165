import React, { Component } from "react";
import WithSortedNavigatorFundAssets, { NAVIGATOR_ASSET_FIELDS, NAVIGATOR_ASSET_FIELD_HEADERS } from "@threeskye/core-components/dist/components/NavigatorFundLookthrough/WithSortedNavigatorFundAssets";
import "./NavigatorAssetTable.scss";
import Formatter from "../functions/Formatter";
import CommonFunctions from "../functions/CommonFunctions";
import Token from "../misc/Token";
import ToolTip from "./ToolTip";
import ErrorMessage from "./ErrorMessage";
import SortByHeader from "../tables/SortByHeader";

class Header extends Component {
	render() {
		const { field } = this.props;
		return <SortByHeader field={field}>{NAVIGATOR_ASSET_FIELD_HEADERS[field]}</SortByHeader>;
	}
}

class NavigatorAssetTable extends Component {
	constructor(props) {
		super(props);
		this.getCSV = this.getCSV.bind(this);
		props.setGetCSVFunc(this.getCSV);
	}

	getCSV() {
		const { sortedAssets } = this.props;
		const fields = NAVIGATOR_ASSET_FIELDS;
		const table = [[fields.asset, fields.tickerCode, fields.currentNzdValue, fields.percentageOfPortfolio].map((field) => NAVIGATOR_ASSET_FIELD_HEADERS[field])].concat(
			sortedAssets && sortedAssets.length > 0
				? sortedAssets.map(({ tickerCode, assetName, value, percentageOfHoldings }) =>
						[assetName, tickerCode, Formatter.numberWithCommas(value, 2), Formatter.asPercentage(percentageOfHoldings || 0, 2)].map((v) => `"${v || ""}"`)
				  )
				: [["", "", "", ""]]
		);
		return table.map((row) => row.join(",")).join("\n");
	}

	render() {
		const { fund, sortedAssets } = this.props;
		const fields = NAVIGATOR_ASSET_FIELDS;
		if (!sortedAssets || sortedAssets.length === 0) {
			return <ErrorMessage infoIcon>No assets to display...</ErrorMessage>;
		} else {
			let { value, percentPortfolio } = fund;
			return (
				<div className="portal-asset-table-container">
					<table id="portal-asset-table" className={CommonFunctions.responsiveClassname()}>
						<tbody className="table-header">
							<tr>
								<th className="asset" style={{width: 190}}>
									<Header field={fields.asset} />
								</th>
								<th className="asset">
									<Header field="" />
								</th>
								<th className="current-nzd-value text-right header-right">
									<Header field={fields.currentNzdValue} />
								</th>
								<th className="percentage-of-portfolio text-right header-right">
									<Header field={fields.percentageOfPortfolio} />
								</th>
							</tr>
						</tbody>
						<tbody>
							{sortedAssets.map(({ tickerCode, assetName, value, percentageOfHoldings }, index) => (
								<tr key={index}>
									<td className="asset">
										{tickerCode && (
											<div className="ticker-col w-auto">
												<ToolTip mobileOnly medium leftAligned title={assetName} style={{ bottom: "120%" }}>
													<Token small>{tickerCode}</Token>
												</ToolTip>
											</div>
										)}
										{/* <span className="ml-2 d-none d-xl-inline-block asset-name"></span> */}
									</td>
									<td>
										{assetName}
									</td>
									<td className="text-right current-nzd-value">{Formatter.numberWithCommas(value, 2)}</td>
									<td className="text-right percentage-of-portfolio">{Formatter.asPercentage(percentageOfHoldings || 0, 2)}</td>
								</tr>
							))}
						</tbody>
						<tbody className="tbody-total">
							<tr>
								<td className="asset">TOTAL</td>
								<td></td>
								<td className="text-right current-nzd-value">{Formatter.numberWithCommas(value, 2)}</td>
								<td className="text-right percentage-of-portfolio">{Formatter.asPercentage(percentPortfolio, 2)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			);
		}
	}
}

export default WithSortedNavigatorFundAssets(NavigatorAssetTable);
