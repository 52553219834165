import React, { Component } from "react";
import { SortParamsWrapper } from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import FixedInterestAssetTable from "../../../../core-components/tables/FixedInterestAssetTable";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import FixedInterestPortfolioCard from "./FixedInterestPortfolioCard";
import LandscapePrompt from "../../../../core-components/misc/LandscapePrompt";
import { FiltersWrapper } from "../../../../core-components/filters-and-toggles/withFilters";
import { MONTH_YEAR_TIME_FRAMES } from "../../../../core-components/functions/ChartFunctions";
import AnalyticsPage from "./AnalyticsPage";
import { withThreeSkyeGlobal } from "@threeskye/global";
import ErrorMessage, { GenericConnectionFailErrorMessage } from "../../../../core-components/layouts/ErrorMessage";
import { withAccountInfo, getPortalAccType, PortalAccTypes } from "../../../../core-components/contexts/AccountsContext";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import { SpaceBetweenDiv } from "../../../../core-components/layouts/FlexDiv";
import ExportButton from "../../../../core-components/inputs/ExportButton";
import Loading from "../../../../core-components/layouts/Loading";

export const FIXED_INTEREST_TYPES = { INCOME: "Income", MATURITY: "Maturity", CREDIT_RATING: "Credit Rating" };
export const ALL_FIXED_INTEREST_TYPES = [FIXED_INTEREST_TYPES.INCOME, FIXED_INTEREST_TYPES.MATURITY, FIXED_INTEREST_TYPES.CREDIT_RATING];
export const INITIAL_FI_TYPE = FIXED_INTEREST_TYPES.INCOME;
export const INITIAL_TIME_FRAME = MONTH_YEAR_TIME_FRAMES.YEARS;

class FixedInterest extends Component {
	tableDivRef = React.createRef();
	state = { data: null, loading: true };
	constructor(props) {
		super(props);
		this.fetchData = this.fetchData.bind(this);
		this.exportFixedInterest = this.exportFixedInterest.bind(this);
		this.setGenerateCSVFunc = this.setGenerateCSVFunc.bind(this);
		this.scrollTableIntoView = this.scrollTableIntoView.bind(this);
	}

	componentDidMount() {
		if (getPortalAccType(this.props.account) === PortalAccTypes.PORTFOLIO) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.account.id !== this.props.account.id && getPortalAccType(this.props.account) === PortalAccTypes.PORTFOLIO) {
			this.fetchData();
		}
	}

	fetchData() {
		const { account } = this.props;
		this.setState({ loading: true });
		this.props.storage.getOrFetch(`/modules/nzxwt/accounts/${account.id}/fixed-interest-assets`).then((response) => {
			if (!response || response.connectionError) {
				this.setState({ data: null, connectionError: true, loading: false });
			} else {
				this.setState({ data: response.data.assets, connectionError: false, loading: false });
			}
		});
	}

	exportFixedInterest() {
		if (this.generateCSV) {
			const { account } = this.props;
			const base64 = btoa(this.generateCSV());
			CommonFunctions.download({ base64, filename: `${account.name} Fixed Interest`, extension: "csv" });
		}
	}

	setGenerateCSVFunc(func) {
		this.generateCSV = func;
	}

	scrollTableIntoView() {
		if (this.tableDivRef.current) {
			this.tableDivRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}

	render() {
		const { connectionError, loading, data } = this.state;
		const { setGenerateCSVFunc, scrollTableIntoView } = this;
		return (
			<AnalyticsPage>
				<FiltersWrapper initialFilters={{ selectedFixedInterestType: INITIAL_FI_TYPE, selectedTimeFrame: INITIAL_TIME_FRAME }}>
					{loading || connectionError || !data || data.length <= 0 ? (
						<div className="card-row">
							<CardContainer xs={12}>
								<Card>
									<CardHeader>
										<div>
											<h3>Fixed Interest</h3>
										</div>
									</CardHeader>
									{loading ? <Loading centered size={70} /> : connectionError ? <GenericConnectionFailErrorMessage /> : <ErrorMessage errorIcon>No assets to display...</ErrorMessage>}
								</Card>
							</CardContainer>
						</div>
					) : (
						<div className="card-row">
							<FixedInterestPortfolioCard data={data} />
							<CardContainer xs={12}>
								<Card forwardRef={this.tableDivRef} >
									<CardHeader>
										<SpaceBetweenDiv>
											<h3>Fixed Interest</h3>
											<ExportButton onClick={this.exportFixedInterest} />
										</SpaceBetweenDiv>
									</CardHeader>
									<SortParamsWrapper>
										<FixedInterestAssetTable {...{ data, setGenerateCSVFunc, scrollIntoView: scrollTableIntoView }} />
									</SortParamsWrapper>
								</Card>
							</CardContainer>
							<LandscapePrompt />
						</div>
					)}
				</FiltersWrapper>
			</AnalyticsPage>
		);
	}
}

export default withThreeSkyeGlobal(withAccountInfo(FixedInterest));
