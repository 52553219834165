import React, { Component } from 'react';
import "./StandingOrderValueGroup.scss";

class StandingOrderValueGroup extends Component {
	render() { 
		const { label, value, className } = this.props;
		
		let valueGroupClass = "standing-order-value-group";
		if (className) {
			valueGroupClass += " " + className;
		}

		return (
			<div className={valueGroupClass}>
				<label>{label ? label : ""}</label>
				<div className="standing-order-value">{value ? value : "-"}</div>
			</div>
		);
	}
}

export default StandingOrderValueGroup;