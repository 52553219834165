import React, { Component, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Search } from "@material-ui/icons";
import withReactKey from "@threeskye/core-components/dist/components/withReactKey";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";
import Button from "../../../../core-components/inputs/Button";
import DateRangeInput from "../../../../core-components/inputs/DateRangeInput";
import FilterInput from "../../../../core-components/inputs/FilterInput";
import SearchInput from '../../../../core-components/inputs/SearchInput';
import DateFieldInput from "../../../../core-components/inputs/DateFieldInput";
import SingleSelectInput from "../../../../core-components/inputs/SingleSelectInput"
import moment from "moment"

const emptyState = { endYear: null, search: "", account: null, endYearSelected: "", endMonthSelected: ["03", "31"] };

export const monthEndOptions = [
	{ label: "31 Jan", value: ["01", "31"] },
	{ label: "28 Feb", value: ["02", "28"] },
	{ label: "31 Mar", value: ["03", "31"] },
	{ label: "30 Apr", value: ["04", "30"] },
	{ label: "31 May", value: ["05", "31"] },
	{ label: "30 Jun", value: ["06", "30"] },
	{ label: "31 Jul", value: ["07", "31"] },
	{ label: "31 Aug", value: ["08", "31"] },
	{ label: "30 Sep", value: ["09", "30"] },
	{ label: "31 Oct", value: ["10", "31"] },
	{ label: "30 Nov", value: ["11", "30"] },
	{ label: "31 Dec", value: ["12", "31"] },
];

class ThirdPartyAccessFilters extends Component {
	state = { ...emptyState };

	constructor(props) {
		super(props);
		this.onFiltersUpdate = this.onFiltersUpdate.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.setFilters = this.setFilters.bind(this)
	}

	getYears2013ToPresent() {
		let years = []
		let currentYear = new Date().getFullYear()

		for (let i = 2013; i <= currentYear; i++) {
			years.push({label: i, value: i})
		}

		this.setState({yearOptions: years.reverse()}) 
	}

	componentDidMount() {
		this.onFiltersUpdate();
		this.getYears2013ToPresent()
	}

	onFiltersUpdate() {
		const { endYear, search, account } = this.state;
		this.props.setFilters({ endYear, search, account });
	}

	setFilters(endYear, search, account) {
		let endYearOrNull = !endYear && endYear !== null ? this.state.endYear : endYear
		let searchOrNull = !search && search !== null ? this.state.search : search
		let accountIdOrNull = (!account || !account.value) && account !== null ? this.state.account && this.state.account.value ? this.state.account.value.id : null : account.value.id
		let accountOrNull = !account && account !== null ? this.state.account : account

		this.setState({endYear: endYearOrNull, search: searchOrNull, account: accountOrNull}, () => {this.props.setFilters({endYear: endYearOrNull, search: searchOrNull, account: accountIdOrNull})})
	}

	editEndYear(year, month) {
		const { endYear } = this.state;
		let newEndOfTaxYear = [
			this.state.endYearSelected 
			? this.state.endYearSelected 
			: "" ,
			this.state.endMonthSelected[0] 
			? this.state.endMonthSelected[0] 
			: "" ,
			this.state.endMonthSelected[1] 
			? this.state.endMonthSelected[1] 
			: "" ] 
		
		if (year !== undefined) {
			this.setState({endYearSelected: year})
			newEndOfTaxYear[0] = year
		}
		if (month !== undefined) {
			this.setState({endMonthSelected: month})
			newEndOfTaxYear[1] = month[0]
			newEndOfTaxYear[2] = month[1]
		}
		if (newEndOfTaxYear[0] && newEndOfTaxYear[1] && newEndOfTaxYear[2]) {
			this.setFilters(newEndOfTaxYear.join("-"))
		} else {
			this.setFilters(null)
		}
	}

	matchMonthValueToOption(value) {
		if (monthEndOptions) {
			return value === null ? null : monthEndOptions.find(option => {
				return option.value[0] === value[0] && option.value[1] === value[1] 
			})
		} else return null
	}

	clearFilters() {
		this.setState({ ...emptyState }, () => {
			this.onFiltersUpdate();
			this.props.changeKey();
		});
	}

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.onFiltersUpdate();
		}
	}

	render() {

		const { reactKey, accounts, filters } = this.props;
		const { search, endYear, account } = this.state;

		return (
			<div className="document-filters" tabIndex="-1" onKeyPress={this.handleKeyPress}>
				<Row>
					{/* <Col xs="12" lg="3">
						<FilterInput
							name="Search Documents"
							placeholder="Search Documents"
							label="Search"
							value={search}
							onChange={(search) => this.setFilters(undefined,search)}
							icon={<Search />}
						/>
					</Col> */}
					<Col xs="12" lg="4">
						<SearchInput
							options={accounts.map((acc) => {return {label: acc.name, value: acc}})}
							label="Filter By Accounts"
							placeholder="Search Accounts"
							value={this.state.account}
							onChange={(account) => {
								if (account === null) {
									this.setFilters(undefined, undefined, null)
								} else this.setFilters(undefined, undefined, account)
							}}
							isClearable={false}
						/>
					</Col>
					<Col xs="12" lg="3" className="date-range-filter-wrapper">
						<SearchInput 
							label="Month of Year End"
							options={monthEndOptions}
							onChange={(endMonth) => {this.editEndYear(undefined, endMonth.value)}}
							isClearable={false}
							required
							value={this.state.endMonthSelected &&  this.state.endMonthSelected[0] ? this.matchMonthValueToOption(this.state.endMonthSelected) : null}
						/>
						{/* <DateFieldInput label="Tax Year Ending" date={endYear} onChange={(endYear) => this.setFilters(endYear)} stateShouldUpdateWhenPropsChange /> */}
					</Col>
					<Col xs="12" lg="3" className="date-range-filter-wrapper">
						<SearchInput 
							label="Tax Year"
							options={this.state.yearOptions ? this.state.yearOptions : [] }
							onChange={(endYear) => {this.editEndYear(endYear.value)}}
							isClearable={false}
							required
							value={this.state.endYearSelected ? {label: this.state.endYearSelected, value: this.state.endYearSelected} : null}
						/>
						{/* <DateFieldInput label="Tax Year Ending" date={endYear} onChange={(endYear) => this.setFilters(endYear)} stateShouldUpdateWhenPropsChange /> */}
					</Col>
				</Row>
				<Row>
					<Col xs="12" lg="6" xl="6" className="align-self-center documents-apply-button documents-clear-button mt-3">
						<Button disabled={!this.state.account && !this.state.endYearSelected} fullWidth small onClick={this.clearFilters}>
							Clear Filters
						</Button>
					</Col>
					{/* <Col xs="12" lg="4" xl="4" className="align-self-center documents-apply-button documents-all-button mt-3">
						<Button fullWidth small onClick={() => alert("Clicked")}>
							Download All
						</Button>
					</Col> */}
				</Row>
			</div>
		);
	}
}

export default withReactKey(withFilters(withAccountInfo(ThirdPartyAccessFilters)));
