import React from "react";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import Input from '../../core-components/inputs/Input';
import RadioInputGroup from '../../core-components/inputs/RadioInputGroup'
import './Onboarding.scss'


const Aml = (props) => {
    const { onBoardingState, setOnBoardingState, section } = props
    const { wealthSource, fundsSource, isPrescribedPerson } = onBoardingState
    const label = section && section.displayLabel ? section.label : null
    const subLabel = section && section.displaySubLabel ? section.subLabel : null

    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel && <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <Input label="Source of Wealth" name="wealth-source" value={wealthSource} placeholder="Source of wealth" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, wealthSource: e } })} errorMessageDown />
                </Col>
                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                    <Input label="Source of Funds" name="funds-source" value={fundsSource} placeholder="Source of funds" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, fundsSource: e } })} errorMessageDown />
                </Col>
                <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 85 }}>
                    <RadioInputGroup
                        groupLabel="Prescribed Person*"
                        className="radio-input-heading"
                        radioButtonClass="onbording-radio-button"
                        options={['Yes', 'No']}
                        value={isPrescribedPerson}
                        onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, isPrescribedPerson: e } })}
                        toggleable
                        clear={() => setOnBoardingState({ onBoardingState: { ...onBoardingState, isPrescribedPerson: null } })}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Aml