import React, { Component } from 'react'
import Select from "react-select";
import CommonFunctions from '../functions/CommonFunctions';
import "./SearchInput.scss";
import { Search } from '@material-ui/icons';

class SearchInput extends Component {
	render() { 
		const { onChange, value, name, placeholder, label, options, isClearable } = this.props;
		const { responsiveClassname } = CommonFunctions;
		return ( 
			<label className={`portal-label filter-input search-input-wrapper ${responsiveClassname()}`}>
				{label && (
					<div className="label-container">
						<span className="filter-heading">{label}</span>
					</div>
				)}
				<Select className="search-input" classNamePrefix="search-input" options={options} onChange={onChange} placeholder={placeholder} name={name} value={value} isClearable={(isClearable === true || isClearable === false) ? isClearable : true} />
				<Search className="search-input-icon" />
			</label>
			// <FilterInput icon={<Search />} label={label ? label : "SEARCH"} name={name ? name : "Name"} value={value} placeholder={placeholder ? placeholder : "Search"} onChange={onChange} />
		);
	}
}
 
export default SearchInput;