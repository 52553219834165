import React, { Component } from 'react';
import "./DocumentTag.scss";
import { LocalOffer } from '@material-ui/icons';

class DocumentTag extends Component {
	render() { 
		const { tag } = this.props;
		return ( 
			<div class={tag && "document-tag"}>
				{tag && <><LocalOffer /> {tag}</>}
			</div>
		);
	}
}

export default DocumentTag;