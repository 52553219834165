import React, { useState, useEffect, useRef } from "react";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import { Col, Row } from "reactstrap";
import Button from "../../../../core-components/inputs/Button"
import { handleNumberChange } from "../../../PreAccountLogin/OnboardingUtils"
import CompanyImage from "../../../../core-components/layouts/CompanyImage"
import CommonFunctions from "../../../../core-components/functions/CommonFunctions"
import "../../../PreAccountLogin/Onboarding.scss"


const SharesPersonalInfo = (props) => {
    const { remote, onBoardingState, setOnBoardingState, commitAndsetOnBoardingState } = props
    const optionsListRef = useRef(null);

    const [shares, setShares] = useState(onBoardingState.shares)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [allTickers, setAllTickers] = useState(null)
    const [searchValue, setSearchValue] = useState("");
    const [matchingOptions, setMatchingOptions] = useState([]);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [autoFocusLastShare, setAutoFocusLastShare] = useState(false)

    useEffect(() => {
        remote.get("/public/modules/hobson-support/stockfox/classification-scheme/Hobson").then((fetchedAssets) => {
            if (fetchedAssets) {
                const anotherListOfAssets = fetchedAssets.unclassified.concat(
                    fetchedAssets.classes.flatMap(classObj => classObj.assets)
                )
                // const allTickers = fetchedTickers.classes.flatMap(classObj => classObj.assets)
                setAllTickers(anotherListOfAssets)
            }
        })
    }, [])

    useEffect(() => {
        if (onBoardingState.shares && onBoardingState.shares.length > 0) {
            setShares(onBoardingState.shares)
        }
    }, [onBoardingState.shares])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (optionsListRef.current && !optionsListRef.current.contains(event.target)) {
                setMatchingOptions([])
                setSearchValue("")
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        }
    }, [optionsListRef])

    useEffect(() => {
        if (optionsListRef.current && selectedOptionIndex > -1 && optionsListRef.current.children && optionsListRef.current.children[selectedOptionIndex]) {
            optionsListRef.current.children[selectedOptionIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
            });
        }
    }, [selectedOptionIndex])

    useEffect(() => {
        if (matchingOptions && matchingOptions.length === 0) setSelectedOptionIndex(-1)
    }, [matchingOptions])


    function removeTicker(index) {
        const updatedShares = shares.filter((p, idx) => idx !== index)
        setShares(updatedShares)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, shares: updatedShares } })
        setUnsavedChanges(true)
        setAutoFocusLastShare(false)
    }

    function updateTickerValue(e, index, string) {
        const formattedValue = handleNumberChange(e, string)
        const updatedShares = shares.map((s, idx) => {
            if (idx === index) {
                const newObject = { ticker: s.ticker, units: string === "units" ? formattedValue : s.units, costPerUnit: string === "costPerUnit" ? formattedValue : s.costPerUnit }
                return newObject
            } else return s
        })
        setShares(updatedShares)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, shares: updatedShares } })
        setUnsavedChanges(true)
    }

    function handleInputChange(e) {
        const { value } = e.target
        setSearchValue(value)
        const list = allTickers.filter(ticker => !shares.map((t) => t.id).includes(ticker.id))
        const matchingOptions = list.filter((option) => (option.name !== null && option.name.toLowerCase().startsWith(value.toLowerCase())) || (option.tickerCode && option.tickerCode.toLowerCase().startsWith(value.toLowerCase())))
        if (list.length === matchingOptions.length) {
            setMatchingOptions([])
        } else setMatchingOptions(matchingOptions)
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            if (selectedOptionIndex < matchingOptions.length - 1) setSelectedOptionIndex(selectedOptionIndex + 1)
            if (selectedOptionIndex === matchingOptions.length - 1) setSelectedOptionIndex(-1)
        } else if (event.key === 'ArrowUp') {
            if (selectedOptionIndex > -1) setSelectedOptionIndex(selectedOptionIndex - 1)
            if (selectedOptionIndex === -1) setSelectedOptionIndex(matchingOptions.length - 1)
        } else if (event.key === 'Enter' && selectedOptionIndex > -1) {
            handleOptionClick(selectedOptionIndex)
            setSelectedOptionIndex(-1)
        }
    }

    function handleOptionClick(index) {
        const option = matchingOptions[index]
        const existingAsset = shares && shares.lengh > 0 && shares.find(s => s.id === option.id)

        if (existingAsset === false || existingAsset === undefined) {
            const tickers = [...shares, { ticker: option, units: null, costPerUnit: null }]
            setOnBoardingState({ onBoardingState: { ...onBoardingState, shares: tickers } })
            setShares(tickers)
            setSelectedOptions([...selectedOptions, option]);
            setSearchValue("")
            setMatchingOptions([])
            setUnsavedChanges(true)
            setAutoFocusLastShare(true)
        } else {
            setSearchValue("")
            setMatchingOptions([])
        }
        setSelectedOptionIndex(index)
    };

    function handleOptionHover(index) {
        setSelectedOptionIndex(index)
    };

    function saveChanges() {
        if (unsavedChanges) {
            commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState } })
            setUnsavedChanges(false)
            setAutoFocusLastShare(false)
        }
    }


    return (
        <div key="personal-info-kiwisaver">
            <CardContainer>
                <Card>
                    <CardHeader>
                        <div className="portal-card-title" style={{ display: "grid", gridTemplateColumns: "auto 175px 40%", gap: '1rem', width: "100%", maxWidth: 1200 }}>
                            <h3>Equities</h3>
                            <Button onClick={saveChanges} disabled={!unsavedChanges}>Save Changes</Button>
                            <label className={`portal-label ${CommonFunctions.responsiveClassname()}`} style={{ position: 'relative' }}>
                                <div className="shares-dropdown" style={{ position: 'absolute', width: '100%' }}>
                                    <input
                                        placeholder="+ Add another share"
                                        type="text"
                                        value={searchValue}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {matchingOptions.length > 0 && (
                                        <ul className='dropdown-selection-list' style={{ width: 330 }} ref={optionsListRef} tabIndex='0'>
                                            {matchingOptions.map((option, index) => (
                                                <li
                                                    key={option.id}
                                                    onClick={() => handleOptionClick(index)}
                                                    onMouseEnter={() => handleOptionHover(index)}
                                                    onKeyDown={handleKeyDown}
                                                    className={`individual-match ${selectedOptionIndex === index ? "selected" : ""}`}
                                                    style={{ backgroundColor: index === selectedOptionIndex ? "#f3f6f7" : null }}
                                                >
                                                    <div className="asset-label">
                                                        <span>{option.tickerCode + (option.exchange ? ("." + option.exchange) : "") + " - " + option.name}</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </label>
                        </div>

                    </CardHeader>
                    <Row>
                        <Col xs="12" xl="12" className="pl-xl-3">
                            <div className="shares-table-headers">
                                <div className="align-left">Asset</div>
                                <div className="align-right">Units</div>
                                <div className="align-right">Cost per unit</div>
                            </div>
                        </Col>
                        {shares && shares.length > 0 && shares.map((s, idx) => {
                            return (
                                <Col xs="12" xl="12" className="pl-xl-3" key={idx}>
                                    <div className="shares-owned-card" >
                                        <div className="share-section-container">
                                            <div className="ticker-info" >
                                                <CompanyImage exchange={s.ticker.nativeCurrency} tickerCode={s.ticker.tickerCode} alt={s.ticker.tickerCode} size={34} />
                                                <div className="ticker-code">{s.ticker.tickerCode || ""}</div>
                                                <div className="ticker-name">{s.ticker.name || ""}</div>
                                            </div>
                                        </div>
                                        <div className="share-section-container">
                                            <input value={s.units || ""} placeholder="0" name={"share-units-" + idx} onChange={(e) => updateTickerValue(e, idx, "units")} autoFocus={autoFocusLastShare && idx === shares.length - 1} />
                                        </div>
                                        <div className="share-section-container">
                                            <input value={s.costPerUnit || ""} placeholder="$0" name={"share-cost-per-unit-" + idx} onChange={(e) => updateTickerValue(e, idx, "costPerUnit")} />
                                        </div>
                                        <div className="share-section-container">
                                            <i className="material-icons delete-icon" onClick={() => removeTicker(idx)}>delete_forever</i>                                    </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Card>
            </CardContainer>
        </div >
    )
}

export default withThreeSkyeGlobal(withAccountInfo(SharesPersonalInfo))