import { AssignmentInd, Group } from '@material-ui/icons';
import React from 'react';
import Button from '../../../core-components/inputs/Button';
import "./LoginViewSelect.scss";
import withRouteChange from "@threeskye/route-change";
import HobsonLogo from '../../../core-components/svgs/HobsonLogo';


const LoginViewSelect = (props) => {
	const {account} = props;
	return (
		<div id="login-view-select">
			<HobsonLogo width={180} />
			<div id="login-view-message">	
				<p>Please select your preferred access - Account View or Third Party View</p>
				<p>(you can switch at any time once you’ve logged in)</p>
			</div>
			<div id="login-view-buttons">
				<Button onClick={() => props.changeRoute(`/${account}/dashboard`)}><Group /> Accounts View</Button>
				<Button secondary onClick={() => props.changeRoute(`/third-party/third-party-access`)}><AssignmentInd /> Third Party View</Button>
			</div>
		</div>
   );
}

export default withRouteChange(LoginViewSelect);