import React, { useEffect, useState } from "react";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import BannerInfoText, { BannerInfoMainText } from "../../../../core-components/misc/BannerInfoText";
import Banner from "../../Banner";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks"
import PortalContentWrapper from "../../PortalContentWrapper";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import Desktop from "../../../../core-components/layouts/Desktop";
import KiwisaverPersonalInfo from "./KiwisaverPersonalInfo";
import PropertyPersonalInfo from "./PropertyPersonalInfo";
import BusinessesPersonalInfo from "./BusinessesPersonalInfo";
import SharesPersonalInfo from "./SharesPersonalInfo";
import AssetAllocationInfo from "./AssetAllocationInfo"
import "../../../PreAccountLogin/Onboarding.scss"


const InvestmentsInfo = (props) => {
    const { account, onBoardingState, setOnBoardingState, user, remote } = props
    const [parsedData, setParsedData] = useState(null)
    const [submittingChange, setSubmittingChange] = useState(false)

    useEffect(() => {
        remote.get("/modules/koura/portal/onboarding").then(signup => {
            const data = signup.signupData;
            const parsed = JSON.parse(data);
            console.log("Got existing parsed data", parsed)
            setOnBoardingState(parsed);
            setParsedData(parsed)
        })
    }, [])

    
    function commitAndsetOnBoardingState(data) {
        console.log("saving changes!", data)
        setSubmittingChange(true)
        remote.put("/modules/koura/portal/onboarding", data, { rawResponse: true }).then(() => {
            setOnBoardingState(data);
            setTimeout(() => {
                setSubmittingChange(false)
            }, 500); 
        });
    }

    //TODO toaster on submittingChange
    
    return <>
        <Mobile>
            <DefaultMobileBanner />
        </Mobile>
        <Desktop>
            <Banner>
                <BannerInfoText>
                    <BannerInfoMainText className="pending-account"><i className="material-icons">info</i>Account Pending</BannerInfoMainText>
                </BannerInfoText>
                <BannerNavLinks parent={LINK_ITEMS(null, null, user).SETTINGS} />
            </Banner>
        </Desktop>
        <PortalContentWrapper>
            <AssetAllocationInfo onBoardingState={onBoardingState}/>
            <KiwisaverPersonalInfo account={account} onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} commitAndsetOnBoardingState={commitAndsetOnBoardingState} parsedData={parsedData}/>
            <PropertyPersonalInfo account={account} onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} commitAndsetOnBoardingState={commitAndsetOnBoardingState} parsedData={parsedData}/>
            <BusinessesPersonalInfo account={account} onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} commitAndsetOnBoardingState={commitAndsetOnBoardingState} parsedData={parsedData}/>
            <SharesPersonalInfo account={account} onBoardingState={onBoardingState} setOnBoardingState={setOnBoardingState} commitAndsetOnBoardingState={commitAndsetOnBoardingState} parsedData={parsedData}/>
        </PortalContentWrapper>
    </>
}

export default withThreeSkyeGlobal(withAccountInfo(InvestmentsInfo))