import React, { Component } from "react";
import AnalyticsPage from "./AnalyticsPage";
import "./Reports/OtherReportsTable.scss";
import OtherReportTableItem from "./Reports/OtherReportTableItem";
import withPeriodSelect from "@threeskye/core-components/dist/components/WealthTechReports/WithPeriodSelect";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import MobileCardDropdown from "../../../../core-components/filters-and-toggles/MobileCardDropdown";
import Mobile from "../../../../core-components/layouts/Mobile";
import { Row, Col } from "reactstrap";
import DateRangeInput from "../../../../core-components/inputs/DateRangeInput";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";

class OtherReports extends Component {
	render() {
		const isMobile = CommonFunctions.isMobile()
		const { from, to, selectPeriod, onToChange, onFromChange, periodLabels, periodLabel, account, reactKey } = this.props;
		return (
			<AnalyticsPage>
				<div className="card-row">
					<CardContainer xs={12}>
						<Card>
							<CardHeader>
								<h3>Other Reports</h3>
							</CardHeader>
							<Mobile>
								<hr />
								<Row>
									<Col xs="12">
										<label className="filter-heading">Period</label>
										<MobileCardDropdown options={periodLabels} value={periodLabel} onSelect={selectPeriod} />
									</Col>
								</Row>
								<Row className="mt-4">
									<Col xs="12">
										<DateRangeInput key={reactKey} to={to} onToChange={onToChange} from={from} onFromChange={onFromChange} />
									</Col>
								</Row>
							</Mobile>
							<hr />
							<div className="other-reports-table-wrapper">
								<table className="other-reports-table">
									<thead>
										<tr>
											<th className="table-header">Report Name</th>
											<th className="table-header">Period</th>
											<th className="table-header">Date Range</th>
											<th />
										</tr>
									</thead>
									<tbody>
										<OtherReportTableItem
											header="Gross Portfolio Performance"
											isDateRange={true} 
											getLink={(fromDesktop, toDesktop) => `/modules/nzxwt/accounts/${account.id}/generate-gross-portfolio-performance/${isMobile ? from : fromDesktop}/${isMobile ? to : toDesktop}`}
										/>
										<OtherReportTableItem header="Performance by Asset" isDateRange={true} getLink={(fromDesktop, toDesktop) => `/modules/nzxwt/accounts/${account.id}/generate-performance-by-asset/${isMobile ? from : fromDesktop}/${isMobile ? to : toDesktop}`} />
										<OtherReportTableItem header="Portfolio Valuation" isSingleDate={true} getLink={(fromDesktop, toDesktop) => `/modules/nzxwt/accounts/${account.id}/generate-portfolio-valuation/${isMobile ? to : toDesktop}`} />
							
									</tbody>
								</table>
							</div>
						</Card>
					</CardContainer>
				</div>
			</AnalyticsPage>
		);
	}
}

export default withPeriodSelect(withAccountInfo(OtherReports));
