import React, { useEffect, useState } from "react";
import { withRemote } from "@threeskye/global";
import { Row, Col } from 'reactstrap';
import { toastDanger, toastSuccess } from "../../../../core-components/popups/Toast";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Input from "../../../../core-components/inputs/Input";
import Button from "../../../../core-components/inputs/Button";
import Logo from "../../../../core-components/svgs/Logo";
import { Link } from "react-router-dom/cjs/react-router-dom";


const ConfirmEmailAddress = ({remote, email, check, triggerNextView, goBack}) => {
    const [verificationCode, setVerificationCode] = useState("")
    const [verficationCodeErrorMessage, setVerficationCodeErrorMessage] = useState("")

    function verifyCode(code) {
        const data = {
            email,
            check: check,
            code: verificationCode
        }

        remote.post("/public/modules/koura/verify-email", data).then(response => {
            console.log("Got response", response);
            if (response.success) {
                toastSuccess('Code verified', {className: 'prelogin-toast'})
                setVerficationCodeErrorMessage("")
                triggerNextView({
                    email: email,
                    check: response.data.check
                })
                return;
            } else {
                toastDanger(response.message, {className: 'prelogin-toast'})
            }
            setVerficationCodeErrorMessage(response.message);
        })
        
    }
    function resendCode() {
        setVerificationCode("")
        setVerficationCodeErrorMessage("")
        toastSuccess('A new code has been sent', {className: 'prelogin-toast'})
    }

    return (
            <Card className="no-box-shadow">
                <CardHeader className="header-with-subheader">
                    <h3 className="onbording-header no-margin">Confirm your email address</h3>
                    <div style={{ paddingTop: 10 }}>Please verify your email address using the code we sent to <span style={{ fontWeight: 600 }}>{email}</span></div>
                </CardHeader>
                <Row>
                    <Col xs="12" xl="12" className="pl-xl-3">
                        <Input
                            label="Verification Code"
                            type="number" name="number"
                            value={verificationCode}
                            placeholder="Code"
                            errorMessage={verficationCodeErrorMessage}
                            onChange={(e) => setVerificationCode(e)}
                            inputClass={verficationCodeErrorMessage !== "" ? "invalid-input" : ""}
                            errorMessageDown
                        />
                    </Col>
                </Row>
                <VerticalSpacer size="40px" />
               
            <VerticalSpacer size="40px" />
            <Row xs="12">
                <Col xs="12" xl="6" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ textAlign: 'start', margin: 0 }}><span>{verficationCodeErrorMessage ? "Having trouble?" : "Didn't receive an email?"}</span><span onClick={resendCode} style={{ fontWeight: 600, cursor: 'pointer' }}>{verficationCodeErrorMessage ? " Send new code" : " Resend email"}</span></p>
                </Col>
                <Col xs="12" xl="6" className="justify-self-end">
                    <div style={{ display: "flex", gap: 20, justifyContent: "end" }}>
                        <Button onClick={() => goBack(false)} className="button-colour-secondary capitalized">
                            Go Back
                        </Button>
                        {/* TODO: add endpoint to verify email/code */}
                        <Button className="capitalized" onClick={() => verifyCode(verificationCode)} disabled={verificationCode.length < 6}>
                            Verify
                        </Button>
                    </div>

                </Col>
            </Row>
        </Card>
    )
    
}

export default ConfirmEmailAddress;