import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { withRemote } from '@threeskye/global';
import withRouteChange from "@threeskye/route-change";
import HobsonLogo from '../../core-components/svgs/HobsonLogo';
import LoadingMessage from '../../core-components/layouts/LoadingMessage';
import "./HandleBankVerification.scss";
import LoadingSpinner from '../../core-components/layouts/LoadingSpinner';

const BankOverlay = ({ children, header, message, loading }) => (
	<div className="bank-overlay-wrapper">
		<HobsonLogo width={120} />
		<div className="header-wrapper">
			<h3>{header}</h3>
			{loading && <LoadingSpinner />}
		</div>
		<div className="message-wrapper">
			{message}
		</div>
		{children}
	</div>
);

const HandleBankVerification = (props) => {

	const searchParams = props.match.params;
	// const [searchParams, ] = useSearchParams();

	let { search } = useLocation();

	const {showMessage} = props;
	const query = new URLSearchParams(search);
	const code = query.get('code');
	const state = query.get('state');
	const error = query.get('error');
	const errorDescription = query.get('error_description');

	const {remote} = props;
	const [response, setResponse] = useState({});

	// dummy states for testing UI
	// const verified = true;
	// const error = true;

	const navigate = (where) => props.history.push(where);

	useEffect(() => {
		if (error) {
			if (error === 'access_denied') {
				remote.get(`/modules/crm/portal/decode-bank-state/${state}`).then(s => {
					const accId = JSON.parse(s.state);
					navigate("/"+accId.number+"/settings/account-information");
				});
				return;
			}
			showMessage(error, "Unable to add bank account", 'Error', () => navigate("/"));
			return;
		}
		if (!code) {
			navigate("/");
		}
		if (state) {
			try {
				remote.post(`/modules/crm/portal/add-bank-account/${code}/HWP`, state).then(json => {
						setResponse(json);
						if (json.data && json.data.redirect) {
							showMessage('You will be notified when your changes have been approved', "Changes Submitted", 'Error', () => navigate('/'+json.data.redirect));
						}
						if (json.connectionError) {
							showMessage( "Please contact us for assistance.",'We were unable to retrieve your bank account details', 'Error', () => navigate("/"));
							return;
						}
				});
			} catch(e) {
				console.log(e);
				
			}
			return;
		}

	}, [code, state])

	// Success
	if (response && response.connectionError === false && response.data.length > 0) {
		navigate("/");
		return null;
	}
		

	// Failed
	if (response && response.connectionError) {
		return (
			<BankOverlay
				header="Unable to verify bank details"
				message={''}
			/>
		);
	}

	// Loading
	return (
		<BankOverlay loading header="Verifying Bank Account" message="Please wait a moment while we verify your bank account"/>
	);

}

export default withRouteChange(withRemote(HandleBankVerification));