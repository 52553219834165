import React, { Component } from "react";
import AsyncSelect from 'react-select/async';
import { withI18n, withThreeSkyeGlobal } from "@threeskye/global";
import _ from "lodash";
import "../styles/StaticDataOverrides.scss"

class PhoneNumberInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			prefixValue: null,
			valid: true
		};
		this.validate = this.validate.bind(this);
		this.handleNumberChange = this.handleNumberChange.bind(this);
		this.handlePrefixChange = this.handlePrefixChange.bind(this);
		this.handleExtChange = this.handleExtChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.focusNumber = this.focusNumber.bind(this);
		this.numberRef = React.createRef();
		this.retrievePhonePrefixList = this.retrievePhonePrefixList.bind(this)
	}

	componentDidMount() {
		this.updatePrefixValue();
	}

	retrievePhonePrefixList(entry) {
		entry = entry && entry.startsWith("+") ? entry.substring(1) : entry;
		const findMatches = (str, list) => {
			var filteredAndTransformedList = [];
			const match = typeof str === "number" ? str : str.toLowerCase();
			for (var i = 0; i < list.length; i++) {
				if (list[i]["name"].toLowerCase().includes(match)
					|| list[i]["idd"].toString().indexOf(match) === 0) {
					filteredAndTransformedList.push({
						label: list[i].name,
						value: "+" + list[i].idd,
						id: list[i].id
					})
				}
			}
			return filteredAndTransformedList;
		}
		return this.props.publicRoute ? this.props.remote.get("/public/modules/hobson-support/stockfox/idds").then((idds) => findMatches(entry, idds)).catch(() => findMatches(entry, []))

			: this.props.storage.getOrFetch("/modules/crm/idds")
				.then(idds => {
					return findMatches(entry, idds);
				}).catch(() => findMatches(entry, []))
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.prefixValue || _.get(this.props.value, "idd") !== _.get(prevProps.value, "idd")) {
			this.updatePrefixValue();
		}

		if (this.props.setPhoneErrorMessage) {
			!this.state.prefixValue ? this.props.setPhoneErrorMessage(this.props.noPrefixMessage) : this.props.setPhoneErrorMessage(this.props.invalidNumberMessage)
		}
	}

	updatePrefixValue() {
		var idd = _.get(this.props.value, "idd");

		if (!idd)
			return;
		this.retrievePhonePrefixList("" + idd).then(iddList => {
			return (iddList.length > 0 ? this.setState({ prefixValue: iddList[0] }) : null);
		});
	}

	/**
	 * Checks if the new inputValue is valid,
	 * If it is, set error state false and call our onChange prop
	 * If it is not, set error state true, do not call onChange
	 * In all cases,  update our inputValue in the state.
	 * @param {String} inputValue 
	 */
	handleNumberChange(input) {
		this.handleChange(this.state.prefixValue, input.target.value, _.get(this.props.value, "extension"));
	}

	handlePrefixChange(newValue) {
		this.handleChange(newValue, _.get(this.props.value, "number"), _.get(this.props.value, "extension"));
		this.focusNumber();
	}

	handleExtChange(event) {
		this.handleChange(this.state.prefixValue, _.get(this.props.value, "number"), event.target.value);
	}

	handleChange(prefix, number, extension) {
		const isValid = this.validate(prefix, number, extension);
		const data = { idd: prefix && prefix.value, number, extension, valid: isValid };
		if (this.props.onChange) this.props.onChange(data);
		if (this.props.onValidChange && isValid) this.props.onValidChange(data);
		this.setState({ valid: isValid })
		if (this.props.overrideValidation) this.props.overrideValidation(!isValid)
	}

	/**
	 * Wrapper method for prop method
	 * */
	validate(prefix, number, extension) {
		if (!(prefix && prefix.value)) {
			return false;
		}
		// TODO: do we need to use the default values here?
		// number = number || "";
		// extension = extension || "";

		if (this.props.validate) {
			return this.props.validate({ idd: prefix.value, number, extension });
		} else if (this.props.validateAsString) {
			return this.props.validateAsString(prefix.value.concat(number, extension));
		}
		return true;
	}

	renderPrefix(prefix) {
		return <span className="prefix-suggestion">
			<span className="prefix-label">
				{prefix.label}
			</span>
			<span className="prefix-value">
				{prefix.value}
			</span>
		</span>;
	}

	focusNumber() {
		this.numberRef.current.focus();
	}

	render() {
		const { number, extension } = this.props.value ? this.props.value : {};
		const { valid } = this.state;
		return (
			<div className={`phone-number-edit-container ${this.props.extraContainerClassNme ? this.props.extraContainerClassNme : ""}`}>
				{/*<label htmlFor={this.props.name}>{this.props.label}</label>*/}
				<div className={`input-validation-prefix${this.props.hasExtension ? " extension" : ""}`}>
					<AsyncSelect
						isMulti={false}
						isClearable={false}
						value={this.state.prefixValue}
						onChange={this.handlePrefixChange}
						components={
							{
								IndicatorSeparator: () => null,
								LoadingIndicator: () => null,
								DropdownIndicator: () => null
							}
						}
						cacheOptions
						defaultOptions
						loadOptions={this.retrievePhonePrefixList}
						placeholder={this.props.placeholderIDD}
						className={`token-field prefix-input${valid ? "" : " input-invalid"}`}
						classNamePrefix="token-field"
						getOptionLabel={this.renderPrefix}
						getOptionValue={this.props.getOptionValue || (o => JSON.stringify(o))}
						autoFocus={this.props.autoFocus}
						isDisabled={this.props.disabled}
					/>
					<input
						type={this.props.type}
						name={this.props.name}
						placeholder={this.props.placeholderValue}
						className={"input-validation-text" + (valid ? "" : " input-validation-text-invalid")}
						onChange={this.handleNumberChange}
						value={number ? number : ""}
						ref={this.numberRef}
						disabled={this.props.disabled}
					/>
					{this.props.hasExtension && (
						<input
							type={this.props.type}
							name="extension"
							className="extension-input"
							placeholder={this.props.placeholderExt}
							onChange={this.handleExtChange}
							value={extension || ""}
							disabled={this.props.disabled}
						/>
					)}
				</div>
				{valid || this.props.overrideValidation ? "" : (
					<div className="input-validation-warning-message">
						<div className="input-validation-warning-icon">
							<i className="material-icons validation-error">error</i>
						</div>
						<p>{!this.state.prefixValue ? this.props.noPrefixMessage : this.props.invalidNumberMessage}</p>
					</div>
				)}
				{this.props.iconEnd && <div className="icon-inside-input">{this.props.iconEnd}</div>}
			</div>
		);
	}
}

export default withThreeSkyeGlobal(withI18n(PhoneNumberInput));