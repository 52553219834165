import React, { useState, useEffect } from "react";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { Col, Row } from "reactstrap";
import { handleNumberChange } from "../../../PreAccountLogin/OnboardingUtils"
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Button from "../../../../core-components/inputs/Button"
import AddyLocationField from "../../../../core-components/inputs/AddyLocationField"
import "../../../PreAccountLogin/Onboarding.scss"


const PropertyPersonalInfo = (props) => {
    const { onBoardingState, setOnBoardingState, commitAndsetOnBoardingState } = props
    const [propertiesOwned, setPropertiesOwned] = useState(onBoardingState.propertiesOwned)
    const [lastProperty, setLastProperty] = useState("")
    const [editedPropertyValue, setEditedPropertyValue] = useState("")
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [propertyOnEditIndex, setPropertyOnEditIndex] = useState(null)

    useEffect(() => {
        if (onBoardingState.propertiesOwned) {
            setPropertiesOwned(onBoardingState.propertiesOwned)
        }
    }, [onBoardingState.propertiesOwned])

    useEffect(() => {
        if (lastProperty) {
            const allProperties = [...propertiesOwned, { propertyAddress: lastProperty, propertyValue: null }]
            setPropertiesOwned(allProperties)
            setOnBoardingState({ onBoardingState: { ...onBoardingState, propertiesOwned: allProperties } })
            setUnsavedChanges(true)
            setPropertyOnEditIndex(propertiesOwned.length)
            setLastProperty("")
        }
    }, [lastProperty])

    function onNzLocationChange(e) {
        if (e == null) return;
        const { text } = e;
        setLastProperty(text)
    }

    function removeProperty(index) {
        const updatedProperties = propertiesOwned.filter((p, idx) => idx !== index)
        setPropertiesOwned(updatedProperties)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, propertiesOwned: updatedProperties } })
        setUnsavedChanges(true)
    }

    function cancelEdit() {
        setEditedPropertyValue("")
        setLastProperty("")
        setPropertyOnEditIndex(null)
    }

    function saveEdit(index) {
        const updatedProperties = propertiesOwned.map((p, idx) => {
            if (idx === index) {
                const newObject = { propertyAddress: p.propertyAddress, propertyValue: editedPropertyValue }
                return newObject
            } else return p
        })
        setPropertiesOwned(updatedProperties)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, propertiesOwned: updatedProperties } })
        setUnsavedChanges(true)
        cancelEdit()
    }

    function saveChanges() {
        if (unsavedChanges) {
            commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState } })
            setUnsavedChanges(false)
        }
    }


    return (
        <div key="personal-info-kiwisaver">
            <CardContainer>
                <Card>
                    <CardHeader>
                        <div className="portal-card-title" style={{ display: "grid", gridTemplateColumns: "auto 175px 40%", gap: '1rem', width: "100%", maxWidth: 1200 }}>
                            <h3>Property</h3>
                            <Button onClick={saveChanges} disabled={!unsavedChanges}>Save Changes</Button>
                            <AddyLocationField
                                value={{ text: lastProperty, fullAddress: lastProperty }}
                                name={"physical-address"}
                                placeholder="+ Add another property"
                                onUpdate={(e) => onNzLocationChange(e)}
                                isClearable
                                className="onbording-address-field"
                            // publicRoute
                            />
                        </div>

                    </CardHeader>
                    <Row>
                        {propertiesOwned && propertiesOwned.length > 0 && propertiesOwned.map((p, idx) => {
                            return (
                                <Col xs="12" xl="12" className="pl-xl-3" key={idx}>
                                    <div className="property-owned-card" >
                                        <div className="property-owned-card-title">
                                            <h5>{p.propertyAddress}</h5>
                                            <div className='action-icons'>
                                                {propertyOnEditIndex === idx ? <i className="material-icons save-icon" onClick={() => saveEdit(idx)}>save</i> : <i className="material-icons edit-icon" onClick={() => setPropertyOnEditIndex(idx)}>create</i>}
                                                {propertyOnEditIndex === idx ? <i className="material-icons cancel-icon" onClick={() => cancelEdit()}>close</i> : <i className="material-icons delete-icon" onClick={() => removeProperty(idx)}>delete_forever</i>}
                                            </div>
                                        </div>
                                        <div className="property-owned-card-value">
                                            {propertyOnEditIndex === idx ?
                                                <>
                                                    <p>Estimated Value: </p>
                                                    <input
                                                        value={editedPropertyValue || p.propertyValue}
                                                        placeholder="$0"
                                                        name={"property-owned-value-" + idx}
                                                        onChange={(e) => setEditedPropertyValue(handleNumberChange(e))}
                                                        autoFocus
                                                    />
                                                </> :
                                                <p>Estimated Value: {p.propertyValue || ""}</p> 
                                            }
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Card>
            </CardContainer>
        </div >
    )
}

export default withThreeSkyeGlobal(withAccountInfo(PropertyPersonalInfo))