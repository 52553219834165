import React, { Component } from "react";
import { compareStrings } from "@threeskye/core-components/dist/components/Sorting/CompareFunctions";
import ThreeSkyeBarChart from "@threeskye/core-components/dist/components/Charts/ThreeSkyeBarChart";
import ErrorMessage from "../../../../../core-components/layouts/ErrorMessage";
import Formatter from "../../../../../core-components/functions/Formatter";
import { getRefAreasForCategoryBarChart, Y_AXIS_PROPS as yAxisProps } from "../../../../../core-components/functions/ChartFunctions";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";

class CreditRatingBarChart extends Component {
	state = { graphData: null };
	xAxisProps = { tickLine: false, onClick: (e) => this.filterByRating(e.value) };
	refAreaProps = { fill: "#30395a" };
	barProps = { onClick: (e) => this.filterByRating(e.payload.x) };

	constructor(props) {
		super(props);
		this.fetchCreditRating = this.fetchCreditRating.bind(this);
		this.renderTooltip = this.renderTooltip.bind(this);
		this.filterByRating = this.filterByRating.bind(this);
		this.getCellProps = this.getCellProps.bind(this);
	}

	componentDidMount() {
		this.fetchCreditRating();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			this.fetchCreditRating();
		}
	}

	fetchCreditRating() {
		const { data } = this.props;
		let graphData = [];
		if (data && data.length > 0) {
			const valueByRating = data.reduce((valueByRatingMap, { rating, value }) => {
				const valueOfRating = value + (valueByRatingMap[rating] || 0);
				return { ...valueByRatingMap, [rating]: valueOfRating };
			}, {});
			graphData = Object.keys(valueByRating)
				.sort(compareStrings)
				.map((rating) => ({ x: rating, y: valueByRating[rating] }));
		}
		this.setState({ graphData });
	}

	renderTooltip(payload) {
		if (!payload.active || payload.payload.length === 0) {
			return null;
		}
		let value = Formatter.asMoney(payload.payload[0].payload.y, 2);
		let rating = payload.payload[0].payload.x;
		return (
			<div className="graph-tooltip">
				<div className="graph-tooltip-label">{rating}</div>
				<div className="graph-tooltip-value">{value}</div>
			</div>
		);
	}

	filterByRating(rating) {
		const { filters, setFilters } = this.props;
		if (filters) {
			if (filters.filterAssetsBy !== rating) {
				setFilters({ ...filters, filterAssetsBy: rating });
			} else {
				setFilters({ ...filters, filterAssetsBy: null });
			}
		}
	}

	getCellProps(singleData) {
		const { filters } = this.props;
		if (filters) {
			const { filterAssetsBy } = filters;
			if (filterAssetsBy) {
				if (filterAssetsBy && filterAssetsBy === singleData.x) {
					return { className: "active-cell" };
				} else {
					return { className: "inactive-cell" };
				}
			}
		}
	}

	render() {
		const { graphData } = this.state;
		if (!graphData || graphData.length <= 0) {
			return <ErrorMessage infoIcon>Could not create a chart for this account...</ErrorMessage>;
		}
		const { xAxisProps, renderTooltip, refAreaProps, barProps, getCellProps } = this;
		const refAreas = getRefAreasForCategoryBarChart(graphData);
		return <ThreeSkyeBarChart {...{ data: graphData, yAxisProps, xAxisProps, renderTooltip, refAreas, refAreaProps, barProps, getCellProps }} />;
	}
}

export default withFilters(withAccountInfo(withThreeSkyeGlobal(CreditRatingBarChart)));
