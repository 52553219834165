import React, { useState, useEffect } from "react";
import Banner from "../../Banner";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { pirOptions, rwtOptions, irdValidation, displayIRDNumber } from '../../../PreAccountLogin/GeneralDetails'
import { Col, Row } from "reactstrap";
import { format, isValid, parseISO, isAfter } from 'date-fns'
import BannerInfoText, { BannerInfoMainText } from "../../../../core-components/misc/BannerInfoText";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks"
import PortalContentWrapper from "../../PortalContentWrapper";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import Desktop from "../../../../core-components/layouts/Desktop";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import LoadingSpinner from "../../../../core-components/layouts/LoadingSpinner";
import Input from '../../../../core-components/inputs/Input'
import SingleDatePicker from '../../../../core-components/inputs/SingleDatePicker'
import PhoneNumberInput from '../../../../core-components/inputs/PhoneNumberInput'
import AddyLocationField from '../../../../core-components/inputs/AddyLocationField'
import DropDown from '../../../../core-components/inputs/DropDown'
import CommonFunctions from '../../../../core-components/functions/CommonFunctions'
import FormErrorMessage from '../../../../core-components/layouts/FormErrorMessage'
import './PendingPersonalInformation.scss'


const PendingPersonalInformation = ({ remote, onBoardingState, setOnBoardingState }) => {
    const { firstName, middleNames, lastName, dateOfBirth, email, mobile, physicalAddress, postalAddress, isPrescribedPerson, irdNumber, rwtRate, pirRate,
        wealthSource, fundsSource } = onBoardingState

    const [parsedData, setParsedData] = useState(null)
    const [invalidDate, setInvalidDate] = useState(false)
    const [dateErrorMessage, setDateErrorMessage] = useState("")
    const [taxType, setTaxType] = useState(null)
    const [onIRDEdit, setOnIRDEdit] = useState(false)
    const [openRWT, setOpenRWT] = useState(false)
    const [openPIR, setOpenPIR] = useState(false)
    const [isValidIRD, setIsValidIRD] = useState(true)


    useEffect(() => {
        remote.get("/modules/koura/portal/onboarding").then(signup => {
            const data = signup.signupData;
            const parsed = JSON.parse(data);
            console.log("Got existing parsed data", parsed)
            if (parsed && parsed.onBoardingState && parsed.onBoardingState.countriesOfResidence && parsed.onBoardingState.countriesOfResidence.length > 0) {
                setTaxType(parsed.onBoardingState.countriesOfResidence.includes("NZ") ? "NZ Resident" : "Non-Resident")
            }
            setOnBoardingState(parsed);
            setParsedData(parsed)
        })
        document.addEventListener("keydown", handleKeyDown)
        return () => document.removeEventListener("keydown", handleKeyDown)
    }, [])

    useEffect(() => {
        const validNumber = irdValidation(irdNumber)
        setIsValidIRD(validNumber)
    }, [irdNumber])


    function commitAndsetOnBoardingState(data) {
        remote.put("/modules/koura/portal/onboarding", data, { rawResponse: true });
        setOnBoardingState(data);
    }

    function onNzLocationChange(e, string) {
        if (e == null) return;
        const { text } = e;
        if (string === "physical") commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, physicalAddress: text } })
        else if (string === "portal") commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, postalAddress: text } })
    }

    function handleKeyDown(e) {
        if (e.key === "Tab" && !e.shiftKey) {
            if (e.target.getAttribute('name') && e.target.getAttribute('name') === "ird-number") {
                setOpenRWT(true)
                setOpenPIR(false)
            } else if (e.target.getAttribute('id') && e.target.getAttribute('id') === "rwt-rate") {
                setOpenRWT(false)
                setOpenPIR(true)
            } else {
                setOpenRWT(false)
                setOpenPIR(false)
            }
        } else if (e.key === "Tab" && e.shiftKey) {
            if (e.target.getAttribute('id') && e.target.getAttribute('id') === "pir") {
                setOpenRWT(true)
                setOpenPIR(false)
            } else if (e.target.parentElement.className.includes('shift-tab-point')) {
                setOpenPIR(true)
                setOpenRWT(false)
            } else {
                setOpenPIR(false)
                setOpenRWT(false)
            }
        }
    }


    return <>
        <Mobile>
            <DefaultMobileBanner />
        </Mobile>
        <Desktop>
            <Banner>
                <BannerInfoText>
                    <BannerInfoMainText className='pending-account'><i className="material-icons">info</i>Account Pending</BannerInfoMainText>
                </BannerInfoText>
                <BannerNavLinks parent={LINK_ITEMS(null, null, true).SETTINGS} />
            </Banner>
        </Desktop>
        <PortalContentWrapper>
            {!parsedData
                ? <LoadingSpinner />
                : <div>
                    <CardContainer>
                        <Card>
                            <CardHeader>
                                <h3>Personal</h3>
                            </CardHeader>
                            <Row>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <Input label="First Name" name="first-name" value={firstName} placeholder="First Name" onChange={(e) => commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, firstName: e } })} />
                                </Col>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <Input label="Middle Name" name="middle-name" value={middleNames} placeholder="Middle Name" onChange={(e) => commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, middleNames: e } })} />
                                </Col>
                                <Col xs="12" xl="6" className="pl-xl-3">
                                    <Input label="Last Name" name="last-name" value={lastName} placeholder="Last Name" onChange={(e) => commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, lastName: e } })} />
                                </Col>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                        <div className="label-container">
                                            <span className={`form-label`}>Date of Birth</span>
                                        </div>
                                        <SingleDatePicker
                                            value={dateOfBirth}
                                            placeholder={dateOfBirth && dateOfBirth.length > 0 ? "" : "31/01/1970"}
                                            format="dd/MM/yyyy"
                                            onChange={(newValue) => {
                                                setInvalidDate(false)
                                                const dateArray = newValue.split("/").reverse()
                                                const dateString = dateArray.join("-")

                                                const valid = isValid(parseISO(dateString))
                                                if (valid) {
                                                    const today = new Date()
                                                    const date = new Date(dateString)
                                                    const formattedFromDate = format(date, "dd/MM/yyyy")
                                                    commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: formattedFromDate } })
                                                    const ridiculousDate = isAfter(date, today)
                                                    if (ridiculousDate) {
                                                        setDateErrorMessage("The date entered has not yet happened")
                                                        setInvalidDate(true)
                                                    } else {
                                                        setDateErrorMessage("")
                                                        setInvalidDate(false)
                                                    }
                                                } else {
                                                    setInvalidDate(true)
                                                    setDateErrorMessage("Invalid Date")
                                                    setOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: null } })
                                                }
                                            }}
                                            autoSubmit
                                            noCalendar={true}
                                            inputClassName={invalidDate ? "invalid-input" : ""}
                                        />
                                        <div className="label-container">
                                            {invalidDate && <span className="form-error"><FormErrorMessage>{dateErrorMessage}</FormErrorMessage></span>}
                                        </div>
                                    </label>
                                </Col>
                            </Row>
                        </Card>
                    </CardContainer>
                    <CardContainer>
                        <Card>
                            <CardHeader>
                                <h3>Contact</h3>
                            </CardHeader>
                            <Row>
                                <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                                    <Input
                                        label="Email"
                                        // label={<><span className="form-label">Email*</span><i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 800 }}>check</i></>}
                                        // labelStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
                                        disabled
                                        // iconEnd={<i className="material-icons lock-icon">lock</i>}
                                        value={email}
                                        onChange={() => null}
                                        type="email" name="email"
                                        placeholder="Email"
                                    />
                                </Col>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                        <div className="label-container">
                                            <span className={`form-label`}>Mobile</span>
                                        </div>
                                        {/* <div className="label-container">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                <span className={`form-label`}>Mobile*</span>
                                                <i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 900 }}>check</i>
                                            </div>
                                        </div> */}
                                        <PhoneNumberInput
                                            disabled
                                            // iconEnd={<i className="material-icons lock-icon">lock</i>}
                                            name="phone-number"
                                            placeholderIDD="IDD"
                                            placeholderValue="Enter phone number"
                                            invalidNumberMessage=""
                                            noPrefixMessage=""
                                            onChange={() => null}
                                            value={mobile}
                                            placeholderExt="Ext"
                                            extraContainerClassNme="onbording-phone"
                                            publicRoute
                                            phoneErrorMessage=""
                                        />
                                    </label>
                                </Col>
                                <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 115 }}>
                                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                        <div className="label-container">
                                            <span className={`form-label`}>Physical Address</span>
                                        </div>
                                        <AddyLocationField
                                            value={{ text: physicalAddress, fullAddress: physicalAddress }}
                                            name={"physical-address"}
                                            placeholder={'Search an Address'}
                                            onUpdate={(e) => onNzLocationChange(e, "physical")}
                                            isClearable
                                            className="onbording-address-field"
                                            publicRoute
                                        />
                                    </label>
                                </Col>
                                <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 115 }}>
                                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                        <div className="label-container">
                                            <span className={`form-label`}>Postal Address</span>
                                        </div>
                                        <AddyLocationField
                                            value={{ text: postalAddress, fullAddress: postalAddress }}
                                            name={"portal-address"}
                                            placeholder={'Search an Address'}
                                            onUpdate={(e) => onNzLocationChange(e, "portal")}
                                            isClearable
                                            className="onbording-address-field"
                                            publicRoute
                                        />
                                    </label>

                                </Col>
                            </Row>
                        </Card>
                    </CardContainer>
                    <CardContainer>
                        <Card>
                            <CardHeader>
                                <h3>FATCA and Tax</h3>
                            </CardHeader>
                            <Row>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <Input label="Tax Type" name="middle-name" value={taxType} onChange={() => null} disabled />
                                </Col>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <Input
                                        onBlur={() => setOnIRDEdit(false)}
                                        onFocus={() => setOnIRDEdit(true)}
                                        inputStyle={{ letterSpacing: 1 }}
                                        type={onIRDEdit ? "number" : "text"}
                                        label="IRD Number"
                                        name="ird-number"
                                        value={onIRDEdit ? irdNumber : displayIRDNumber(irdNumber)}
                                        placeholder="IRD Number"
                                        onChange={(e) => {
                                            commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, irdNumber: e } })
                                        }}
                                        errorMessage={isValidIRD ? "" : "Please enter a valid IRD number"}
                                        errorMessageDown
                                        invalidState={!isValidIRD}
                                        inputClass={!isValidIRD ? "invalid-input" : ""}
                                    />
                                </Col>

                                <Col xs="12" xl="4" className="pr-xl-3" style={{ height: 115 }}>
                                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                        <div className="label-container">
                                            <span className={`form-label`}>RWT Rate</span>
                                        </div>
                                        <DropDown id="rwt-rate" openDropDown={openRWT} clickable placeholder="Select a Prescribed Investor Rate" value={rwtRate} options={rwtOptions} updateValue={(option) => commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, rwtRate: option } })} fullWidth />
                                    </label>
                                </Col>
                                <Col xs="12" xl="4" className="pr-xl-3" style={{ height: 115 }}>
                                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                        <div className="label-container">
                                            <span className={`form-label`}>PIR</span>
                                        </div>
                                        <DropDown id="pir" openDropDown={openPIR} clickable placeholder="Select a Prescribed Investor Rate" value={pirRate} options={pirOptions} updateValue={(option) => commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, pirRate: option } })} fullWidth />
                                    </label>
                                </Col>
                                <Col xs="12" xl="4" className="pl-xl-3" >
                                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                        <div className="label-container">
                                            <span className={`form-label`}>&nbsp;</span>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            {isPrescribedPerson === "Yes" ?
                                                <i className="material-icons prescribed-person-icon" onClick={() => null}>radio_button_checked</i> :
                                                <i className="material-icons prescribed-person-icon" onClick={() => null}>radio_button_unchecked</i>
                                            }
                                            <p style={{ marginBottom: 0, marginTop: '1rem' }}>Prescribed Person</p>
                                        </div>
                                    </label>
                                </Col>
                            </Row>
                        </Card>
                    </CardContainer>
                    <CardContainer>
                        <Card>
                            <CardHeader>
                                <h3>AML</h3>
                            </CardHeader>
                            <Row>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <Input label="Source of Wealth" name="wealth-source" value={wealthSource} placeholder="Source of wealth" extraClassName="shift-tab-point" onChange={(e) => commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, wealthSource: e } })} errorMessageDown />
                                </Col>
                                <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                                    <Input label="Source of Funds" name="funds-source" value={fundsSource} placeholder="Source of funds" onChange={(e) => commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState, fundsSource: e } })} errorMessageDown />
                                </Col>
                            </Row>
                        </Card>
                    </CardContainer>
                </div >
            }
            {/* <SaveChangesBanner openModal={requiresDocs ? openModal : submitChangeRequest} display={changesMade} submittingChange={submittingChange} removeChanges={removeChanges} /> */}
        </PortalContentWrapper>
    </>
}

export default withThreeSkyeGlobal(withAccountInfo(PendingPersonalInformation));
