import React from 'react';
import Button from '../../../../core-components/inputs/Button';
import "./SaveChangesBanner.scss";
import LoadingSpinner from '../../../../core-components/layouts/LoadingSpinner';

const SaveChangesBanner = ({ onRevertClick, onSaveClick, openModal, changesSubmitted, display, submittingChange, removeChanges}) => {
	return (
		<div id="save-changes-banner" className={display ? ' display-banner' : ''}>
			<div className="save-changes-banner-inner">
				{/* <Button secondary onClick={onRevertClick}>Revert Changes</Button> */}
				{/* <Button onClick={onSaveClick}>Save Changes</Button> */}
				<Button disabled={submittingChange} secondary onClick={removeChanges}>Revert Changes</Button>
				<Button disabled={submittingChange} onClick={openModal}>{submittingChange ?<LoadingSpinner /> : "Save Changes"}</Button>
			</div>
		</div>
   );
}

export default SaveChangesBanner;