import React, { Component } from 'react';
import "./GridTableRow.scss";

class GridTableRow extends Component {
	render() {
		const { columns, columnWidths, onClick, className, style } = this.props;
		return (
			<div className={`grid-table-row${className ? " " + className : ""}`} style={{gridTemplateColumns: columnWidths, ...style}} onClick={onClick}>
				{columns && columns.map((column, idx) => (
					<div key={idx} className={`grid-table-column column-${idx+1}`}>
						{column}
					</div>
				))}
			</div>
		);
	}
}

export default GridTableRow;