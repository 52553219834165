import { memoize, partialRight } from 'lodash';


export const expiringMemo = partialRight(memoize, function memoResolver(...args) {
    const time = (new Date()).getMinutes();
  
    args.push({ time });
  
    const cacheKey = JSON.stringify(args);
  
    return cacheKey;
  });