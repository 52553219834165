import React, { useEffect, useState } from "react";

const ReviewTemplateClients = (props) => {
    const { clients } = props
    const [text, setText] = useState("")

    useEffect(() => {
        console.log("clients", clients)
        if (clients) {
            let string = ""
            const clientsIncluded = clients.filter(c => c.required)
            clientsIncluded.map((c, index) => {
                if (clientsIncluded.length === 1) string = c.name
                else if (index !== clientsIncluded.length - 1) {
                    if (clientsIncluded.length === 2) string = c.name + " "
                    else string += c.name + ", "
                } else string += "and " + c.name
            })
            setText("Dear " + string)
        }
    }, [clients])


    return <span>{text}</span>
}

export default ReviewTemplateClients