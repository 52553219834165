import React, { Component } from "react";

export const MobileMenuContext = React.createContext({
	menuIsOpen: false,
});

export function withMobileMenu(WrappedComponent) {
	return class extends Component {
		render() {
			return <MobileMenuContext.Consumer>{({ menuIsOpen, openMenu, closeMenu }) => <WrappedComponent {...this.props} {...{ menuIsOpen, openMenu, closeMenu }} />}</MobileMenuContext.Consumer>;
		}
	};
}

export class MobileMenuWrapper extends Component {
	state = { menuIsOpen: this.props.initialMenuIsOpen || false };

	constructor(props) {
		super(props);
		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	openMenu() {
		this.setState({ menuIsOpen: true });
	}

	closeMenu() {
		this.setState({ menuIsOpen: false });
	}

	render() {
		const { menuIsOpen } = this.state;
		const { openMenu, closeMenu } = this;
		const { children } = this.props;

		return <MobileMenuContext.Provider value={{ menuIsOpen, openMenu, closeMenu }}>{children}</MobileMenuContext.Provider>;
	}
}
