import React, { Component } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import CardNoPadding from "../../../../core-components/card/CardNoPadding";
import CardList from "../../../../core-components/card/CardList";
import AccountSelectItem from "./AccountSelectItem";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";

class DashboardPortfolios extends Component {
	render() {
		const { accounts } = this.props;
		return (
			<CardContainer xs={12}>
				<CardNoPadding header="Portfolios">
					<CardList
						list={accounts.map((account, key) => (
							<AccountSelectItem key={key} selectableAccount={account} />
						))}
					/>
				</CardNoPadding>
			</CardContainer>
		);
	}
}

export default withAccountInfo(DashboardPortfolios);
