import React, { Component } from "react";
import Token from "../../../../core-components/misc/Token";
import "./AccountSelectItem.scss";
import { displayPortalAccType, getPortalAccType, withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import Formatter from "../../../../core-components/functions/Formatter";
import withRouteChange from "@threeskye/route-change";
import { getRoute, LINK_ITEMS } from "../../CustomerPortalPage";
import Loading from "../../../../core-components/layouts/Loading";

class AccountSelectItem extends Component {
	render() {
		const { account, selectableAccount, changeRoute, accPortfolioMap, accPortfolioIsLoading } = this.props;
		const { asClassName, isNullOrUndefined } = CommonFunctions;
		const { asMoney } = Formatter;
		if (selectableAccount) {
			return (
				<div className="as-option" onClick={() => changeRoute(getRoute(selectableAccount, LINK_ITEMS().PORTFOLIO))}>
					<div className={asClassName(["as-detail-wrapper", account.id === selectableAccount.id && "active-account"])}>
						<div className="as-account-name">
							{selectableAccount.name}
							<Token small>{displayPortalAccType(getPortalAccType(selectableAccount))}</Token>
						</div>
						{accPortfolioIsLoading[account.id] ? (
							<Loading size={50} />
						) : (
							<div className="as-valuation">{isNullOrUndefined(accPortfolioMap[selectableAccount.id]) ? "-" : asMoney(accPortfolioMap[selectableAccount.id].valuation)}</div>
						)}
					</div>
				</div>
			);
		}
	}
}

export default withRouteChange(withAccountInfo(AccountSelectItem));
