export const fields = {
	date: "DATE",
	asset: "ASSET",
	tickerCode: "TICKER_CODE",
	localCost: "LOCAL_COST",
	localCurrency: "LOCAL_CURRENCY",
	nzdCost: "NZD_COST",
	units: "UNITS",
	unitCost: "UNIT_COST",
	currentUnitPrice: "CURRENT_UNIT_PRICE",
	localValue: "LOCAL_VALUE",
	nzdValue: "NZD_VALUE",
	priceGL: "PRICE G/L",
	fxGL: "FX G/L",
	unrealisedGL: "UNREALISED_G/L",
	glPerc: "G/L_PERCENTAGE",
	pendingTransactions: "PENDING_TRANSACTIONS",
	estimatedValue: "ESTIMATED_VALUE",
	percentOfPortfolio: "PERCENT_OF_PORTFOLIO",
};
