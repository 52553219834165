import React, { Component } from "react";
import moment from "moment";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import "./DateRange.scss";
import DateFieldInput from "./DateFieldInput";
import CommonFunctions from "../functions/CommonFunctions";

export const dateRangeIsValid = (fromDate, toDate) => {
	const from = fromDate ? moment(fromDate) : null;
	const to = toDate ? moment(toDate) : null;
	if (from && to) {
		return from.isBefore(to.add(1, "day"));
	}
	return true;
};

export const dateRangeIsValidAndNonNull = (fromDate, toDate) => {
	const from = fromDate ? moment(fromDate) : null;
	const to = toDate ? moment(toDate) : null;
	if (from && to) {
		return from.isBefore(to.add(1, "day"));
	}
	return false;
};

class DateRangeInput extends Component {
	constructor(props) {
		super(props);
		this.getValidity = this.getValidity.bind(this);
	}

	getValidity() {
		const { from, to } = this.props;
		return dateRangeIsValid(from, to);
	}

	render() {
		const { noLabels, noMargins, from, to, onFromChange, onToChange, onBlur, stateShouldUpdateWhenPropsChange } = this.props;
		const isValid = this.getValidity();
		const { asClassName } = CommonFunctions;
		return (
			<div className={asClassName(["date-range-inputs", noLabels && "no-labels", noMargins && "no-margins"])} tabIndex="-1" onBlur={onBlur}>
				<DateFieldInput label={noLabels ? "" : "From"} className={asClassName([!isValid && "invalid"])} date={from} onChange={onFromChange} stateShouldUpdateWhenPropsChange={stateShouldUpdateWhenPropsChange}/>
				<ArrowRightAlt className="date-range-arrow" />
				<DateFieldInput label={noLabels ? "" : "To"} className={asClassName([!isValid && "invalid"])} date={to} onChange={onToChange} stateShouldUpdateWhenPropsChange={stateShouldUpdateWhenPropsChange}/>
			</div>
		);
	}
}

export default DateRangeInput;
