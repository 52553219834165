import React from 'react';
import { withThreeSkyeGlobal } from "@threeskye/global/dist/ThreeSkyeGlobal";

const ProfileImage = ({client}) => {
	if (client && client.hasPhoto) {
		return (
			<div className="provider-image-wrapper">
				<img className="provider-image" src={`data:image/png;base64, ${client.photo}`} alt="Profile" />
			</div>
		);
	} else if (client && client.initials) {
		return <div className="provider-initials-wrapper">{client.initials}</div>;
	}
	return null;
}

export default withThreeSkyeGlobal(ProfileImage);