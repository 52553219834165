import React, { Component } from 'react';
import Button from './Button';
import { FilterList } from '@material-ui/icons';

class MoreFiltersButton extends Component {
	render() { 
		const { onClick, filtersShowing } = this.props;
		return ( 
			<Button compact secondary onClick={onClick}>{filtersShowing ? "Less" : "More"} <FilterList className="ml-2" /></Button>
		 );
	}
}

export default MoreFiltersButton;