import React, { Component } from 'react';
import Async from 'react-select/async';
import { components } from 'react-select';
// import '../TokenField.scss';
// import SelectClearIcon from './SelectClearIcon';

class TokenField extends Component {
	static defaultProps = {
		cacheOptions: true,
	}
	
	constructor(props){
		super(props)
		this.state = {
			isEmpty:true
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	//Overwriting this behavior, keeps the menu closed when the user has erased text
	//The default behavior of this will leave all of the options visible if a user does this
	handleInputChange(input,actionInfo){
		// string, action required One of "set-value","input-change","input-blur","menu-close"
		if(input.length < 1){
			this.setState({
				isEmpty:true,
			});
		}else{
			this.setState({
				isEmpty:false,
			});
		}
	}

	// componentWillReceiveProps(props) {
	// 	this.setState({value: props.value});
	// }
	onChange (value, { action, removedValue }) {
		switch (action) {
			case 'remove-value':
		  	case 'pop-value':
				if (removedValue) {
					if (!this.props.isRemovable(removedValue)) {
						return;
					}
				}
				break;
		  	case 'clear':
				value = value.filter(el=>!this.props.isRemovable(el));
				break;
			default:
				break;
		}
	
		this.props.onUpdate(value, removedValue);
	  }
	
	findMatches(inputValue) {
		return Promise.resolve(this.props.data(inputValue));
	}

	render() {
		return (
			<Async
				{...this.props}
				styles={{
					multiValueRemove: (base,state)=>{return this.props.isRemovable(state.data) ? base : { ...base, display: "none"}}
				}}
				isMulti={this.props.isMulti}
				menuIsOpen={!this.state.isEmpty}
				onInputChange={this.handleInputChange}
				//Overriding the default format of the select
				//These options disable clicking for dropdown,
				//and disables the dropdown indicator.
				openMenuOnClick={this.props.openMenuOnClick}
				components={
					{
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
						MultiValueContainer: (innerprops) => {
							const className = this.props.isSuggestion(innerprops.data) ? "suggestion" : "";
							return (
								<div { ... innerprops.innerProps } ref={innerprops.innerRef} className={className}>
									<components.MultiValueContainer { ... innerprops } />
								</div>
							);
						},
						MultiValueLabel: (innerprops) => {
							return this.props.isSuggestion(innerprops.data) ? 
								<div onClick={()=>this.props.acceptSuggestion(innerprops.data)}>
									<components.MultiValueLabel { ... innerprops} />
								</div> 
								:
								<components.MultiValueLabel { ... innerprops} />;
						},
						MultiValueRemove: (innerprops) => {
							return <components.MultiValueRemove { ... innerprops}><i className="material-icons select-clear-icon">close</i></components.MultiValueRemove>
						},
						ClearIndicator: (innerprops) => {
                            return (
                                <components.ClearIndicator {...innerprops}>
                                    {/* <SelectClearIcon /> */} X
                                </components.ClearIndicator>
                            );
                        },
					}
				}
				cacheOptions={this.props.cacheOptions}
				loadOptions={this.findMatches.bind(this)}
				getOptionLabel={this.props.display}
				placeholder={this.props.placeholder}
				value={this.props.value}
				onChange={this.onChange}
				isClearable={this.props.isClearable}
				blurInputOnSelect={this.props.blurInputOnSelect}
				className={`token-field country-field${this.props.isSingle ? " single-value" : ""}${this.props.clientAccToken ? " cat-token-field" : ""}`} 
				classNamePrefix="token-field"
				openMenuOnFocus={this.props.openMenuOnFocus}
				//This is necessary so that react-select knows what has been selected and what has not.
				getOptionValue={this.props.getOptionValue || (o=>JSON.stringify(o))}
			/>
		);
	}
}

export default TokenField;
