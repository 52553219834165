import React, { Component } from "react";
import "./RadioInputGroup.scss";
import RadioInput from "./RadioInput";

class RadioInputGroup extends Component {
	state = {};
	render() {
		const { groupLabel, options, onChange, value, toggleable, clear, className, radioButtonClass, disabled } = this.props;

		return (
			<div className="radio-input-group">
				<div className={className? className : "filter-heading"}>{groupLabel}</div>
				<div className="radio-fields">{options && options.map((option, key) => <RadioInput key={key} label={option} checked={option === value} onClick={() => disabled ? null : toggleable && option === value ? clear() : onChange(option)} radioButtonClass={radioButtonClass} disabled/>)}</div>
			</div>
		);
	}
}

export default RadioInputGroup;
