import React from "react";
import DashboardAdvisers from "./DashboardAdvisers";
import DashboardNotifications from "./DashboardNotifications";
import Desktop from "../../../../core-components/layouts/Desktop";
import PortalContentWrapper from "../../PortalContentWrapper";
import Banner from "../../Banner";
import BannerInfoText, { BannerInfoIntroText } from "../../../../core-components/misc/BannerInfoText";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import DashboardPortfolios from "./DashboardPortfolios";
import DashboardDisclaimer from "./DashboardDisclaimer";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";


const Dashboard = ({ userDetails }) => {
	const isPendingAccount = userDetails && userDetails.clientStatus === 'PROSPECT';// && user.id == "17745" || user && user.id == "49" || user && user.id == "11569"

	return (
		<>
			<Mobile>
				<DefaultMobileBanner />
			</Mobile>
			<Desktop>
				<Banner>
					<BannerInfoText noPadding={isPendingAccount}>
						{!isPendingAccount && <BannerInfoIntroText>Welcome to your dashboard</BannerInfoIntroText>}
					</BannerInfoText>
				</Banner>
			</Desktop>
			<PortalContentWrapper hideBackToTop whiteBackground>
				<div className="card-row" style={isPendingAccount ? { backGroundColor: "#fff" } : null}>
					{isPendingAccount ?
						<div style={{ width: "100%" }}>
							{/* <div style={{ display: "flex", justifyItems: "center" }}> */}
								<div style={{ display: "flex", width: "100%", justifyContent: "center", gap: 5, paddingBottom: 20 }}>
									<i className="material-icons" style={{color: "#0E8EFD", backgroundColor: "#fff"}}>info</i>
									<h4>Account Pending</h4>
								</div>
							{/* </div> */}
							<p style={{ textAlign: "center", fontSize: 16}}>
								Looks like your account is still being setup. You will receive an email when this process has been completed.<br />
								<span style={{ color: "blue" }}>Contact</span> your adviser if you have any questions.
							</p>
						</div>
						// <p>
						// 	Your Dashboard has been stolen.
						// 	<br /><br />
						// 	Regards,
						// 	<br />
						// 	Andy
						// </p>
						: <>
							<DashboardPortfolios />
							<DashboardAdvisers />
							<Desktop>
								<DashboardNotifications />
							</Desktop>
							<DashboardDisclaimer />
						</>
					}
				</div>
			</PortalContentWrapper >
		</>
	);
}
export default withAccountInfo(Dashboard);
