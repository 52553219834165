import React, { Component } from "react";
import "./FormErrorMessage.scss";

class FormErrorMessage extends Component {
	constructor(props) {
		super(props);
		this.state = { errorMessage: this.props.children, hiding: !this.props.children, shouldAnimate: !!this.props.children };
		this.onHiding = this.onHiding.bind(this);
	}

	componentDidUpdate(prevProps) {
		const currProps = this.props;
		if (currProps.children !== prevProps.children) {
			if (!this.state.shouldAnimate) this.setState({ shouldAnimate: true });
			clearTimeout(this.hidingTimeout);
			if (currProps.children) {
				this.setState({ errorMessage: currProps.children, hiding: false });
			} else {
				this.setState({ hiding: true }, this.onHiding);
			}
		}
	}

	componentWillUnmount() {
		clearTimeout(this.hidingTimeout);
	}

	onHiding() {
		this.hidingTimeout = setTimeout(() => {
			this.setState({ errorMessage: "" });
		}, 600);
	}

	render() {
		const { errorMessage, hiding, shouldAnimate } = this.state;
		let className = "form-error-message";

		if (shouldAnimate) className += ` message-fade-${hiding ? "out" : "in"}`;

		if (this.props.warning) {
			className += " warning";
		}
		if (this.props.right) {
			className += " right";
		}

		return <div className={className}>{errorMessage}</div>;
	}
}

export default FormErrorMessage;
