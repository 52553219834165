import React, { Component } from "react";
import "./ExpandedRow.scss";
import { Collapse } from "reactstrap";
import CommonFunctions from "../functions/CommonFunctions";

class ExpandedRow extends Component {
	render() {
		const { colSpan, showRow, onClose, children } = this.props;
		const { asClassName } = CommonFunctions;

		return (
			<tr className={asClassName(["expanded-row", showRow && "row-active"])}>
				<td className="p-0 expanded-td" colSpan={colSpan}>
					<Collapse isOpen={showRow} onExited={onClose}>
						{children}
					</Collapse>
				</td>
			</tr>
		);
	}
}

export default ExpandedRow;
