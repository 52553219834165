export const getErrorMessage = (pw1, pw2) => {

	//Check strength
	if (pw1.length < 8) {
		return "Password must be at least 8 characters";
	}

	let count = pw1.match(/[a-z]/) ? 1 : 0;
	count += pw1.match(/[A-Z]/) ? 1 : 0;
	count += pw1.match(/[0-9]/) ? 1 : 0;
	count += pw1.match(/[^A-Za-z0-9]/) ? 1 : 0;
	if (count < 3) {
		return "Password must contain at least three of lower case, upper case, numeric and non alphanumeric characters";
	}
	
	if (pw1 !== pw2) {
		return "Passwords do not match";
	}

	return "";
	
};
