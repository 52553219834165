import React, { Component } from 'react';
import "./TextOptionGroup.scss";

class TextOptionGroup extends Component {
	render() { 
		const { options, activeOption, select } = this.props;
		return (
			<ul className="text-option-group">
				{options &&
					options.length >= 0 &&
					options.map((option, idx) => (
						<li key={idx} className={`text-option${activeOption === option ? " active-option" : ""}`} onClick={() => select(option)}>
							{option}
						</li>
					))}
			</ul>
		);
	}
}

export default TextOptionGroup;