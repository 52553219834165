import React, { useEffect, useState } from "react";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { Col, Row } from "reactstrap";
import { handleNumberChange } from "../../../PreAccountLogin/OnboardingUtils"
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import DropDown from "../../../../core-components/inputs/DropDown";
import Input from "../../../../core-components/inputs/Input";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer"
import Button from "../../../../core-components/inputs/Button"


const KiwisaverPersonalInfo = (props) => {
    const { onBoardingState, setOnBoardingState, commitAndsetOnBoardingState } = props
    const [kiwisaverProvider, setKiwisaverProvider] = useState(onBoardingState.kiwisaverProvider)
    const [kiwisaverValue, setKiwisaverValue] = useState(onBoardingState.kiwisaverValue)
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    const providers = [
        "AE KiwiSaver Limited", "AMP", "Anglican Financial Care", "ANZ Investments", "ASB Group Investments Ltd",
        "BNZ", "Booster Investment Management", "BT Funds (Westpac)", "Civic Financial Services", "Consilium NZ Limited",
        "Craigs Investment Partners Superannuation Management Limited", "Fisher Funds Management Ltd", "Fisher Funds Wealth Limited",
        "Generate Investment Management Limited", "Implemented Investment Solutions Limited", "Kernel Wealth Limited",
        "Koura Wealth Limited", "Lifestages", "Medical Funds Management Limited", "Mercer (NZ) Limited", "Milford Funds Limited",
        "New Zealand Funds Management Limited", "Nikko Asset Management New Zealand Limited", "Pathfinder Asset Management",
        "PIE KiwiSaver Scheme", "SBS Bank", "Sharesies Investment Management Limited", "Simplicity", "Smartshares Limited",
        "Summer"
    ]

    useEffect(() => {
        if (onBoardingState.kiwisaverProvider) {
            setKiwisaverProvider(onBoardingState.kiwisaverProvider)
            setKiwisaverValue(onBoardingState.kiwisaverValue)
        }
    }, [onBoardingState.kiwisaverProvider, onBoardingState.kiwisaverValue])


    function updatePropertyValue(e) {
        const formattedValue = handleNumberChange(e)
        setKiwisaverValue(formattedValue)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, kiwisaverValue: formattedValue } })
        setUnsavedChanges(true)
    }

    function updateProvider(option) {
        setKiwisaverProvider(option);
        setOnBoardingState({ onBoardingState: { ...onBoardingState, kiwisaverProvider: option } })
        setUnsavedChanges(true)
    }

    function saveChanges() {
        if (unsavedChanges) {
            commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState } })
            setUnsavedChanges(false)
        }
    }

    return (
        <div key="personal-info-kiwisaver">
            <CardContainer>
                <Card>
                    <CardHeader>
                        <h3>Kiwisaver</h3>
                    </CardHeader>
                    <Row>
                        <Col xs="12" xl="6" className="pl-xl-3">
                            <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                <div style={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}>Provider</div>
                                <DropDown clickable placeholder="Select a Kiwisaver provider" value={kiwisaverProvider} options={providers} updateValue={(option) => updateProvider(option)} fullWidth />
                            </label>
                        </Col>
                        <Col xs="12" xl="6" className="pl-xl-3">
                            <Input label="Value" labelStyle={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }} name="kiwisaverValue" placeholder="$0" value={kiwisaverValue || ""} onChange={(e) => updatePropertyValue(e)} />
                        </Col>
                    </Row>
                    <VerticalSpacer size="40px" />
                    <Row className="justify-content-end">
                        <Col xs="auto" className="justify-self-end">
                            <div style={{ display: "flex", gap: 20 }}>
                                <Button onClick={saveChanges} disabled={!unsavedChanges}>
                                    Save Changes
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </CardContainer>
        </div >
    )
}

export default withThreeSkyeGlobal(withAccountInfo(KiwisaverPersonalInfo))