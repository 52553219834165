import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import withRouteChange from "@threeskye/route-change";
import "./SideNav.scss";
import HobsonLogo from "../../core-components/svgs/HobsonLogo";
import Logo from "../../core-components/svgs/Logo";
import Button from "../../core-components/inputs/Button";
import { withTheme } from "../../core-components/contexts/ThemeContext";
import { LINK_ITEMS, getRoute } from "./CustomerPortalPage";
import LinkItem from "./LinkItem";
import { getPortalAccType, PortalAccTypes, withAccountInfo } from "../../core-components/contexts/AccountsContext";
import CommonFunctions from "../../core-components/functions/CommonFunctions";
import { AssignmentInd, Group } from "@material-ui/icons";

const SideNav = withAccountInfo(
	class extends Component {

		state = { 
			notifications: null,
			isPendingAccount: null
		 }

		constructor(props) {
			super(props);
			this.getNotifications = this.getNotifications.bind(this)
			this.checkPendingAccount = this.checkPendingAccount.bind(this)
		}

		componentDidMount() {
			this.props.storage.getOrFetch("/modules/crm/client-notifications").then(this.getNotifications)
			this.props.storage.watch("/modules/crm/client-notifications", this.getNotifications)
			this.checkPendingAccount()
		}

		componentWillUnmount() {
			this.props.storage.unwatch("/modules/crm/client-notifications")
		}

		getNotifications(fetchNotifications) {
			const newReviews = fetchNotifications && fetchNotifications.length > 0 && fetchNotifications.filter(ntf => {
				const draft = JSON.parse(ntf.content)
				if (draft && draft.clientPortalLink) return true
			})
			this.setState({ notifications: newReviews })
		}

		checkPendingAccount() {
			const isPending = this.props.userDetails && this.props.userDetails.clientStatus === 'PROSPECT' || !this.props.accounts || this.props.accounts.length === 0
			this.setState({ isPendingAccount: isPending})
		}

		render() {
			const { theme, logout, accounts, thirdPartyView, showSwitchType, switchToAccountAccess, switchToThirdParty, history, userDetails } = this.props;
			const PortalLogo = theme === "theme-hobson" ? HobsonLogo : Logo;
			const { pathname } = history.location
			const accountNumber = pathname.split("/")[1]
			const hasPortfolioAcc = accounts && !!accounts.find((account) => getPortalAccType(account) === PortalAccTypes.PORTFOLIO);

			if (this.state.isPendingAccount === null) return <div>caca</div>
			return (
				<div id="portal-side-nav" className={showSwitchType ? 'switch-showing' : ''}>
					<div id="portal-side-nav-content">
						<div className="side-nav-logo">
							<PortalLogo width={150} sideNavLogo />
						</div>
						<div id="portal-side-nav-links">
							{!thirdPartyView && (
								<>
									<SideNavLinkItem linkItem={LINK_ITEMS().DASHBOARD} />
									{this.state.isPendingAccount === false && <SideNavAccountSelect />}
								</>
							)}
							{Object.keys(LINK_ITEMS()).map((key, idx) => {
								const link = LINK_ITEMS(this.state.notifications, accountNumber, this.state.isPendingAccount, userDetails)[key];
								if (link.excludeFromSideNav)
									return null;

								const onlyPortfolio = !!link.onlyPortfolio;
								if (onlyPortfolio && !hasPortfolioAcc)
									return null;

								if (thirdPartyView && !link.thirdParty)
									return null;

								if (!thirdPartyView && link.thirdPartyOnly)
									return null;

								if (this.state.isPendingAccount && link.disableForPendingAccs)
									return null;

								if (link.subPages) {
									return <SideNavLinkItem key={idx} linkItem={link} subLinkItem={link.subPages[key]} thirdPartyView={thirdPartyView} isPendingAccount={this.state.isPendingAccount}/>
								} else {
									return <SideNavLinkItem key={idx} linkItem={link} thirdPartyView={thirdPartyView} isPendingAccount={this.state.isPendingAccount}/>;
								}
							})}
						</div>
					</div>
					<div className="side-nav-footer">
						{showSwitchType && <>
							{!thirdPartyView && <Button className="mb-2" secondary onClick={() => switchToThirdParty()}><AssignmentInd />Third Party View</Button>}
							{thirdPartyView && <Button className="mb-2" secondary onClick={() => switchToAccountAccess()}><Group />Accounts View</Button>}
						</>}
						<Button primaryHighlight onClick={logout}>log out</Button>
					</div>
				</div>
			);
		}
	}
);

const SideNavLinkItem = withAccountInfo(
	class extends Component {
		render() {
			const { linkItem, subLinkItem, account, thirdPartyView, disabled, isPendingAccount } = this.props;
			return (
				<div className="side-nav-link-wrapper">
					<NavLink to={getRoute(account, linkItem, subLinkItem, thirdPartyView)} activeClassName="active-link">
						<LinkItem linkItem={linkItem} account={account} />
					</NavLink>
				</div>
			);
		}
	}
);

const SideNavAccountSelect = withAccountInfo(
	class extends Component {
		render() {
			const { accounts, switchAccount, account } = this.props;
			const { asClassName } = CommonFunctions;
			return (
				<div className="sub-link-outer">
					<div className="side-nav-link-wrapper">
						<div className="sub-links-wrapper">
							{accounts.map((selectableAcc, index) => (
								<div className={asClassName(["sub-link", selectableAcc.id === account.id && "active-link"])} key={index} onClick={() => switchAccount(selectableAcc)}>
									{selectableAcc.name}
								</div>
							))}
						</div>
					</div>
				</div>
			);
		}
	}
);

export default withAccountInfo(withTheme(withRouteChange(SideNav)));
