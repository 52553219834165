import React, { useEffect, useState } from 'react';
import Button from '../../../../core-components/inputs/Button';
import TextArea from '../../../../core-components/inputs/TextArea';
import FlexWrapper from '../../../../core-components/misc/FlexWrapper';
import CommentBlock from './CommentBlock.js';
import { withThreeSkyeGlobal } from '@threeskye/global';
import { intlFormatDistance } from 'date-fns'
import './CommentsPanel.scss';

const CommentsPanel = (props) => {
	const { remote, storage, display, document, currentUser, comments, setComments, account, advisersIds } = props
	const [newComment, setNewComment] = useState("")


	useEffect(() => {
		if (comments && comments.length > 0) {
			comments.sort((a,b) => b.commentTime.localeCompare(a.commentTime))

			const formattedComments = comments && comments.length > 0 && comments.map(c => {
				return {
					user: getAuthor(c.commenter),
					date: getCommentTime(c.commentTime),
					text: c.comment
				}
			});

			setComments(formattedComments)
		}
	}, [])

	function getAuthor(commenter) {
		const commenterIsAdviser = account.advisers.find(adviser => adviser.id === commenter)
		const commenterIsClient = account.holders.find(holder => holder.id === commenter)
		if (commenterIsAdviser) {
			const adviser = {
				fullName: commenterIsAdviser.givenName + " " + commenterIsAdviser.familyName,
				initials: commenterIsAdviser.givenName.charAt(0) + commenterIsAdviser.familyName.charAt(0)
			}
			return adviser
		} else if (commenterIsClient) {
			const client = {
				fullName: commenterIsClient.givenName + " " + commenterIsClient.familyName,
				initials: commenterIsClient.givenName.charAt(0) + commenterIsClient.familyName.charAt(0)
			}
			return client
		}
		else {
			const notFoundUser = { fullName: "unknown author", initials: "N/A" }
			return notFoundUser
		}
	}

	function getCommentTime(date) {
		const now = new Date();
		const commentDate = new Date(date)
		const dateDistance = intlFormatDistance(commentDate, now)
		return dateDistance.includes("seconds") || dateDistance === "now" ? "just now" : dateDistance
	}

	function handleChange(value) {
		setNewComment(value)
	}

	function submitComment() {
		if (newComment && document) {
			const commentAndNotificationObject = {
				commentDto: {
					comment: newComment,
					reviewId: document.id,
				},
				reviewAdviserNotificationDTO: {
					"advisersIds": advisersIds,
					"accountId": document.accountId,
					"message": `Review Feedback by ${currentUser.fullName}`,
					"reviewId": document.id
				}
			}
 
			advisersIds && advisersIds.length > 0 && remote.post(`/modules/crm/account/${account.id}/review/${document.id}/client-comment`, commentAndNotificationObject)
				.then((resp) => {
					const postedComment = {
						user: getAuthor(currentUser.id),
						date: getCommentTime(new Date()),
						text: newComment
					}
					setComments([postedComment, ...comments])
					storage.refresh(`/modules/crm/account/${account.id}/review/${document.id}`)
				})
			setNewComment("")
		}
	}


	return (
		<div className={`comment-panel${display ? " display-panel" : ""}`}>
			{document.status === "Authorised" ? "" :
				<div className="comment-input-section">
					<TextArea type="text" placeholder="Enter your comment" className="comment-input" autoFocus={display} onChange={handleChange} value={newComment} />
					<Button disabled={newComment === ""} className="submit-button" compact secondary onClick={submitComment}>
						Submit
					</Button>
				</div>}
			<div className="comment-list-section">
				{comments && comments.length > 0 ? (
					<ul className="comment-list">
						{comments.map((comment, index) => (
							<li key={index} className="comment-item">
								<CommentBlock comment={comment} />
							</li>
						))}
					</ul>
				) : (
					<FlexWrapper justify="center" gap={8} className="empty-state-message">
						<h5>No Comments Posted</h5>
						<p>Have issues or questions? Try posting a comment above</p>
					</FlexWrapper>
				)}
			</div>
		</div>
	);
};

export default withThreeSkyeGlobal(CommentsPanel);
