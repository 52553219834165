import React, { Component } from "react";
import "./Portfolio.scss";
import Returns from "./Returns";
import AssetGroups from "./AssetGroups";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withAccountInfo, PortalAccTypes } from "../../../../core-components/contexts/AccountsContext";
import NavigatorAssets from "./navigator-assets/NavigatorAssets";
import AccountPageFilterCard from "./AccountPageFilterCard";
import PortfolioAssets from "./portfolio-assets/PortfolioAssets";
import LandscapePrompt from "../../../../core-components/misc/LandscapePrompt";
import PortfolioBanner from "./PortfolioBanner";
import PortalContentWrapper from "../../PortalContentWrapper";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";

class Portfolio extends Component {
	assetComponents = {
		[PortalAccTypes.NAVIGATOR]: NavigatorAssets,
		[PortalAccTypes.PORTFOLIO]: PortfolioAssets,
	};

	constructor(props) {
		super(props);
		this.generateCSV = this.generateCSV.bind(this);
	}

	generateCSV() {
		let csv = "";
		const { account } = this.props;
		if (this.getAssetGroupsCSV) {
			csv += this.getAssetGroupsCSV();
		}
		if (this.getAssetCSVFuncList && this.getAssetCSVFuncList.length > 0) {
			if (csv) {
				csv += "\n\n\n";
			}
			csv += this.getAssetCSVFuncList
				.filter((func) => !!func)
				.map((getAssetCSVFunc) => getAssetCSVFunc())
				.join("\n\n\n");
		}
		if (csv) {
			const base64 = btoa(csv);
			CommonFunctions.download({ base64, filename: `${account.name} Portfolio`, extension: "csv" });
		}
	}

	render() {
		const { portalAccountType } = this.props;
		const AssetComponent = this.assetComponents[portalAccountType];

		return (
			<>
				<PortfolioBanner onExport={this.generateCSV} />
				<PortalContentWrapper>
					<div id="portal-account-overview">
						<div className="card-row">
							<Returns />
							<AssetGroups setGetCSVFunc={(func) => (this.getAssetGroupsCSV = func)} />
							<AccountPageFilterCard onExport={this.generateCSV} />
							{AssetComponent && <AssetComponent setGetCSVFuncList={(funcList) => (this.getAssetCSVFuncList = funcList)} />}
							<LandscapePrompt />
						</div>
					</div>
				</PortalContentWrapper>
			</>
		);
	}
}

export default withAccountInfo(withThreeSkyeGlobal(Portfolio));
