import React, { useCallback, useMemo } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withAccountInfo } from '../../../../core-components/contexts/AccountsContext';
import coverPicture from './coverPicture.jpg'
import threeskyeCoverPicture from './threeSkyeCoverPicture.png'
import threeSkyeLogo from './threeSkyeLogo.png'
import RangeBar from '../../../../core-components/graphs/RangeBar'
import ReviewsPortfolioPerformanceChart from './ReviewsPortfolioPerformanceChart'
import ReviewRecommendedTacticalAllocation from './ReviewRecommendedTacticalAllocation'
import ProposePortfolioTableRow from './ProposePortfolioTableRow'
import ReviewTemplateClients from './ReviewTemplateClients'


const EditableVoidsExample = (props) => {
    const { account, review, reviewPeriodSelected, signatures } = props
    const readOnly = props.readOnly
    const editor = useMemo(() => {
        return withReact(createEditor())
    }, [])

    const DefaultElement = (props) => {
        return <p {...props.attributes}>{props.children}</p>
    }

    const Image = (props) => {
        const url = props.element.url.split("/img/Demo/")[1]
        const name = url.split(".")[0]
        const image = name === "coverPicture" ? threeskyeCoverPicture : threeSkyeLogo
        // console.log(url)

        return <img {...props.attributes} alt=''
            className={props.element.class}
            width={props.element.width}
            height={props.element.height}
            src={image} ></img>
    }

    const Leaf = (props) => {
        let { attributes, children, leaf } = props
        let style = {}
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.code) {
            children = <code>{children}</code>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }

        if (leaf.highlight) {
            style = { backgroundColor: "yellow", color: "black" }
        }

        return <span style={style} {...attributes}>{children}</span>
    }

    const renderLeaf = useCallback((props) => <Leaf {...props} />, [])

    const renderElement = useCallback((props) => {
        const { element, attributes, children, data } = props
        const style = {
            textAlign: element.align,
            width: element.width,
            marginBottom: element.marginBottom,
            marginTop: element.marginTop,
            paddingTop: element.paddingTop,
            paddingLeft: element.paddingLeft,
            height: element.height
        }

        switch (element.type) {
            case 'heading-three':
                return <h3 style={style} {...attributes}>{children}</h3>
            case 'heading-four':
                return <h4 {...attributes}>{children}</h4>
            case 'uneditable':
                return <div style={style} contentEditable={false} {...props.attributes}>{props.children}</div>
            case 'paragraph':
                return <p className={props.element.class} style={style} {...props.attributes}>{props.children}</p>
            case 'span':
                return <span className={props.element.class} style={style} {...props.attributes}>{props.children}</span>
            case 'header':
                return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: '45px' }} {...props.attributes} {...data}>{props.children}</div>
            case 'list-item':
                return (
                    <li style={style} {...props.attributes}>
                        {props.children}
                    </li>
                )
            case 'bulleted-list':
                return (
                    <ul className={props.element.class} style={style} {...props.attributes}>
                        {props.children}
                    </ul>
                )
            case 'numbered-list':
                return (
                    <ol style={{ paddingLeft: 30 }} {...attributes}>
                        {children}
                    </ol>
                )
            case 'horizontal-rule':
                return <hr style={style} id={props.element.id} {...props.attributes} />
            case 'break':
                return <br />
            case 'valuation-graph':
                return <div><ReviewsPortfolioPerformanceChart account={account && account} reviewPeriodSelected={reviewPeriodSelected && reviewPeriodSelected} /></div>
            case 'image':
                return <Image {...props} />
            case 'div':
                return <div className={props.element.class} {...props.attributes}>{props.children}</div>
            case 'heading':
                return (
                    <h1 style={style} {...attributes} id={data?.id}>
                        {children}
                    </h1>
                )
            case 'tactical-allocation':
                return <ReviewRecommendedTacticalAllocation selectedSquares={element.selectedSquares} />
            case 'proposed-portfolio-table-row':
                return <ProposePortfolioTableRow
                    min={element.min}
                    max={element.max}
                    target={element.target}
                    assetClassName={element.assetClassName}
                    group={element.group}
                />
            case 'footer':
                return <footer {...props.attributes}>{children}</footer>
            case 'table':
                return <table style={style} className={element.class} {...attributes}>{children}</table>
            case 'table-row':
                return <tr {...attributes}>{children}</tr>
            case 'uneditable-table-row':
                return <tr contentEditable={false} className={element.class} {...attributes}>{children}</tr>
            case 'table-cell':
                return <td className={element.class} style={style} {...attributes}>{children}</td>
            case 'uneditable-table-cell':
                return <td contentEditable={false} className={element.class} style={style} {...attributes}>{children}</td>
            case 'table-head':
                return <thead {...attributes}>{children}</thead>
            case 'table-body':
                return <tbody {...attributes}>{children}</tbody>
            case 'table-header':
                return <th {...attributes} style={style} >{children}</th>
            case 'hobson-slider':
                return <RangeBar
                    extent={100}
                    value={element.value} //{holdings.percentPortfolio}  // Pass these through from the element???
                    min={element.min}
                    max={element.max}
                    colour1={"rgb(13 142 253 / 60%)"}
                    colour2={"#0E8EFD"}
                    colour3={"#F4F4F4"}
                    noToolTip={true}
                    centreLogoOnPoint={true}
                />
            case 'clients-array':
                return <ReviewTemplateClients clients={signatures} />
            case 'review-input':
                return <div
                    placeholder={element.placeholder}
                    className={`${element.class} ${readOnly ? "read-only" : ""}`}
                >{props.children}</div>
            default:
                return <DefaultElement {...props} />
        }
    }, [account])

    const value = props.template

    return (
        <Slate editor={editor} value={value}>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="Enter some text..."
                readOnly={readOnly}
            />
        </Slate>
    )
}


export default withAccountInfo(EditableVoidsExample)