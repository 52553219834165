import React, { Component } from "react";
import "./Alert.scss";
import Button from "../inputs/Button";
import CommonFunctions from "../functions/CommonFunctions";

class Alert extends Component {
	render() {
		let { children, isOpen, close, className, subMessage } = this.props;
		if (className) className = `portal-alert ${className}`;
		else className = "portal-alert";
		if (!isOpen) className += " closed";
		className += ` ${CommonFunctions.responsiveClassname()}`
		return (
			<div className={className}>
				<div className="content">
					<div className="text">{children}</div>
					<div className="sub-text">{subMessage}</div>
					<Button onClick={close}>Okay</Button>
				</div>
			</div>
		);
	}
}

export default Alert;
