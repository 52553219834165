import React from "react";
import { NavLink } from "react-router-dom";
import withRouteChange from "@threeskye/route-change";
import Logo from "../../core-components/svgs/Logo";
import { getRoute } from "./OnboardingUtils";
import LinkItem from "../CustomerPortalPage/LinkItem";
import "../CustomerPortalPage/SideNav.scss";


const SideNavLinkItem = (props) => {
    const { linkItem, pathname } = props;

    return (
        <div className="side-nav-link-wrapper">
            <NavLink to={getRoute(linkItem)} activeClassName={pathname === linkItem.path ? "active-link" : null}>
                <LinkItem linkItem={linkItem} />
            </NavLink>
        </div>
    );
}

const PreAccountSideNav = (props) => {
    const { logout, location, preLoginRoutes } = props;

    return (
        <div id="portal-side-nav">
            <div id="portal-side-nav-content">
                <div className="side-nav-logo">
                    <Logo width={150} sideNavLogo />
                </div>
                <div id="portal-side-nav-links">
                    {preLoginRoutes.map((link, idx) => {
                        return <SideNavLinkItem key={idx} linkItem={link} pathname={location && location.pathname}/>;
                    })}
                </div>
            </div>
        </div>
    );
}

export default withRouteChange(PreAccountSideNav);
