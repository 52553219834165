import React, { Component, useState, useEffect } from "react";
import withRouteChange from "@threeskye/route-change";
import Banner from "../../Banner";
import PortalContentWrapper from "../../PortalContentWrapper";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import DocumentFilters from "./DocumentsFilters";
import DocumentsTable from "./DocumentsTable";
import Loading from "../../../../core-components/layouts/Loading";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks";
import BannerInfoText, { BannerInfoMainText, BannerInfoHighlightText, BannerInfoIntroText } from "../../../../core-components/misc/BannerInfoText";
import { withAccountInfo, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import { compareDates } from "@threeskye/core-components/dist/components/Sorting/CompareFunctions";
import { LINK_ITEMS } from "../../CustomerPortalPage";
import { FiltersWrapper } from "../../../../core-components/filters-and-toggles/withFilters";
import "./MyDocuments.scss";


const MyDocuments = (props) => {
	const { remote, storage, account, portalAccountType, thirdPartyView } = props
	const [isLoadingDocuments, setIsLoadingDocuments] = useState(true)
	const [documents, setDocuments] = useState([])
	const [notifications, setNotifications] = useState(null)


	useEffect(() => {
		storage.getOrFetch("/modules/crm/client-notifications").then((fetchNotifications) => {
			const reviewNotification = fetchNotifications && fetchNotifications.length > 0 && fetchNotifications.filter(ntf => {
				const draft = JSON.parse(ntf.content)
				if (draft && draft.clientPortalLink) return true
			})
			setNotifications(reviewNotification)
		})

		getData()
	}, [account])

	const getData = async () => {
		let documents = await getAllDocuments("public/");
		if (documents && documents.length > 0) {
			documents = documents.sort((a, b) => compareDates(b.uploadedAt, a.uploadedAt));
		}
		setDocuments(documents);
		setIsLoadingDocuments(false);
	}

	const getAllDocuments = async (folderName) => {
		const filesAndFolders = await storage.getOrFetch(`/modules/crm/provider/documents/accounts/${account.id}/${folderName}`);
		if (filesAndFolders && filesAndFolders.fileDTOs) {
			const token = remote.createTokenHeader().headers["X-3Skye-Session"];
			const tags = folderName.replace("public/", "").split("/");
			// const tag = tags && tags.length > 0 ? tags[0] : null;
			let fileDTOs = filesAndFolders.fileDTOs.map((file) => {
				return {
					...file,
					tags,
					onClick: () => window.open(`/api/direct/${token}/modules/crm/accounts/${account.id}/documents/${file.id}`),
				}
			});
			if (filesAndFolders.folders && filesAndFolders.folders.length > 0) {
				fileDTOs = fileDTOs.concat(await Promise.all(filesAndFolders.folders.map((folder) => getAllDocuments(folderName + `${folder.name}/`)))).flat();
			}
			return fileDTOs;
		}
		return [];
	}


	return (
		<>
			<Mobile>
				<DefaultMobileBanner />
			</Mobile>
			<Desktop>
				<Banner thirdPartyView={thirdPartyView}>
					<BannerInfoText>
						<BannerInfoMainText>{account.name}</BannerInfoMainText>
						{thirdPartyView ?
							<BannerInfoIntroText>You are now viewing your Third Party accounts</BannerInfoIntroText> :
							<BannerInfoHighlightText>{displayPortalAccType(portalAccountType)}</BannerInfoHighlightText>
						}
					</BannerInfoText>
					<BannerNavLinks parent={LINK_ITEMS(notifications, account.number).DOCUMENTS} />
				</Banner>
			</Desktop >
			<PortalContentWrapper>
				<div id="portal-my-documents">
					<CardContainer>
						<Card>
							<CardHeader>
								<h3>Documents</h3>
							</CardHeader>
							<FiltersWrapper>
								<DocumentFilters documents={documents} />
								<hr />
								{isLoadingDocuments ? <Loading centered size={80} /> : <DocumentsTable documents={documents} />}
							</FiltersWrapper>
						</Card>
					</CardContainer>
				</div>
			</PortalContentWrapper>
		</>
	);
}

export default withAccountInfo(withRouteChange(MyDocuments));
