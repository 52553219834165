import React from 'react';
import { toast as toasty } from 'react-toastify';
import FlexWrapper from '../../core-components/layouts/FlexWrapper';
import Button from '../../core-components/inputs/Button';

export const UndoToastMessage = ({onClick, message}) => (
	<FlexWrapper justify="space-between">{message}<Button onClick={() => {onClick(); toasty.dismiss()}} className="toast-undo-button" size="small" variant="secondary">Undo</Button></FlexWrapper>
);

export const toast = (message, params) => toasty(message, {autoClose: 2500, ...params});
export const toastInfo = (message, params) => toasty.info(message, {icon: <i className='material-icons'>info</i>, autoClose: 2500, ...params});
export const toastSuccess = (message, params) => toasty.success(message, {icon: <i className='material-icons'>task_alt</i>, autoClose: 2500, ...params});
export const toastWarning = (message, params) => toasty.warning(message, {icon: <i className='material-icons'>warning</i>, autoClose: 2500, ...params});
export const toastDanger = (message, params) => toasty.error(message, { icon: <i className='material-icons'>error</i>, autoClose: 2500, ...params });