import React, { useState } from 'react';
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import VerticalSpacer from '../../core-components/layouts/VerticalSpacer'
import Button from '../../core-components/inputs/Button';
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import './Onboarding.scss'


const Businesses = (props) => {
    const { onBoardingState, setOnBoardingState, handleNumberChange, section } = props
    const { businesses } = onBoardingState
    const label = section.displayLabel ? section.label : null
    const subLabel = section.displaySubLabel ? section.subLabel : null

    const [lastBusinessName, setLastBusinessName] = useState("")
    const [lastBusinessRevenue, setLastBusinessRevenue] = useState("")
    const [lastBusinessValuation, setLastBusinessValuation] = useState("")
    const [businessIndex, setBusinessIndex] = useState(null)
    const [editedBusinessName, setEditedBusinessName] = useState("")
    const [editedBusinessRevenue, setEditedBusinessRevenue] = useState("")
    const [editedBusinessValuation, setEditedBusinessValuation] = useState("")


    function removeBusiness(index) {
        const updatedBusinesses = businesses.filter((b, idx) => idx !== index)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, businesses: updatedBusinesses } })
    }

    function addBusiness(index) {
        const newObject = { name: lastBusinessName, annualRevenue: lastBusinessRevenue, estimatedValuation: lastBusinessValuation }
        setOnBoardingState({ onBoardingState: { ...onBoardingState, businesses: businesses && businesses.length > 0 ? [...businesses, newObject] : [newObject] } })
        setLastBusinessName("")
        setLastBusinessRevenue("")
        setLastBusinessValuation("")
    }

    function cancelEdit() {
        setEditedBusinessName("")
        setEditedBusinessRevenue("")
        setEditedBusinessValuation("")
        setBusinessIndex(null)
    }

    function saveEdit(index) {
        const updatedBusinesses = businesses.map((b, idx) => {
            if (idx === index) {
                const newObject = { name: editedBusinessName || b.name, annualRevenue: editedBusinessRevenue || b.annualRevenue, estimatedValuation: editedBusinessValuation || b.estimatedValuation }
                return newObject
            } else return b
        })
        setOnBoardingState({ onBoardingState: { ...onBoardingState, businesses: updatedBusinesses } })
        cancelEdit()
    }


    return (
        <>
           {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel &&<div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Business Name</span>
                        </div>
                        <input value={lastBusinessName} placeholder="Business Name" name={"business-name"} onChange={(e) => setLastBusinessName(e.target.value)} />
                    </label>
                </Col>
                <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Annual Revenue</span>
                        </div>
                        <input value={lastBusinessRevenue} placeholder="$0" name={"business-annual-revenue"} onChange={(e) => setLastBusinessRevenue(handleNumberChange(e))} />
                    </label>
                </Col>
                <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div className="label-container">
                            <span className={`form-label`}>Estimated Company Valuation</span>
                        </div>
                        <input value={lastBusinessValuation} placeholder="$0" name={"business-estimated-valuation"} onChange={(e) => setLastBusinessValuation(handleNumberChange(e))} />
                    </label>
                </Col>
                <VerticalSpacer size="20px" />
                <Row className="justify-content-end" style={{ padding: 0 }}>
                    <Col xs="auto" className="justify-self-end" style={{ padding: 0 }}>
                        <Button onClick={() => addBusiness()} disabled={!lastBusinessName} className='capitalized'>
                            + Add Business
                        </Button>
                    </Col>
                </Row>
            </Row>
            {businesses && businesses.length > 0 && <VerticalSpacer size="25px" />}
            {businesses && businesses.length > 0 && <hr style={{ borderTop: "1.5px solid", borderColor: "#b6bcbc", opacity: 0.4, marginBottom: 30 }} />}

            {businesses && businesses.length > 0 &&
                <Row>
                    {businesses && businesses.length > 0 &&
                        <div className="business-sections-label">
                            <p>Business Name</p>
                            <p className="right">Revenue / Valuation</p>
                            <p>&nbsp;</p>
                        </div>
                    }
                    {businesses.map((b, idx) => {
                        return (
                            <Col xs="12" xl="12" className="pl-xl-3" key={idx}>
                                <div className={`businesses-owned-row ${businessIndex === idx ? "editing" : ""}`}>
                                    {businessIndex === idx ?
                                        <input
                                            value={editedBusinessName || b.name}
                                            placeholder="$0" name={"revenue-" + idx}
                                            onChange={(e) => setEditedBusinessName(e.target.value)}
                                        />
                                        : <p>{b.name}</p>
                                    }
                                    {businessIndex === idx ?
                                        <input
                                            value={editedBusinessRevenue || b.annualRevenue}
                                            placeholder="$0" name={"revenue-" + idx}
                                            onChange={(e) => setEditedBusinessRevenue(handleNumberChange(e, "revenue"))}
                                        /> :
                                        <p className='business-values'>{(b.annualRevenue || "$0") + " / " + (b.estimatedValuation || "$0")}</p>
                                    }
                                    {businessIndex === idx ?
                                        <input
                                            value={editedBusinessValuation || b.estimatedValuation}
                                            placeholder="$0" name={"revenue-" + idx}
                                            onChange={(e) => setEditedBusinessValuation(handleNumberChange(e, "valuation"))}
                                            style={{ margin: 0 }}
                                        /> : ""
                                    }
                                    <div className='action-icons'>
                                        {businessIndex === idx ? <i className="material-icons save-icon" onClick={() => saveEdit(idx)}>save</i> : <i className="material-icons edit-icon" onClick={() => setBusinessIndex(idx)}>create</i>}
                                        {businessIndex === idx ? <i className="material-icons cancel-icon" onClick={() => cancelEdit()}>close</i> : <i className="material-icons delete-icon" onClick={() => removeBusiness(idx)}>delete_forever</i>}
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>}
            {businesses && businesses.length > 0 && <VerticalSpacer size="20px" />}
            {businesses && businesses.length > 0 && <hr style={{ borderTop: "1.5px solid", borderColor: "#b6bcbc", marginBottom: 10, opacity: 0.4, marginBottom: 30 }} />}
        </>
    )
}

export default Businesses