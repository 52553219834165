import React, { Component } from "react";

class HobsonLogo extends Component {
	render() {
		const width = this.props.width || 156;
		const height = this.props.height || width * (46 / 156);
		return (
			<svg width={width} height={height} viewBox="0 0 156 46" className="hobson-logo ">
				<defs>
					<clipPath id="clip-Artboard_1">
						<rect width="156" height="46" />
					</clipPath>
				</defs>
				<g id="Artboard_1" name="Artboard – 1" clipPath="url(#clip-Artboard_1)">
					<g id="Group_972" name="Group 972" transform="translate(2 2)">
						<path
							id="Path_1436"
							name="Path 1436"
							d="M29.893,164.548c1.9-.289,1.9-.288,2.446,1.266q1.417,4.034,2.839,8.067a1.9,1.9,0,0,0,.151.25,4.763,4.763,0,0,0,.291-.571c.846-2.484,1.669-4.976,2.546-7.45.12-.34.5-.588.757-.878.26.287.638.53.763.868.966,2.607,1.884,5.232,2.877,8.024.148-.329.245-.506.309-.695.93-2.7,1.863-5.389,2.778-8.089.214-.631.475-1.229,1.273-.978s.67.908.463,1.533c-1.337,4.028-2.664,8.06-4.028,12.079-.106.31-.471.53-.716.792-.258-.248-.64-.45-.751-.751-.877-2.37-1.7-4.759-2.542-7.142-.1-.292-.215-.581-.415-1.119-.517,1.492-.957,2.757-1.392,4.023-.495,1.44-.963,2.89-1.5,4.315a1.137,1.137,0,0,1-.705.637c-.183.037-.576-.3-.662-.542C33.074,173.677,31.5,169.154,29.893,164.548Z"
							transform="translate(-29.893 -136.921)"
							fill="#19424c"
						/>
						<path
							id="Path_1437"
							name="Path 1437"
							d="M79.157,141.4a15.916,15.916,0,0,0,1-3.129,5.133,5.133,0,0,0-4.625-5.609,5.232,5.232,0,0,0-5.768,4.694A5.154,5.154,0,0,0,74.6,143.07c2.138.113,4.285.041,6.427.053.292,0,.66-.092.858.049.326.231.755.625.743.936a1.184,1.184,0,0,1-.817.835,65.084,65.084,0,0,1-7.883-.054,7.017,7.017,0,0,1-5.97-8.341,7.125,7.125,0,1,1,13.852,3.278C81.236,141.694,81.509,141.591,79.157,141.4Z"
							transform="translate(-37.012 -130.623)"
							fill="#19424c"
						/>
						<path
							id="Path_1438"
							name="Path 1438"
							d="M174.319,145.142a7.274,7.274,0,1,1,7.3-7.186A7.243,7.243,0,0,1,174.319,145.142Zm-.007-1.907a5.353,5.353,0,1,0-5.332-5.359A5.391,5.391,0,0,0,174.312,143.235Z"
							transform="translate(-55.638 -130.595)"
							fill="#19424c"
						/>
						<path
							id="Path_1439"
							name="Path 1439"
							d="M211.641,141.03c0-3.062,0-5.846,0-8.63,0-.324-.088-.725.067-.953a1.561,1.561,0,0,1,.956-.712c.261-.016.608.439.811.753.12.186.04.505.04.764,0,3.9.009,7.794-.011,11.689a5.283,5.283,0,0,1-.273,1.163,4.535,4.535,0,0,1-.988-.633q-4.077-4.454-8.113-8.946a1.112,1.112,0,0,0-.6-.434v9.82h-1.893c-.019-.258-.056-.539-.056-.819,0-4.123-.011-8.247.013-12.371a3.424,3.424,0,0,1,.337-1.006,3.762,3.762,0,0,1,.849.537q4.031,4.43,8.028,8.891C211.02,140.381,211.244,140.608,211.641,141.03Z"
							transform="translate(-62.113 -130.618)"
							fill="#19424c"
						/>
						<path
							id="Path_1440"
							name="Path 1440"
							d="M107.164,130.774a27.111,27.111,0,0,1,4.755.287,3.563,3.563,0,0,1,1.847,5.622A16.285,16.285,0,0,1,115.39,140a4.174,4.174,0,0,1-4.01,4.985c-1.2.037-2.4-.007-3.6.014-.512.008-.773-.128-.77-.708.018-4.345.014-8.69.021-13.035A2.872,2.872,0,0,1,107.164,130.774Zm1.638,12.393a20.872,20.872,0,0,0,2.73-.023,2.495,2.495,0,0,0,.068-4.916,21.808,21.808,0,0,0-2.8-.024Zm-.009-6.842c1.351-.132,2.76.435,3.624-1a1.6,1.6,0,0,0-.193-1.983c-.976-1.153-2.249-.527-3.431-.69Z"
							transform="translate(-44.365 -130.628)"
							fill="#19424c"
						/>
						<path
							id="Path_1441"
							data-name="Path 1441"
							d="M212.989,178.56h-1.883v-5.99h-6.8v5.973h-1.871c-.022-.256-.061-.506-.061-.756q-.006-6.039,0-12.077c0-.162-.065-.382.015-.477.284-.333.578-.81.942-.886.6-.127.933.326.933.951q0,2.045,0,4.09v1.123h6.82c0-1.5,0-2.947,0-4.4,0-.324-.108-.729.044-.954a1.719,1.719,0,0,1,1-.792c.261-.024.824.518.828.813C213,169.618,212.989,174.059,212.989,178.56Z"
							transform="translate(-62.256 -136.925)"
							fill="#19424c"
						/>
						<path
							id="Path_1442"
							data-name="Path 1442"
							d="M36.154,136.92h6.811c0-1.143,0-2.263,0-3.383a8.749,8.749,0,0,1,.047-2.03c.087-.353.624-.594.959-.885.286.331.818.661.822,1,.049,4.348.023,8.7.013,13.044a.946.946,0,0,1-.091.239h-1.7v-5.971H36.184v5.987H34.328c-.023-.283-.065-.561-.066-.84,0-4.024,0-8.049-.01-12.072,0-.722.109-1.39.975-1.361s.94.719.933,1.424C36.142,133.653,36.154,135.237,36.154,136.92Z"
							transform="translate(-30.711 -130.6)"
							fill="#19424c"
						/>
						<path
							id="Path_1443"
							data-name="Path 1443"
							d="M103.334,178.526c.127-.308.238-.6.368-.892,1.86-4.139,3.713-8.281,5.6-12.407.166-.361.621-.885.863-.846a1.607,1.607,0,0,1,.955.868c1.842,3.969,3.637,7.961,5.452,11.942.277.608.474,1.226-.279,1.584-.8.381-1.169-.174-1.453-.833-.411-.952-.817-1.91-1.308-2.821a1.21,1.21,0,0,0-.86-.557c-1.622-.054-3.249-.059-4.87,0a1.357,1.357,0,0,0-.921.606,17.87,17.87,0,0,0-.946,2.024C105.218,178.818,105.219,178.819,103.334,178.526Zm4.561-5.663h4.642L110.2,167.7Z"
							transform="translate(-43.676 -136.935)"
							fill="#19424c"
						/>
						<path
							id="Path_1444"
							data-name="Path 1444"
							d="M137.485,141.667c1.175-.655,1.948-.836,2.548.349a2.7,2.7,0,0,0,1.263,1.006,2.6,2.6,0,0,0,3.473-1.006,2.462,2.462,0,0,0-1.28-3.188c-.613-.358-1.295-.6-1.928-.923a10.606,10.606,0,0,1-1.764-1.014,3.307,3.307,0,0,1-1.413-3.311,3.359,3.359,0,0,1,2.5-2.718,5.094,5.094,0,0,1,5.283,1.34c.467.449.732.974.175,1.514-.513.5-1.015.27-1.439-.18a3.18,3.18,0,0,0-3.084-1.033,1.8,1.8,0,0,0-1.612,1.354,1.563,1.563,0,0,0,.979,1.734c1.049.579,2.165,1.04,3.2,1.637a4.269,4.269,0,0,1,2.331,4.705,4.459,4.459,0,0,1-7.335,2.094A16.286,16.286,0,0,1,137.485,141.667Z"
							transform="translate(-50.085 -130.598)"
							fill="#19424c"
						/>
						<path
							id="Path_1445"
							data-name="Path 1445"
							d="M173.711,166.479h-4v-1.838a3.86,3.86,0,0,1,.62-.1c2.824-.007,5.648,0,8.472-.01.663,0,1.205.163,1.214.924.008.8-.554,1.018-1.254,1.015-1,0-2,0-3.107,0v12.082h-1.949Z"
							transform="translate(-56.133 -136.964)"
							fill="#19424c"
						/>
						<path
							id="Path_1446"
							data-name="Path 1446"
							d="M140.044,164.547h1.829v12.187h4.75c.325,0,.65-.012.974,0,.617.032,1.089.266,1.091.972s-.465.946-1.093.947c-1.752.006-3.505,0-5.258,0h-2.292Z"
							transform="translate(-50.565 -136.967)"
							fill="#19424c"
						/>
						<path
							id="Path_1447"
							data-name="Path 1447"
							d="M68.931,173.965v-1.846c.283-.022.562-.062.841-.062,3.634,0,7.266,0,10.9-.007.7,0,1.392.054,1.4.937s-.66.982-1.37.981C76.815,173.961,72.927,173.965,68.931,173.965Z"
							transform="translate(-37.219 -138.375)"
							fill="#19424c"
						/>
						<path
							id="Path_1448"
							data-name="Path 1448"
							d="M68.928,181.415v-1.776a4.224,4.224,0,0,1,.586-.084q5.6-.007,11.2-.006c.707,0,1.374.125,1.374,1,0,.825-.648.936-1.314.936q-5.6,0-11.2,0C69.384,181.476,69.194,181.441,68.928,181.415Z"
							transform="translate(-37.219 -139.782)"
							fill="#19424c"
						/>
						<path
							id="Path_1449"
							data-name="Path 1449"
							d="M68.948,166.418v-1.8a7.58,7.58,0,0,1,.762-.076q5.5-.007,11.008,0c.195,0,.458-.061.57.039.3.271.719.6.751.935.025.266-.4.7-.719.84a3.346,3.346,0,0,1-1.25.067q-4.968,0-9.936,0Z"
							transform="translate(-37.222 -136.961)"
							fill="#19424c"
						/>
						<path id="Path_1450" data-name="Path 1450" d="M67.906,147.795v-1.611l1.51,1.611Z" transform="translate(-37.027 -133.52)" fill="#19424c" />
					</g>
				</g>
			</svg>
		);
	}
}

export default HobsonLogo;
