import React, { Component } from "react";
import "./MobileBanner.scss";
import Formatter from "../../core-components/functions/Formatter";
import HobsonLogo from "../../core-components/svgs/HobsonLogo";
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import Logo from "../../core-components/svgs/Logo";
import { Link } from "react-router-dom";
import { withTheme } from "../../core-components/contexts/ThemeContext";
import { withAccountInfo } from "../../core-components/contexts/AccountsContext";
import withRouteChange from "@threeskye/route-change";
import NotificationBell from "../../core-components/misc/NotificationBell";
import MobileAccountSelectBanner from "../../core-components/misc/MobileAccountSelectBanner";
import { withMobileMenu } from "../../core-components/contexts/MobileMenuContext";
import { isNullOrUndefinedOrNaN } from "@threeskye/core-components/dist/components/Functions/isNullOrUndefined";
import Loading from "../../core-components/layouts/Loading";
import { FlexDiv } from "../../core-components/layouts/FlexDiv";
import { Launch } from "@material-ui/icons";
import AlertBanner from "../../core-components/misc/AlertBanner";

export const MobileBanner = withMobileMenu(
	withTheme(
		withRouteChange(
			withAccountInfo(
				class extends Component {
					render() {
						const { accounts, menuIsOpen, theme, user, openMenu, closeMenu, children, accPortfolioMap, accPortfolioIsLoading, thirdPartyView } = this.props;
						const { accountNumber } = this.props.match.params;
						const isLoading = Object.values(accPortfolioIsLoading).find((isLoading) => !!isLoading);
						const bannerActive = false
						const balance = isLoading
							? null
							: accounts.reduce((totalValue, account) => {
									if (totalValue === null || !accPortfolioMap[account.id]) {
										return null;
									}
									return totalValue + accPortfolioMap[account.id].valuation;
							  }, 0);
						return (
							<div className="portal-mobile-banner-container">
								{accountNumber !== "third-party" && bannerActive &&
									<AlertBanner
										message="System Upgrade - Portfolio Valuations & Trades may not be updated until Mon 3 Apr due to a System Upgrade. Apologies for any inconvenience."
									/>
								}
								<div id="portal-mobile-banner" className={`portal-mobile-banner--${menuIsOpen ? "open" : "closed"}`}>
									<div className="portal-mobile-banner-logo">
										<Link to="/dashboard">{theme === "theme-hobson" ? <HobsonLogo width={100} /> : <Logo width={90} />}</Link>
									</div>
									{!thirdPartyView && (
										<>
											<div className="landscape-banner-valuation">
												<h6 className="sub-header-small">TOTAL PORTFOLIO VALUATION</h6>
												{isLoading ? (
													<Loading size={30} />
												) : !isNullOrUndefinedOrNaN(balance) ? (
													<h3 className="info-banner-valuation">{Formatter.asMoney(balance)}</h3>
												) : (
													<h4>
														Could not get valuation
														<br />
														Please try again later
													</h4>
												)}
											</div>
										</>
									)}
									<div className="mobile-banner-icons notification-bell">
										{!thirdPartyView && <NotificationBell />}
										{menuIsOpen ? <Close onClick={closeMenu} /> : <Menu onClick={openMenu} />}
									</div>
								</div>
								<div className={`${!thirdPartyView || menuIsOpen ? "portal-mobile-info-banner" : ""}${menuIsOpen ? " menu-open" : ""}`}>
									{menuIsOpen ? (
										<FlexDiv className="mobile-name-banner">
											<h2 className="menu-open-banner">Hi {user.preferredName || user.givenName}</h2>
											{thirdPartyView && (
												<a href="https://www.hobsonwealth.co.nz/wp-content/uploads/2023/05/Hobson-Final-Tax-guide-Mar23.pdf" target="_blank" id="tax-guide-link-outer">
													<FlexDiv className="tax-guide-link">
														<h5>Tax Guide</h5>
														<Launch className="banner-link-icon" />
													</FlexDiv>
												</a>
											)}
										</FlexDiv>
									) : (
										children
									)}
								</div>
								{!thirdPartyView && !menuIsOpen && <MobileAccountSelectBanner />}
							</div>
						);
					}
				}
			)
		)
	)
);

class DefaultMobileBanner extends Component {
	render() {
		const { accounts, accPortfolioMap, accPortfolioIsLoading, thirdPartyView } = this.props;
		const isLoading = Object.values(accPortfolioIsLoading).find((isLoading) => !!isLoading);
		const balance = isLoading
			? null
			: accounts.reduce((totalValue, account) => {
					if (totalValue === null || !accPortfolioMap[account.id]) {
						return null;
					}
					return totalValue + accPortfolioMap[account.id].valuation;
			}, 0);

		return (
			<MobileBanner thirdPartyView={thirdPartyView}>
				{!thirdPartyView && <h6 className="sub-header-small">TOTAL PORTFOLIO VALUATION</h6>}
				{!thirdPartyView && ( isLoading ? (
					<Loading size={30} />
				) : !isNullOrUndefinedOrNaN(balance) ? (
					<h3 className="info-banner-valuation">{Formatter.asMoney(balance)}</h3>
				) : (
					<h4>
						Could not get valuation
						<br />
						Please try again later
					</h4>					
				))}
			</MobileBanner>
		);
	}
}

export default withAccountInfo(DefaultMobileBanner);
