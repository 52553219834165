import SaveAlt from '@material-ui/icons/SaveAlt';
import React from 'react';
import "./NotificationDoc.scss";

function NotificationDoc(props) {
	const { document } = props;
	const { filename, onClick } = document;

	return (
		<div className="notification-doc" onClick={(e) => {
			e.stopPropagation()
			onClick()
		}} >
			<div className="nd-filename">{filename}</div>
			<SaveAlt />
		</div>

	)
}

export default NotificationDoc;
