import React from 'react';
import Tooltip from '../popups/Tooltip';

const TooltipIcon = ({ icon, label, onClick, disabled }) => {
    const iconWithClickHandler = React.cloneElement(icon, { onClick });

    return (
		<Tooltip delay={500} disabled={disabled} label={label}>
            {iconWithClickHandler}
        </Tooltip>
    );
};

export default TooltipIcon;
