import styled from 'styled-components';
import CommonFunctions from '../functions/CommonFunctions';

export default styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: ${CommonFunctions.isMobile() ? "15px" : "30px"};
	${props => props.className};
`;
