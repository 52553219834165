import React, { Component } from "react";
import moment from "moment";
import ThreeSkyeBarChart from "@threeskye/core-components/dist/components/Charts/ThreeSkyeBarChart";
import Formatter from "../../../../../core-components/functions/Formatter";
import { getRefAreasForCategoryBarChart, Y_AXIS_PROPS as yAxisProps } from "../../../../../core-components/functions/ChartFunctions";
import ErrorMessage from "../../../../../core-components/layouts/ErrorMessage";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";

export const emptyDataByMonth = Array(12)
	.fill("")
	.reduce((obj, _, i) => ({ ...obj, [moment().set("month", i).format("MMM")]: 0 }), {}); 

class MonthBarChart extends Component {
	xAxisProps = { tickLine: false, onClick: (e) => this.filterByMonth(e.value) };
	refAreaProps = { fill: "#30395a" };
	barProps = { onClick: (e) => this.filterByMonth(e.payload.x) };

	constructor(props) {
		super(props);
		this.renderTooltip = this.renderTooltip.bind(this);
		this.filterByMonth = this.filterByMonth.bind(this);
		this.getCellProps = this.getCellProps.bind(this);
	}

	renderTooltip(payload) {
		if (!payload.active || payload.payload.length === 0) {
			return null;
		}
		let value = Formatter.asMoney(payload.payload[0].payload.y, 2);
		let date = payload.payload[0].payload.x;
		return (
			<div className="graph-tooltip">
				<div className="graph-tooltip-label">{date}</div>
				<div className="graph-tooltip-value">{value}</div>
			</div>
		);
	}

	filterByMonth(shortMonth) {
		const { filters, setFilters } = this.props;
		if (filters) {
			if (filters.filterAssetsBy !== shortMonth) {
				setFilters({ ...filters, filterAssetsBy: shortMonth });
			} else {
				setFilters({ ...filters, filterAssetsBy: null });
			}
		}
	}

	getCellProps(singleData) {
		const { filters } = this.props;
		if (filters) {
			const { filterAssetsBy } = filters;
			if (filterAssetsBy) {
				if (filterAssetsBy && filterAssetsBy === singleData.x) {
					return { className: "active-cell" };
				} else {
					return { className: "inactive-cell" };
				}
			}
		}
	}

	render() {
		const { data } = this.props;
		if (!data || data.length <= 0) {
			return <ErrorMessage infoIcon>Could not create a chart for this account...</ErrorMessage>;
		}
		const { xAxisProps, renderTooltip, refAreaProps, barProps, getCellProps } = this;
		const refAreas = getRefAreasForCategoryBarChart(data);
		return <ThreeSkyeBarChart {...{ data, yAxisProps, xAxisProps, renderTooltip, refAreas, refAreaProps, barProps, getCellProps }} />;
	}
}

export default withFilters(MonthBarChart);
