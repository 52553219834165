import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import withReactKey from "@threeskye/core-components/dist/components/withReactKey";
import { DATE_FORMAT } from "@threeskye/core-components/dist/components/DateField/DateField";
import MoreFiltersButton from "../../../../core-components/inputs/MoreFiltersButton";
import Mobile from "../../../../core-components/layouts/Mobile";
import DateRangeInput from "../../../../core-components/inputs/DateRangeInput";
import FilterInput from "../../../../core-components/inputs/FilterInput";
import RadioInputGroup from "../../../../core-components/inputs/RadioInputGroup";
import SearchInput from "../../../../core-components/inputs/SearchInput";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import Button from "../../../../core-components/inputs/Button";
import { Search } from "@material-ui/icons";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";

export const transactionTypes = { buy: "Buy", sell: "Sell" };
const emptyState = {
	fromDate: moment().subtract(6, "months").format(DATE_FORMAT),
	toDate: moment().format(DATE_FORMAT),
	selectedCurrency: null,
	selectedAsset: null,
	units: "",
	price: "",
	totalCost: "",
	type: "",
	search: "",
	showMoreFilters: false,
};

class TransactionFilters extends Component {
	state = {
		...emptyState,
	};

	constructor(props) {
		super(props);
		this.onFiltersUpdate = this.onFiltersUpdate.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	componentDidMount() {
		this.onFiltersUpdate();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.account.id !== this.props.account.id) {
			this.onFiltersUpdate();
		}
	}

	onFiltersUpdate() {
		const { fromDate, toDate, selectedCurrency, units, price, totalCost, type, search, selectedAsset } = this.state;
		this.props.onFiltersUpdate({ fromDate, toDate, selectedCurrency, units, price, totalCost, type, search, selectedAsset });
	}

	clearFilters() {
		this.setState({ ...emptyState }, () => {
			this.onFiltersUpdate();
			this.props.changeKey();
		});
	}

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.onFiltersUpdate();
		}
	}

	render() {
		const { currencies, assets, reactKey } = this.props;
		const { showMoreFilters, fromDate, toDate, units, price, totalCost, type, search, selectedAsset, selectedCurrency } = this.state;
		const isMobile = CommonFunctions.isMobile();
		return (
			<div tabIndex="-1" onKeyPress={this.handleKeyPress}>
				<Row xs="12">
					<Col className="fade-up" xs="12" xl="4">
						<FilterInput icon={<Search />} placeholder="Search" label="Search" name="search" value={search} onChange={(search) => this.setState({ search })} onBlur={this.onFiltersUpdate} />
					</Col>
					{(!isMobile || showMoreFilters) && (
						<>
							<Col className="fade-up" xs="12" xl="4">
								<SearchInput
									options={currencies}
									label="Currencies"
									placeholder="Search Currencies"
									value={selectedCurrency ? { value: selectedCurrency, label: selectedCurrency } : null}
									onChange={(selection) => this.setState({ selectedCurrency: selection ? selection.value : null }, this.onFiltersUpdate)}
								/>
							</Col>
							<Col className="fade-up" xs="12" xl="4">
								<SearchInput
									options={assets}
									label="Assets"
									placeholder="Search Assets"
									value={selectedAsset ? assets.find(({ value }) => value.ticker === selectedAsset.ticker && value.asset === selectedAsset.asset) : null}
									onChange={(selection) => this.setState({ selectedAsset: selection ? selection.value : null }, this.onFiltersUpdate)}
								/>
							</Col>
						</>
					)}
				</Row>
				<Row>
					{(!isMobile || showMoreFilters) && (
						<>
							<Col className="fade-up" xs="12" xl="5">
								<DateRangeInput
									key={reactKey}
									from={fromDate}
									onFromChange={(fromDate) => this.setState({ fromDate })}
									to={toDate}
									onToChange={(toDate) => this.setState({ toDate })}
									onBlur={this.onFiltersUpdate}
								/>
							</Col>
							<Col className="fade-up mt-3 mt-xl-0" xs="12" xl="7">
								<Row>
									<Col xs="12" xl="3">
										<FilterInput placeholder="Quantity" label="Quantity" name="units" value={units} onChange={(units) => this.setState({ units })} onBlur={this.onFiltersUpdate} />
									</Col>
									<Col xs="12" xl="3">
										<FilterInput placeholder="Unit Cost" label="Unit Cost" name="price" value={price} onChange={(price) => this.setState({ price })} onBlur={this.onFiltersUpdate} />
									</Col>
									<Col xs="12" xl="3">
										<FilterInput
											placeholder="Total Cost"
											label="Total Cost"
											name="totalCost"
											value={totalCost}
											onChange={(totalCost) => this.setState({ totalCost })}
											onBlur={this.onFiltersUpdate}
										/>
									</Col>
									<Col className="fade-up" xs="3">
										<RadioInputGroup
											groupLabel="Type"
											options={[transactionTypes.buy, transactionTypes.sell]}
											value={type}
											onChange={(type) => this.setState({ type }, this.onFiltersUpdate)}
											toggleable
											clear={() => this.setState({ type: null }, this.onFiltersUpdate)}
										/>
									</Col>
								</Row>
							</Col>
							<Col xs="12">
								<Button onClick={this.clearFilters}>Clear Filters</Button>
							</Col>
						</>
					)}
					<Mobile>
						<Col xs="12" className="mt-3">
							<MoreFiltersButton onClick={() => this.setState({ showMoreFilters: !showMoreFilters })} filtersShowing={showMoreFilters} />
						</Col>
					</Mobile>
				</Row>
			</div>
		);
	}
}

export default withReactKey(withAccountInfo(TransactionFilters));
