import React, { Component } from "react";
import StandingOrderValueGroup from "./StandingOrderValueGroup";
import "./StandingOrderRow.scss";
import { ExpandMore } from "@material-ui/icons";
import Formatter from "../../functions/Formatter";
import CommonFunctions from "../../functions/CommonFunctions";

class StandingOrderRow extends Component {
	state = { expanded: false };

	render() {
		const { nextRun, type, startDate, endDate, amount } = this.props;
		const { expanded } = this.state;
		const { fromIsoDateToDisplay } = Formatter;
		const { isNullOrUndefined, asClassName } = CommonFunctions;
		return (
			<div onClick={() => this.setState({ expanded: !expanded })} className={asClassName(["order-row", expanded && "expanded-order-row"])}>
				<StandingOrderValueGroup className="next-date" label="Next Run" value={nextRun ? fromIsoDateToDisplay(nextRun) : null} />
				<StandingOrderValueGroup className="type" label="Type" value={type} />
				<StandingOrderValueGroup className="start-date" label="Start Date" value={startDate ? fromIsoDateToDisplay(startDate) : null} />
				<StandingOrderValueGroup className="end-date" label="End Date" value={endDate ? fromIsoDateToDisplay(endDate) : null} />
				<StandingOrderValueGroup className="amount" label="Amount" value={!isNullOrUndefined(amount) ? Formatter.asMoney(amount, 2) : null} />
				<ExpandMore className={asClassName(["expand-icon", expanded && "expanded"])} />
			</div>
		);
	}
}

export default StandingOrderRow;
