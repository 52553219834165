import React, { useEffect, useState } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import CardNoPadding from "../../../../core-components/card/CardNoPadding";
import CardList from "../../../../core-components/card/CardList";
import AdviserListItem from "./AdviserListItem";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import ErrorMessage from "../../../../core-components/layouts/ErrorMessage";
import {withStorage} from "@threeskye/global"
import Loading from "../../../../core-components/layouts/Loading";
import "./DashboardAdvisers.scss"

const DashboardAdvisers = ({ account, storage }) => {
	const [fullAdvisers, setFullAdvisers] = useState([])
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		let getFullAdvisers = account.advisers.map((adviser) => {return storage.getOrFetch(`/modules/crm/client/adviser/${adviser.id}`)})
		Promise.all(getFullAdvisers).then((FullAdviserList) => {setFullAdvisers(FullAdviserList)}).then(() => setLoading(false))
	}, [])


	const visibleAdvisers = fullAdvisers.filter((adviser) => {
		const adviserPhone = adviser && adviser.twoFANumber && adviser.twoFANumber.length > 0 && adviser.twoFANumber[0].content;
		const adviserName = adviser && `${adviser.preferredName || adviser.givenName || ""} ${adviser.middleNames || ""} ${adviser.familyName || ""}`;
		const adviserJobTitle = adviser && adviser.jobTitle ? adviser.jobTitle : "";

		if (adviser && ((adviserName.trim() !== "")
			|| adviser.ddi
			|| adviser.userId
			|| adviserJobTitle
			|| adviserPhone)) {
			return true
		}
		return false
	})

	return (
		<CardContainer xs={12} xl={5}>
			<CardNoPadding header="Advisers">
				{loading ? 
					<div className="loading-spinner-container" ><Loading /></div>
				: account && account.advisers && account.advisers.length > 0 ? (
					<CardList
						list={visibleAdvisers.map((adviser) => (
							<AdviserListItem key={adviser.id} adviser={adviser} />
						))}
					/>
				) : (
					<ErrorMessage infoIcon>No advisers to display...</ErrorMessage>
				)
				
				}
			</CardNoPadding>
		</CardContainer>
	);
}


export default withStorage(withAccountInfo(DashboardAdvisers));
