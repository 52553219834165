import React, { Component } from "react";
import moment from "moment";
import DateFieldInput from "./DateFieldInput";
import CommonFunctions from "../functions/CommonFunctions";

const INVALID_MSGS = {
	NOT_HISTORIC: "Date cannot be in the future",
	DEFAULT: "Date is invalid",
};

class HistoricDateFieldInput extends Component {
	constructor(props) {
		super(props);
		this.isHistoric = this.isHistoric.bind(this);
		this.getValidity = this.getValidity.bind(this);
		this.onDateFieldChange = this.onDateFieldChange.bind(this);
		this.updateParent = this.updateParent.bind(this);

		//Keeping date in state so we can send it back on blur
		this.state = { validity: this.getValidity(props.date), date: props.date };
	}

	isHistoric(date) {
		return !moment(date).isAfter(moment());
	}

	getValidity(date) {
		const { isNullOrUndefined } = CommonFunctions;
		if (isNullOrUndefined(date)) {
			return { isValid: false, invalidMessage: INVALID_MSGS.NOT_HISTORIC };
		} else if (!this.isHistoric(date)) {
			return { isValid: false, invalidMessage: INVALID_MSGS.DEFAULT };
		} else {
			return { isValid: true };
		}
	}

	onDateFieldChange(date) {
		const validity = this.getValidity(date);
		this.setState({ validity, date });
	}

	updateParent() {
		const { date, validity } = this.state;
		this.props.onChange({ data: date, ...validity });
	}

	render() {
		const { isValid } = this.state.validity;
		const { label, date } = this.props;
		const { asClassName } = CommonFunctions;
		return (
			<DateFieldInput
				{...{ label, date }}
				className={asClassName(["historic-date-field", !isValid && "invalid"])}
				onChange={this.onDateFieldChange}
				onBlur={this.updateParent}
				onEnter={this.updateParent}
			/>
		);
	}
}

export default HistoricDateFieldInput;
