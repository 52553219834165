import React, { useState, useEffect } from 'react';
import { expiringMemo } from '../functions/ExpiringMemo';
import './CompanyImage.scss'


const getDataUrl = (exchange, tickerCode) => {
	let countryCode = exchange

	if (exchange === 'NZX' || exchange === 'NZD') {
		countryCode = 'NZ';
	} else if (exchange === 'ASX' || exchange === 'AUD') {
		countryCode = 'AU'
	} else if (exchange === 'NYSE' || exchange === 'USD') {
		countryCode = 'US'
	} else if (exchange === 'GBP') {
		countryCode = 'GB'
	}

	return fetch(`https://dja724jxx1.execute-api.ap-southeast-2.amazonaws.com/GenerateAssetSignedUrl?folder=${countryCode}&filename=${tickerCode}.png`).then(response => response.json()).catch(e => {});
}

const cachedUrl = expiringMemo(getDataUrl);


const CompanyImage = ({exchange, tickerCode, alt, size}) => {
    const [dataUrl, setDataUrl] = useState(null);

    useEffect(() => {
		

        if (!exchange) {
            // console.log(`No logo found for ${tickerCode} due to no exchange`)
            return;
        }

		cachedUrl(exchange, tickerCode).then(json => {
			
			if (json.url) {
				setDataUrl(json.url)
			} else {
			}
		})
    }, [exchange, tickerCode]);

	return (
		<div className={`asset-logo-wrapper${!dataUrl ? " no-logo" : ""}`} style={{width: size, height: size, minWidth: size, minHeight: size}}>
			{dataUrl ? <img src={dataUrl} alt={alt ? alt : "Asset logo"} /> : alt ? alt.charAt(0) : ""}
		</div>
   );
}

export default CompanyImage;

