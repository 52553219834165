import React, { Component } from "react";
import PropTypes from "prop-types";
import CommonFunctions from "../functions/CommonFunctions";
import "./TextArea.scss";
import "./Input.scss";

const TextArea = (props) => {
	const { label, value, placeholder, onChange, disabled, autoFocus, className } = props;
	return (
		<label className={`portal-text-area portal-label ${className ? ' ' + className : ''} ${CommonFunctions.responsiveClassname()}`}>
			<div className="label-container">
				<span className={`form-label ${CommonFunctions.responsiveClassname()}`}>{label}</span>
			</div>
			<textarea
				autoFocus={autoFocus}
				className={CommonFunctions.responsiveClassname()}
				value={value}
				placeholder={placeholder}
				onChange={userInput => onChange(userInput.target.value)}
				rows={props.rows}
				disabled={disabled}
			/>
		</label>
	);
}

TextArea.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	rows: PropTypes.number,
};

export default TextArea;
