import React, { Component } from 'react';
import CommonFunctions from '../functions/CommonFunctions';

class Desktop extends Component {
    render() { 
        if (CommonFunctions.isMobile()) return null;
        return <>
            {this.props.children}
        </>;
    }
}
 
export default Desktop;