import React, { useEffect, useState } from "react";
import Card from '../../core-components/card/Card';
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import { withThreeSkyeGlobal } from "@threeskye/global";
import Input from '../../core-components/inputs/Input';
import RadioInputGroup from '../../core-components/inputs/RadioInputGroup'
import PhoneNumberInput from '../../core-components/inputs/PhoneNumberInput'
import Button from '../../core-components/inputs/Button';
import VerticalSpacer from '../../core-components/layouts/VerticalSpacer'
import withRouteChange from "@threeskye/route-change";
import SingleDatePicker from "../../core-components/inputs/SingleDatePicker";
import { format, isValid, parseISO, isAfter } from 'date-fns'
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import FormErrorMessage from '../../core-components/layouts/FormErrorMessage'
import AddyLocationField from '../../core-components/inputs/AddyLocationField'
import DropDown from '../../core-components/inputs/DropDown'
import CountryTokenField from '../../core-components/inputs/CountryTokenField';
import IrdValidator from "ird-nz";
import './Onboarding.scss'

export const rwtOptions = [10.5, 17.5, 30, 33, 39]
export const pirOptions = [10.5, 17.5, 28]
export const prescribedPerson = { yes: "Yes", no: "No" };

export function irdValidation(num) {
    if (num === "") return true
    else return IrdValidator.isValid(num)
}

export function displayIRDNumber(num) {
    return num || num === 0
        ? (+num.toFixed ? +num.toFixed(0) : num)
            .toString()
            .replace(/[^0-9]/g, "")
            .substring(0, 9)
            .replace(/\B(?=(\d{3})+(?!\d))/g, "-")
        : "";
}

const GeneralDetails = (props) => {
    const { onBoardingState, setOnBoardingState, changeRoute, user, parsedData } = props
    const { firstName, middleNames, lastName, dateOfBirth, email, mobile, physicalAddress, postalAddress, isPrescribedPerson, irdNumber, rwtRate, pirRate, countryOfBirth,
        countriesOfCitizenship, countriesOfResidence, contriesOfTaxResidence, wealthSource, fundsSource } = onBoardingState

    const [isDisabled, setIsDisabled] = useState(true)
    const [invalidDate, setInvalidDate] = useState(false)
    const [dateErrorMessage, setDateErrorMessage] = useState("")
    const [isValidIRD, setIsValidIRD] = useState(true)
    const [onIRDEdit, setOnIRDEdit] = useState(false)
    const [openRWT, setOpenRWT] = useState(false)
    const [openPIR, setOpenPIR] = useState(false)


    useEffect(() => {
        const disabled = invalidDate || isPrescribedPerson === null || (!countriesOfCitizenship || (countriesOfCitizenship && countriesOfCitizenship.length === 0)) || (!countriesOfResidence || (countriesOfResidence && countriesOfResidence.length === 0)) || (!contriesOfTaxResidence || (contriesOfTaxResidence && contriesOfTaxResidence.length === 0)) || !isValidIRD
        setIsDisabled(disabled)
    }, [invalidDate, isPrescribedPerson, countriesOfCitizenship, countriesOfResidence, contriesOfTaxResidence, isValidIRD])

    useEffect(() => {
        const validNumber = irdValidation(irdNumber)
        setIsValidIRD(validNumber)
    }, [irdNumber])

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown)
        if (!onBoardingState.firstName) {
            setOnBoardingState({
                onBoardingState: {
                    ...onBoardingState,
                    firstName: parsedData.givenName,
                    lastName: parsedData.familyName,
                    email: parsedData.email,
                    mobile: { idd: parsedData.phone.idd, number: parsedData.phone.number, valid: true }
                }
            })
        }
        return () => document.removeEventListener("keydown", handleKeyDown)
    }, [])


    function onNzLocationChange(e, string) {
        if (e == null) return;
        const { text } = e;
        if (string === "physical") setOnBoardingState({ onBoardingState: { ...onBoardingState, physicalAddress: text } })
        else if (string === "portal") setOnBoardingState({ onBoardingState: { ...onBoardingState, postalAddress: text } })
    }

    function handleKeyDown(e) {
        if (e.key === "Tab" && !e.shiftKey) {
            if (e.target.getAttribute('name') && e.target.getAttribute('name') === "ird-number") {
                setOpenRWT(true)
                setOpenPIR(false)
            } else if (e.target.getAttribute('id') && e.target.getAttribute('id') === "rwt-rate") {
                setOpenRWT(false)
                setOpenPIR(true)
            } else {
                setOpenRWT(false)
                setOpenPIR(false)
            }
        } else if (e.key === "Tab" && e.shiftKey) {
            if (e.target.getAttribute('id') && e.target.getAttribute('id') === "pir") {
                setOpenRWT(true)
                setOpenPIR(false)
            } else if (e.target.parentElement.className.includes('token-field__input-container')) {
                const originalAncestor = e.target.parentElement.parentElement.parentElement.parentElement
                if (originalAncestor.className.includes('country-field single-value')) {
                    setOpenPIR(true)
                    setOpenRWT(false)
                }
            } else {
                setOpenPIR(false)
                setOpenRWT(false)
            }
        }
    }

    return (
        <Card className="height-fit-content no-box-shadow">
            <CardHeader className="header-with-subheader">
                <h3 className="onbording-header">Welcome {user && user.givenName && user.givenName}</h3>
                <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>Let's finish setting up your profile</div>
            </CardHeader>
            <div style={{ maxWidth: 1100 }}>
                <Row>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <Input label="First Name*" name="first-name" value={firstName || onBoardingState.givenName || parsedData.givenName} placeholder="First Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, firstName: e } })} />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <Input label="Middle Name" name="middle-name" value={middleNames} placeholder="Middle Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, middleNames: e } })} />
                    </Col>
                    <Col xs="12" xl="6" className="pl-xl-3">
                        <Input label="Last Name*" name="last-name" value={lastName || onBoardingState.familyName || parsedData.familyName} placeholder="Last Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, lastName: e } })} />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>Date of Birth*</span>
                            </div>
                            <SingleDatePicker
                                value={onBoardingState.dateOfBirth}
                                placeholder={dateOfBirth && dateOfBirth.length > 0 ? "" : "31/01/1970"}
                                format="dd/MM/yyyy"
                                onChange={(newValue) => {
                                    setInvalidDate(false)
                                    const dateArray = newValue.split("/").reverse()
                                    const dateString = dateArray.join("-")

                                    const valid = isValid(parseISO(dateString))
                                    if (valid) {
                                        const today = new Date()
                                        const date = new Date(dateString)
                                        const formattedFromDate = format(date, "dd/MM/yyyy")
                                        setOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: formattedFromDate } })
                                        const ridiculousDate = isAfter(date, today)
                                        if (ridiculousDate) {
                                            setDateErrorMessage("The date entered has not yet happened")
                                            setInvalidDate(true)
                                        } else {
                                            setDateErrorMessage("")
                                            setInvalidDate(false)
                                        }
                                    } else {
                                        setInvalidDate(true)
                                        setDateErrorMessage("Invalid Date")
                                        setOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: null } })
                                    }
                                }}
                                autoSubmit
                                noCalendar={true}
                                inputClassName={invalidDate ? "invalid-input" : ""}
                            />
                            <div className="label-container">
                                {invalidDate && <span className="form-error"><FormErrorMessage>{dateErrorMessage}</FormErrorMessage></span>}
                            </div>
                        </label>
                    </Col>
                    <hr style={{ borderTop: "1.5px solid", borderColor: "#b6bcbc", marginBottom: 35, opacity: 0.4 }} />
                    <div style={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}>Contact</div>
                </Row>
                <Row>
                    <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                        <Input
                            label={<><span className="form-label">Email*</span><i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 800 }}>check</i></>}
                            labelStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
                            type="email" name="email"
                            value={email || parsedData.email}
                            placeholder="Email"
                            onChange={() => null}
                            disabled
                            iconEnd={<i className="material-icons lock-icon">lock</i>}
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                    <span className={`form-label`}>Mobile*</span>
                                    <i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 900 }}>check</i>
                                </div>
                            </div>
                            <PhoneNumberInput
                                type="number"
                                name="phone-number"
                                placeholderIDD="IDD"
                                placeholderValue="Enter phone number"
                                invalidNumberMessage=""
                                noPrefixMessage=""
                                onChange={() => null}
                                value={mobile}
                                placeholderExt="Ext"
                                extraContainerClassNme="onbording-phone"
                                publicRoute
                                phoneErrorMessage=""
                                disabled
                                iconEnd={<i className="material-icons lock-icon">lock</i>}
                            />
                        </label>
                    </Col>
                    <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>Physical Address</span>
                            </div>
                            <AddyLocationField
                                value={{ text: physicalAddress, fullAddress: physicalAddress }}
                                name={"physical-address"}
                                placeholder={'Search an Address'}
                                onUpdate={(e) => onNzLocationChange(e, "physical")}
                                isClearable
                                className="onbording-address-field"
                                publicRoute
                            />
                        </label>
                    </Col>
                    <Col xs="12" xl="6" className="pl-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>Postal Address</span>
                            </div>
                            <AddyLocationField
                                value={{ text: postalAddress, fullAddress: postalAddress }}
                                name={"portal-address"}
                                placeholder={'Search an Address'}
                                onUpdate={(e) => onNzLocationChange(e, "portal")}
                                isClearable
                                className="onbording-address-field"
                                publicRoute
                            />
                        </label>

                    </Col>
                    <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 115 }}>
                        <RadioInputGroup
                            groupLabel="Prescribed Person*"
                            className="radio-input-heading"
                            radioButtonClass="onbording-radio-button"
                            options={[prescribedPerson.yes, prescribedPerson.no]}
                            value={isPrescribedPerson}
                            onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, isPrescribedPerson: e } })}
                            toggleable
                            clear={() => setOnBoardingState({ onBoardingState: { ...onBoardingState, isPrescribedPerson: null } })}
                        />
                    </Col>

                </Row>
                <hr style={{ borderTop: "1.5px solid", borderColor: "#b6bcbc", marginBottom: 35, opacity: 0.4 }} />
                <div style={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}>FATCA / Tax</div>
                <Row>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <Input
                            onBlur={() => setOnIRDEdit(false)}
                            onFocus={() => setOnIRDEdit(true)}
                            inputStyle={{ letterSpacing: 1 }}
                            type={onIRDEdit ? "number" : "text"}
                            label="IRD Number*"
                            name="ird-number"
                            value={onIRDEdit ? irdNumber : displayIRDNumber(irdNumber)}
                            placeholder="IRD Number"
                            onChange={(e) => {
                                setOnBoardingState({ onBoardingState: { ...onBoardingState, irdNumber: e } })
                            }}
                            errorMessage={isValidIRD ? "" : "Please enter a valid IRD number"}
                            errorMessageDown
                            invalidState={!isValidIRD}
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>RWT Rate</span>
                            </div>
                            <DropDown id="rwt-rate" openDropDown={openRWT} clickable placeholder="Select a Resident Withholding Tax rate" value={rwtRate} options={rwtOptions} updateValue={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, rwtRate: option } })} fullWidth />
                        </label>
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                            <div className="label-container">
                                <span className={`form-label`}>PIR</span>
                            </div>
                            <DropDown id="pir" openDropDown={openPIR} clickable placeholder="Select a Prescribed Investor Rate" value={pirRate} options={pirOptions} updateValue={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, pirRate: option } })} fullWidth />
                        </label>
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={countryOfBirth}
                            isMulti={false}
                            label="Country of Birth"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, countryOfBirth: option } })}
                            publicRoute
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={countriesOfCitizenship}
                            isMulti
                            label="Countries of Citizenship*"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, countriesOfCitizenship: option } })}
                            publicRoute
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={countriesOfResidence}
                            isMulti
                            label="Countries of Residence*"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, countriesOfResidence: option } })}
                            publicRoute
                        />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <CountryTokenField
                            value={contriesOfTaxResidence}
                            isMulti
                            label="Countries of Tax Residence*"
                            onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, contriesOfTaxResidence: option } })}
                            publicRoute
                        />
                    </Col>
                </Row>
                <hr style={{ borderTop: "1.5px solid", borderColor: "#b6bcbc", marginBottom: 35, opacity: 0.4 }} />
                <div style={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}>AML</div>
                <Row>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <Input label="Source of Wealth" name="wealth-source" value={wealthSource} placeholder="Source of wealth" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, wealthSource: e } })} errorMessageDown />
                    </Col>
                    <Col xs="12" xl="6" className="pr-xl-3" style={{ height: 115 }}>
                        <Input label="Source of Funds" name="funds-source" value={fundsSource} placeholder="Source of funds" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, fundsSource: e } })} errorMessageDown />
                    </Col>
                </Row>
                <VerticalSpacer size="40px" />

                <Row className="justify-content-end">
                    <Col xs="auto" className="justify-self-end">
                        <Button onClick={() => changeRoute('/investments')} disabled={isDisabled} className='capitalized'>
                            Next
                        </Button>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}

export default withThreeSkyeGlobal(withRouteChange(GeneralDetails))