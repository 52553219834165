import React, { Component } from 'react';
import "./DashboardDisclaimer.scss";


class DashboardDisclaimer extends Component {
	render() { 
		return (
			<p className="footer-disclaimer">
				Information about your portfolio is presented as at a particular point in time based on available information and is subject to change. Information may be sourced
				from third parties or our other information technology systems (which incorporate third party data), and will be updated from time to time as information becomes
				available in the client portal. The information may not reflect your immediate financial position. Please contact your adviser if you have any questions.
			</p>
		);
	}
}
 
export default DashboardDisclaimer;