import React, { memo, useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';
import { withRemote } from "@threeskye/global";
import { Link } from "react-router-dom";
import LoadingOverlay from "../../../../core-components/layouts/LoadingOverlay";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer";
import withRouteChange from "@threeskye/route-change";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Input from "../../../../core-components/inputs/Input";
import Button from "../../../../core-components/inputs/Button";
import Logo from "../../../../core-components/svgs/Logo";
import ConfirmEmailAddress from './ConfirmEmailAddress'
import CodeVerified from "./CodeVerified";
import ConfirmPhoneNumber from './ConfirmPhoneNumber'
import "./WelcomePage.scss";
import { toastDanger, toastSuccess } from "../../../../core-components/popups/Toast";


const JoinPage = ({ remote, login, changeRoute }) => {

    const invalid = false;

    const [loading, setLoading] = useState(false);

    const [emailVerified, setEmailVerified] = useState(false);
    const [codeSentToEmailVerified, setCodeSentToEmailVerified] = useState(false);
    const [codeSent, setCodeSent] = useState(false)

    const [emailCheck, setEmailCheck] = useState("");
    const [email, setEmail] = useState("");

    const steps = [
        "inital data entry",
        "email verification",
        "phone data entry",
        "phone verification"
    ]

    const setPhoneVerified = () => {
        login();
        changeRoute('/')
    }
    
    const setField = (event, setter) => {
        console.log("Setting field with event ", event, event.target)
        console.log(event.target.value)
    }

    const setAccountData = (data) => {
        setEmail(data.email);
        setEmailCheck(data.check);
    }

    const setEmailVerification = (data) => {
        setEmail(data.email);
        console.log("Set email verification setting email check to ", data.check);
        setEmailCheck(data.check);
        setEmailVerified(true);
    }


    const ChildComponent = () => {
        console.log("Email verified, emailCheck is ", emailCheck)
        if (!email) return <InitialDataEntry setData={setAccountData} remote={remote} />
        if (emailVerified) return <ConfirmPhoneNumber remote={remote} email={email} check={emailCheck} triggerNextView={setPhoneVerified} goBack={setCodeSentToEmailVerified} codeSent={codeSent} setCodeSent={setCodeSent} />
        if (emailCheck) return <ConfirmEmailAddress remote={remote} email={email} check={emailCheck} triggerNextView={setEmailVerification} goBack={() => setEmailCheck("")} />
        return <InitialDataEntry setData={setAccountData} remote={remote} />
    }
   
    return (
        <div className="welcome-page">
            <div className="welcome-page-header">
                <Logo width={150} />
                <div className="button-with-link secondary-button" style={{ fontSize: 15 }}>Already have an account?
                    <Link to="/" className="colour-white">
                        <Button className="button-colour-secondary capitalized" noIcon small onClick={() => null}>Sign In</Button>
                    </Link>
                </div>
            </div>

            {loading ? <LoadingOverlay message="Getting you started" /> : invalid ? <p>I'm sorry but there was an issue processing your request</p> : <ChildComponent/>}
        </div>
    );
}

export default withRemote(withRouteChange(JoinPage));


const InitialDataEntry = ({setData, remote}) => {

    const [errorMessage, setErrorMessage] = useState("");

    const [firstName, setFirstName] = useState("");
    const [middleNames, setMiddleNames] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("")

    const [emailTouchCount, setEmailTochCount] = useState(0)
    const [emailState, setEmailState] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(true)

    const [loading, setLoading] = useState(false);

    const passwordErrorMessage = "", invalidPassword = "", confirmPasswordErrorMessage = "";


    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        emailValidation(email)
        setInvalidConfirmPassword(password2 && password !== password2)
    }, [email, password, password2])

    useEffect(() => {
        const disable = !firstName || !lastName || (invalidEmail && email && email.length > 0) || !password || (!password2 || (password2 && invalidConfirmPassword))
        setIsDisabled(disable)
    }, [firstName, lastName, invalidEmail, email, password, password2, invalidConfirmPassword])

    const confirmPasswordValidation = (confirmPassword, password) => {
        if (confirmPassword && confirmPassword.length > 0 && confirmPassword !== password) return "Passwords must match"
        else return ""
    }

    function emailValidation(email) {
        let message = ""
        if (email) {
            if (!email.includes("@")) message = "Your email is missing an @";
            else if (email.endsWith("@")) message = "Domain value is missing";
            else if (email.endsWith(".") || !email.replace(/[^@]+@/g, "").includes(".")) message = "Top level domain is missing";
            else if (email.includes("@.")) message = "Lower level domain is missing";
            else message = ""
        }
        setEmailErrorMessage(message)
    }

    function handleOnKeyDown(e) {
        if (e.key === "Enter" || e.key === "Tab") {
            setEmail(emailState)
            setEmailTochCount(emailTouchCount + 1)
        } else {
            setInvalidEmail(false)
            setEmailErrorMessage("")
        }
    }

    const submit = () => {
        console.log("Would be submitting")
        setErrorMessage(false);
        const data = {
            givenName: firstName,
            middleNames: middleNames,
            familyName: lastName,
            email,
            password,
            password2
        }

        setLoading(true);
        remote.post("/public/modules/koura/register", data).then(r => {
            console.log(r);
            setLoading(false);
            if (r && r.success) {
                toastSuccess('Account registered', { className: 'prelogin-toast' })
                setData({
                    email: email,
                    check: r.data.emailVerification
                });
            } else {
                toastDanger(r.message, { className: 'prelogin-toast' })
                console.log(r.message)
            }
        });
    }

    if (loading) return  <LoadingOverlay message="Getting you started" />;

    return <Card className="no-box-shadow">
    <CardHeader className="header-with-subheader">
        <h3 className="onbording-header no-margin">Welcome {firstName && firstName}</h3>
        <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>Please enter your details to create your account</div>
    </CardHeader>

    <Row>
        <Col xs="12" xl="6" className="pr-xl-3">
            <Input label="First Name*" name="first-name" value={firstName} placeholder="First Name" onChange={setFirstName} />
        </Col>
        <Col xs="12" xl="6" className="pl-xl-3">
            <Input label="Last Name*" name="last-name" value={lastName} placeholder="Last Name" onChange={setLastName} />
        </Col>
        <Col xs="12" xl="12" className="pl-xl-3" style={{height: 126}}>
            <Input
                label="Email*"
                type="email" name="email"
                value={emailState}
                placeholder="Email"
                errorMessage={emailErrorMessage}
                onChange={(e) => setEmailState(e)}
                inputClass={emailErrorMessage !== "" ? "invalid-input" : ""}
                errorMessageDown
                onBlur={() => setEmail(emailState)}
                onClick={() => { setEmailErrorMessage(""); setInvalidEmail(false) }}
                onKeyPress={handleOnKeyDown}
            />
        </Col>
    </Row>
    <hr style={{ borderTop: "1.5px solid", borderColor: "#b6bcbc", marginBottom: 35, opacity: 0.4 }} />
    <div style={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}>Password</div>
    <Row>
        <Col xs="12" xl="6" className="pr-xl-3">
            <Input
                label="Password*"
                name="password"
                value={password}
                placeholder="Password"
                errorMessage={passwordErrorMessage}
                onChange={(e) => setPassword(e)}
                inputClass={password && invalidPassword ? "invalid-input" : ""}
                errorMessageDown
                autoComplete="off"
            />
        </Col>
        <Col xs="12" xl="6" className="pr-xl-3" style={{height: 126}}>
            <Input
                type="password"
                errorMessage={password ? confirmPasswordValidation(password2, password) : confirmPasswordErrorMessage}
                label="Confirm Password*" name="password" value={password2}
                placeholder="Confirm Password"
                onChange={(e) => setPassword2(e)}
                inputClass={password2 && invalidConfirmPassword ? "invalid-input" : ""}
                errorMessageDown
                autoComplete="off"
            />
        </Col>
    </Row>
    {errorMessage && <Row>
        <Col xs="12" xl="12" className="pr-xl-3 error">{errorMessage}.  Please correct any errors and try again, or contact us.</Col>
    </Row>}
    <VerticalSpacer size="40px" />

    <Row className="justify-content-end">
        <Col xs="auto" className="justify-self-end">
            <Button className="capitalized" onClick={submit} disabled={isDisabled}>
                Next
            </Button>
        </Col>
    </Row>
</Card>
}
