import moment from "moment";

const Formatter = {
	asMoney: (money, dp, ) => {
		if (!money && money !== 0)
			return "$-";
		const error = errorMessage(money, "money", "number");
		if (dp === undefined) dp = 2;
		if (error) throw console.error(error);
		if (money < 0) return "-$" + Formatter.numberWithCommas(money * -1, dp);
		return "$" + Formatter.numberWithCommas(money, dp);
	},
	asTransaction: (money) => {
		if (!money && money !== 0)
			return "$-";
		const error = errorMessage(money, "money", "number");
		if (error) throw console.error(error);
		if (money < 0) return "-$" + Formatter.numberWithCommas(money * -1, 2);
		return "+$" + Formatter.numberWithCommas(money, 2);
	},
	numberWithCommas: (number, toFixed) => {
		if (!number && number !== 0)
			return "-";
		const error = errorMessage(number, "number", "number") || errorMessage(toFixed, "number", "number", true);
		if (error) throw console.error(error);
		const fix = toFixed === undefined ? n => [n.toString(), null] : n => n.toFixed(toFixed).split(".");
		const [ fixedNum, decimal ] = fix(number);
		return fixedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (decimal ? `.${decimal}` : "");
	},
	asPercentage: (perc, toFixed) => {
		if (!perc && perc !== 0)
			return "-";
		const error = errorMessage(perc, "number", "number") || errorMessage(toFixed, "number", "number", true);
		if (error) throw console.error(error);
		const fix = toFixed === undefined ? n => n.toString() : n => n.toFixed(toFixed);
		return fix(perc) + "%";
	},
	monthAsString: (monthNumber, isSliced) => {
		if (!monthNumber && monthNumber !== 0)
			return "-";
		const error = errorMessage(monthNumber, "number", "number");
		if (error) throw console.error(error);
		if (isSliced) return monthNames[monthNumber].slice(0, 3);
		return monthNumber[monthNumber];
	},
	dateAsString: (date, isMonthDisplayedAsString) => {
		const error = errorMessage(date, "object", "object") || errorMessage(isMonthDisplayedAsString, "boolean", "boolean", true);
		if (error) throw console.error(error);
		let day = date.getDate().toString();
		if (day.length === 1) day = "0" + day;
		let month = date.getMonth();
		if (isMonthDisplayedAsString) month = Formatter.monthAsString(month, true);
		else {
			month = month.toString();
			if (month.length === 1) month = "0" + month;
		}
		var year = date.getFullYear();

		return [day, month, year].join(isMonthDisplayedAsString ? " " : "/");
	},
	dateAsDTO: date => {
		const error = errorMessage(date, "date", "object");
		if (error) throw console.error(error);
		return date.toISOString().slice(0, 10);
	},
	asKilobytes: (fileSize, toFixed) => {
		if (!fileSize && fileSize !== 0)
			return "-";
		const error = errorMessage(fileSize, "number", "number") || errorMessage(toFixed, "number", "number", true);
		if (error) throw console.error(error);
		const fix = toFixed === undefined ? n => n.toFixed(0) : n => n.toFixed(toFixed);
		return fix(fileSize) + " KB";
	},
	asKDollars: number => {
		if (!number && number !== 0)
			return "-";
		const error = errorMessage(number, "number", "number");
		if (error) throw console.error(error);
		if (number < 1000) return "$" + number.toFixed(0);
		return "$" + (number / 1000).toFixed(0) + "k";
	},
	capitalise: text => {
		const error = errorMessage(text, "text", "string");
		if (error) throw console.error(error);
		if (text.length === 0) return "";
		const pre = text[0].toUpperCase();
		if (text.length === 1) return pre;
		return pre + text.slice(1);
	},
	fromIsoDateToDisplay: isoDate => {
		const error = errorMessage(isoDate, "ISO DATE", "string");
		if (error) throw console.error(error);
		return moment(isoDate).format("Do MMM YYYY");
	}
};

const errorMessage = (value, valueName, expectedType, isOptional) => {
	if ((isOptional && value === undefined) || typeof value === expectedType) return false;
	return `${valueName}:${value} is type ${typeof value}.\nIt must be of type ${expectedType}.`;
};

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default Formatter;
