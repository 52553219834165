import React, { Component } from "react";
import "./BannerAccountPageFilters.scss";
import { withThreeSkyeGlobal } from "@threeskye/global";
import AccountPageFilters from "./AccountPageFilters";

class BannerAccountPageFilters extends Component {
	state = { showFilters: false };

	constructor(props) {
		super(props);
		this.onScroll = this.onScroll.bind(this);
		this.addScrollListener = this.addScrollListener.bind(this);
	}

	componentDidMount() {
		const ref = this.props.storage.get("content-wrapper-ref");
		if (ref && ref.current) {
			this.addScrollListener(ref);
		} else {
			this.props.storage.watch("content-wrapper-ref", this.addScrollListener);
		}
	}

	componentWillUnmount() {
		const contentWrapperRef = this.props.storage.get("content-wrapper-ref");
		if (contentWrapperRef && contentWrapperRef.current) {
			contentWrapperRef.current.removeEventListener("scroll", this.onScroll);
		}
		this.props.storage.unwatch("content-wrapper-ref", this.addScrollListener);
	}

	addScrollListener(ref) {
		if (ref && ref.current) {
			ref.current.addEventListener("scroll", this.onScroll);
		}
	}

	onScroll() {
		const accPageFiltersRef = this.props.storage.get("account-page-filters-ref");

		if (accPageFiltersRef && accPageFiltersRef.current) {
			this.setState({ showFilters: accPageFiltersRef.current.getBoundingClientRect().y <= 0 });
		}
	}

	render() {
		const { onExport } = this.props;
		const { showFilters } = this.state;
		return (
			<div className={`banner-account-filters-container${showFilters ? " filters-slide-in" : ""}`}>
				<div className="container">
					<div className="banner-account-filters">
						<AccountPageFilters onExport={onExport} />
					</div>
				</div>
			</div>
		);
	}
}

export default withThreeSkyeGlobal(BannerAccountPageFilters);
