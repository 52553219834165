import React, { Component } from "react";
import HobsonLogo from "../../../core-components/svgs/HobsonLogo";
import VerticalSpacer from "../../../core-components/layouts/VerticalSpacer";
import Logo from "../../../core-components/svgs/Logo";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withTheme } from "../../../core-components/contexts/ThemeContext";

class CenteredLogo extends Component {
	render() {
		const { children, theme, dividerHeight } = this.props;
		return (
			<div className="logged-out-wrapper">
				<div className="logged-out-content">
					<div className="logo-container">{theme === "theme-hobson" ? <HobsonLogo width={120} /> : (theme === "theme-3skye" || theme === "theme-light") && <Logo width={170} />}</div>
					<VerticalSpacer size={dividerHeight || "52px"} />
					<div className="forms">{children}</div>
				</div>
			</div>
		);
	}
}

export default withTheme(withThreeSkyeGlobal(CenteredLogo));
