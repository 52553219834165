import React, { Component } from "react";
import withSortParams from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import { compareDates, compareStrings, baseCompareWithMapFunc, baseCompare, compareNumbers } from "@threeskye/core-components/dist/components/Sorting/CompareFunctions";
import Formatter from "../../../../core-components/functions/Formatter";
import withRouteChange from "@threeskye/route-change";
import "./TransactionList.scss";
import "./TransactionListMobile.scss";
import ErrorMessage, { GenericConnectionFailErrorMessage } from "../../../../core-components/layouts/ErrorMessage";
import Token from "../../../../core-components/misc/Token";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import Loading from "../../../../core-components/layouts/Loading";
import ToolTip from "../../../../core-components/layouts/ToolTip";
import SortByHeader from "../../../../core-components/tables/SortByHeader";

export const fields = {
	date: "DATE",
	particulars: "PARTICULARS",
	assetName: "NAME",
	typeOrActivity: "TYPE_OR_ACTIVITY",
	unitQty: "UNIT_QUANTITY",
	unitCost: "UNIT_COST",
	currency: "CURRENCY",
	// creditOrDebit: "CREDIT_OR_DEBIT",
	total: "TOTAL",
	balance: "BALANCE",
};

const headers = {
	[fields.date]: "date",
	[fields.particulars]: "Particulars",
	[fields.assetName]: "Asset Name",
	[fields.typeOrActivity]: "Type/Activity",
	[fields.unitQty]: "Unit Qty",
	[fields.unitCost]: "Unit Cost",
	[fields.currency]: "Currency",
	// [fields.creditOrDebit]: "Credit/Debit",
	[fields.total]: "Total",
	[fields.balance]: "Cash Balance",
};

const display = {
	[fields.date]: ({ date }) => (CommonFunctions.isNullOrUndefined(date) ? null : Formatter.fromIsoDateToDisplay(date, true)),
	[fields.particulars]: ({ incomeTransaction, assetTransaction, nativeCurrency }) => (incomeTransaction && incomeTransaction.ticker) || (assetTransaction && assetTransaction.ticker) || nativeCurrency,
	[fields.assetName]: ({ asset }) => asset,
	[fields.typeOrActivity]: ({ narrative }) => narrative,
	[fields.unitQty]: ({ assetTransaction }) => (assetTransaction ? Formatter.numberWithCommas(assetTransaction.units, 2) : null),
	[fields.unitCost]: ({ assetTransaction }) => (assetTransaction ? Formatter.numberWithCommas(assetTransaction.price, 2) : null),
	[fields.currency]: ({ nativeCurrency }) => (nativeCurrency),
	// [fields.creditOrDebit]: ({ cashTransactions }) => (cashTransactions && cashTransactions.length > 0 && cashTransactions[0] ? (cashTransactions[0].credit ? "Credit" : "Debit") : null),
	[fields.total]: ({ nativeCurrencyValue }) => (CommonFunctions.isNullOrUndefined(nativeCurrencyValue) ? null : Formatter.numberWithCommas(nativeCurrencyValue, 2)),
	[fields.balance]: ({ nativeCurrencyBalance }) => (CommonFunctions.isNullOrUndefined(nativeCurrencyBalance) ? null : Formatter.numberWithCommas(nativeCurrencyBalance, 2)),
};

export const compareFunctions = ({ field, isDescending }) => {
	let paramTuple = null;
	switch (field) {
		case fields.date:
			paramTuple = ["date", compareDates];
			break;
		case fields.typeOrActivity:
			paramTuple = ["narrative", compareStrings];
			break;
		case fields.unitQty:
			paramTuple = [({ assetTransaction }) => (assetTransaction ? assetTransaction.units : null), compareNumbers, true];
			break;
		case fields.unitCost:
			paramTuple = [({ assetTransaction }) => (assetTransaction ? assetTransaction.price : null), compareNumbers, true];
			break;
		case fields.currency:
			paramTuple = ["nativeCurrency", compareStrings];
			break;
		case fields.creditOrDebit:
			paramTuple = [({ cashTransactions }) => (cashTransactions && cashTransactions.length > 0 && cashTransactions[0] ? (cashTransactions[0].credit ? "Credit" : "Debit") : "-"), compareStrings, true];
			break;
		case fields.total:
			paramTuple = ["value", compareNumbers];
			break;
		case fields.balance:
			paramTuple = ["nativeCurrencyBalance", compareNumbers];
			break;
		case fields.particulars:
			paramTuple = [
				({ incomeTransaction, assetTransaction, nativeCurrency }) => (incomeTransaction && incomeTransaction.ticker) || (assetTransaction && assetTransaction.ticker) || nativeCurrency,
				compareStrings,
				true,
			];
			break;
		default:
			return null;
	}
	const [nameOrMapFunc, compareFunction, isMapFunc] = paramTuple;
	if (isMapFunc) {
		return (f1, f2) => baseCompareWithMapFunc(isDescending, f1, f2, nameOrMapFunc, compareFunction);
	} else {
		return (f1, f2) => baseCompare(isDescending, f1, f2, nameOrMapFunc, compareFunction);
	}
};

class Header extends Component {
	render() {
		const { field, prefix } = this.props;
		return (
			<SortByHeader field={field}>
				{prefix ? prefix + " " : ""}
				{headers[field]}
			</SortByHeader>
		);
	}
}

class TransactionList extends Component {
	state = { sortedTransactionHistory: null };

	constructor(props) {
		super(props);
		this.updateSortedTransactionHistory = this.updateSortedTransactionHistory.bind(this);
		this.generateCSV = this.generateCSV.bind(this);

		if (props.setGenerateCSVFunc) {
			props.setGenerateCSVFunc(this.generateCSV);
		}
	}

	componentDidMount() {
		this.updateSortedTransactionHistory();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.transactionHistory !== this.props.transactionHistory || prevProps.sortParams !== this.props.sortParams) {
			this.updateSortedTransactionHistory();
		}
	}

	updateSortedTransactionHistory() {
		const { transactionHistory, sortParams } = this.props;
		if (transactionHistory && transactionHistory.length > 0) {
			let sortedTransactionHistory = transactionHistory;
			if (sortParams) {
				const compareFunction = compareFunctions(sortParams);
				if (compareFunction) {
					sortedTransactionHistory = transactionHistory.slice().sort(compareFunction);
				}
			}
			this.setState({ sortedTransactionHistory });
		} else {
			this.setState({ sortedTransactionHistory: null });
		}
	}

	generateCSV() {
		const { date, particulars, assetName, typeOrActivity, unitQty, unitCost, currency, total, balance } = fields;
		const { sortedTransactionHistory } = this.state;
		let fieldsToDisplay = [date, particulars, assetName, typeOrActivity, unitQty, unitCost,  currency, total, balance];
		return [fieldsToDisplay.map((field) => headers[field])]
			.concat(sortedTransactionHistory.map((transaction) => fieldsToDisplay.map((field) => `"${display[field](transaction) || ""}"`)))
			.map((row) => row.join(","))
			.join("\n");
	}

	render() {
		const { transactionHistory, loadingMessage, connectionError } = this.props;
		const { sortedTransactionHistory } = this.state;
		const { isMobile } = CommonFunctions;
		if (loadingMessage) {
			return <Loading centered />;
		} else if (connectionError) {
			return <GenericConnectionFailErrorMessage />;
		} else if (isMobile() ? !transactionHistory || transactionHistory.length === 0 : !sortedTransactionHistory || sortedTransactionHistory.length === 0) {
			return <ErrorMessage infoIcon>No transactions to display for this period and these filters.</ErrorMessage>;
		} else {
			return (
				<div className="transaction-list">
					<Mobile>
						<div class={`transaction-list__mobile-headers balance-showing`}>
							<div className="transaction-list__mobile-header">{headers[fields.typeOrActivity]}</div>
							<div className="transaction-list__mobile-header">{headers[fields.total]}</div>
							<div className="transaction-list__mobile-header">{headers[fields.balance]}</div>
						</div>
						{transactionHistory &&
							transactionHistory.length > 0 &&
							transactionHistory.map((transaction, index) => (
								<div key={index} className={`transaction-list__mobile-transaction balance-showing`}>
									<div className="transaction-list__left">
										<div className="transaction-list__header">{display[fields.typeOrActivity](transaction) || "-"}</div>
										<div className="transaction-list__date">{display[fields.date](transaction) || "-"}</div>
									</div>
									<div className={`transaction-list__right total-col ${transaction.value < 0 ? "negative" : "positive"}`}>{display[fields.total](transaction) || "-"}</div>
									<div className={`transaction-list__right balance-col ${transaction.nativeCurrencyBalance < 0 ? "negative" : "positive"}`}>{display[fields.balance](transaction) || "-"}</div>
								</div>
							))}
					</Mobile>
					<Desktop>
						<table className="transaction-list__desktop">
							<tbody>
								<tr>
									<th className="table-header th-date">
										<Header field={fields.date} />
									</th>
									<th className="table-header">
										<Header field={fields.particulars} />
									</th>
									<th className="table-header">
										<Header field={fields.typeOrActivity} />
									</th>
									<th className="table-header right-align">
										<Header field={fields.unitQty} />
									</th>
									<th className="table-header right-align">
										<Header field={fields.unitCost} />
									</th>
									<th className="table-header right-align">
										<Header field={fields.currency} />
									</th>
									{/* <th className="table-header right-align">
										<Header field={fields.creditOrDebit} />
									</th> */}
									<th className="table-header right-align">
										<Header field={fields.total} />
									</th>
									<th className="table-header right-align"><Header field={fields.balance} /></th>
								</tr>
							</tbody>
							<tbody>
								{sortedTransactionHistory &&
									sortedTransactionHistory.length > 0 &&
									sortedTransactionHistory.map((transaction, index) => (
										<tr key={index}>
											<td className={`td-date${transaction.nativeCurrencyValue < 0 ? " negative" : " positive"}`}>{display[fields.date](transaction) || "-"}</td>
											<td className="td-particulars">
												<ToolTip medium leftAligned title={display[fields.assetName](transaction) || "-"} style={{ bottom: "120%" }}>
													<Token small>{display[fields.particulars](transaction) || "-"}</Token>
												</ToolTip>
											</td>
											<td className="td-description">{display[fields.typeOrActivity](transaction) || "-"}</td>
											<td className="right-align">{display[fields.unitQty](transaction) || "-"}</td>
											<td className="right-align">{display[fields.unitCost](transaction) || "-"}</td>
											<td className="right-align">{display[fields.currency](transaction) || "-"}</td>
											{/* <td className="right-align">{display[fields.creditOrDebit](transaction) || "-"}</td> */}
											<td className={`td-amount right-align ${transaction.nativeCurrencyValue < 0 ? "negative" : "positive"}`}>{display[fields.total](transaction) || "-"}</td>
											<td className={`td-amount right-align ${transaction.nativeCurrencyBalance < 0 ? "negative" : "positive"}`}>{display[fields.balance](transaction) || "-"}</td>
										</tr>
									))}
							</tbody>
						</table>
					</Desktop>
				</div>
			);
		}
	}
}

export default withSortParams(withAccountInfo(withRouteChange(TransactionList)));
