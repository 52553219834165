import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Button from "../../../../core-components/inputs/Button";
import TextArea from "../../../../core-components/inputs/TextArea";
import Input from "../../../../core-components/inputs/Input";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withAccountInfo, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import Banner from "../../Banner";
import BannerInfoText, { BannerInfoMainText, BannerInfoHighlightText, BannerInfoIntroText } from "../../../../core-components/misc/BannerInfoText";
import PortalContentWrapper from "../../PortalContentWrapper";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import { withIsReadOnly } from "../../../../core-components/functions/withIsReadOnly";
class ContactUs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			lastName: "",
			email: "",
			subject: "",
			message: "",
			error: "",
			loading: false,
			success: false,
		};
		this.update = this.update.bind(this);
	}

	update() {
		const { account, thirdPartyView } = this.props;
		const { message, subject } = this.state;
		if (thirdPartyView) {
			this.props.remote.post(`/modules/hobson-support/third-party/contact`, { subject, message }).then(({ success }) => this.setState({ success }));
		} else this.props.remote.post(`/modules/hobson-support/accounts/${account.id}/contact`, { subject, message }).then(({ success }) => this.setState({ success }));
	}

	render() {
		const { firstName, lastName, email, subject, message, error, loading, success } = this.state;
		const { account, portalAccountType, isReadOnly, thirdPartyView } = this.props;
		const disabled = isReadOnly();
		return (
			<>
				<Mobile>
					<DefaultMobileBanner thirdPartyView={thirdPartyView} />
				</Mobile>
				<Desktop>
					<Banner thirdPartyView={thirdPartyView}>
						<BannerInfoText>
							{account.name && <BannerInfoMainText>{account.name}</BannerInfoMainText>}
							{
								thirdPartyView
									? <BannerInfoIntroText>You're now viewing your Third Party accounts</BannerInfoIntroText>
									: <BannerInfoHighlightText>{displayPortalAccType(portalAccountType)}</BannerInfoHighlightText>
							}
						</BannerInfoText>
					</Banner>
				</Desktop>
				<PortalContentWrapper thirdPartyView={thirdPartyView} >
					<CardContainer>
						<Card>
							<CardHeader>
								<h3>Contact Us</h3>
							</CardHeader>
							<div id="get-in-touch">
								<Row noGutters>
									<Col xs="12" xl="8">
										<Row>
											<Col xs="12" xl="6" className="mb-1">
												<Input
													label="First Name"
													errorMessage={error}
													name="firstName"
													value={firstName}
													placeholder="First Name"
													disabled={success || disabled}
													onChange={(firstName) => this.setState({ firstName })}
												/>
											</Col>
											<Col xs="12" xl="6" className="mb-1">
												<Input
													label="Last Name"
													errorMessage={error}
													name="lastName"
													value={lastName}
													placeholder="Last Name"
													disabled={success || disabled}
													onChange={(lastName) => this.setState({ lastName })}
												/>
											</Col>

											<Col xs="12" xl="12" className="mb-1">
												<Input
													label="Email"
													errorMessage={error}
													name="email"
													value={email}
													placeholder="Email"
													disabled={success || disabled}
													onChange={(email) => this.setState({ email })}
												/>
											</Col>

											<Col xs="12" xl="12" className="mb-1">
												<Input
													label="Subject"
													errorMessage={error}
													name="subject"
													value={subject}
													placeholder="Subject"
													disabled={success || disabled}
													onChange={(subject) => this.setState({ subject })}
												/>
											</Col>
											<Col xs="12">
												<TextArea
													label="Message"
													value={message}
													placeholder="Your message..."
													disabled={success || disabled}
													onChange={(message) => this.setState({ message })}
													rows={10}
												/>
											</Col>
											<Col xs="12" className="d-flex justify-content-end mb-3">
												<Button disabled={!message || !subject || loading || success || disabled} loading={loading} onClick={this.update}>
													{success ? "Message successfully sent!" : "SUBMIT"}
												</Button>
											</Col>
										</Row>
									</Col>
									<Col xs="auto" xl="1" />
									<Col xs="12" xl="auto" className="d-flex flex-column align-content-xl-end flex-wrap">
										<div className="mb-1">
											<h4>Email Us</h4>
											<p>adrian.visor@3skye.com</p>
										</div>
										<div className="mb-1">
											<h4>FAQS</h4>
											<p className="faqs-link">Find answers here</p>
										</div>
										<div className="mb-1">
											<h4>Email Us</h4>
											<p>adrian.visor@3skye.com</p>
										</div>
										<div className="mb-1">
											<h4>FAQS</h4>
											<p className="faqs-link">Find answers here</p>
										</div>
										<div className="mb-1">
											<h4>Address</h4>
											<p className="mb-1">Level 5/1 Post Office Square,</p>
											<p>Wellington 6011</p>
										</div>
									</Col>
								</Row>
							</div>
						</Card>
					</CardContainer>
				</PortalContentWrapper>
			</>
		);
	}
}

export default withIsReadOnly(withAccountInfo(withThreeSkyeGlobal(ContactUs)));
