import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Search } from "@material-ui/icons";
import withReactKey from "@threeskye/core-components/dist/components/withReactKey";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";
import Button from "../../../../core-components/inputs/Button";
import DateRangeInput from "../../../../core-components/inputs/DateRangeInput";
import FilterInput from "../../../../core-components/inputs/FilterInput";
import SearchInput from '../../../../core-components/inputs/SearchInput';
import "./DocumentsFilters.scss";

const emptyState = { from: null, to: null, search: "", tag: null };
class DocumentsFilters extends Component {
	state = { ...emptyState };

	constructor(props) {
		super(props);
		this.onFiltersUpdate = this.onFiltersUpdate.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	componentDidMount() {
		this.onFiltersUpdate();
	}

	onFiltersUpdate() {
		const { from, to, search, tag } = this.state;
		this.props.setFilters({ from, to, search, tag });
	}

	clearFilters() {
		this.setState({ ...emptyState }, () => {
			this.onFiltersUpdate();
			this.props.changeKey();
		});
	}

	handleKeyPress(event) {
		if (event.key === "Enter") {
			this.onFiltersUpdate();
		}
	}

	render() {

		const tags = this.props.documents.reduce((acc, current) => {
			const mapped = current.tags.map(tag => { return { label: tag, value: tag } })
			return acc.concat(mapped)
		}, [])
		const uniqueTags = tags.filter((tag, index, array) => array.findIndex(t => (t.value === tag.value)) === index && tag.value != "")

		const { reactKey } = this.props;
		const { search, to, from, tag } = this.state;

		return (
			<div className="document-filters" tabIndex="-1" onKeyPress={this.handleKeyPress}>
				<Row>
					<Col xs="12" lg="3">
						<FilterInput placeholder="Search Documents" label="Search" name="search" value={search} onChange={(search) => this.setState({ search })} icon={<Search />} onBlur={this.onFiltersUpdate} />
					</Col>
					<Col xs="12" lg="3">
						{/* <Select placeholder="Search By Tags" label="Tags" value={search} onChange={(search) => this.setState({ search })} icon={<Search />} onBlur={this.onFiltersUpdate} /> */}
						{/* <SearchInput  options={["OOP", "HRA", "AWE"]} value={this.state.value} select={tag => this.setState({value: tag })} /> */}
						<SearchInput
							options={uniqueTags}
							label="Tags"
							placeholder="Search Tag"
							value={this.state.tag || null}
							onChange={(tag) => {
								this.setState({ tag }, this.onFiltersUpdate)
							}}
						/>
					</Col>
					<Col xs="12" lg="6" className="date-range-filter-wrapper">
						<DateRangeInput
							key={reactKey}
							from={from}
							onFromChange={(from) => this.setState({ from }, this.onFiltersUpdate)}
							to={to}
							onToChange={(to) => this.setState({ to }, this.onFiltersUpdate)}
						/>
					</Col>
					<Col xs="12" lg="4" xl="4" className="align-self-center documents-apply-button documents-clear-button mt-3">
						<Button fullWidth small onClick={this.clearFilters}>
							Clear Filters
						</Button>
					</Col>
				</Row>
			</div>
		);
	}
}

export default withReactKey(withFilters(withAccountInfo(DocumentsFilters)));
