import React, { Component } from "react";
import "./Loading.scss";
import CommonFunctions from "../functions/CommonFunctions";
import LoadingIcon from "@threeskye/loading-icon/dist/LoadingIcon";

class Loading extends Component {
	render() {
		const className = "loading-overlay " + CommonFunctions.responsiveClassname();
		return (
			<div className={className}>
				<div className="loading-container">
					<LoadingIcon />
				</div>
			</div>
		);
	}
}

export default Loading;
