import React, { Component } from "react";
import "./ContentToggler.scss";
import CommonFunctions from "../functions/CommonFunctions";

class ContentToggler extends Component {
	render() {
		const { options, className, activeOption, select, format } = this.props;
		const allClassNames = CommonFunctions.asClassName(["content-toggler", className]);
		return (
			<div className={allClassNames}>
				{options &&
					options.map((option, idx) => {
						const optionClassName = CommonFunctions.asClassName(["content-toggler-option", option === activeOption && "active-option"]);
						return (
							<span key={idx} className={optionClassName} onClick={() => select(option)}>
								<span className="option-text">{format ? format(option) : option}</span>
							</span>
						);
					})}
			</div>
		);
	}
}

export default ContentToggler;
