import React, { useEffect, useState } from "react";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { Col, Row } from "reactstrap";
import { handleNumberChange } from "../../../PreAccountLogin/OnboardingUtils"
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Button from "../../../../core-components/inputs/Button"
import CommonFunctions from "../../../../core-components/functions/CommonFunctions"
import "../../../PreAccountLogin/Onboarding.scss"


const BusinessesPersonalInfo = (props) => {
    const { onBoardingState, setOnBoardingState, commitAndsetOnBoardingState } = props
    const [businesses, setBussinesses] = useState(onBoardingState.businesses)
    const [searchValue, setSearchValue] = useState("")
    const [businessIndex, setBusinessIndex] = useState(null)
    const [editedBusinessName, setEditedBusinessName] = useState("")
    const [editedBusinessRevenue, setEditedBusinessRevenue] = useState("")
    const [editedBusinessValuation, setEditedBusinessValuation] = useState("")
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    useEffect(() => {
        if (onBoardingState.businesses && onBoardingState.businesses.length > 0) {
            setBussinesses(onBoardingState.businesses)
        }
    }, [onBoardingState.businesses])

    function addBusiness() {
        const newObject = { name: "", annualRevenue: "", estimatedValuation: "" }
        setBussinesses([...businesses, newObject])
        setOnBoardingState({ onBoardingState: { ...onBoardingState, businesses: [...businesses, newObject] } })
        setSearchValue("")
        setBusinessIndex(businesses.length)
        setUnsavedChanges(null)
    }

    function removeBusiness(index) {
        const updatedBusinesses = businesses.filter((p, idx) => idx !== index)
        setBussinesses(updatedBusinesses)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, bussinesses: updatedBusinesses } })
        setUnsavedChanges(true)
    }

    function cancelEdit() {
        setEditedBusinessName("")
        setEditedBusinessRevenue("")
        setEditedBusinessValuation("")
        setBusinessIndex(null)
    }

    function saveEdit(index) {
        const updatedBusinesses = businesses.map((b, idx) => {
            if (idx === index) {
                const newObject = { name: editedBusinessName || b.name, annualRevenue: editedBusinessRevenue || b.annualRevenue, estimatedValuation: editedBusinessValuation || b.estimatedValuation }
                return newObject
            } else return b
        })
        setBussinesses(updatedBusinesses)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, businesses: updatedBusinesses } })
        cancelEdit()
        setUnsavedChanges(true)
    }

    function saveChangesOrAddBussiness() {
        if (unsavedChanges) {
            commitAndsetOnBoardingState({ onBoardingState: { ...onBoardingState } })
            setUnsavedChanges(false)
        } else addBusiness()
    }


    return (
        <div key="personal-info-kiwisaver">
            <CardContainer>
                <Card>
                    <CardHeader>
                        <div className="portal-card-title" style={{ display: "grid", gridTemplateColumns: "auto 175px", gap: '1rem', width: "100%", maxWidth: 1200 }}>
                            <h3>Businesses</h3>
                            <Button onClick={saveChangesOrAddBussiness} disabled={unsavedChanges === null}>{unsavedChanges === false ? "+ Add Business" : "Save Changes" }</Button>
                            {/* <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                                <input value={searchValue} placeholder="Business Name" name={"business-name"} onChange={(e) => setSearchValue(e.target.value)} />
                            </label> */}
                        </div>

                    </CardHeader>
                    <Row>
                        {businesses && businesses.length > 0 &&
                            <div className="business-sections-label">
                                <p>Business Name</p>
                                <p className="right">Revenue / Valuation</p>
                                <p>&nbsp;</p>
                            </div>
                        }
                        {businesses && businesses.length > 0 && businesses.map((b, idx) => {
                            return (
                                <Col xs="12" xl="12" className="pl-xl-3" key={idx}>
                                    <div className={`businesses-owned-row ${businessIndex === idx ? "editing" : ""}`}>
                                        {businessIndex === idx ?
                                            <input
                                                value={editedBusinessName || b.name}
                                                placeholder="Business Name" name={"revenue-" + idx}
                                                onChange={(e) => setEditedBusinessName(e.target.value)}
                                                autoFocus
                                            />
                                            : <p>{b.name}</p>
                                        }
                                        {businessIndex === idx ?
                                            <input
                                                value={editedBusinessRevenue || b.annualRevenue}
                                                placeholder="$0" name={"revenue-" + idx}
                                                onChange={(e) => setEditedBusinessRevenue(handleNumberChange(e, "revenue"))}
                                            /> :
                                            <p className='business-values'>{(b.annualRevenue || "$0") + " / " + (b.estimatedValuation || "$0")}</p>
                                        }
                                        {businessIndex === idx ?
                                            <input
                                                value={editedBusinessValuation || b.estimatedValuation}
                                                placeholder="$0" name={"revenue-" + idx}
                                                onChange={(e) => setEditedBusinessValuation(handleNumberChange(e, "valuation"))}
                                                style={{ margin: 0 }}
                                            /> : ""
                                        }
                                        <div className='action-icons'>
                                            {businessIndex === idx ? <i className="material-icons save-icon" onClick={() => {saveEdit(idx); setUnsavedChanges(true)}}>save</i> : <i className="material-icons edit-icon" onClick={() => setBusinessIndex(idx)}>create</i>}
                                            {businessIndex === idx ? <i className="material-icons cancel-icon" onClick={() => cancelEdit()}>close</i> : <i className="material-icons delete-icon" onClick={() => removeBusiness(idx)}>delete_forever</i>}
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Card>
            </CardContainer>
        </div >
    )
}

export default withThreeSkyeGlobal(withAccountInfo(BusinessesPersonalInfo))