import React, { Component } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import StandingOrderRow from "../../../../core-components/lists/orders/StandingOrderRow";
import ErrorMessage, { GenericConnectionFailErrorMessage } from "../../../../core-components/layouts/ErrorMessage";
import { withThreeSkyeGlobal } from "@threeskye/global";
import Loading from "../../../../core-components/layouts/Loading";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";

class StandingOrdersTable extends Component {
	state = { orders: null, isLoading: false };

	constructor(props) {
		super(props);
		this.fetchData = this.fetchData.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.account.id !== this.props.account.id) {
			this.fetchData();
		}
	}

	fetchData() {
		const { account } = this.props;
		this.setState({ isLoading: true });
		this.props.storage
			.getOrFetch(`/modules/hobson-support/accounts/${account.id}/regular-cash-movements`)
			.then((cashMovementsResponse) => this.setState({ cashMovementsResponse, isLoading: false }));
	}

	render() {
		const { cashMovementsResponse, isLoading } = this.state;
		return (
			<CardContainer xs={12}>
				<Card>
					<CardHeader>
						<h3>Recurring Payments</h3>
					</CardHeader>
					{isLoading ? (
						<Loading centered size={80} />
					) : cashMovementsResponse && !cashMovementsResponse.connectionError && cashMovementsResponse.data && cashMovementsResponse.data.length > 0 ? (
						cashMovementsResponse.data.map(({ nextRun, type, startDate, endDate, amount }, key) => <StandingOrderRow {...{ nextRun, type, startDate, endDate, amount, key }} />)
					) : cashMovementsResponse && cashMovementsResponse.connectionError ? (
						<GenericConnectionFailErrorMessage />
					) : (
						<ErrorMessage infoIcon>Please contact your Adviser if you would like to set up a Recurring Payment.</ErrorMessage>
					)}
				</Card>
			</CardContainer>
		);
	}
}

export default withAccountInfo(withThreeSkyeGlobal(StandingOrdersTable));
