import React, { Component } from 'react';
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import ContentToggler from '../../../../../core-components/filters-and-toggles/ContentToggler';
import { MONTH_YEAR_TIME_FRAME_LIST } from '../../../../../core-components/functions/ChartFunctions';

class MonthYearFilter extends Component {
	constructor(props) {
		super(props);
		this.selectTimeUnit = this.selectTimeUnit.bind(this);
	}

	selectTimeUnit(selectedTimeFrame) {
		const { setFilters, filters } = this.props;
		setFilters({ selectedFixedInterestType: filters.selectedFixedInterestType, selectedTimeFrame });
	}

	render() {
		const { filters } = this.props;
		return <ContentToggler options={MONTH_YEAR_TIME_FRAME_LIST} activeOption={filters.selectedTimeFrame} select={this.selectTimeUnit} />;
	}
}

export default withFilters(MonthYearFilter);
