import React, { Component } from "react";
import withSortParams from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import { compareStrings, compareNumbers, baseCompare, baseCompareWithMapFunc, /*compareDates*/ } from "@threeskye/core-components/dist/components/Sorting/CompareFunctions";
import Token from "../../../../../core-components/misc/Token";
import CardContainer from "../../../../../core-components/card/CardContainer";
import Card from "../../../../../core-components/card/Card";
import CardHeader from "../../../../../core-components/card/CardHeader";
import ErrorMessage from "../../../../../core-components/layouts/ErrorMessage";
import Formatter from "../../../../../core-components/functions/Formatter";
import HorizontalSpacer from "../../../../../core-components/layouts/HorizontalSpacer";
import SortByHeader from "../../../../../core-components/tables/SortByHeader";
import { fields } from "./Fields";
import Loading from "../../../../../core-components/layouts/Loading";
import { Col } from "reactstrap";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import { Assessment } from "@material-ui/icons";
import "./PortfolioAssetTable.scss";
import '../../../../../core-components/tables/SortByHeader.scss'
import withRouteChange from "@threeskye/route-change";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import { LINK_ITEMS, getRoute } from "../../../CustomerPortalPage";
import CommonFunctions from "../../../../../core-components/functions/CommonFunctions";
import NumberFormat from "./NumberFormat"

const compareFunctions = ({ isDescending, field }, gainsAndLossesMap, glIsLoading) => {
	switch (field) {
		case fields.asset:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "name", compareStrings);
		case fields.localCost:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "nativeAssetCost", compareNumbers);
		case fields.localCurrency:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "nativeCurrency", compareStrings);
		case fields.nzdCost:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "assetCost", compareNumbers);
		case fields.units:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "unitPrice", compareNumbers);
		case fields.currentUnitPrice:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "units", compareNumbers);
		case fields.localValue:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "nativeValue", compareNumbers);
		case fields.nzdValue:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "value", compareNumbers);
		case fields.percentOfPortfolio:
			return (f1, f2) => baseCompare(isDescending, f1, f2, "percentPortfolio", compareNumbers);
		// case fields.date:
		// 	return (f1, f2) => baseCompareWithMapFunc(isDescending, f1, f2, ({ assetId }) => gainsAndLossesMap && gainsAndLossesMap[assetId] && gainsAndLossesMap[assetId].date, compareDates);
		case fields.priceGL:
			return (f1, f2) => baseCompareWithMapFunc(isDescending, f1, f2, ({ assetId }) => gainsAndLossesMap && gainsAndLossesMap[assetId] && gainsAndLossesMap[assetId].priceGain, compareNumbers);
		case fields.fxGL:
			return (f1, f2) => baseCompareWithMapFunc(isDescending, f1, f2, ({ assetId }) => gainsAndLossesMap && gainsAndLossesMap[assetId] && gainsAndLossesMap[assetId].fxGain, compareNumbers);
		case fields.unrealisedGL:
			return (f1, f2) => baseCompareWithMapFunc(isDescending, f1, f2, ({ assetId }) => gainsAndLossesMap && gainsAndLossesMap[assetId] && gainsAndLossesMap[assetId].unrealisedGL, compareNumbers);
		case fields.glPerc:
			return (f1, f2) =>
				baseCompareWithMapFunc(
					isDescending,
					f1,
					f2,
					({ assetId, assetCost }) => gainsAndLossesMap && gainsAndLossesMap[assetId] && (gainsAndLossesMap[assetId].unrealisedGL / assetCost) * 100,
					compareNumbers
				);
		default:
			return null;
	}
};

const headers = {
	// [fields.date]: "Date",
	[fields.asset]: "Asset",
	[fields.tickerCode]: "Ticker",
	[fields.localCost]: "Local Cost",
	[fields.localCurrency]: "Local Currency",
	[fields.nzdCost]: "NZD Cost",
	[fields.units]: "Units",
	[fields.currentUnitPrice]: "Current Unit Price",
	[fields.localValue]: "Current Local Value",
	[fields.nzdValue]: "Current NZD Value",
	[fields.percentOfPortfolio]: "% of Holdings",
	[fields.priceGL]: "Price G/L",
	[fields.fxGL]: "FX G/L",
	[fields.unrealisedGL]: "Unrealised G/L",
	[fields.glPerc]: "G/L %",
};

const display = {
	// [fields.date]: ({ assetId }, gainsAndLossesMap, glIsLoading) => (!glIsLoading && gainsAndLossesMap && gainsAndLossesMap[assetId] ? gainsAndLossesMap[assetId].date : null),
	[fields.asset]: ({ name }, gainsAndLossesMap, glIsLoading) => name || null,
	[fields.tickerCode]: ({ tickerCode, ric, nativeCurrency }, gainsAndLossesMap, glIsLoading) => tickerCode || ric || nativeCurrency,
	[fields.localCost]: ({ nativeAssetCost }, gainsAndLossesMap, glIsLoading) =>
		!CommonFunctions.isNullOrUndefinedOrNaN(nativeAssetCost) ? `${Formatter.numberWithCommas(nativeAssetCost, 2)}` : null,
	[fields.localCurrency]: ({ nativeCurrency }, gainsAndLossesMap, glIsLoading) => nativeCurrency,
	[fields.nzdCost]: ({ assetCost }, gainsAndLossesMap, glIsLoading) => (!CommonFunctions.isNullOrUndefinedOrNaN(assetCost) ? Formatter.numberWithCommas(assetCost, 2) : null),
	[fields.units]: ({ units }, gainsAndLossesMap, glIsLoading) => (!CommonFunctions.isNullOrUndefinedOrNaN(units) ? Formatter.numberWithCommas(units) : null),
	[fields.currentUnitPrice]: ({ unitPrice }, gainsAndLossesMap, glIsLoading) => (!CommonFunctions.isNullOrUndefinedOrNaN(unitPrice) ? Formatter.numberWithCommas(unitPrice, 3) : null),
	[fields.localValue]: ({ nativeValue }, gainsAndLossesMap, glIsLoading) => (!CommonFunctions.isNullOrUndefinedOrNaN(nativeValue) ? Formatter.numberWithCommas(nativeValue, 2) : null),
	[fields.nzdValue]: ({ value }, gainsAndLossesMap, glIsLoading) => (!CommonFunctions.isNullOrUndefinedOrNaN(value) ? Formatter.numberWithCommas(value, 2) : null),
	[fields.percentOfPortfolio]: ({ percentPortfolio }, gainsAndLossesMap, glIsLoading) => (!CommonFunctions.isNullOrUndefinedOrNaN(percentPortfolio) ? Formatter.asPercentage(percentPortfolio, 2) : null),
	[fields.priceGL]: ({ assetId }, gainsAndLossesMap, glIsLoading) =>
		!glIsLoading && gainsAndLossesMap && gainsAndLossesMap[assetId] && !CommonFunctions.isNullOrUndefinedOrNaN(gainsAndLossesMap[assetId].priceGain)
			? Formatter.numberWithCommas(gainsAndLossesMap[assetId].priceGain, 2)
			: null,
	[fields.fxGL]: ({ assetId }, gainsAndLossesMap, glIsLoading) =>
		!glIsLoading && gainsAndLossesMap && gainsAndLossesMap[assetId] && !CommonFunctions.isNullOrUndefinedOrNaN(gainsAndLossesMap[assetId].fxGain)
			? Formatter.numberWithCommas(gainsAndLossesMap[assetId].fxGain, 2)
			: null,
	[fields.unrealisedGL]: ({ assetId }, gainsAndLossesMap, glIsLoading) =>
		!glIsLoading && gainsAndLossesMap && gainsAndLossesMap[assetId] && !CommonFunctions.isNullOrUndefinedOrNaN(gainsAndLossesMap[assetId].unrealisedGL)
			? Formatter.numberWithCommas(gainsAndLossesMap[assetId].unrealisedGL, 2)
			: null,
	[fields.glPerc]: ({ assetId, assetCost }, gainsAndLossesMap, glIsLoading) =>
		!glIsLoading && gainsAndLossesMap && gainsAndLossesMap[assetId] && !CommonFunctions.isNullOrUndefinedOrNaN(gainsAndLossesMap[assetId].unrealisedGL) && !CommonFunctions.isNullOrUndefinedOrNaN(assetCost)
			? Formatter.asPercentage((gainsAndLossesMap[assetId].unrealisedGL / assetCost) * 100, 2)
			: null,
};

class Header extends Component {
	render() {
		const { field, className } = this.props;
		return <SortByHeader className={className} field={field}>{headers[field]}</SortByHeader>;
	}
}

class PortfolioAssetTable extends Component {
	state = { sortedHoldings: null };

	constructor(props) {
		super(props);
		this.updateSortedHoldings = this.updateSortedHoldings.bind(this);
		this.getCSV = this.getCSV.bind(this);
	}

	componentDidMount() {
		this.updateSortedHoldings();
		this.props.setGetCSVFunc(this.getCSV);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.fund !== this.props.fund || prevProps.sortParams !== this.props.sortParams) {
			this.updateSortedHoldings();
			this.props.setGetCSVFunc(this.getCSV);
		}
	}

	updateSortedHoldings() {
		const { fund, sortParams, glIsLoading, gainsAndLossesMap } = this.props;
		if (fund && fund.holdings && fund.holdings.length > 0) {
			let sortedHoldings = fund.holdings;
			if (sortParams) {
				const compareFunction = compareFunctions(sortParams, glIsLoading ? null : gainsAndLossesMap);
				if (compareFunction) {
					sortedHoldings = fund.holdings.slice().sort(compareFunction);
				}
			}
			this.setState({ sortedHoldings });
		}
	}

	getTotalColumn(assetClass, column) {
        let total = null
        function roundToTwo(num) {
            return +(Math.round(num + "e+2") + "e-2");
        }
        let noRoundedPercentage = assetClass.reduce((accumulator, { percentPortfolio }) => accumulator + percentPortfolio, 0)
        let roundedValue = assetClass.map(asset => roundToTwo(asset.value)) 
        let roundedCost = assetClass.map(asset => {let a = asset.assetCost; return +(a.toFixed(2))}) 

        switch (column) {
            case "percentPortfolio":
                total = roundToTwo(noRoundedPercentage)
                break;
            case "value":
                total = roundedValue.reduce((previusValue, currentValue ) => previusValue + currentValue, 0)
                break;
            case "assetCost":
                total = roundedCost.reduce((previusValue,  currentValue ) => previusValue + currentValue, 0)
                break;
            default:
                break;
        }
        return total
    }

	getCSV() {
		const { fund, filters, glIsLoading, gainsAndLossesMap } = this.props;
		const { gainOrLoss } = filters;
		const { sortedHoldings } = this.state;
		const fieldsInOrder = gainOrLoss
			? [
					fields.asset,
					fields.tickerCode,
					fields.localCost,
					fields.localCurrency,
					fields.nzdCost,
					fields.units,
					fields.currentUnitPrice,
					fields.localValue,
					fields.nzdValue,
					fields.percentOfPortfolio,
					fields.priceGL,
					fields.fxGL,
					fields.unrealisedGL,
					fields.glPerc,
			  ]
			: [
				fields.asset,
				fields.tickerCode,
				fields.localCost,
				fields.localCurrency,
				fields.nzdCost,
				fields.units,
				fields.currentUnitPrice,
				fields.localValue,
				fields.nzdValue,
				fields.percentOfPortfolio,
				fields.glPerc,
			];
		const table = [fieldsInOrder.map((field) => headers[field])].concat(sortedHoldings.map((asset) => fieldsInOrder.map((field) => `"${display[field](asset, gainsAndLossesMap, glIsLoading)}"`)));
		return `${fund.name}\n${table.map((row) => row.join(",")).join("\n")}`;
	}

	render() {
		const { sortedHoldings } = this.state;
		const { fund, filters, fixedInterest, changeRoute, account, glIsLoading, gainsAndLossesMap } = this.props;
		if (!filters) {
			return (
				<Col xs="12">
					<Loading size={80} centered />
				</Col>
			);
		}
		const { gainOrLoss } = filters;
		return (
			<CardContainer className="portfolio-asset-table-card" xs="12">
				<Card>
						<CardHeader>
							<h3><a className="in-page-anchor" name={`class-${fund.name}`}></a>
								{fund.name} {fixedInterest && <Assessment onClick={() => changeRoute(getRoute(account, LINK_ITEMS().ANALYTICS, LINK_ITEMS().ANALYTICS.FIXED_INTEREST))} />}
							</h3>
						</CardHeader>
						{sortedHoldings && sortedHoldings && sortedHoldings.length > 0 ? (
							<div className="portfolio-table-container">
								<table>
									<thead>
										<tr>
											{/* {gainOrLoss && <th className="d-none d-sm-table-cell">{glIsLoading ? <Loading size={20} /> : <Header field={fields.date} />}</th>} */}
											<th className="">
												<Header field={fields.asset} />
											</th>
											<th className="">
												<Header field="" />
											</th>
											<th className="d-none d-lg-table-cell header-right">
												<Header field={fields.localCost} className="justify-flex-end" />
											</th>
											<th className="d-none d-lg-table-cell text-left">
												<Header field={fields.localCurrency} />
											</th>
											<th className="d-none d-md-table-cell header-right">
												<Header field={fields.nzdCost} className="justify-flex-end" />
											</th>
											<th className="d-none d-md-table-cell header-right">
												<Header field={fields.units} className="justify-flex-end" />
											</th>
											<th className="d-none d-lg-table-cell header-right">
												<Header field={fields.currentUnitPrice} />
											</th>
											<th id="portfolio-local-value" className="d-none d-sm-table-cell header-right">
												<Header field={fields.localValue} />
											</th>
											<th id="portfolio-current-nzd-value" className="header-right">
												<Header field={fields.nzdValue} />
											</th>
											<th id="portfolio-holdings" className="d-none d-sm-table-cell header-right">
												<Header field={fields.percentOfPortfolio} />
											</th>
											{gainOrLoss && <th className="d-none d-md-table-cell header-right">{glIsLoading ? <Loading size={20} /> : <Header field={fields.priceGL} />}</th>}
											{gainOrLoss && <th className="d-none d-md-table-cell header-right">{glIsLoading ? <Loading size={20} /> : <Header field={fields.fxGL} />}</th>}
											{gainOrLoss && <th className="d-none d-md-table-cell header-right">{glIsLoading ? <Loading size={20} /> : <Header field={fields.unrealisedGL} />}</th>}
											{gainOrLoss && <th className="header-right">{glIsLoading ? <Loading size={20} /> : <Header field={fields.glPerc} />}</th>}
										</tr>
									</thead>
									<tbody>
										{sortedHoldings.map((asset, index) => (
											<tr key={index}>
												{/* {gainOrLoss && <td className="d-none d-sm-table-cell">{display[fields.date](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>} */}
												<td>
													<Token small>{display[fields.tickerCode](asset, gainsAndLossesMap, glIsLoading) || "-"}</Token>
												</td>
												<td>
													{display[fields.asset](asset, gainsAndLossesMap, glIsLoading) || "-"}
												</td>
												<td className="d-none d-lg-table-cell text-right">{display[fields.localCost](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												<td className="d-none d-lg-table-cell text-left">{display[fields.localCurrency](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												<td className="d-none d-md-table-cell text-right">{display[fields.nzdCost](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												<td className="text-right d-none d-md-table-cell">{display[fields.units](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												<td className="d-none d-lg-table-cell text-right">{display[fields.currentUnitPrice](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												<td className="d-none d-sm-table-cell text-right">{display[fields.localValue](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												<td className="text-right">{display[fields.nzdValue](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												<td className="d-none d-sm-table-cell text-right">{display[fields.percentOfPortfolio](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>
												{gainOrLoss && <td className="d-none d-md-table-cell text-right">{display[fields.priceGL](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>}
												{gainOrLoss && <td className="d-none d-md-table-cell text-right">{display[fields.fxGL](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>}
												{gainOrLoss && <td className="d-none d-md-table-cell text-right">{display[fields.unrealisedGL](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>}
												{gainOrLoss && <td className="text-right">{display[fields.glPerc](asset, gainsAndLossesMap, glIsLoading) || "-"}</td>}
											</tr>
										))}
										<tr>
											<td>TOTALS</td>
											<td></td>
											<td className="d-none d-lg-table-cell text-right"></td>
											<td className="d-none d-lg-table-cell text-right"></td> 
											<td className="d-none d-md-table-cell text-right"><NumberFormat places={2} value={this.getTotalColumn(sortedHoldings, "assetCost")} /></td>
											<td className="d-none d-md-table-cell text-right"></td>
											<td className="d-none d-lg-table-cell text-right"></td>
											<td className="d-none d-sm-table-cell text-right"></td>
											<td className="text-right"><NumberFormat places={2} value={this.getTotalColumn(sortedHoldings, "value")} /></td>
											<td className="d-none d-sm-table-cell text-right">{this.getTotalColumn(sortedHoldings, "percentPortfolio")}%</td>
											{gainOrLoss && <td className="d-none d-sm-table-cell text-right"></td>}
											{gainOrLoss && <td className="d-none d-md-table-cell text-right"></td>}
											{gainOrLoss && <td className="d-none d-md-table-cell text-right"></td>}
											{gainOrLoss && <td className="d-none d-md-table-cell text-right"></td>}
										</tr>
									</tbody>
								</table>
							</div>
						) : (
							<ErrorMessage infoIcon>There are no assets under this fund...</ErrorMessage>
						)}
				</Card>
			</CardContainer>
		);
	}
}

export default withAccountInfo(withRouteChange(withFilters(withSortParams(PortfolioAssetTable))));
