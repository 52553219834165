import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { withRemote, withI18n } from '@threeskye/global';
import LoadingIcon from "@threeskye/loading-icon";

// import './TokenField.scss';
// import './LocationField.scss';

// const MapComponent = React.lazy(() => import('./MapComponent'));

class AddyLocationField extends Component {
	
	constructor(props){
		super(props)
		this.state = {
			isEmpty:true,
			locatable: this.props.value && this.props.value.locatable,
			optionsLoading: false,
			expanded: false,
			expandedLoading: false,
			refs: {
				"dropdown": React.createRef()
			}
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.sessionUUID = null;
		this.toggleExpanded = this.toggleExpanded.bind(this);
		this.doGeocodeLookup = this.doGeocodeLookup.bind(this);
		this.addManualAddress = this.addManualAddress.bind(this);
	}
	//Overwriting this behavior, keeps the menu closed when the user has erased text
	//The default behavior of this will leave all of the options visible if a user does this
	handleInputChange(input,actionInfo) {
		switch(actionInfo.action) {
			case "menu-close":
				this.setState({
					locatable: false,
					expanded: false,
					isEmpty: true,
				});
				break;
			default:
				break;
		}
		// string, action required One of "set-value","input-change","input-blur","menu-close"
		if(input.length < 1){
			this.setState({
				isEmpty:true
			});
		}else{
			this.setState({
				isEmpty:false,
			});
		}
	}

	setTextValue(value) {
		var dropdown = this.state.refs.dropdown.current;
		if (dropdown && dropdown.props.value instanceof Object) {
			dropdown.props.value.text = value;
		}
	}

	onChange (value, { action, removedValue }) {
		switch (action) {
			case 'select-option':
				this.setState({
					locatable: value.locatable
				});
				// if (value.locatable) {
				// 	this.props.remote.post("/modules/crm/geocode/autocomplete/location", value)
				// 		.then(result => {
				// 			this.props.onUpdate(result);
				// 		});
				// }
				break;
			case 'clear':
				this.setTextValue("");
				this.setState({
					locatable: false,
					expanded: false
				});
				break;
			default:
				break;
		}
		// console.log("in addy component: ",value)
		this.props.onUpdate(value);
	}

	findMatches(text) {
		if (text.length < 4) {
			return Promise.resolve([]);
		}

		this.setState({optionsLoading: true})
		let remote = this.props.publicRoute ? this.props.remote.get("/public/modules/hobson-support/stockfox/geocode/autocomplete/simple?q=" + text) : this.props.remote.get("/modules/crm/geocode/autocomplete/simple?q=" + text);

		return remote.then(result=>{
//			this.sessionUUID = result.uuid;
			this.setState({optionsLoading: false});
			return result;
		});
	}

	renderSuggestion(suggestion) {
		return <span className="suggestion-text">{suggestion.text}</span>;
	}

	renderValue(suggestion) {
		return suggestion.text;
	}

	renderCreateLabel(text) {
		return <span className="create-label">Use <span className="create-label-text">{text}</span></span>
	}

	createSuggestion(text, label) {
		return {
			text: text,
			source: "new",
			locatable: true
		};
	}

	toggleExpanded() {
		if (!this.props.value.locatable)
			return;
		
		if (!this.state.expanded) {
			if (!this.props.value.location) {
				this.doGeocodeLookup();
				this.setState({expanded: true, expandedLoading: true});
			} else {
				this.setState({expanded: true});
			}
		} else {
			this.setState({expanded: false});
		}
	}

	doGeocodeLookup() {
		this.props.remote.post("/modules/crm/geocode/autocomplete/geocode", this.props.value)
			.then(location=>{
				Object.assign(this.props.value, location);
				this.setState({expandedLoading: false});
			});
	}

	locationLocation(location) {
		let results = [];
		results.push({
			latitude: location.location.latitude,
			longitude: location.location.longitude,
			popup: location.text
		});
		return results;
	}

	addManualAddress() {
		// this can only show when adding or when the address label is already "Physical"
		if (this.props.manualOverrideEnabled) {
			return <div className="create-suggestion" onClick={this.props.onManualOverride}>
				Add new Physical Address +
			</div>;
		}
		return "No options";

	}

	render() {
		const components = (this.state.locatable && !(this.props.hideLocatable))? {
			DropdownIndicator: () => <i className="material-icons location-field-locator" onClick={this.toggleExpanded}>place</i>
		} : {
			DropdownIndicator: () => null,
			IndicatorSeparator: () => null
		}
		let i = this.props.i18n.get;
		// let mapData = [ this.props.value ];
		return (
			<div style={this.props.style}>
				<AsyncSelect ref={this.state.refs.dropdown}
					{...this.props}
					styles={{
						multiValueRemove: (base,state)=>{return this.props.isRemovable(state.data) ? base : { ...base, display: "none"}}
					}}
					clearable={true}
					menuIsOpen={!this.state.isEmpty}
					onInputChange={this.handleInputChange}
					//Overriding the default format of the select
					//These options disable clicking for dropdown,
					//and disables the dropdown indicator.
					openMenuOnClick={false}
					blurInputOnSelect={true}
					components={components}
					allowCreateWhileLoading
					isLoading={this.state.optionsLoading}
					isClearable={this.props.isClearable}
					formatCreateLabel={this.renderCreateLabel}
					getNewOptionData={this.createSuggestion}
					loadOptions={this.findMatches.bind(this)}
					getOptionLabel={this.renderSuggestion}
					getOptionValue={this.renderValue}
					createOptionPosition="first"
					placeholder={this.props.placeholder||""}
					value={this.props.value}
					onChange={this.onChange}
					className={`token-field ${this.props.className && this.props.className}`} 
					classNamePrefix="token-field"
					tabSelectsValue={false}
					noOptionsMessage={this.addManualAddress}
				/>
				{/* { this.state.expanded ? <div className="location-expanded">
					{ this.state.expandedLoading ?  */}
					{/* <div className="location-expanded-loading">
						<LoadingIcon size={30} centered/>
					</div>  */}
					{/* : <React.Fragment>
						<div className="event-modal-field">
							<label htmlFor="event-people">{i("Address")}</label>
							<input id={this.props.id} readOnly={true} value={this.props.value.fullAddress} />
						</div>
						<React.Suspense fallback={<LoadingIcon size={50} centered />}>
							<MapComponent data={mapData} locate={this.locationLocation} classNamePrefix="location" />
						</React.Suspense>
					</React.Fragment> } */}
				{/* </div>
				 : ""} */}
			</div>
		);
	}
}

export default withI18n(withRemote(AddyLocationField));
