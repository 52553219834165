import React, { Component } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import "./AccountPageFilterCard.scss";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import AccountPageFilters from "./AccountPageFilters";

class AccountPageFilterCard extends Component {
	forwardRef = React.createRef();

	componentDidMount() {
		this.props.storage.put("account-page-filters-ref", this.forwardRef);
	}

	render() {
		const { onExport } = this.props;
		return (
			<CardContainer xs={12}>
				<Card className="account-page-filter-card" forwardRef={this.forwardRef}>
					<CardContainer xs={12}>
						<AccountPageFilters onExport={onExport} />
					</CardContainer>
				</Card>
			</CardContainer>
		);
	}
}

export default withThreeSkyeGlobal(withAccountInfo(AccountPageFilterCard));
