import React, { Component } from "react";
import withSortParams from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import { KeyboardArrowDown } from "@material-ui/icons";
import CommonFunctions from "../functions/CommonFunctions";
import "./SortByHeader.scss";

class SortByHeader extends Component {
	render() {
		const { sortParams, field, children, toggleSort, className } = this.props;
		const { asClassName } = CommonFunctions;
		const isSorting = !!sortParams && !!sortParams.field && sortParams.field === field;
		return (
			<div className={asClassName(["sort-header", isSorting && "sorting-active", className])} onClick={() => toggleSort(field)}>
				<div className="header-text-wrapper">{children}</div>
				<KeyboardArrowDown className={(isSorting ? "slide-in-icon" : "slide-out-icon") + (isSorting && sortParams.isDescending ? " sort-descending" : " sort-ascending")} />
			</div>
		);
	}
}

export default withSortParams(SortByHeader);
