import React, { Component } from "react";
import ContentToggler from "../../../../core-components/filters-and-toggles/ContentToggler";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import { Row, Col } from "reactstrap";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import MobileCardDropdown from "../../../../core-components/filters-and-toggles/MobileCardDropdown";
import ErrorMessage from "../../../../core-components/layouts/ErrorMessage";
import IncomeBarChart from "./Charts/IncomeBarChart";
import MaturityBarChart from "./Charts/MaturityBarChart";
import CreditRatingBarChart from "./Charts/CreditRatingBarChart";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";
import MonthYearFilter from "./Charts/MonthYearFilter";
import { FIXED_INTEREST_TYPES, ALL_FIXED_INTEREST_TYPES, INITIAL_TIME_FRAME } from "./FixedInterest";

class FixedInterestPortfolioCard extends Component {
	CHARTS_COMPONENTS = {
		[FIXED_INTEREST_TYPES.INCOME]: { Chart: IncomeBarChart, TimeSelect: MonthYearFilter },
		[FIXED_INTEREST_TYPES.MATURITY]: { Chart: MaturityBarChart, TimeSelect: MonthYearFilter },
		[FIXED_INTEREST_TYPES.CREDIT_RATING]: { Chart: CreditRatingBarChart },
	};

	render() {
		const { data, setFilters } = this.props;
		const { selectedFixedInterestType } = this.props.filters;
		const { Chart, TimeSelect } = this.CHARTS_COMPONENTS[selectedFixedInterestType];
		return (
			<CardContainer xs={12}>
				<Card>
					<CardHeader>
						<div className="w-100 d-flex justify-content-between">
							<h3>Fixed Interest Portfolio</h3>
							<Desktop>
								<ContentToggler
									options={ALL_FIXED_INTEREST_TYPES}
									activeOption={selectedFixedInterestType}
									select={(selectedFixedInterestType) => setFilters({ selectedFixedInterestType, selectedTimeFrame: INITIAL_TIME_FRAME })}
								/>
							</Desktop>
						</div>
					</CardHeader>
					<Mobile>
						<Row>
							<Col xs="12" md="4" style={{marginBottom: 15}}>
								<MobileCardDropdown
									options={ALL_FIXED_INTEREST_TYPES}
									value={selectedFixedInterestType}
									onSelect={(selectedFixedInterestType) => setFilters({ selectedFixedInterestType, selectedTimeFrame: INITIAL_TIME_FRAME })}
								/>
							</Col>
						</Row>
					</Mobile>
					{TimeSelect && <TimeSelect />}
					{Chart ? <Chart data={data} /> : <ErrorMessage infoIcon>Could not create a chart for this account...</ErrorMessage>}
				</Card>
			</CardContainer>
		);
	}
}

export default withFilters(FixedInterestPortfolioCard);
