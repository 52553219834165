import React, { Component } from 'react';
import "./ToolTip.scss";

class ToolTip extends Component {
	render() { 
		const { className, title, children, disabled, left, right, bottom, style, leftAligned, small, medium, mobileOnly, outerStyle } = this.props;
		let classes = "tooltip-container";
		if (className) {
			classes += " " + className;
		}
		if (left) {
			classes += " left";
		}
		if (right) {
			classes += " right";
		}
		if (bottom) {
			classes += " bottom";
		}
		if (leftAligned) {
			classes += " left-aligned";
		}
		if (small) {
			classes += " small";
		}
		if (mobileOnly) {
			classes += " mobile-only";
		}
		if (medium) {
			classes += " medium";
		}
		if (!disabled) {
			classes += " active";
		}

		return ( 
			<div className={classes} style={outerStyle}>
				{children}
				<div className="portal-tooltip-popup" style={style}>
					{title}
				</div>
			</div>
		 );
	}
}

export default ToolTip;