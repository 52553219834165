import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { format } from 'date-fns'
import Tooltip from '../../../../core-components/popups/Tooltip';
import "./ReviewNameToken.scss";

const ReviewNameToken = (props) => {
	const { name, required, signed, date } = props;

	return (
		<Tooltip label={required ? signed ? format((new Date(date)), "dd/MM/yyyy, h:mmaa") : "Signature required" : "Signature not required"}>
			<div className={`review-name-token${required ? ' token-required' : ''}`}>
				{name}
				{required && signed && <CheckCircle className="signed-icon"/>}
			</div>
		</Tooltip>
	);
}

export default ReviewNameToken;