import React, { useEffect, useState } from "react";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import AddyLocationField from '../../core-components/inputs/AddyLocationField'
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import './Onboarding.scss'

const Property = (props) => {
    const { onBoardingState, setOnBoardingState, handleNumberChange, section } = props
    const { propertiesOwned } = onBoardingState
    const label = section.displayLabel ? section.label : null
    const subLabel = section.displaySubLabel ? section.subLabel : null

    const [lastProperty, setLastProperty] = useState("")

    function onNzLocationChange(e) {
        if (e == null) return;
        const { text } = e;
        setLastProperty(text)
    }

    function removeProperty(index) {
        const updatedProperties = propertiesOwned.filter((p, idx) => idx !== index)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, propertiesOwned: updatedProperties } })
    }

    function updatePropertyValue(e, index) {
        const formattedValue = handleNumberChange(e)

        const updatedProperties = propertiesOwned.map((p, idx) => {
            if (idx === index) {
                const newObject = { propertyAddress: p.propertyAddress, propertyValue: formattedValue }
                return newObject
            } else return p
        })
        setOnBoardingState({ onBoardingState: { ...onBoardingState, propertiesOwned: updatedProperties } })
    }

    useEffect(() => {
        if (lastProperty) {
            const allProperties = propertiesOwned && propertiesOwned.length > 0 ? [...propertiesOwned, { propertyAddress: lastProperty, propertyValue: null }] : [{ propertyAddress: lastProperty, propertyValue: null }]
            setOnBoardingState({ onBoardingState: { ...onBoardingState, propertiesOwned: allProperties } })
            setLastProperty("")
        }
    }, [lastProperty])


    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel && <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div style={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}>Address</div>
                        <AddyLocationField
                            value={{ text: lastProperty, fullAddress: lastProperty }}
                            name={"physical-address"}
                            placeholder="Search an Address"
                            onUpdate={(e) => onNzLocationChange(e)}
                            isClearable
                            className="onbording-address-field"
                            publicRoute
                        />
                    </label>
                </Col>
                {propertiesOwned && propertiesOwned.length > 0 && propertiesOwned.map((p, idx) => {
                    return (
                        <Col xs="12" xl="12" className="pl-xl-3" key={idx}>
                            <div className="property-owned-card" >
                                <div className="property-owned-card-title">
                                    <h5>{p.propertyAddress}</h5>
                                    <i className="material-icons delete-icon" onClick={() => removeProperty(idx)}>delete_forever</i>
                                </div>
                                <div className="property-owned-card-value">
                                    <p>Estimated Value: </p>
                                    <input
                                        value={p.propertyValue || ""}
                                        placeholder="$0" name={"property-owned-value-" + idx}
                                        onChange={(e) => updatePropertyValue(e, idx)}
                                        autoFocus={p.propertyValue === null}
                                    />
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </>

    )
}

export default Property