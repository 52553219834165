import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FlexDiv } from "../layouts/FlexDiv";
import CommonFunctions from "../../core-components/functions/CommonFunctions";
import "./TimeFrameSelector.scss";

const TimeFrameButton = styled.button``;

class TimeFrameSelector extends Component {
	render() {
		let className = CommonFunctions.responsiveClassname();

		return (
			<FlexDiv id="time-frame-selector" className={className}>
				{this.props.timeFrames.map((timeFrameItem, index) => {
					return (
						<TimeFrameButton key={index} className={index === this.props.selectedTimeFrameIndex ? "selected" : ""} onClick={() => this.props.setSelectedTimeFrameIndex(index)}>
							{timeFrameItem.label}
						</TimeFrameButton>
					);
				})}
			</FlexDiv>
		);
	}
}

TimeFrameSelector.propTypes = {
	timeFrames: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.number,
		}),
	).isRequired,
	selectedTimeFrameIndex: PropTypes.number.isRequired,
	setSelectedTimeFrameIndex: PropTypes.func.isRequired,
};

export default TimeFrameSelector;
