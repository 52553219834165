import React, { useState, useEffect } from "react";
import withRouteChange from "@threeskye/route-change";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import Banner from "../../Banner";
import PortalContentWrapper from "../../PortalContentWrapper";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import DefaultMobileBanner from "../../DefaultMobileBanner";
import ReviewDocsFilters from "./ReviewDocsFilters";
import ReviewDocsList from "./ReviewDocsList";
import BannerNavLinks from "../../../../core-components/layouts/BannerNavLinks";
import BannerInfoText, { BannerInfoHighlightText, BannerInfoMainText } from "../../../../core-components/misc/BannerInfoText";
import { displayPortalAccType, withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { FiltersWrapper } from "../../../../core-components/filters-and-toggles/withFilters";
import { LINK_ITEMS } from "../../CustomerPortalPage";


const ReviewDocsPage = (props) => {
	const [documents, setDocuments] = useState(null);
	const [originalDocumentsList, setOriginalDocumentsList] = useState(null)
	const [notifications, setNotifications] = useState(null)
	const { storage, account, user, portalAccountType } = props;

	console.log("documents", documents)


	useEffect(() => {
		storage.getOrFetch("/modules/crm/client-notifications").then((fetchNotifications) => {
			const reviewNotification = fetchNotifications && fetchNotifications.length > 0 && fetchNotifications.filter(ntf => {
				const draft = JSON.parse(ntf.content)
				if (draft && draft.clientPortalLink) return true
			})
			setNotifications(reviewNotification)
		})

		account && user && storage.getOrFetch(`/modules/crm/account/${account.id}/client/${user.id}/reviews/published`).then(fetchedReviews => {
			console.log("fetchedReviews", fetchedReviews)

			const reviewsWithExtraData = fetchedReviews && fetchedReviews.length > 0 && fetchedReviews.map(rev => {
				const extraDataRev = {
					...rev,
					filename: `Performance Review`,
					signaturesRequired: account.holders.length,
					accountNumber: account.number,
				}
				return extraDataRev
			})
			const sortedReviews = reviewsWithExtraData && reviewsWithExtraData.length > 0 && reviewsWithExtraData.sort((a, b) => b.lastUpdateTime.localeCompare(a.lastUpdateTime))  // in reverse order 
			setDocuments(sortedReviews)
			setOriginalDocumentsList(sortedReviews)
		})
	}, [])


	return (
		<>
			<Mobile>
				<DefaultMobileBanner />
			</Mobile>
			<Desktop>
				<Banner>
					<BannerInfoText>
						<BannerInfoMainText>{account.name}</BannerInfoMainText>
						<BannerInfoHighlightText>{displayPortalAccType(portalAccountType)}</BannerInfoHighlightText>
					</BannerInfoText>
					<BannerNavLinks parent={LINK_ITEMS(notifications, account.number).DOCUMENTS} />
				</Banner>
			</Desktop>
			<PortalContentWrapper>
				<CardContainer>
					{/* Using dummy data for UI building */}
					<Card>
						<CardHeader>
							<h3>Reviews</h3>
						</CardHeader>
						<FiltersWrapper>
							<ReviewDocsFilters documents={documents} setDocuments={setDocuments} originalDocumentsList={originalDocumentsList} />
							<hr />
						</FiltersWrapper>
						{documents && documents.length > 0 ?
							<ReviewDocsList documents={documents} user={user} account={account} notifications={notifications}/> :
							<div>No reviews found</div>
						}
					</Card>
				</CardContainer>
			</PortalContentWrapper>
		</>
	);
}

export default withAccountInfo(withRouteChange((ReviewDocsPage)));