import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { DATE_FORMAT } from "@threeskye/core-components/dist/components/DateField/DateField";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";
import { PortalAccTypes, withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import ExportButton from "../../../../core-components/inputs/ExportButton";
import ToggleButtonField from "../../../../core-components/filters-and-toggles/ToggleButtonField";
import Loading from "../../../../core-components/layouts/Loading";
import HistoricDateFieldInput from "../../../../core-components/inputs/HistoricDateFieldInput";

class AccountPageFilters extends Component {
	constructor(props) {
		super(props);
		this.onDateFieldChange = this.onDateFieldChange.bind(this);
	}

	componentDidMount() {
		const { filters } = this.props;
		if (!filters) {
			this.props.setFilters({ date: moment().format(DATE_FORMAT), gainOrLoss: false });
		}
	}

	onDateFieldChange({ isValid, data }) {
		if (isValid) {
			const { filters, setFilters } = this.props;
			setFilters({ ...filters, date: data });
		}
	}

	render() {
		const { portalAccountType, filters, setFilters, onExport } = this.props;
		if (!filters) {
			return <Loading centered size={80} />;
		}
		return (
			<Row>
				<Col xs="auto" xl="3">
					<HistoricDateFieldInput key={filters.date} date={filters.date} onChange={this.onDateFieldChange} label="From Date" />
				</Col>
				{portalAccountType === PortalAccTypes.PORTFOLIO && (
					<Col xs="3">
						<ToggleButtonField
							checked={filters.gainOrLoss}
							toggleChecked={() => setFilters({ ...filters, gainOrLoss: !filters.gainOrLoss })}
							label="Additional"
							toggleLabel="Gain/Loss"
							id="gain-loss-filter"
						/>
					</Col>
				)}
				<Col xs="auto" xl={portalAccountType === PortalAccTypes.PORTFOLIO ? "6" : "9"} className="d-flex align-self-center justify-content-xl-end mb-2 mt-3 mt-xl-0">
					<ExportButton onClick={onExport} />
				</Col>
			</Row>
		);
	}
}

export default withThreeSkyeGlobal(withAccountInfo(withFilters(AccountPageFilters)));
