import React, { Component } from "react";
import CommonFunctions from "../functions/CommonFunctions";
import "./Cards.scss";

const desktopStyle = { background: "white", borderRadius: 12, boxShadow: "0px 3px 10px #00000016" };
const mobileStyle = { background: "white", borderRadius: 12, boxShadow: "0px 3px 10px #00000016" };

class CardNoPadding extends Component {
	render() {
		return (
			<div className="portal-card no-padding" style={CommonFunctions.isMobile() ? mobileStyle : desktopStyle}>
				<h3>{this.props.header}</h3>
				{this.props.children}
			</div>
		);
	}
}

export default CardNoPadding;