import React, { Component } from "react";
import Formatter from "../../../../../core-components/functions/Formatter";
import Token from "../../../../../core-components/misc/Token";
import ErrorMessage from "../../../../../core-components/layouts/ErrorMessage";
import CommonFunctions from "../../../../../core-components/functions/CommonFunctions";
import ToolTip from "../../../../../core-components/layouts/ToolTip";

class CashAssetPendingTransactions extends Component {
	render() {
		const { pendingCashTransactions } = this.props;
		const { isNullOrUndefined } = CommonFunctions;
		const { fromIsoDateToDisplay, numberWithCommas, asTransaction } = Formatter;
		return (
			<div className="expanded-row-content">
				<div className="expanded-row-header">
					<span className="tr-expanded-header">PENDING TRANSACTIONS</span>
				</div>
				{pendingCashTransactions && pendingCashTransactions.length > 0 ? (
					<div className="expanded-row-table-container">	
						<table className="expanded-row-table">
							<thead>
								<tr>
									<th>Date</th>
									<th>Asset</th>
									<th>Type/Activity</th>
									<th className="text-right">Quantity</th>
									<th className="text-right">Cost</th>
								</tr>
							</thead>
							<tbody>
								{pendingCashTransactions.map(({ date, asset, ticker, narrative, value, nativeCurrency, quantity }, key) => (
									<tr key={key}>
										<td>{date ? fromIsoDateToDisplay(date) : "-"}</td>
										<td>
											<ToolTip medium leftAligned title={asset} style={{ bottom: "120%" }}>
												<Token small>{ticker || nativeCurrency || "-"}</Token>
											</ToolTip>
										</td>
										<td>{narrative || "-"}</td>
										<td className="text-right">{isNullOrUndefined(quantity) ? "-" : numberWithCommas(quantity, 2)}</td>
										<td className="text-right">{isNullOrUndefined(value) ? "-" : asTransaction(value)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<ErrorMessage infoIcon>This asset does not have pending transactions...</ErrorMessage>
				)}
			</div>
		);
	}
}
 
export default CashAssetPendingTransactions;
