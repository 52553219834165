import React, { Component } from "react";
import CommonFunctions from "../functions/CommonFunctions";

const desktopStyle = { background: "white", borderRadius: 12, padding: "36px 40px", boxShadow: "0px 3px 10px #00000016" };
const mobileStyle = { background: "white", borderRadius: 12, padding: "16px 18px", boxShadow: "0px 3px 10px #00000016" };

class Card extends Component {
	render() {
		return (
			<div id={this.props.id} className={`portal-card${this.props.className ? " " + this.props.className : ""}`} style={CommonFunctions.isMobile() ? mobileStyle : desktopStyle} ref={this.props.forwardRef}>
				<div className="card-header-wrapper">{this.props.children.length > 1 && this.props.children[0]}</div>
				<div className="card-content-wrapper">{this.props.children.length > 1 ? this.props.children.slice(1) : this.props.children}</div>
			</div>
		);
	}
}

export default Card;
