import React, { useState } from 'react';
import { format } from 'date-fns'
import "./Input.scss";

const SingleDatePicker = (props) => {
    const { value, autoSubmit, placeholder, onBlur, autoFocus, disabled, inputClassName } = props
    const [date, setDate] = useState(value ? value : "")
    const [currentBackspace, setCurrentBackspace] = useState(false)

    const onKeyDown = (e) => {
        //onChange needs to know if we're deleting
        if (e.keyCode === 8) {
            setCurrentBackspace(true);
        } else {
            setCurrentBackspace(false)
        }

        if (e.key === "Enter") {
            props.onChange(e.target.value)
        }
    }

    const update = (e) => {
        let value = e.target.value;
        if (currentBackspace) {
            let current = date;
            if (current.lastIndexOf("/") === current.length - 1 && value === current.substring(0, current.length - 1)) {
                value = value.substring(0, value.length - 1);
            }
        } else {
            if (value.match(/^\d\d$/))
                value += "/";
            else if (value.match(/^\d\d\/\d\d$/))
                value += "/";
            else if (!value.match(/^\d\d\/\d\d\/\d{0,4}$/) && !value.match(/^\d$/) && !value.match(/^\d\d\/\d$/)) {
                // console.log("wrong value", !value.match(/^\d\d\/\d\d\/\d{0,4}$/) && !value.match(/^\d$/) && !value.match(/^\d\d\/\d$/))
                return;
            }
        }

        if (value.length === 10 && autoSubmit) {
            console.log("date value", value, value.length)
            props.onChange(e.target.value)
        }
        setDate(value)
    }

    let dateAsDate = new Date();

    if (date.match(/^\d\d\/\d\d\/\d{4}$/)) {
        dateAsDate = new Date(date.substring(6), date.substring(3, 5) - 1, date.substring(0, 2));
    }

    return (
        <input
            className={`single-date-picker-field ${inputClassName ? inputClassName : ""}`}
            value={date}
            onKeyDown={(e) => onKeyDown(e)}
            onChange={(e) => disabled ? null : update(e)}
            placeholder={date && date.length > 0 ? "" : placeholder}
            onBlur={onBlur}
            autoFocus={autoFocus}
        />
    )
}

export default SingleDatePicker;