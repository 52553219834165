import React, { useState, useEffect } from "react";
import TokenField from "./TokenField";
import CommonFunctions from '../functions/CommonFunctions'
import { withThreeSkyeGlobal } from "@threeskye/global";
import "./Input.scss";


const CountryTokenField = (props) => {

    const { remote, value, isMulti, onChange, label, publicRoute } = props
    const [countries, setCountries] = useState([])

    useEffect(() => {
        const path = publicRoute ? "/public/modules/hobson-support/stockfox/country-codes" : "/modules/crm/country-codes"
        remote.get(path).then(list => {
            setCountries(list)
        }).catch(() => setCountries([]))
    }, [])


    function search(searchText) {
        return Promise.resolve(countries.filter((country) => {
            return country.name.toLowerCase().startsWith(searchText.toLowerCase())
        }));
    }

    function getOne(countryOrCode, isSingle) {
        // console.log("get one", countryOrCode)
        const code = countryOrCode instanceof Object && countryOrCode.code ? countryOrCode.code : countryOrCode;

        if (isSingle) {
            const foundContry = countries && countries.length > 0 && countries.filter(c => c.code === code )
            return foundContry
        } else return code;
    }

    function getAll(countriesOrCodes) {
        if (arguments.length === 0) {
            return countries;
        }
        const result = [];
        if (countriesOrCodes) {
            // console.log("countriesOrCodes", countriesOrCodes)

            for (var i = 0; i < countriesOrCodes.length; i++) {
                var country = getOne(countriesOrCodes[i]);
                if (country) {
                    // console.log("getall country", country)
                    result.push(country);
                }
            }
            const foundContries = countries && countries.length > 0 && countries.filter(c => result.includes(c.code) )
            // console.log("foundContries", foundContries)
            return foundContries
        } else return []
    }

    function renderCountry(country) {
        return country.name;
    }

    function onUpdate(newValue) {
        // console.log("newValue", newValue)
        if (onChange) {
            if (Array.isArray(newValue)) {
                onChange(newValue.map(c => c.code));
            } else {
                onChange(newValue.code);
            }
        }
    }


    return (
        <div className="form-row">
            <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                <div className="label-container">
                    <span className={`form-label${props.onPrimary ? ' on-primary' : ''}`}>{label}</span>
                </div>

                {/* Needs form wrapper to disable chrome auto-fill */}
                <TokenField
                    isMulti={isMulti}
                    data={search}
                    display={renderCountry}
                    isRemovable={() => true}
                    value={isMulti ? getAll(value) : getOne(value, true)}
                    onUpdate={onUpdate}
                    isSuggestion={() => false}
                    placeholder={isMulti ? "Search a country" : "Select a Country"}
                    isSingle={!isMulti}
                />
            </label>
        </div>);
}

export default withThreeSkyeGlobal(CountryTokenField);
