import styled from 'styled-components';

// Modified components with className prop added
export const FlexDiv = styled.div`
  display: flex;
  &.${props => props.className} {
    /* Add any custom styles for the className here */
  }
`;

export const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &.${props => props.className} {
    /* Add any custom styles for the className here */
  }
`;
