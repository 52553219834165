import React, { Component } from "react";
import PropTypes from "prop-types";
import CommonFunctions from "../functions/CommonFunctions";
import "./Input.scss";
import FormErrorMessage from "../layouts/FormErrorMessage";

class Input extends Component {
	render() {
		let { label, name, type, value, placeholder, onChange, onKeyPress, disabled, onBlur, errorMessage, autoFocus, autoComplete, labelStyle, onFocus, inputStyle, inputClass, onClick, errorMessageDown, innerRef, iconEnd, extraClassName, id } = this.props;
		type = type || name;
		return (
			<label className={`portal-label ${CommonFunctions.responsiveClassname()}${extraClassName ? " " + extraClassName : ""}`}>
				<div className="label-container">
					{labelStyle ? <div style={labelStyle}>{label}</div> : <span className={`form-label${this.props.onPrimary ? ' on-primary' : ''}`}>{label}</span>}
					{!errorMessageDown && <span className="form-error"><FormErrorMessage>{errorMessage}</FormErrorMessage></span>}
				</div>
				<input
					autoFocus={autoFocus}
					name={name}
					type={type}
					value={value}
					placeholder={placeholder}
					onChange={userInput => onChange(userInput.target.value)}
					onKeyDown={onKeyPress}
					disabled={disabled}
					onBlur={onBlur}
					autoComplete={autoComplete}
					onFocus={onFocus}
					style={inputStyle}
					className={inputClass}
					onClick={onClick}
					ref={innerRef}
					id={id}
				/>
				{iconEnd && <div className="icon-inside-input">{iconEnd}</div>}
				{errorMessageDown &&
					<div className="label-container">
						<div style={{ width: "100%" }} className="form-error down-message"><FormErrorMessage>{errorMessage}</FormErrorMessage></div>
					</div>
				}
			</label>
		);
	}
}

Input.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
};

export default Input;
