import React, { Component } from "react";
import moment from "moment";
import withRouteChange from "@threeskye/route-change";
import withChartDurationSelected from "@threeskye/core-components/dist/components/Charts/withChartDurationSelected";
import { withAccountInfo } from '../../../../core-components/contexts/AccountsContext';
import { isBefore, isSameDay } from 'date-fns'

const withReviewValuation = function (WrappedComponent) {

    return withChartDurationSelected(
        withAccountInfo(
            withRouteChange(
                class extends Component {
                    timeFrames = [
                        { label: "1m", value: 31 },
                        { label: "3m", value: 93 },
                        { label: "6m", value: 186 },
                        { label: "1y", value: 365 },
                        { label: "2y", value: 731 },
                        { label: "5y", value: 1826 },
                        { label: "Max", value: 10000 },
                    ];

                    constructor(props) {
                        super(props);
                        this.state = {
                            selectedTimeFrameIndex: this.props.reviewPeriodSelected === 3 ? 1 : this.props.reviewPeriodSelected === 6 ? 2 : 3,
                            loadingValuations: true,
                            backend: "the backend",
                            hideContributions: false,
                            fullTimeFrameRetrieved: false
                        };
                        this.initialise = this.initialise.bind(this);
                        this.fetchValuations = this.fetchValuations.bind(this);
                        this.initialise()
                    }

                    initialise() {
                        this.fetchValuations()
                    }

                    componentDidUpdate(prevProps) {
                        if (!this.props.loadingAccount) {
                            if ((prevProps.account && prevProps.account.id) !== this.props.account.id) {
                                this.fetchValuations(this.props.startDate);
                            }

                            if ((prevProps.startDate && prevProps.startDate) !== this.props.startDate) {
                                this.fetchValuations(this.props.startDate);
                            }
                        }
                    }

                    // don't fetch data before cutoff date
                    fetchValuations(startDate) {
                        console.log("startDate", startDate)
                        const { account } = this.props;
                        const endDate = this.props.endDate || new Date().toISOString().slice(0, 10);
                        
                        this.setState({ loadingValuations: true });
                        let valuationEndpoint = `/modules/crm/accounts/${account.id}/valuation?endDate=${endDate}`
                        let contributionsEndpoint = `/modules/crm/accounts/${account.id}/capital-movements?endDate=${endDate}`
                        if (!!startDate) {
                            valuationEndpoint += `&cutoffDate=${startDate}`
                            contributionsEndpoint += `&cutoffDate=${startDate}`
                        }

                        Promise.all([
                            this.props.storage.getOrFetch(valuationEndpoint),
                            this.props.storage.getOrFetch(contributionsEndpoint),
                        ]).then(([valuation, contributionsResponse]) => {

                            let filteredContributions = {
                                ...contributionsResponse,
                                data: contributionsResponse.data.filter(c => isBefore(new Date(c.date), new Date(endDate)) || isSameDay(new Date(c.date), new Date(endDate)))
                            }
                            let filteredValuation = !valuation ? [] : valuation.filter(v => isBefore(new Date(v.date), new Date(endDate)) || isSameDay(new Date(v.date), new Date(endDate)))

                            let map = {};
                            if (filteredValuation && filteredValuation.length > 0) {
                                map = Object.assign(...filteredValuation.map(({ date, value }) => ({ [date]: { value } })));
                            }

                            if (this.state.hideContributions === false && filteredContributions && !filteredContributions.connectionError && filteredContributions.data && filteredContributions.data.length > 0) {
                                map = filteredContributions.data.reduce((vMap, { date, value }) => ({ ...vMap, [date]: { ...(vMap[date] || {}), value2: value } }), map);
                            }

                            filteredValuation = Object.keys(map)
                                .map((date) => ({ date: parseInt(moment(date).format("x")), ...map[date] }))
                                .sort((a, b) => a.date - b.date);

                            this.setState({ valuation: filteredValuation, loadingValuations: false });
                            this.props.durationSelected(this.timeFrames[this.state.selectedTimeFrameIndex].value, filteredValuation, false, this.state.selectedTimeFrameIndex);
                        });

                        this.props.remote.get(`/modules/crm/provider/accounts/${account.id}`).then(({ remoteSystemIds }) => {
                            if (remoteSystemIds.length > 0)
                                this.setState({ backend: remoteSystemIds[0].key });
                        });
                    }

                    setSelectedTimeFrameIndex(selectedTimeFrameIndex) {
						this.setState({ selectedTimeFrameIndex });
						this.props.durationSelected(this.timeFrames[selectedTimeFrameIndex].value, this.state.valuation, false, selectedTimeFrameIndex);
					}

                    render() {
                        const { loadingValuations, valuation } = this.state;
                        const { withDurationSelectedData } = this.props;
                        return (
                            <WrappedComponent
                                loadingValuations={loadingValuations}
                                valuation={valuation}
                                timeFrames={this.timeFrames}
                                hasValuations={valuation && valuation.length > 0 && Object.keys(withDurationSelectedData).length > 0}
                                chartProps={{ ...withDurationSelectedData, withBackground: true }}
                                {...this.props}
                            />
                        );
                    }
                }
            )
        )
    );
};

export default withReviewValuation;