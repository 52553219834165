import React from "react";
import CardHeader from '../../core-components/card/CardHeader';
import { Row, Col } from 'reactstrap';
import Input from '../../core-components/inputs/Input';
import DropDown from '../../core-components/inputs/DropDown'
import CommonFunctions from '../../core-components/functions/CommonFunctions'
import './Onboarding.scss'


const Kiwisaver = (props) => {
    const { onBoardingState, setOnBoardingState, handleNumberChange, section } = props
    const { kiwisaverProvider, kiwisaverValue } = onBoardingState
    const label = section.displayLabel ? section.label : null
    const subLabel = section.displaySubLabel ? section.subLabel : null
    const providers = [
        "AE KiwiSaver Limited", "AMP", "Anglican Financial Care", "ANZ Investments", "ASB Group Investments Ltd",
        "BNZ", "Booster Investment Management", "BT Funds (Westpac)", "Civic Financial Services", "Consilium NZ Limited",
        "Craigs Investment Partners Superannuation Management Limited", "Fisher Funds Management Ltd", "Fisher Funds Wealth Limited",
        "Generate Investment Management Limited", "Implemented Investment Solutions Limited", "Kernel Wealth Limited",
        "Koura Wealth Limited", "Lifestages", "Medical Funds Management Limited", "Mercer (NZ) Limited", "Milford Funds Limited",
        "New Zealand Funds Management Limited", "Nikko Asset Management New Zealand Limited", "Pathfinder Asset Management",
        "PIE KiwiSaver Scheme", "SBS Bank", "Sharesies Investment Management Limited", "Simplicity", "Smartshares Limited",
        "Summer"
    ]

    function updatePropertyValue(e) {
        const formattedValue = handleNumberChange(e)
        setOnBoardingState({ onBoardingState: { ...onBoardingState, kiwisaverValue: formattedValue } })
    }

    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel && <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>{subLabel}</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 115 }}>
                    <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
                        <div style={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}>Provider</div>
                        <DropDown clickable placeholder="Select a Kiwisaver provider" value={kiwisaverProvider} options={providers} updateValue={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, kiwisaverProvider: option } })} fullWidth />
                    </label>
                </Col>
                <Col xs="12" xl="12" className="pl-xl-3" style={{ height: 115 }}>
                    <Input
                        label="Value"
                        labelStyle={{ fontSize: 20, fontWeight: 700, paddingBottom: 15 }}
                        name="kiwisaverValue"
                        placeholder="$0"
                        value={kiwisaverValue || ""}
                        onChange={(e) => updatePropertyValue(e)}
                        autoFocus={kiwisaverValue.length === 0 && kiwisaverProvider.length > 0}
                    />
                </Col>
            </Row>
        </>

    )
}

export default Kiwisaver