import React, { useMemo, useCallback } from "react";
import CardHeader from "../../core-components/card/CardHeader";
import { Row } from "reactstrap";
import { Col } from "reactstrap";
import { Slate, Editable, withReact } from 'slate-react'
import { createEditor } from 'slate'
import VerticalSpacer from '../../core-components/layouts/VerticalSpacer'
import withRouteChange from "@threeskye/route-change";
import './TermsAndConditions.scss'

const TermsAndConditions = (props) => {
    const { checked, setChecked, section } = props
    const label = section && section.displayLabel ? 'Terms & Conditions' : null
    const subLabel = section && section.displaySubLabel ? section.subLabel : null
    const url = section.fields[1].value
    const href = url !== '' && url.includes('http://') ? url : 'http://' + url

    const editor = useMemo(() => {
        return withReact(createEditor())
    }, [])

    const renderElement = useCallback((props) => {
        const { element, attributes, children, data } = props
        const style = {
            textAlign: element.align,
            width: element.width,
            marginBottom: element.marginBottom,
            marginTop: element.marginTop,
            paddingTop: element.paddingTop,
            paddingLeft: element.paddingLeft,
            height: element.height, 
        }

        switch (element.type) {
            case 'heading-one':
                return <h3 style={{ ...style, marginBottom: 10 }} {...attributes}>{children}</h3>
            case 'heading-two':
                return <h4 style={{ ...style, marginBottom: 10 }} {...attributes}>{children}</h4>
            case 'paragraph':
                // console.log("CACA", children[0].props.parent.children)
                const highlight = children[0].props.parent.children[0].highlight
                console.log("highlight", highlight )
                return <p className={highlight ? 'yellow': ""} style={{ ...style, lineHeight: 2, marginBottom: 0 }} {...attributes}>{children}</p>
            case 'span':
                return <span className={props.element.class} style={{ ...style, lineHeight: 2, marginBottom: 0, backgroundColor: highlight ? 'yellow': null }} {...props.attributes}>{props.children}</span>
            case 'header':
                return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: '45px' }} {...props.attributes} {...data}>{props.children}</div>
            case 'list-item':
                return <li style={style} {...props.attributes}>{props.children}</li>
            case 'bulleted-list':
                return <ul className={props.element.class} style={{ ...style, lineHeight: 2, marginBottom: 0 }} {...props.attributes}>{props.children}</ul>
            case 'numbered-list':
                return <ol style={{ ...style, lineHeight: 2, marginBottom: 0, paddingLeft: 30 }} {...attributes}>{children}</ol>
            case 'div':
                return <div className={props.element.class} {...props.attributes}>{props.children}</div>
            case 'heading':
                return <h1 style={style} {...attributes} id={data?.id}>{children}</h1>
            case 'table':
                return <table style={style} className={element.class} {...attributes}>{children}</table>
            case 'table-row':
                return <tr {...attributes}>{children}</tr>
            case 'table-cell':
                return <td className={element.class} style={style} {...attributes}>{children}</td>
            case 'table-head':
                return <thead {...attributes}>{children}</thead>
            case 'table-body':
                return <tbody {...attributes}>{children}</tbody>
            case 'table-header':
                return <th {...attributes} style={style} >{children}</th>
            default:
                return <p style={style} {...attributes}>{children}</p>
        }
    }, [])

    return (
        <>
            {(label || subLabel) &&
                <CardHeader className="header-with-subheader">
                    {label && <h3 className="onbording-header">{label}</h3>}
                    {subLabel && <div style={{ fontSize: 20, fontWeight: 700, paddingTop: 10 }}>User Agreement and Conditions of Use</div>}
                </CardHeader>
            }
            <Row>
                <Col xs="12" xl="12" className="pl-xl-3" >
                    <Slate editor={editor} value={section.fields[0].value}>
                        <Editable renderElement={renderElement} readOnly={true}/>
                    </Slate>
                </Col>
                <VerticalSpacer size="20px" />
                <Col xs="12" xl="12" className="pl-xl-3" >
                    <div style={{ display: "flex", gap: 10 }}>
                        {checked ?
                            <i className="material-icons" style={{ color: "#0E8EFD", cursor: "pointer" }} onClick={() => setChecked(false)}>check_box</i> :
                            <i className="material-icons" style={{ color: "#0E8EFD", cursor: "pointer" }} onClick={() => setChecked(true)}>check_box_outline_blank</i>
                        }
                        <p>{section.fields[1].title + " "}
                            <span style={section.fields[1].value !== "" ? { fontWeight: 600, textDecoration: "underline" } : null}>
                                {section.fields[1].value !== "" ? <a target="_blank" href={href}>Terms & Conditions</a> : "Terms & Conditions"}
                            </span>
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default withRouteChange(TermsAndConditions)