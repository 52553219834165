import React, { Component } from 'react';
import CommonFunctions from '../functions/CommonFunctions';

class Mobile extends Component {
    render() { 
        if (!CommonFunctions.isMobile()) return null;
        return <>
            {this.props.children}
        </>;
    }
}
 
export default Mobile;