import { withRemote } from "@threeskye/global";
import React, { useState, useEffect } from "react";

const BankAccountEditableComponent = props => {
	const {update, data, remote, account} = props;

	const [bankUrl, setBankUrl] = useState("");

	const state = encodeURI(JSON.stringify({account:account.id}));

	useEffect(() => {
		remote.get(`/modules/crm/client/bank-verification-url?state=${state}`).then(setBankUrl);
	}, [])


	return <div>
		<p>Here be bank accounts</p>
		{data?.account?.bankAccounts?.map((acc,idx) => <p key={idx}>{JSON.stringify(acc)}</p>)}
		<a href={bankUrl?.url}>Add bank account</a>
		</div>
}

export default withRemote(BankAccountEditableComponent);