import React, { Component } from "react";
import Token from "./Token";
import "./MobileAccountBanner.scss";
import "./MobileAccountSelectBanner.scss";
import ExpandMore from "@material-ui/icons/ExpandMore";
import withRouteChange from "@threeskye/route-change";
import { withAccountInfo, getPortalAccType, displayPortalAccType } from "../contexts/AccountsContext";
import { Info } from "@material-ui/icons";
import ReactTooltip from 'react-tooltip';

class MobileAccountSelectBanner extends Component {
	state = { bannerActive: false };

	componentDidUpdate(){
		if (this.props.account && this.props.account.dynamicFields && this.props.account.dynamicFields.CustodyType && this.props.account.dynamicFields.CustodyType === "Safe-keeping"){
			ReactTooltip.rebuild()
		}
	}
	render() {
		const { account, accounts, switchAccount } = this.props;
		const { bannerActive } = this.state;

		// ERIC, I've just added this as a temp solution. Can you do it properly when you get a moment please :)
		if (bannerActive) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}

		return (
			<>
			<ReactTooltip id="safe-keeping-tooltip" place="top" type="light" effect="solid" globalEventOff='click' />
			<div className="mobile-account-select-outer">
				<div className={`mobile-account-select-inner${bannerActive ? " banner-active" : ""}${account && account.dynamicFields && account.dynamicFields.CustodyType && account.dynamicFields.CustodyType === "Safe-keeping" ? " banner-tall" : ""}`} onClick={() => this.setState({ bannerActive: !bannerActive })}>
					<div className="mobile-account-select-banner">
						<div className="mobile-account-select-option-wrapper">
							<span className="mobile-account-select-name ellipse">{account.name}</span>
							<Token small>{displayPortalAccType(getPortalAccType(account))}</Token>
							{account && account.dynamicFields && account.dynamicFields.CustodyType && account.dynamicFields.CustodyType === "Safe-keeping" &&
								<>
									<a
										className="safe-custody-a"
										data-for='safe-keeping-tooltip'
										data-tip="This a view of your Portfolio held with Hobson Wealth Custodian Limited"
										data-event='click focus'
										data-iscapture='true'
										onClick={(e) => {
											e.stopPropagation()
										}}
									>
										<Token small warning>
											<Info className="mobile-account-safe-custody-icon" />
										</Token>
									</a>
								</>
							}
						</div>
						<ExpandMore className="mobile-account-select-icon" />
					</div>
					<div className="mobile-account-banner-options">
						{accounts
							.filter((selectableAcc) => selectableAcc.id !== account.id)
							.map((account, idx) => (
								<div key={idx} className="account-option" onClick={() => switchAccount(account)}>
									{account.name} <Token small>{displayPortalAccType(getPortalAccType(account))}</Token>
								</div>
							))}
					</div>
				</div>
			</div>
			</>
		);
	}
}

export default withAccountInfo(withRouteChange(MobileAccountSelectBanner));
