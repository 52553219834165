import IrdValidator from "ird-nz";

export function displayIRDNumber(num) {
    return num || num === 0
        ? (+num.toFixed ? +num.toFixed(0) : num)
            .toString()
            .replace(/[^0-9]/g, "")
            .substring(0, 9)
            .replace(/\B(?=(\d{3})+(?!\d))/g, "-")
        : "";
}

export function irdValidation(num) {
    if (num === "") return true
    else return IrdValidator.isValid(num)
}

export const rwtOptions = [10.5, 17.5, 30, 33, 39]
export const pirOptions = [10.5, 17.5, 28]

export function onNzLocationChange(e, string, onBoardingState, setOnBoardingState) {
    if (e == null) return;
    const { text } = e;
    if (string === "physical") setOnBoardingState({ onBoardingState: { ...onBoardingState, physicalAddress: text } })
    else if (string === "portal") setOnBoardingState({ onBoardingState: { ...onBoardingState, postalAddress: text } })
}

export const getRoute = (linkItem) => linkItem.path;

export function PRELOGIN_LINK_ITEMS(steps) {
    const preloginRoutes = steps && steps.map((step, index) => {
        const path = index === 0 ? "/" : "/" + step.title.toLowerCase().split(" ").join("-")
        return { text: step.title, path: path.includes("&") ? path.replace("&", "and") : path, sections: step.sections }
    })
    return preloginRoutes
};

export function handleNumberChange(event, formatType) {
    let value = event.target ? event.target.value : event;
    value = value.replace(/[^0-9.,]/g, '');
    const numericInput = value.replace(/,/g, '');

    if (numericInput.length === 0) return '';
    if (numericInput === '.' || !numericInput.match(/[0-9]/)) return numericInput;

    if (formatType === "units") {
        if (numericInput.endsWith('.')) return numericInput;
        const numberValue = parseFloat(numericInput);
        const formattedValue = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(numberValue);
        return formattedValue;

    } else {
        // Allow dynamic decimal places while typing, but format properly once complete.
        if (numericInput.includes('.')) {
            const parts = numericInput.split('.');
            const decimalPlaces = parts[1].length > 10 ? 10 : parts[1].length;
            const numberValue = parseFloat(numericInput);
            const formattedValue = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces
            }).format(numberValue);

            if (parts[1].length === 0) {
                return formattedValue + "."
            } else return formattedValue;
        }

        const numberValue = parseFloat(numericInput);
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(numberValue);

        return formattedValue;
    }
}

  // function renderField(sectionIndex, field, fieldIndex) {
    //     const { type, title, value, placeholder, inputType, text } = field
    //     switch (type) {
    //         case 'input':
    //             if (placeholder === 'First Name') {
    //                 return <Input label="First Name*" name="first-name" value={firstName || onBoardingState.givenName || parsedData.givenName} placeholder="First Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, firstName: e } })} />
    //             } else if (placeholder === 'Middle Names') {
    //                 return <Input label="Middle Name" name="middle-name" value={middleNames} placeholder="Middle Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, middleNames: e } })} />
    //             } else if (placeholder === 'Last Name') {
    //                 return <Input label="Last Name*" name="last-name" value={lastName || onBoardingState.familyName || parsedData.familyName} placeholder="Last Name" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, lastName: e } })} />
    //             } else if (inputType === 'irdNumber') {
    //                 return <Input
    //                     onBlur={() => setOnIRDEdit(false)}
    //                     onFocus={() => setOnIRDEdit(true)}
    //                     inputStyle={{ letterSpacing: 1 }}
    //                     type={onIRDEdit ? "number" : "text"}
    //                     label="IRD Number*"
    //                     name="ird-number"
    //                     value={onIRDEdit ? irdNumber : displayIRDNumber(irdNumber)}
    //                     placeholder="IRD Number"
    //                     onChange={(e) => {
    //                         setOnBoardingState({ onBoardingState: { ...onBoardingState, irdNumber: e } })
    //                     }}
    //                     errorMessage={isValidIRD ? "" : "Please enter a valid IRD number"}
    //                     errorMessageDown
    //                     invalidState={!isValidIRD}
    //                     id="ird-number"
    //                 />
    //             } else if (inputType === 'wealthSource') {
    //                 return <Input label="Source of Wealth" name="wealth-source" value={wealthSource} placeholder="Source of wealth" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, wealthSource: e } })} errorMessageDown />
    //             } else if (inputType === 'fundsSource') {
    //                 return <Input label="Source of Funds" name="funds-source" value={fundsSource} placeholder="Source of funds" onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, fundsSource: e } })} errorMessageDown />
    //             }
    //         case 'email':
    //             return <Input
    //                 label={<><span className="form-label">Email*</span><i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 800 }}>check</i></>}
    //                 labelStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
    //                 type="email" name="email"
    //                 value={email || parsedData.email}
    //                 placeholder="Email"
    //                 onChange={() => null}
    //                 disabled
    //                 iconEnd={<i className="material-icons lock-icon">lock</i>}
    //             />
    //         case 'date':
    //             return <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
    //                 <div className="label-container">
    //                     <span className={`form-label`}>Date of Birth*</span>
    //                 </div>
    //                 <SingleDatePicker
    //                     value={onBoardingState.dateOfBirth}
    //                     placeholder={dateOfBirth && dateOfBirth.length > 0 ? "" : "31/01/1970"}
    //                     format="dd/MM/yyyy"
    //                     onChange={(newValue) => {
    //                         setInvalidDate(false)
    //                         const dateArray = newValue.split("/").reverse()
    //                         const dateString = dateArray.join("-")

    //                         const valid = isValid(parseISO(dateString))
    //                         if (valid) {
    //                             const today = new Date()
    //                             const date = new Date(dateString)
    //                             const formattedFromDate = format(date, "dd/MM/yyyy")
    //                             setOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: formattedFromDate } })
    //                             const ridiculousDate = isAfter(date, today)
    //                             if (ridiculousDate) {
    //                                 setDateErrorMessage("The date entered has not yet happened")
    //                                 setInvalidDate(true)
    //                             } else {
    //                                 setDateErrorMessage("")
    //                                 setInvalidDate(false)
    //                             }
    //                         } else {
    //                             setInvalidDate(true)
    //                             setDateErrorMessage("Invalid Date")
    //                             setOnBoardingState({ onBoardingState: { ...onBoardingState, dateOfBirth: null } })
    //                         }
    //                     }}
    //                     autoSubmit
    //                     noCalendar={true}
    //                     inputClassName={invalidDate ? "invalid-input" : ""}
    //                 />
    //                 <div className="label-container">
    //                     {invalidDate && <span className="form-error"><FormErrorMessage>{dateErrorMessage}</FormErrorMessage></span>}
    //                 </div>
    //             </label>
    //         case 'phone':
    //             return <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
    //                 <div className="label-container">
    //                     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
    //                         <span className={`form-label`}>Mobile*</span>
    //                         <i className="material-icons" style={{ color: '#00853D', marginBottom: 10, fontSize: 20, fontWeight: 900 }}>check</i>
    //                     </div>
    //                 </div>
    //                 <PhoneNumberInput
    //                     type="number"
    //                     name="phone-number"
    //                     placeholderIDD="IDD"
    //                     placeholderValue="Enter phone number"
    //                     invalidNumberMessage=""
    //                     noPrefixMessage=""
    //                     onChange={() => null}
    //                     value={mobile}
    //                     placeholderExt="Ext"
    //                     extraContainerClassNme="onbording-phone"
    //                     publicRoute
    //                     phoneErrorMessage=""
    //                     disabled
    //                     iconEnd={<i className="material-icons lock-icon">lock</i>}
    //                 />
    //             </label >
    //         case 'address':
    //             if (placeholder === 'Physical Address') {
    //                 return <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
    //                     <div className="label-container">
    //                         <span className={`form-label`}>Physical Address</span>
    //                     </div>
    //                     <AddyLocationField
    //                         value={{ text: physicalAddress, fullAddress: physicalAddress }}
    //                         name={"physical-address"}
    //                         placeholder={'Search an Address'}
    //                         onUpdate={(e) => onNzLocationChange(e, "physical", onBoardingState, setOnBoardingState)}
    //                         isClearable
    //                         className="onbording-address-field"
    //                         publicRoute
    //                     />
    //                 </label>
    //             } else {
    //                 return <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
    //                     <div className="label-container">
    //                         <span className={`form-label`}>Postal Address</span>
    //                     </div>
    //                     <AddyLocationField
    //                         value={{ text: postalAddress, fullAddress: postalAddress }}
    //                         name={"portal-address"}
    //                         placeholder={'Search an Address'}
    //                         onUpdate={(e) => onNzLocationChange(e, "portal", onBoardingState, setOnBoardingState)}
    //                         isClearable
    //                         className="onbording-address-field"
    //                         publicRoute
    //                     />
    //                 </label>
    //             }
    //         case 'radio':
    //             return <RadioInputGroup
    //                 groupLabel="Prescribed Person*"
    //                 className="radio-input-heading"
    //                 radioButtonClass="onbording-radio-button"
    //                 options={['Yes', 'No']}
    //                 value={isPrescribedPerson}
    //                 onChange={(e) => setOnBoardingState({ onBoardingState: { ...onBoardingState, isPrescribedPerson: e } })}
    //                 toggleable
    //                 clear={() => setOnBoardingState({ onBoardingState: { ...onBoardingState, isPrescribedPerson: null } })}
    //             />
    //         case 'singleCountry':
    //             return <CountryTokenField value={countryOfBirth} isMulti={false} publicRoute label={title} onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, countryOfBirth: option } })} />
    //         case 'multiCountry':
    //             return <CountryTokenField value={inputType === 'countriesOfCitizenship' ? countriesOfCitizenship : inputType === 'countriesOfResidence' ? countriesOfResidence : contriesOfTaxResidence} isMulti publicRoute label={placeholder} onChange={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, [inputType]: option } })} />
    //         case 'dropdown':
    //             if (title === 'RwtRate') {
    //                 return <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
    //                     <div className="label-container">
    //                         <span className={`form-label`}>RWT Rate</span>
    //                     </div>
    //                     <DropDown id="rwt-rate" openDropDown={openRWT} clickable placeholder="Select a Resident Withholding Tax rate" value={rwtRate} options={rwtOptions} updateValue={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, rwtRate: option } })} fullWidth />
    //                 </label>
    //             } else if (title === 'PirRate') {
    //                 return <label className={`portal-label ${CommonFunctions.responsiveClassname()}`}>
    //                     <div className="label-container">
    //                         <span className={`form-label`}>PIR</span>
    //                     </div>
    //                     <DropDown id="pir" openDropDown={openPIR} clickable placeholder="Select a Prescribed Investor Rate" value={pirRate} options={pirOptions} updateValue={(option) => setOnBoardingState({ onBoardingState: { ...onBoardingState, pirRate: option } })} fullWidth />
    //                 </label>
    //             }
    //         case 'textarea':
    //             return <textarea value={value} onChange={(e) => updateField(sectionIndex, fieldIndex, type, 'value', e.target.value)} />
    //         case 'checkbox':
    //             return (
    //                 <div style={{ display: 'flex', gap: 7, alignItems: 'center' }}>
    //                     <i className="material-icons" style={{ color: "rgba(255, 255, 255, 0.5)", opacity: 0.6 }} onClick={() => null}>check_box_outline_blank</i>
    //                     <input className='invisible-input' style={{ width: '66%' }} value={text} onChange={(e) => updateField(sectionIndex, fieldIndex, type, 'text', e.target.value)} />
    //                     <span style={{ fontWeight: 600, textDecoration: "underline", cursor: 'pointer' }} /*onClick={uploadPDF}*/>Terms & Conditions</span>
    //                 </div>
    //             )
    //         case 'sublabel':
    //             return (
    //                 <input value={value} className='invisible-input ' onChange={(e) => updateField(sectionIndex, fieldIndex, type, 'value', e.target.value)} />
    //             )
    //         default:
    //             break;
    //     }
    // }