import React, { Component } from 'react';
import "./HorizontalListItem.scss";

class HorizontalListItem extends Component {
	render() { 
		const { label, value } = this.props;
		return (
			<div className="horizontal-list-item">
				<div className="horizontal-list-item-label">{label}</div>
				<div className="horizontal-list-item-value">{value}</div>
			</div>
		);
	}
}
 
export default HorizontalListItem;