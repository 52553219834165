import React, { Component } from "react";
import PropTypes from "prop-types";

class HorizontalSpacer extends Component {
	render() {
		return <div style={{ width: this.props.size, height: "100%", display: "inline-block" }} />;
	}
}

HorizontalSpacer.propTypes = {
	size: PropTypes.string.isRequired,
};

export default HorizontalSpacer;
