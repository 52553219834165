import { Info } from "@material-ui/icons";
import React, { Component } from "react";
import HorizontalSpacer from "../../core-components/layouts/HorizontalSpacer";
import Tooltip from "../../core-components/popups/Tooltip";
import withRouteChange from "@threeskye/route-change/dist/RouteChange";

class LinkItem extends Component {
	render() {
		const { linkItem, account } = this.props;

		const onClickingInfoIcon = (e) => {
			if (account && linkItem.infoIcon && linkItem.infoIcon === "New Review Available") {
				e.preventDefault()
				this.props.changeRoute(`/${account.number}/my-documents/reviews`)
			} else return null
		}

		return (
			<>
				{linkItem.icon && <linkItem.icon />}
				<HorizontalSpacer size="10.4px" />
				{linkItem.text}
				{linkItem.infoIcon && <Tooltip className="ml-auto" label={linkItem.infoIcon}><Info className="link-item-alert-icon" onClick={(e) => onClickingInfoIcon(e)}/></Tooltip>}
			</>
		);
	}
}

export default withRouteChange(LinkItem);
