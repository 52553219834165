import React, { Component } from "react";
import { withThreeSkyeGlobal } from "@threeskye/global";
import withPeriodSelect from "@threeskye/core-components/dist/components/WealthTechReports/WithPeriodSelect";
import TextOptionGroup from "../../../../../core-components/filters-and-toggles/TextOptionGroup";
import DateRangeInput from "../../../../../core-components/inputs/DateRangeInput";
import GenerateButton from "../../../../../core-components/inputs/GenerateButton";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import DateFieldInput from "../../../../../core-components/inputs/DateFieldInput";
import { saveAs } from 'file-saver'

class OtherReportTableItem extends Component {
	state = { isGenerating: false };
	constructor(props) {
		super(props);
		this.generateReport = this.generateReport.bind(this);
	}

	generateReport() {
		const { getLink, from, to } = this.props;
		this.setState({ isGenerating: true });
		this.props.remote
			.get(getLink(from, to))
			.then((response) => {

				const { data } = response;
				const byteString = atob(data.base64);

				// create a view into the buffer
				const ab = new ArrayBuffer(byteString.length);
				const ia = new Uint8Array(ab);

				// set the bytes of the buffer to the correct values
				for (let i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}

				// write the ArrayBuffer to a blob, and you're done
				const blob = new Blob([ab], { type: "application/pdf" });

				saveAs(blob, `${data.filename}.${data.extension}`);
			})
			.finally(() => this.setState({ isGenerating: false }));
	}

	render() {
		const { isGenerating } = this.state;
		const { header, from, to, selectPeriod, onToChange, onFromChange, periodLabels, periodLabel, reactKey, isDateRange, isSingleDate } = this.props;
		return (
			<tr>
				<td>{header}</td>
				<td>
					{isDateRange &&
						<TextOptionGroup options={periodLabels} activeOption={periodLabel} select={selectPeriod} />
					}
				</td>
				<td>
					{/* KEY ensures date range gets remounted if you select by text option group */}
					{isDateRange &&
						<DateRangeInput key={reactKey} noLabels noMargins from={from} onFromChange={onFromChange} to={to} onToChange={onToChange} />
					}
					{isSingleDate &&
						<DateFieldInput className="or-single-date" key={reactKey} noLabels noMargins date={to} onChange={onToChange} />
					}
				</td>
				<td>
					<GenerateButton onClick={this.generateReport} generating={isGenerating} />
				</td>
			</tr>
		);
	}
}

export default withPeriodSelect(withThreeSkyeGlobal(withAccountInfo(OtherReportTableItem)));
