import React, { Component } from 'react';
import "./CardList.scss";

class CardList extends Component {
	state = {  }
	render() { 
		return ( 
			<div style={this.props.style} className="card-list-container hide-scroll-bar">
				<ul className="card-list">
					{this.props.list.map((li, idx) => (
						<li key={idx} className="card-list-item">{li}</li>
					))}
				</ul>
			</div>
		 );
	}
}

export default CardList;