import React, { Component } from "react";
import moment from "moment";
import AssetTableMobile from "../../../../../core-components/layouts/AssetTableMobile";
import Formatter from "../../../../../core-components/functions/Formatter";
import CommonFunctions from "../../../../../core-components/functions/CommonFunctions";
import RangeBar from "../../../../../core-components/graphs/RangeBar";
import ErrorMesssage from "../../../../../core-components/layouts/ErrorMessage";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import { DATE_FORMAT } from "@threeskye/core-components/dist/components/DateField/DateField";
import Loading from "../../../../../core-components/layouts/Loading";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import "./PortfolioAssetAllocationTable.scss"

class PortfolioAssetAllocationTable extends Component {
	state = { loading: true, allocations: null };

	constructor(props) {
		super(props);
		this.updateAssetGroups = this.updateAssetGroups.bind(this);
		this.getCSV = this.getCSV.bind(this);

		props.setGetCSVFunc(this.getCSV);
	}

	componentDidMount() {
		this.updateAssetGroups();
	}

	componentDidUpdate(prevProps) {
		if ((prevProps.filters && prevProps.filters.date) !== (this.props.filters && this.props.filters.date) || prevProps.account.id !== this.props.account.id) {
			this.updateAssetGroups();
		}
	}

	updateAssetGroups() {
		const { account, filters } = this.props;
		if (filters) {
			this.setState({ loading: true });
			const notToday = filters.date !== moment().format(DATE_FORMAT);
			Promise.all([
				this.props.storage.getOrFetch(`/modules/crm/classification-scheme/${account.classificationScheme.id}/model-data`),
				this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/model`),
				this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio${notToday ? `/${filters.date}` : ""}`)
					.then((remoteReliantDTO) => (remoteReliantDTO && !remoteReliantDTO.connectionError && remoteReliantDTO.data ? remoteReliantDTO.data : null)),
			]).then(([scheme, model, portfolio]) => {
				const portfolioValue = portfolio && portfolio.valuation;
				if (scheme && scheme.classes && model && model.success && model.data && model.data.categories) {
					const portfolioClasses = portfolio && portfolio.classes ? portfolio.classes : [];
					const allocations = model.data.categories
						.map((cat) => cat.allocations)
						.flat()
						.map(({ classId, min, max }) => {
							const name = scheme.classes.find((schemeClass) => schemeClass.id === classId).name;
							const { percentPortfolio, value } = portfolioClasses.find((portfolioClass) => portfolioClass.classId === classId) || { percentPortfolio: 0, value: 0 };
							if (name) {
								return { name, min, max, value, percentPortfolio };
							}
							return null;
						})
						.filter((allocation) => !!allocation);
					const maxMax = allocations.reduce((maxMax, { max, percentPortfolio }) => Math.max(maxMax, Math.max(max, percentPortfolio)), 0);
					this.setState({ allocations, maxMax });
				}
				this.setState({ loading: false, portfolioValue });
			});
		}
	}

	getCSV() {
		const { allocations, portfolioValue } = this.state;
		const table = [["Asset Allocation", "Value", "% of Portfolio"]]
			.concat((allocations && allocations.length > 0 ? allocations : []).map(({ name, value, percentPortfolio }) => [name, `"${Formatter.asMoney(value)}"`, `"${Formatter.asPercentage(percentPortfolio, 1)}"`]))
			.concat([["Total Portfolio Value", `"${Formatter.asMoney(portfolioValue)}"`]]);
		return table.map((row) => row.join(",")).join("\n");
	}

	render() {
		const { allocations, loading } = this.state;
		const isMobile = CommonFunctions.isMobile();

		if (loading) {
			return <Loading centered size={80} />;
		} else if (!allocations || allocations.length === 0) {
			return <ErrorMesssage infoIcon>There is no model or valuation for this account.</ErrorMesssage>;
		}
		return (
			<table id="portal-overview-asset-groups" className={CommonFunctions.responsiveClassname()}>
				{allocations.map(({ name, min, max, value, percentPortfolio }, index) =>
					isMobile ? (
						<AssetTableMobile key={index} {...{ name, min, max, value, percentPortfolio }} />
					) : (
						<tbody key={index} className={index === 0 ? "tbody--first" : ""}>
							<tr className="tr-asset-allocation" onClick={() => window.location.href = "#class-" + name}>
								<td className="td-name">{name}</td>
								<td className="td-value">{Formatter.asMoney(value)}</td>
								<td className="td-perc">{Formatter.asPercentage(percentPortfolio, 1)}</td>
								<td className="td-graphic">
									<RangeBar extent={100} value={percentPortfolio} min={min} max={max} />
								</td>
							</tr>
						</tbody>
					)
				)}
			</table>
		);
	}
}

export default withFilters(withAccountInfo(withThreeSkyeGlobal(PortfolioAssetAllocationTable)));
