import React, { Component } from "react";
import HobsonLogo from "../../../core-components/svgs/HobsonLogo";
import VerticalSpacer from "../../../core-components/layouts/VerticalSpacer";
import Logo from "../../../core-components/svgs/Logo";
import { withThreeSkyeGlobal } from "@threeskye/global";
import Desktop from "../../../core-components/layouts/Desktop";
import Mobile from "../../../core-components/layouts/Mobile";
import { withTheme } from "../../../core-components/contexts/ThemeContext";

class TopLeftLogoOnMobile extends Component {
	render() {
		const { children, theme } = this.props;
		return (
			<>
				<Mobile>
					<div className="logo-container top-left">{theme === "theme-hobson" ? <HobsonLogo width={100} /> : <Logo width={90} />}</div>
				</Mobile>
				<div className="logged-out-wrapper">
					<div className="logged-out-content">
						<Desktop>
							<div className="logo-container">{theme === "theme-hobson" ? <HobsonLogo width={120} /> : theme === "theme-3skye" && <Logo width={170} />}</div>
							<VerticalSpacer size="52px" />
						</Desktop>
						<div className="forms">{children}</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTheme(withThreeSkyeGlobal(TopLeftLogoOnMobile));
