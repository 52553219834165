import moment from "moment";
import Variables from "../styles/Variables.scss";

const CommonFunctions = {
	isMobile: () => window.matchMedia(`(max-width: calc(${Variables.xl} - 1px))`).matches,
	responsiveClassname: () => (CommonFunctions.isMobile() ? "mobile" : "desktop"),
	assets: {
		getAllAssets: (assetGroups) => Object.keys(assetGroups).reduce((currentGroup, key) => currentGroup.concat(assetGroups[key].assets), []),
		minAsset: (assets) => Math.min(...assets.map((asset) => asset.currentNzdValue)),
		maxAsset: (assets) => Math.max(...assets.map((asset) => asset.currentNzdValue)),
		getAssetsValue: (assets) => assets.reduce((prev, asset) => prev + asset.currentNzdValue, 0),
	},
	isEmailValid: (email) => {
		// from https://cs.chromium.org/chromium/src/third_party/blink/web_tests/fast/forms/resources/ValidityState-typeMismatch-email.js?q=ValidityState-typeMismatch-email.js&sq=package:chromium&dr;
		// bugging eslint - don't remove comment below.
		// eslint-disable-next-line
		var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(String(email).toLowerCase());
	},
	has2FA: (cookies, email) => {
		let username = email.replace("@", "#");
		const cookie = cookies.get("hwp.login." + username);
		return cookie;
	},
	getAccountFromNumber: (accounts, number) => {
		return accounts && accounts.find((account) => account.number === number);
	},
	range: (start, end, length = end - start + 1) => Array.from({ length }, (_, i) => start + i),
	getNumOfPages: (transactionHistory) => Math.ceil(transactionHistory.totalCount / transactionHistory.pageSize),
	asClassName: (list) => list.filter((cn) => !!cn).join(" ") || undefined,
	parseHasPrimarySingularPhone: (json) => {
		try {
			const hasPrimaryDTOs = JSON.parse(json);
			if (hasPrimaryDTOs && hasPrimaryDTOs.length > 0) {
				const content = hasPrimaryDTOs[0].content;
				return `+${content.idd} ${content.number}` + (content.extension ? ` x${content.extension}` : "");
			}
		} catch (e) {}
		return null;
	},
	isNullOrUndefined(value) {
		return value === null || value === undefined;
	},
	isNullOrUndefinedOrNaN(value) {
		return CommonFunctions.isNullOrUndefined(value) || isNaN(value);
	},
	parseHasPrimaryDTOSingletonListJson(hasPrimaryDTOListJson) {
		try {
			return JSON.parse(hasPrimaryDTOListJson)[0].content;
		} catch (err) {
			return null;
		}
	},
	doNothing() {
		return null;
	},
	download({ filename, extension, base64 }) {
		const linkSource = `data:${extension === "pdf" ? "application" : "text"}/${extension};base64,${base64}`;
		const downloadLink = document.createElement("a");
		downloadLink.href = linkSource;
		downloadLink.download = `${filename}.${extension}`;
		downloadLink.click();
	},
	isToday(date) {
		return date === moment().format("YYYY-MM-DD");
	}
};

export default CommonFunctions;
