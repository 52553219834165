import React, { Component } from "react";
import "./Logo.scss";

class Logo extends Component {
	state = {};
	render() {
		return (
			<svg className="fade-up" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 204 42" style={{ width: this.props.width }}>
				<g className={this.props.sideNavLogo ? "sidenav-logo" : ""} style={{ opacity: "0.9983" }}>
					<path
						className="three"
						d="M26.7,28.7c0,6.7-5.4,12-13.6,12c-4.7,0-9.4-2.2-12.1-4.6L3.1,32c2.4,1.9,6.4,3.7,9.5,3.7
					c4.9,0,8.4-2.9,8.4-6.8c0-4-4-6.9-8.9-6.9c-2,0-3.8,0.4-4.9,0.8L5.8,21L16.5,7H2.5V1.8h22.7v2.7L15.6,17
					C22.1,18.1,26.7,22.9,26.7,28.7z"
					/>
					<path
						className="skye"
						d="M59,40.8c-6.3,0-13.1-3-13.1-11.8h5.5c0,5.1,4.6,6.7,7.6,6.7c3.6,0,6.7-1.7,6.7-5.5c0-7.9-19.3-5.8-19.3-18.6
					c0-6,5.3-10.4,11.6-10.4c6.3,0,12.5,3.4,12.5,11h-5.5c0-4.3-4-5.9-7-5.9c-3.6,0-6.2,2.1-6.2,4.9c0,7.2,19.3,6.3,19.3,18.6
					C71.2,36.8,65.5,40.8,59,40.8z"
					/>
					<path className="skye" d="M95.9,40.2h-5.5V1.8h5.5v17.9L111,1.8h6.9l-15.9,18.8l16.5,19.6h-6.9L95.9,21.5V40.2z" />
					<path className="skye" d="M131.2,1.8h6.3l8.9,16.3l8.9-16.3h6.3l-12.5,22.5v15.9h-5.5V24.3L131.2,1.8z" />
					<path className="skye" d="M178.8,1.8H203V7h-18.7v9.7h13.2v5.2h-13.2V35H203v5.2h-24.2V1.8z" />
				</g>
			</svg>
		);
	}
}

export default Logo;
