import React, {useState, useEffect} from "react";
import moment from "moment";
import GridTable from "../../../../core-components/layouts/GridTable";
import GridTableRow from "../../../../core-components/layouts/GridTableRow";
import Token from "../../../../core-components/misc/Token";
import PdfIcon from "../../../../core-components/svgs/PdfIcon";
import "./ThirdPartyAccessDocuments.scss";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";

const ThirdPartyAccessDocuments = ({documents, filters}) => {

	const [documentsToDisplay, setDocumentsToDisplay] = useState([])

	useEffect( () => {
			updateDocumentsToDisplay();
	}, [documents, filters])

	const download = (file) => {
		const a = document.createElement("a");
			a.href = file.url;
			a.download = file.filename;
			document.body.appendChild(a);
			a.targetEntity = "_top"
			a.click();
	}

	const updateDocumentsToDisplay = () => {
		if (documents) {
			const { account, search, endYear } = filters || {};
			let updatedDocumentsToDisplay = documents;
			if (search) {
				updatedDocumentsToDisplay = updatedDocumentsToDisplay.filter((doc) => doc && doc.filename && doc.filename.toLowerCase().includes(search.toLowerCase()));
			}
			if (account){
				updatedDocumentsToDisplay = updatedDocumentsToDisplay.filter((doc) => doc && doc.entityId && doc.entityId === account);
			}
			if (endYear) {
				updatedDocumentsToDisplay = updatedDocumentsToDisplay.filter((doc) => {
					if (doc && doc.filename) {
						const date = moment(`${doc.filename.substr(-12, 4)}-${doc.filename.substr(-8, 2)}-${doc.filename.substr(-6, 2)}`);
						if (date) {
							return moment(date).isSame(endYear, 'day') ;
						}
					}
					return false;
				});
			}
			setDocumentsToDisplay(updatedDocumentsToDisplay);
		}
	}


	return (
		<GridTable id="tpa-documents-table" headers={["Date", "Account", "File Name", ""]} columnWidths="150px 1fr 1fr 30px">
			{documentsToDisplay.map((document, index) => {
				return (
					<GridTableRow
						style={{cursor: "pointer"}}
						onClick={() => download(document)}
						key={index}
						columns={[moment(document.uploadedAt).format("Do MMM YYYY"), <Token text={document.entityName} />, document.filename, <PdfIcon />]}
					/>
				);
			})}
		</GridTable>
   );
}

export default withFilters(ThirdPartyAccessDocuments);