import React, { Component } from "react";

export const HOBSON_THEME = "theme-hobson";
export const THREESKYE_THEME = "theme-3skye";
export const THREESKYE_THEME_LIGHT = "theme-light";

export const Theme = React.createContext({
	theme: THREESKYE_THEME_LIGHT
});

export function withTheme(WrappedComponent) {
	return class extends Component {
		render() {
			return <Theme.Consumer>{({ theme }) => <WrappedComponent {...{ theme }} {...this.props} />}</Theme.Consumer>;
		}
	};
}
