import React, { useEffect, useState } from "react";
import { withAccountInfo } from "../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import { Col, Row } from "reactstrap";
import { handleNumberChange } from "../../../PreAccountLogin/OnboardingUtils"
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer"
import _ from "lodash";


const AssetAllocationInfo = (props) => {
    const { onBoardingState } = props
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        getTotals()
    }, [onBoardingState])

    function getValue(val) {
        if (val && val.length > 0) {
            const tempVal = val.split(',').join('')
            const newVal = tempVal.split('$').join('')
            return +newVal
        }
    }

    function getTotals() {
        const kiwisaverValue = onBoardingState.kiwisaverValue ? getValue(onBoardingState.kiwisaverValue) : null
        const propertiesValue = onBoardingState.propertiesOwned && onBoardingState.propertiesOwned.length > 0 ? onBoardingState.propertiesOwned.reduce((a, c) => a + (c.propertyValue ? getValue(c.propertyValue) : 0), 0) : null
        const businessesValue = onBoardingState.businesses && onBoardingState.businesses.length > 0 ? onBoardingState.businesses.reduce((a, c) => a + (c.estimatedValuation ? getValue(c.estimatedValuation) : 0), 0) : null
        const equitiesValue = onBoardingState.shares && onBoardingState.shares.length > 0 ? onBoardingState.shares.reduce((a, c) => a + (c.units && c.costPerUnit ? getValue(c.units) * getValue(c.costPerUnit) : 0), 0) : null
        const totalValue = (!kiwisaverValue ? 0 : kiwisaverValue) + (!propertiesValue ? 0 : propertiesValue) + (!businessesValue ? 0 : businessesValue) + (!equitiesValue ? 0 : equitiesValue)

        const data = [
            { name: 'Kiwisaver', value: kiwisaverValue, percentage: totalValue ? (kiwisaverValue * 100) / totalValue : null },
            { name: 'Property', value: propertiesValue, percentage: totalValue ? (propertiesValue * 100) / totalValue : null },
            { name: 'Businesses', value: businessesValue, percentage: totalValue ? (businessesValue * 100) / totalValue : null },
            { name: 'Equities', value: equitiesValue, percentage: totalValue ? (equitiesValue * 100) / totalValue : null },
            { name: 'Total', value: totalValue === 0 ? null : totalValue, className: 'total-values' }
        ]
        setTableData(data)
    }

    return (
        <div key='asset-allocation-info'>
            <CardContainer>
                <Card>
                    <CardHeader>
                        <h3>Asset Allocation</h3>
                    </CardHeader>
                    <Row>
                        <Col xs='12' xl='6' className='pl-xl-3' style={{ width: '100%' }}>
                            <table className="asset-allocation-summary-table">
                                <tbody>
                                    {tableData && tableData.length > 0 && tableData.map((asset, index) => {
                                        const percentage = asset.percentage === null ? '-' : asset.percentage === undefined ? '' : Math.round(asset.percentage * 100) / 100 + '%'
                                        const value = asset.value ? handleNumberChange(asset.value.toString(), 'currency') : '-'
                                        return (
                                            <tr className='asset-allocation-tr' key={asset.name + '-' + index}>
                                                <td className={`asset-allocation-table-cell ${asset.className ? asset.className : ""}`}>{asset.name}</td>
                                                <td className={`asset-allocation-table-cell justify-self-right ${asset.className ? asset.className : ""}`}>{value}</td>
                                                <td className={`asset-allocation-table-cell justify-self-right ${asset.className ? asset.className : ""}`}>{percentage}</td>
                                                {asset.percentage !== undefined ?
                                                    <td className={`asset-allocation-table-cell justify-self-right ${asset.className ? asset.className : ""}`}>
                                                        <div className='percentage-bar-container'>
                                                            <div className='percentage-bar-progress' style={{ width: percentage === '-' ? 0 : percentage }}></div>
                                                        </div>
                                                    </td> : <td className='asset-allocation-table-cell'>&nbsp;</td>
                                                }
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <VerticalSpacer size='40px' />
                </Card>
            </CardContainer>
        </div >
    )
}

export default withThreeSkyeGlobal(withAccountInfo(AssetAllocationInfo))