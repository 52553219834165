import React, { Component } from "react";
import { Col } from "reactstrap";
import { SortParamsWrapper } from "@threeskye/core-components/dist/components/Sorting/WithSortParams";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import WithNavigatorFunds, { TYPES } from "@threeskye/core-components/dist/components/NavigatorFundLookthrough/WithNavigatorFunds";
import NavigatorAssetCard from "../NavigatorAssetCard";
import Loading from "../../../../../core-components/layouts/Loading";
import withFilters from "../../../../../core-components/filters-and-toggles/withFilters";
import { GenericConnectionFailErrorMessage } from "../../../../../core-components/layouts/ErrorMessage";

const NavigatorAssets = withThreeSkyeGlobal(
	WithNavigatorFunds(
		class extends Component {
			getCSVFuncList = [];

			constructor(props) {
				super(props);
				props.setGetCSVFuncList(this.getCSVFuncList);
			}

			render() {
				const { navigatorFunds, navigatorFundsLoading, navigatorFundsHasError } = this.props;
				if (navigatorFundsLoading) {
					return (
						<Col xs="12">
							<Loading centered size={80} />
						</Col>
					);
				}
				if (navigatorFundsHasError) {
					return (
						<Col xs="12">
							<GenericConnectionFailErrorMessage />
						</Col>
					);
				}
				return (
					<SortParamsWrapper>
						{navigatorFunds && navigatorFunds.map((fund, index) => <NavigatorAssetCard key={index} fund={fund} setGetCSVFunc={(func) => (this.getCSVFuncList[index] = func)} />)}
					</SortParamsWrapper>
				);
			}
		}
	)
);

class NavigatorAssetsWrapper extends Component {
	render() {
		const { account, filters, ...otherProps } = this.props;
		return <NavigatorAssets {...otherProps} accountId={account.id} date={filters && filters.date} type={TYPES.PORTAL} />;
	}
}

export default withFilters(withAccountInfo(NavigatorAssetsWrapper));
