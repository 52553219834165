
export const ModalTypes = {
	TestModal: {
		type: "TestModal"
	},
	ViewNotificationModal: {
		type: "ViewNotificationModal"
	},
	ReviewConfirmModal: {
		type: "ReviewConfirmModal"
	},
	LinkRemoteModal: {
		type: "LinkRemoteModal"
	},
	SupportingDocumentationModal: {
		type: "SupportingDocumentationModal"
	},
	ChangesSubmittedModal: {
		type: "ChangesSubmittedModal"
	},
	UploadModal: {
		type: "UploadModal"
	},
	GenericMessageModal: {
		type: "GenericMessageModal"
	}
};