import React, { Component } from 'react';
import "./GridTable.scss";
import GridTableHeaders from './GridTableHeaders';

class GridTable extends Component {
	render() {
		const { columnWidths, headers, children, className, id } = this.props;
		return (
			<div id={id} className={`grid-table${className ? " " + className : ""}`}>
				<GridTableHeaders headers={headers} columnWidths={columnWidths} />
				{React.Children.map(children, (child) => {
					return React.cloneElement(child, { columnWidths }, null);
				})}
			</div>
		);
	}
}

export default GridTable;