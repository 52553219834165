import React, { Component } from "react";
import "./ErrorMessage.scss";
import { Error, CloudOff, Info } from "@material-ui/icons";

class ErrorMessage extends Component {
	render() {
		const { children, colour, errorIcon, noDataIcon, infoIcon } = this.props;
		return (
			<div className={`error-message${errorIcon || noDataIcon ? " with-icon" : ""}`} style={{ textAlign: "center" }}>
				{errorIcon && <Error />}
				{noDataIcon && <CloudOff />}
				{infoIcon && <Info />}
				<h4 style={{ color: colour }}>{children}</h4>
			</div>
		);
	}
}

export class GenericConnectionFailErrorMessage extends Component {
	render() {
		return <ErrorMessage errorIcon>We’re having trouble getting this information right now. Please try again later</ErrorMessage>;
	}
}

export default ErrorMessage;
