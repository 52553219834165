import React, { Component, Suspense } from "react";
import Card from "../../../../core-components/card/Card";
import ErrorMessage from "../../../../core-components/layouts/ErrorMessage";
import CardHeader from "../../../../core-components/card/CardHeader";
import CardContainer from "../../../../core-components/card/CardContainer";
import TimeFrameSelector from "../../../../core-components/inputs/TimeFrameSelector";
import withRouteChange from "@threeskye/route-change";
import withValuations from "../../../../core-components/valuations/withValuations";
import ThreeSkyeLineChart from "@threeskye/core-components/dist/components/Charts/ThreeSkyeLineChart";
import Loading from "../../../../core-components/layouts/Loading";

class Returns extends Component {
	render() {
		const { loadingValuations, valuation, selectedTimeFrameIndex, setSelectedTimeFrameIndex, timeFrames, hasValuations, chartProps } = this.props;
		return (
			<CardContainer xs={12} xl={6}>
				<Card>
					<CardHeader>
						<h3>Valuations</h3>
						{!loadingValuations && hasValuations && <TimeFrameSelector timeFrames={timeFrames} selectedTimeFrameIndex={selectedTimeFrameIndex} setSelectedTimeFrameIndex={setSelectedTimeFrameIndex} />}
					</CardHeader>
					{loadingValuations ? (
						<Loading centered size={80} />
					) : hasValuations ? (
						<Suspense fallback={<Loading />} size={30}>
							<ThreeSkyeLineChart chartData={valuation} {...chartProps} />
						</Suspense>
					) : (
						<ErrorMessage infoIcon>This account has no returns.</ErrorMessage>
					)}
				</Card>
			</CardContainer>
		);
	}
}

export default withRouteChange(withValuations(Returns));
