import React, { Component } from 'react';
import "./GridTableHeaders.scss";

class GridTableHeaders extends Component {
	render() {
		const { columnWidths, headers } = this.props;
		return (
			<div className="grid-table-headers" style={{gridTemplateColumns: columnWidths}}>
				{headers && headers.map((header, idx) => (
					<div key={idx} className={`grid-table-header column-${idx+1}`}>
						{header}
					</div>
				))}
			</div>
		);
	}
}
 
export default GridTableHeaders;