import React, { Component } from "react";
import CardContainer from "../../../../core-components/card/CardContainer";
import Card from "../../../../core-components/card/Card";
import CardHeader from "../../../../core-components/card/CardHeader";
import { Row, Col } from "reactstrap";
import Button from "../../../../core-components/inputs/Button";
import VerticalSpacer from "../../../../core-components/layouts/VerticalSpacer";
import Input from "../../../../core-components/inputs/Input";
import { withThreeSkyeGlobal } from "@threeskye/global";
import "./ChangeYourPassword.scss";
import Alert from "../../../../core-components/layouts/Alert";
import { getErrorMessage } from "../../../../core-components/functions/PasswordValidator";
import FormErrorMessage from "../../../../core-components/layouts/FormErrorMessage";
import { withIsReadOnly } from "../../../../core-components/functions/withIsReadOnly";

class ChangeYourPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			current: "",
			replacement: "",
			confirmReplacement: "",
			errorMessage: "",
			isValid: false,
		};
		this.userIsFinished = this.userIsFinished.bind(this);
		this.setErrorMessage = this.setErrorMessage.bind(this);
		this.setIsValid = this.setIsValid.bind(this);
		this.update = this.update.bind(this);
	}

	userIsFinished() {
		const { current, replacement, confirmReplacement } = this.state;
		return [current, replacement, confirmReplacement].reduce((userIsFinished, pw) => userIsFinished && pw.length > 0, true);
	}

	setErrorMessage(justReplacement) {
		const { replacement, confirmReplacement } = this.state;
		let pw1 = replacement;
		let pw2 = justReplacement ? replacement : confirmReplacement;
		const errorMessage = getErrorMessage(pw1, pw2);
		this.setState({ errorMessage });
	}

	setIsValid() {
		const isValid = getErrorMessage(this.state.replacement, this.state.confirmReplacement) === "" && this.userIsFinished();
		this.setState({ isValid });
	}

	update() {
		this.setState({ loading: true }, () => {
			const { current, replacement } = this.state;
			const data = { current, replacement };
			this.props.remote.post("/users/current-user/update-password", data).then((updateResponse) => {
				let newState = { loading: false };
				if (updateResponse.success) {
					newState = { ...newState, updateResponse, current: "", replacement: "", confirmReplacement: "", errorMessage: "" };
				} else {
					newState.errorMessage = updateResponse.message;
				}
				this.setState(newState);
			});
		});
	}

	render() {
		const { isReadOnly } = this.props;
		const { current, replacement, confirmReplacement, errorMessage, updateResponse, isValid, loading } = this.state;
		const disabled = isReadOnly();
		const alertIsOpen = !!updateResponse;
		return (
			<CardContainer className="portal-change-your-password">
				<Alert isOpen={alertIsOpen} close={() => this.setState({ updateResponse: null })}>
					{updateResponse && updateResponse.message}
				</Alert>
				<Card>
					<CardHeader>
						<div>
							<h3>Edit Password</h3>
							<p className="filter-heading fs-6 mb-0 mt-2">CLICK UPDATE TO SAVE CHANGES</p>
							{/* <div style={{ textTransform: "uppercase", color: "#58595B80" }}>Click Update to save changes</div> */}
						</div>
					</CardHeader>
					<div id="change-your-password">
						<Row>
							<Col xs="12" xl="4" className="pr-xl-3">
								<Input
									label="Current password"
									name="current-password"
									type="password"
									placeholder="Password..."
									value={current}
									onChange={(current) => this.setState({ current, errorMessage: "" }, this.setIsValid)}
									onBlur={() => this.setState({ errorMessage: current === "" ? "Please enter your current password" : "" })}
									disabled={disabled}
								/>
							</Col>
							<Col xs="12" xl="4" className="px-xl-3">
								<Input
									label="New password"
									name="new-password"
									type="password"
									placeholder="Password..."
									value={replacement}
									onBlur={() => this.setState({ errorMessage: getErrorMessage(replacement, replacement) })}
									onChange={(replacement) => this.setState({ replacement, errorMessage: "" }, this.setIsValid)}
									disabled={disabled}
								/>
							</Col>
							<Col xs="12" xl="4" className="pl-xl-3">
								<Input
									label="Confirm new password"
									name="confirm-new-password"
									type="password"
									placeholder="Password..."
									value={confirmReplacement}
									onBlur={() => this.setState({ errorMessage: getErrorMessage(replacement, confirmReplacement) })}
									onChange={(confirmReplacement) => this.setState({ confirmReplacement, errorMessage: "" }, this.setIsValid)}
									disabled={disabled}
								/>
							</Col>
						</Row>
						{errorMessage && <div id="errorMsg">
							<FormErrorMessage right id="abc">
								{errorMessage}
							</FormErrorMessage>
						</div>}
						<VerticalSpacer size="20px" />
						<Row className="justify-content-end">
							<Col xs="auto" className="justify-self-end">
								<Button loading={loading} onClick={this.update} disabled={disabled || !isValid}>
									UPDATE
								</Button>
							</Col>
						</Row>
					</div>
				</Card>
			</CardContainer>
		);
	}
}

export default withIsReadOnly(withThreeSkyeGlobal(ChangeYourPassword));
