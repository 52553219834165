import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { FiltersWrapper } from "../../core-components/filters-and-toggles/withFilters";
import { MobileMenuWrapper } from "../../core-components/contexts/MobileMenuContext";
import { withRemote } from "@threeskye/global";
import { handleNumberChange, PRELOGIN_LINK_ITEMS } from './OnboardingUtils'
import withRouteChange from "@threeskye/route-change";
import CommonFunctions from "../../core-components/functions/CommonFunctions";
import PreAccountSideNav from "./PreAccountSideNav";
import Desktop from "../../core-components/layouts/Desktop";
import Loading from "../../core-components/layouts/Loading";
import PreloginStep from './PreloginStep'
import "../CustomerPortalPage/CustomerPortalPage.scss";
import "../CustomerPortalPage/ChartStyleOverrides.scss";


const PreAccountLogin = (props) => {
    const { location, finishedPreLogin, user, onBoardingState, setOnBoardingState } = props
    const [parsedData, setParsedData] = useState(null)
    const [preLoginRoutes, setPreLoginRoutes] = useState(null)
    const { remote } = props;

    useEffect(() => {
        remote.get('/modules/koura/portal/onboarding/form-builder/Portal').then((fetchedSteps) => {
            console.log("fetchedSteps", fetchedSteps)
            const routes = PRELOGIN_LINK_ITEMS(fetchedSteps)
            console.log("routes", routes)
            setPreLoginRoutes(routes)
        })

        remote.get("/modules/koura/portal/onboarding").then(signup => {
            const data = signup.signupData
            const parsed = JSON.parse(data)
            console.log("Got existing data", parsed)
            setOnBoardingState(parsed)
            setParsedData(parsed)
        })
    }, [])


    const commitAndsetOnBoardingState = (data) => {
        remote.put("/modules/koura/portal/onboarding", data, { rawResponse: true });
        setOnBoardingState(data);
    }

    function renderRouteComponent(sections, stepIndex) {
        return (
            <FiltersWrapper>
                <div id="component-wrapper-container" className="overflow-y-auto">
                    <PreloginStep
                        onBoardingState={onBoardingState}
                        parsedData={parsedData}
                        setOnBoardingState={commitAndsetOnBoardingState}
                        user={user}
                        handleNumberChange={handleNumberChange}
                        finishedPreLogin={finishedPreLogin}
                        sections={sections}
                        stepIndex={stepIndex}
                        preLoginRoutes={preLoginRoutes}
                    />
                </div>
            </FiltersWrapper >
        );
    }


    if (!parsedData || !preLoginRoutes) return (
        <Loading centered size={80} />
    )

    // hard coded default to Welcome page if no path match
    return (
        <MobileMenuWrapper>
            <div id="customer-portal-page" className={`fade-in ${CommonFunctions.responsiveClassname()}`}>
                <Desktop>
                    <PreAccountSideNav location={location} preLoginRoutes={preLoginRoutes} />
                </Desktop>
                <Switch>
                    {preLoginRoutes.map((link, idx) => {
                        return <Route key={`link-${idx}`} path={link.path} exact render={() => renderRouteComponent(link.sections, idx)} />
                    })}
                    {/* return <Route key={`link-default`} path={"/"} render={(props) => renderRouteComponent()} /> */}
                </Switch>
            </div>
        </MobileMenuWrapper>
    );
}

export default withRemote(withRouteChange(PreAccountLogin));