import React from 'react';
import { withThreeSkyeGlobal } from '@threeskye/global';
import Loading from '../../core-components/layouts/Loading';
import { withRouter } from 'react-router-dom';
import withRouteChange from '@threeskye/route-change';

class AdviserAuth extends React.PureComponent {

	state = {
		unauthorised: false
	}

	componentDidMount() {
		const { auth, client } = this.props.match.params;

		const url = "/direct/" + client + "/modules/hwp/portal/view/" + auth;

		this.props.remote.get(url)
			.then(response=>{
				if (response.success) {
					this.props.storage.put("adviser.override", true);
					this.props.login();
					this.props.changeRoute("/");
				} else {
					this.setState({unauthorised: true});
				}
			});
	}

	render() {
		return this.state.unauthorised ? "You are not authorised to do that" : <Loading size={70} />
	}
}

export default withRouter(withThreeSkyeGlobal(withRouteChange(AdviserAuth)));