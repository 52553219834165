import React, { Component } from "react";
import withRouteChange from "@threeskye/route-change";
import BannerAccountPageFilters from "./BannerAccountPageFilters";
import "../../Banner.scss";
import { MobileBanner } from "../../DefaultMobileBanner";
import Formatter from "../../../../core-components/functions/Formatter";
import NotificationBell from "../../../../core-components/misc/NotificationBell";
import { withAccountInfo, displayPortalAccType } from "../../../../core-components/contexts/AccountsContext";
import BannerInfoText, { BannerInfoMainText, BannerInfoHighlightText } from "../../../../core-components/misc/BannerInfoText";
import Desktop from "../../../../core-components/layouts/Desktop";
import Mobile from "../../../../core-components/layouts/Mobile";
import withFilters from "../../../../core-components/filters-and-toggles/withFilters";
import CommonFunctions from "../../../../core-components/functions/CommonFunctions";
import { isNullOrUndefinedOrNaN } from "@threeskye/core-components/dist/components/Functions/isNullOrUndefined";
import Loading from "../../../../core-components/layouts/Loading";
import ToolTip from '../../../../core-components/layouts/ToolTip';
import { Info } from "@material-ui/icons";


class PortfolioBanner extends Component {
	state = { portfolioValuation: null, hasDataFetchError: false };

	constructor(props) {
		super(props);
		this.updateValuation = this.updateValuation.bind(this);
	}

	componentDidMount() {
		this.updateValuation();
	}

	componentDidUpdate(prevProps) {
		if (
			(prevProps.filters && this.props.filters && prevProps.filters.date !== this.props.filters.date) ||
			prevProps.account.id !== this.props.account.id ||
			prevProps.accPortfolioMap !== this.props.accPortfolioMap ||
			prevProps.accPortfolioIsLoading !== this.props.accPortfolioIsLoading
		) {
			this.updateValuation();
		}
	}

	updateValuation() {
		const { filters, account, accPortfolioMap, accPortfolioIsLoading } = this.props;
		if (!filters || CommonFunctions.isToday(filters.date)) {
			if (accPortfolioIsLoading[account.id]) {
				this.setState({ portfolioValuation: null, hasDataFetchError: true });
			} else if (!accPortfolioMap[account.id]) {
				this.setState({ portfolioValuation: null, hasDataFetchError: true });
			} else {
				this.setState({ portfolioValuation: accPortfolioMap[account.id].valuation, hasDataFetchError: false });
			}
		} else {
			this.props.storage.getOrFetch(`/modules/crm/accounts/${account.id}/portfolio/${filters.date}`).then((portfolioRemoteReliant) => {
				if (!portfolioRemoteReliant || portfolioRemoteReliant.connectionError || !portfolioRemoteReliant.data) {
					this.setState({ portfolioValuation: null, hasDataFetchError: true });
				} else {
					this.setState({ portfolioValuation: portfolioRemoteReliant.data.valuation, hasDataFetchError: false });
				}
			});
		}
	}

	render() {
		const { portfolioValuation, hasDataFetchError } = this.state;
		const { account, user, portalAccountType, filters, onExport, accPortfolioIsLoading } = this.props;
		const dateText = filters && !CommonFunctions.isToday(filters.date) && `ON ${Formatter.fromIsoDateToDisplay(filters.date).toUpperCase()}`;
		return (
			<>
				<Mobile>
					<MobileBanner>
						<h6 className="sub-header-small">PORTFOLIO VALUATION {dateText}</h6>
						{accPortfolioIsLoading[account.id] ? (
							<Loading size={30} />
						) : hasDataFetchError || isNullOrUndefinedOrNaN(portfolioValuation) ? (
							<h6 className="info-banner-valuation">Could not get valuation. Please try again later</h6>
						) : (
							<h3 className="info-banner-valuation">{Formatter.asMoney(portfolioValuation)}</h3>
						)}
					</MobileBanner>
				</Mobile>
				<Desktop>
					<div id="portal-banner" className="desktop">
						<div className="container portal-banner-top-container">
							<div id="portal-banner-left">
								<h2>Hi {user.preferredName || user.givenName}</h2>
							</div>
							<div id="portal-banner-right">
								<div className="notifications-wrapper">
									<NotificationBell />
								</div>
								<div className="banner-valuation">
									<div>
										<h6>Portfolio Valuation</h6>
									</div>
									{accPortfolioIsLoading[account.id] ? (
										<Loading size={40} />
									) : hasDataFetchError || isNullOrUndefinedOrNaN(portfolioValuation) ? (
										<h4>
											Could not get valuation
											<br />
											Please try again later
										</h4>
									) : (
										<>
											<h3>{Formatter.asMoney(portfolioValuation)}</h3>
											{dateText && (
												<div>
													<h6>{dateText}</h6>
												</div>
											)}
										</>
									)}
								</div>
							</div>
						</div>
						<BannerInfoText>
							<BannerInfoMainText>{account.name}</BannerInfoMainText>
							<BannerInfoHighlightText>
								<div>
									{displayPortalAccType(portalAccountType)}
								</div>
							</BannerInfoHighlightText>

							{account && account.dynamicFields && account.dynamicFields.CustodyType && account.dynamicFields.CustodyType === "Safe-keeping" &&
								<BannerInfoHighlightText warning>

									<ToolTip
										className="safe-custody-tooltip"
										title="This a view of your Portfolio held with Hobson Wealth Custodian Limited"
										style={{ top: "120%" }}
										bottom
										warning
									>
										<div className="safe-custody-container" >
											<Info className="safe-custody-icon" />
											International Custody
										</div>
									</ToolTip>

								</BannerInfoHighlightText>
							}
						</BannerInfoText>
						<BannerAccountPageFilters onExport={onExport} />
					</div>
				</Desktop>
			</>
		);
	}
}

export default withAccountInfo(withRouteChange(withFilters(PortfolioBanner)));
