import React from "react";
import RangeBar from '../../../../core-components/graphs/RangeBar'

const ProposePortfolioTableRow = (props) => {
    const { min, max, target, assetClassName, group } = props

    return (
        <tr className="proposed-portfolio-table-row">
            <td className={group? "" : "asset-group-title-row"}>{assetClassName}</td>
            <td className="">{target}%</td>
            <td>{`${min} - ${max}%`}</td>
            <td><RangeBar
                extent={100}
                value={target}
                min={min}
                max={max}
                colour1={"rgb(13 142 253 / 60%)"}
                colour2={"#0E8EFD"}
                colour3={"#F4F4F4"}
                noToolTip={true}
                centreLogoOnPoint={true}
            /></td>
        </tr>
    )
}

export default ProposePortfolioTableRow