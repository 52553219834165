import React, { Component } from "react";
import Button from "../../../core-components/inputs/Button";
import Input from "../../../core-components/inputs/Input";
import VerticalSpacer from "../../../core-components/layouts/VerticalSpacer";
import { getErrorMessage } from "../../../core-components/functions/PasswordValidator";
import FormErrorMessage from "../../../core-components/layouts/FormErrorMessage";
import withRouteChange from "@threeskye/route-change";

class SetPasswordPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: "",
			password2: "",
			errorMessage: "",
			isValid: false,
		};
		this.setPassword = this.setPassword.bind(this);
		this.setErrorMessage = this.setErrorMessage.bind(this);
		this.userIsFinished = this.userIsFinished.bind(this);
		this.setIsValid = this.setIsValid.bind(this);
	}

	userIsFinished() {
		const { password, password2 } = this.state;
		return [password, password2].reduce((userIsFinished, pw) => userIsFinished && pw.length > 0, true);
	}

	setPassword() {
		this.setState({ loading: true });
		const { username, remote, respKey, code, onSetPassword, source } = this.props;
		const { password } = this.state;
		const data = { email: username, code, key: respKey, password, source: source };
		remote.post("/um/reset-password", data).then(
			resp => {
				if (resp.success) {
					onSetPassword(password);
				} else {
					this.setState({ errorMessage: resp.message, loading: false });
				}
			},
			_ => {
				this.setState({ errorMessage: "Could not change password", loading: false });
			},
		);
	}

	setIsValid() {
		const isValid = getErrorMessage(this.state.password, this.state.password2) === "";
		this.setState({ isValid });
	}

	setErrorMessage() {
		const errorMessage = getErrorMessage(this.state.password, this.state.password2);
		if (this.userIsFinished()) this.setState({ errorMessage });
	}

	render() {
		const { loading, password, password2, errorMessage, isValid } = this.state;
		return (
			<div id="set-password-page">
				<h3 className="colour-white">Please set your password below.</h3>
				<Input
					onPrimary
					type="password"
					name="password"
					label="Password"
					value={password}
					onChange={password => this.setState({ password, errorMessage: "" }, this.setIsValid)}
					onBlur={this.setErrorMessage}
					placeholder="Password"
				/>
				<VerticalSpacer size="14px" />
				<Input
					onPrimary
					type="password"
					name="password2"
					label="retype password"
					value={password2}
					onChange={password2 => this.setState({ password2, errorMessage: "" }, this.setIsValid)}
					onBlur={this.setErrorMessage}
					placeholder="Retype Password"
				/>
				<VerticalSpacer size="14px" />
				<FormErrorMessage right>{errorMessage}</FormErrorMessage>
				<VerticalSpacer size="8px" />
				<Button primaryHighlight loading={loading} disabled={!isValid} onClick={this.setPassword}>
					Set Password
				</Button>
			</div>
		);
	}
}

export default withRouteChange(SetPasswordPage);
