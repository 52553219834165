import React, { Component } from "react";

export const PortalAccTypes = {
	NAVIGATOR: "NAVIGATOR",
	PORTFOLIO: "PORTFOLIO",
};

export const getPortalAccType = (account) => {
	if (account && account.remoteSystem) {
		switch (account.remoteSystem) {
			case "MMC":
				return PortalAccTypes.NAVIGATOR;
			case "Aegis":
			case "NZXWT":
				return PortalAccTypes.PORTFOLIO;
			default:
				break;
		}
	}
};

export const displayPortalAccType = (accType) => {
	switch (accType) {
		case PortalAccTypes.NAVIGATOR:
			return "Navigator";
		case PortalAccTypes.PORTFOLIO:
			return "Portfolio";
		default:
			break;
	}
};

export const AccountInfo = React.createContext({
	accounts: [],
	account: {},
	switchAccount: () => { },
	portalAccountType: PortalAccTypes.NAVIGATOR,
	user: {},
	logout: () => { },
	accPortfolioMap: {},
	accPortfolioIsLoading: {},
});

export function withAccountInfo(WrappedComponent) {
	return class extends Component {
		render() {
			return (
				<AccountInfo.Consumer>
					{({ accounts, account, switchAccount, portalAccountType, user, logout, accPortfolioMap, accPortfolioIsLoading, allAccounts, userDetails }) => (
						<WrappedComponent {...{ accounts, account, switchAccount, portalAccountType, user, logout, accPortfolioMap, accPortfolioIsLoading, allAccounts, userDetails }} {...this.props} />
					)}
				</AccountInfo.Consumer>
			);
		}
	};
}
