import React, { Component } from "react";
import { fields } from "@threeskye/core-components/dist/components/Portfolio/Fields";
import withSortedCashAssets, { getTotalPendingTransactionValue } from "@threeskye/core-components/dist/components/Portfolio/CashFundTable/withSortedCashAssets";
import Token from "../../../../../core-components/misc/Token";
import Formatter from "../../../../../core-components/functions/Formatter";
import CardContainer from "../../../../../core-components/card/CardContainer";
import Card from "../../../../../core-components/card/Card";
import CardHeader from "../../../../../core-components/card/CardHeader";
import ErrorMessage, { GenericConnectionFailErrorMessage } from "../../../../../core-components/layouts/ErrorMessage";
import ExpandedRow from "../../../../../core-components/layouts/ExpandedRow";
import "./CashFundTable.scss";
import CashAssetPendingTransactions from "./CashAssetPendingTransactions";
import HorizontalSpacer from "../../../../../core-components/layouts/HorizontalSpacer";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import { withThreeSkyeGlobal } from "@threeskye/global";
import SortByHeader from "../../../../../core-components/tables/SortByHeader";
import CommonFunctions from "../../../../../core-components/functions/CommonFunctions";
import Loading from "../../../../../core-components/layouts/Loading";
import LoadingMessage from "../../../../../core-components/layouts/LoadingMessage";
import NumberFormat from "./NumberFormat"

export const headers = {
	[fields.asset]: "Asset",
	[fields.tickerCode]: "Ticker",
	[fields.localValue]: "Current Local Value",
	[fields.nzdValue]: "Current NZD Value",
	[fields.pendingTransactions]: "Pending Transactions",
	[fields.estimatedValue]: "Available Cash",
	[fields.percentOfPortfolio]: "% of Holdings",
};

class Header extends Component {
	render() {
		const { field, className } = this.props;
		return <SortByHeader className={className} field={field}>{headers[field]}</SortByHeader>;
	}
}

class CashFundTable extends Component {
	state = { isOpen: {} };

	constructor(props) {
		super(props);
		this.expandExisingTransactions = this.expandExisingTransactions.bind(this);
		this.toggleExpandFund = this.toggleExpandFund.bind(this);
		this.getCSV = this.getCSV.bind(this);
		this.state = { width: 0, height: 0 };
  		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.props.setGetCSVFunc(this.getCSV);
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	  }

	componentDidUpdate(prevProps) {
		if (this.props.cashAsset !== prevProps.cashAsset || prevProps.accountId !== this.props.accountId) {
			this.props.setGetCSVFunc(this.getCSV);
		}
		if (this.props.pendingCashTransactions !== prevProps.pendingCashTransactions) {
			this.expandExisingTransactions();
		}
	}

	expandExisingTransactions() {
		const { pendingCashTransactions } = this.props;
		if (pendingCashTransactions) {
			const isOpen = Object.keys(pendingCashTransactions).reduce((isOpen, identifier) => ({ ...isOpen, [identifier]: !!pendingCashTransactions[identifier] }), {});
			this.setState({ isOpen });
		}
	}

	toggleExpandFund(identifier) {
		this.setState((prevState) => ({
			isOpen: { ...prevState.isOpen, [identifier]: !prevState.isOpen[identifier] },
		}));
	}


	getNZDValueTotal(assetClass) {
		let total = assetClass.reduce((accumulator, { value }) => accumulator + value, 0)

		function roundToTwo(num) {
			return +(Math.round(num + "e+2") + "e-2");
		}
		let rounded = roundToTwo(total)
		return rounded
	}

	getPercentPortfolioTotal(assetClass) {
		let total = assetClass.reduce((accumulator, { percentPortfolio }) => accumulator + percentPortfolio, 0)

		function roundToTwo(num) {
			return +(Math.round(num + "e+2") + "e-2");
		}
		let rounded = roundToTwo(total)
		return rounded
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	  }

	getCSV() {
		const { sortedHoldings, sortedHoldingsLoading, pendingCashLoading, pendingCashError, pendingCashTransactions } = this.props;
		let table = [[]];
		if (!sortedHoldingsLoading && sortedHoldings) {
			table = [[fields.asset, fields.tickerCode, fields.pendingTransactions, fields.estimatedValue, fields.localValue, fields.nzdValue, fields.percentOfPortfolio].map((field) => headers[field])].concat(
				// table = [[fields.asset, fields.tickerCode, fields.localValue, fields.nzdValue, fields.pendingTransactions, fields.estimatedValue, fields.percentOfPortfolio].map((field) => headers[field])].concat(
				sortedHoldings.map(({ tickerCode, ric, nativeCurrency, name, nativeValue, value, percentPortfolio, cash, rawValue }) => {
					const identifier = tickerCode || ric || nativeCurrency;
					const totalPendingTransactionValue = getTotalPendingTransactionValue(!pendingCashLoading && !pendingCashError && pendingCashTransactions, identifier);
					return [
						name,
						identifier,
						cash && Formatter.numberWithCommas(totalPendingTransactionValue || 0, 2),
						cash && Formatter.numberWithCommas(rawValue + (totalPendingTransactionValue || 0), 2),
						Formatter.numberWithCommas(nativeValue, 2),
						Formatter.numberWithCommas(value, 2),
						Formatter.asPercentage(percentPortfolio),
					].map((v) => `"${v || ""}"`);
				})
			);
		}
		return `Cash\n${table.map((row) => row.join(",")).join("\n")}`;
	}

	render() {
		const { pendingCashTransactions, pendingCashError, pendingCashLoading, sortedHoldingsLoading, sortedHoldings } = this.props;
		const { isOpen } = this.state;
		const { asClassName } = CommonFunctions;
		const width = this.state.width;

		return (
			<CardContainer xs="12">
				<Card>
					<a className="in-page-anchor" name={`class-Cash`}></a>
					<CardHeader>
						<h3>Cash</h3>
					</CardHeader>
					<div className="cash-table-container">
						{sortedHoldingsLoading ? (
							<LoadingMessage message="Loading cash assets" />
						) : sortedHoldings && sortedHoldings.length > 0 ? (
							<table>
								<thead>
									<tr>
										<th>
											<Header field={fields.asset} />
										</th>
										<th>
											<Header field="" />
										</th>
										<th className="header-right d-lg-table-cell">
											<Header field={fields.pendingTransactions} className="justify-flex-end" />
										</th>
										<th className="header-right d-lg-table-cell">
											<Header field={fields.estimatedValue} className="justify-flex-end" />
										</th>
										<th id="cash-local-value" className="d-none header-right d-md-table-cell">
											<Header field={fields.localValue} className="justify-flex-end" />
										</th>
										<th id="cash-current-nzd-value" className="header-right d-none d-sm-table-cell">
											<Header field={fields.nzdValue} className="justify-flex-end" />
										</th>
										<th id="cash-holdings" className="header-right d-none d-sm-table-cell">
											<Header field={fields.percentOfPortfolio} className="justify-flex-end" />
										</th>
										{/* <th className="expand-icon-col" /> */}
									</tr>
								</thead>
								<tbody>
									{sortedHoldings.map(({ tickerCode, ric, nativeCurrency, name, nativeValue, value, percentPortfolio, cash, rawValue }, index) => {
										const shouldShowTransactions = name.includes("Call Account") || name.includes("Pending Transaction")
										const identifier = tickerCode || ric || nativeCurrency;
										const isCashAsset = name.includes("Call Account");  // pending transactions should only show for Call accounts, not assets like Nikko.
										const totalPendingTransactionValue = getTotalPendingTransactionValue(!pendingCashLoading && !pendingCashError && pendingCashTransactions, nativeCurrency);
										return (
											<React.Fragment key={index}>
												<tr onClick={() => this.toggleExpandFund(identifier)} className={asClassName(["expandable-row", isOpen[identifier] && "expanded-row-active"])}>
													<td>
														<Token small>{identifier}</Token>
														<HorizontalSpacer size="8px" />
													</td>
													<td>
														{name}
													</td>
													<td className="text-right">{cash && Formatter.numberWithCommas(totalPendingTransactionValue || 0, 2)}</td>
													<td className="text-right">{cash && Formatter.numberWithCommas(rawValue + (totalPendingTransactionValue || 0), 2)}</td>
													<td className="text-right d-none d-md-table-cell">{Formatter.numberWithCommas(nativeValue, 2)}</td>
													<td className="text-right d-none d-sm-table-cell">{Formatter.numberWithCommas(value, 2)}</td>
													<td className="text-right d-none d-sm-table-cell"><NumberFormat places={2} value={percentPortfolio} />%</td>
												</tr>
												<ExpandedRow colSpan={(width <= 575) ? "4" : (width <= 768) ? "6" : "7"} showRow={isOpen[identifier]}>
													{pendingCashLoading ? (
														<div className="p-3">
															<Loading centered size={40} />
														</div>
													) : !pendingCashError && pendingCashTransactions && isCashAsset && pendingCashTransactions[nativeCurrency] && shouldShowTransactions ? (
														<CashAssetPendingTransactions pendingCashTransactions={pendingCashTransactions[nativeCurrency]} />
													) : pendingCashError ? (
														<GenericConnectionFailErrorMessage />
													) : (
														<ErrorMessage infoIcon>No pending cash transactions to display</ErrorMessage>
													)}
												</ExpandedRow>
											</React.Fragment>
										);
									})}
									<tr className="">
										<td>TOTALS</td>
										<td></td>
										<td className="d-sm-table-cell text-right"></td>
										<td className="d-sm-table-cell text-right"></td>
										<td className="d-none d-md-table-cell text-right"></td>
										<td className="d-none d-sm-table-cell text-right"><NumberFormat places={2} value={this.getNZDValueTotal(sortedHoldings)} /></td>
										<td className="d-none d-sm-table-cell text-right"><NumberFormat places={2} value={this.getPercentPortfolioTotal(sortedHoldings)} />%</td>
									</tr>
								</tbody>
							</table>
						) : (
							<ErrorMessage infoIcon>This account has no cash holdings.</ErrorMessage>
						)}
					</div>
				</Card>
			</CardContainer>
		);
	}
}

export default withAccountInfo(withThreeSkyeGlobal(withSortedCashAssets(CashFundTable)));
