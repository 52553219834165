import React, { Component } from "react";
import ErrorMessage from "../../../../../core-components/layouts/ErrorMessage";
import MonthBarChart from "./MonthBarChart";
import YearBarChart from "./YearBarChart";
import { MONTH_YEAR_TIME_FRAMES } from "../../../../../core-components/functions/ChartFunctions";
import { withAccountInfo } from "../../../../../core-components/contexts/AccountsContext";
import Loading from "../../../../../core-components/layouts/Loading";

class MonthYearBarChart extends Component {
	Charts = { [MONTH_YEAR_TIME_FRAMES.MONTHS]: MonthBarChart, [MONTH_YEAR_TIME_FRAMES.YEARS]: YearBarChart };

	state = { selectedTimeFrame: null };

	componentDidMount() {
		this.props.updateDataByTimeFrame(() => this.setState({ selectedTimeFrame: this.props.selectedTimeFrame }));
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedTimeFrame !== this.props.selectedTimeFrame || prevProps.watchedObj !== this.props.watchedObj) {
			//ENSURES DATA and TIME FRAME stay synced
			this.props.updateDataByTimeFrame(() => this.setState({ selectedTimeFrame: this.props.selectedTimeFrame }));
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		//ENSURES DATA and TIME FRAME stay synced
		return nextState.selectedTimeFrame !== this.state.selectedTimeFrame || nextProps.selectedTimeFrame !== this.props.selectedTimeFrame || nextProps.watchedObj !== this.props.watchedObj;
	}

	render() {
		const { selectedTimeFrame } = this.state;
		const { data } = this.props;
		if (!data || data.length <= 0) {
			return <ErrorMessage infoIcon>Could not create a chart for this account...</ErrorMessage>;
		}
		if (!selectedTimeFrame) {
			return <Loading centered size={70} />;
		}
		const Chart = this.Charts[selectedTimeFrame];
		return <Chart data={data} />;
	}
}

export default withAccountInfo(MonthYearBarChart);
