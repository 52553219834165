import React from 'react';
import DetailsLinkage from './DetailsLinkage';
import "./RepeatableLinkage.scss";
import { Info } from '@material-ui/icons';
import SingleItem from "@threeskye/core-components/dist/components/ClientAccountDetails/Fields/SingleItem/SingleItem"

/*
 * Takes a single child and repeats for the given data
*/
class RepeatableLinkage extends React.Component {

	componentDidUpdate(prevProps) {
		if (prevProps.data.length !== this.props.data.length) {
			this.forceUpdate();
		}
	}

	render() {
		const { data, update, label, value, keyBase, addPending, instancePending, blockLabel,
			onChange, onAdd, fieldTypeLabel, onDelete, inDelete, idPrefix, maxRepeats, editable, revertKey, ...otherProps } = this.props;

		const repeatableOnChange = (data, update, index, val) => {
			delete data[index].isAdd;
			onChange(data, update, index, val);
		}

		var isAdding = false;

		return <>
			{blockLabel && blockLabel.length > 0 && <label>{blockLabel}</label>}
			{
			// data.length > 0? 
				data.map((datum, index) => {
					const isAdd = data[index].isAdd;
					const fieldValue = value(data, update, index);
					if (isAdd) {
						isAdding = true;
					}
					return <DetailsLinkage
						omitLabel={isAdd}
						instantAdd={isAdd}
						editable={editable}
						deletable
						label={label(data, update, index)? label(data, update, index) : fieldTypeLabel}
						value={fieldValue}
						key={keyBase + fieldValue.content.id}
						displayId={idPrefix + (index + 1)}
						pending={instancePending(fieldValue.content.id)}
						onChange={val => repeatableOnChange(data, update, index, val)}
						onDelete={() => onDelete(data, update, index)}
						cancelDeletes={isAdd}
						revertKey={revertKey}
						{...otherProps}
					/>
				})
		}
			{/* {
			(!maxRepeats || data.length < maxRepeats) && !isAdding && 
			<div className="add-field-button" onClick={onAdd}>{addPending && <Info />} Add {fieldTypeLabel}</div>
			} */}
		</>

	}
}

export default RepeatableLinkage;